import React from 'react';
import { Bar } from 'react-chartjs-2';


const Chart = ({
    labelX,
    valueOne,
    titleValueOne,
    valueTwo,
    valueFour,
    titleValueTwo,
    valueThree,
    titleValueThree,
    titleValueFour,
    valueFive,
    titleValueFive,
    titleValueSix,
    valueSix,
    titleValueSeven,
    valueSeven,
    titleValueEight,
    valueEight,
    titleValueNine,
    valueNine,
    titleValueTen,
    valueTen,
    titleValueEleven,
    valueEleven,
}) => {
    const data = {
        labels: labelX,
        datasets: [
            {
                label: `${titleValueOne}`,
                data: valueOne,
                backgroundColor: 'rgb(255, 99, 132)',
            },
            {
                label: `${titleValueTwo}`,
                data: valueTwo,
                backgroundColor: 'rgb(54, 162, 235)',
            },
            {
                label: `${titleValueThree}`,
                data: valueThree,
                backgroundColor: 'rgb(75, 192, 192)',
            },
            {
                label: `${titleValueFour}`,
                data: valueFour,
                backgroundColor: 'blue',
            },
            {
                label: `${titleValueFive}`,
                data: valueFive,
                backgroundColor: 'pink',
            },
            {
                label: `${titleValueSix}`,
                data: valueSix,
                backgroundColor: 'green',
            },
            {
                label: `${titleValueSeven}`,
                data: valueSeven,
                backgroundColor: 'grey',
            },
            {
                label: `${titleValueEight}`,
                data: valueEight,
                backgroundColor: 'aqua',
            },
            {
                label: `${titleValueNine}`,
                data: valueNine,
                backgroundColor: 'purple',
            },
            {
                label: `${titleValueTen}`,
                data: valueTen,
                backgroundColor: 'red',
            },
            {
                label: `${titleValueEleven}`,
                data: valueEleven,
                backgroundColor: 'black',
            },
        ],
    };

    return (<Bar data={data} />);
}

export default Chart;