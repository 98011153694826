import React from 'react';
import DataTable from 'react-data-table-component';
import Loader from 'react-loader-spinner';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import ReactModal from 'react-modal';
import Select from "react-select";
import swal from 'sweetalert';
import { Modal } from 'react-bootstrap';
import { Styles, ActionButton, Spinner, Chart } from '../../components';
import Button from '@mui/material/Button';

const Pegawai = ({
    getPegawai,
    pegawai,
    addPegawai,
    pegawaiMessage,
    deletePegawai,
    uploadPegawai,
    role
}) => {
    const { id, nama } = useParams();
    const history = useHistory();
    const { register, handleSubmit, control } = useForm();
    const [inputTahun, setInputTahun] = React.useState('');
    const [tahun, setTahun] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [labelChart, setLabelChart] = React.useState([]);
    const [chartWna, setChartWna] = React.useState([]);
    const [chartWni, setChartWni] = React.useState([]);
    const [chartTetap, setChartTetap] = React.useState([]);
    const [chartPria, setChartPria] = React.useState([]);
    const [chartWanita, setChartWanita] = React.useState([]);
    const [chartSmp, setChartSmp] = React.useState([]);
    const [chartSma, setChartSma] = React.useState([]);
    const [chartDiploma, setChartDiploma] = React.useState([]);
    const [chartStrata1, setChartStrata1] = React.useState([]);
    const [chartStrata2, setChartStrata2] = React.useState([]);
    const [chartTidakTetap, setChartTidakTetap] = React.useState([]);
    const [fileUpload, setFileUpload] = React.useState(null);
    const [showModalAddPegawai, setShowModalAddPegawai] = React.useState(false);

    const setupShowModalAddPegawai = () => setShowModalAddPegawai(!showModalAddPegawai);
    React.useEffect(() => {
        pegawai && pegawai.pegawai && setLabelChart(pegawai.pegawai.map(p => p.tahun));
        pegawai && pegawai.pegawai && setChartWna(pegawai.pegawai.map(p => p.wna));
        pegawai && pegawai.pegawai && setChartWni(pegawai.pegawai.map(p => p.wni));
        pegawai && pegawai.pegawai && setChartTetap(pegawai.pegawai.map(p => p.tetap));
        pegawai && pegawai.pegawai && setChartPria(pegawai.pegawai.map(p => p.pria));
        pegawai && pegawai.pegawai && setChartWanita(pegawai.pegawai.map(p => p.wanita));
        pegawai && pegawai.pegawai && setChartSmp(pegawai.pegawai.map(p => p.smp));
        pegawai && pegawai.pegawai && setChartSma(pegawai.pegawai.map(p => p.sma));
        pegawai && pegawai.pegawai && setChartDiploma(pegawai.pegawai.map(p => p.diploma));
        pegawai && pegawai.pegawai && setChartStrata1(pegawai.pegawai.map(p => p.strata1));
        pegawai && pegawai.pegawai && setChartStrata2(pegawai.pegawai.map(p => p.strata2));
        pegawai && pegawai.pegawai && setChartStrata2(pegawai.pegawai.map(p => p.strata2));
        pegawai && pegawai.pegawai && setChartTidakTetap(pegawai.pegawai.map(p => p.tidak_tetap));
    }, [pegawai, setLabelChart]);

    // console.log(chartWni);

    React.useEffect(() => {
        setTahun(Tahun().map(t => ({ value: t, label: t })));
    }, []);

    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none'
            },
        },
    };
    const columns = [
        {
            name: 'No.',
            selector: (_, index) => index + 1,
        },
        {
            name: 'Karyawan Tetap',
            selector: row => row.tetap,
        },
        {
            name: 'Karyawan Tidak tetap',
            selector: row => row.tidak_tetap,
        },
        {
            name: 'WNI',
            selector: row => row.wni
        },
        {
            name: 'WNA',
            selector: row => row.wna
        },
        {
            name: 'Tahun',
            selector: row => row.tahun
        },
        {
            name: 'Action',
            selector: row => row.action
        },
    ];
    const columnsGuest = [
        {
            name: 'Karyawan Tetap',
            selector: row => row.tetap
        },
        {
            name: 'Karyawan Tidak tetap',
            selector: row => row.tidak_tetap
        },
        {
            name: 'WNI',
            selector: row => row.wni
        },
        {
            name: 'WNA',
            selector: row => row.wna
        },
        {
            name: 'Tahun',
            selector: row => row.tahun
        }
    ];

    React.useEffect(() => {
        pegawaiMessage.loading && pegawaiMessage.pegawaiMessage && swal(`${pegawaiMessage.pegawaiMessage.message}`, {
            buttons: ["cancel", true]
        }).then((value) => {
            if (value) {
                getPegawai(id);
            }
        });
    }, [getPegawai, id, pegawaiMessage]);

    React.useEffect(() => {
        getPegawai(id);
    }, [getPegawai, id]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            pegawai && pegawai.pegawai &&
                setRows(
                    pegawai.pegawai.map(p => ({
                        tetap: p.tetap,
                        tidak_tetap: p.tidak_tetap,
                        wni: p.wni,
                        wna: p.wna,
                        tahun: p.tahun,
                        action: <>
                            <ActionButton
                                title="fa fa-pencil"
                                css="btn-warning"
                                fun={() => history.push(`/penyelenggara-detail-edit/${id}/${nama}/edit/${p.id}/pegawai`)} />
                            &nbsp;
                            <ActionButton title="fa fa-trash" css="btn-danger" fun={() => swal(`Apakah Yakin Data Karyawan ${p.company_name} ini akan dihapus?`, {
                                buttons: ["Batal", true],
                            }).then((value) => {
                                if (value) {
                                    deletePegawai(p.id);
                                }
                            })
                            } />
                        </>
                    }))
                );
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [deletePegawai, history, id, nama, pegawai]);

    const submitPegawai = async (data) => {
        const dataInputPegawai = {
            data_umum_id: data.data_umum_id,
            company_name: data.company_name,
            tetap: data.tetap,
            tidak_tetap: data.tidak_tetap,
            pria: data.pria,
            wanita: data.wanita,
            smp: data.smp,
            sma: data.sma,
            diploma: data.diploma,
            strata1: data.strata1,
            strata2: data.strata2,
            wni: data.wni,
            wna: data.wna,
            tahun: inputTahun,
        };
        addPegawai(dataInputPegawai);
    }

    const onUpload = (e) => setFileUpload(e.target.files[0]);

    const submitUploadPegawai = (data) => {
        const formData = new FormData();
        formData.append("nama_file", fileUpload);
        uploadPegawai(formData)
    };

    const customStylesModal = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: '0',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '700px',
            height: '90%'
        },
    };

    return pegawai.loading ? <Spinner /> : pegawai.error === "Unauthenticated." ? (
        window.location.href = "/") : (
        <div className="card">
            <h4 className="card-title text-bold" style={{ margin: 10, fontWeight: 'bold' }}>PEGAWAI {nama}</h4>
            {
                role && role.role_id !== 3 &&
                <div className="toolbar" style={{ display: 'flex', flexDirection: 'row-reverse', margin: '20px' }}>
                    <Button
                        id="basic-button"
                        aria-controls="basic-menu"
                        className="btn btn-sm"
                        size="large"
                        aria-haspopup="true"
                        onClick={setupShowModalAddPegawai}
                        style={{ backgroundColor: 'rgba(0, 50, 122, 1)', color: '#fff', marginRight: '10px' }}
                    >
                        <i className="fa fa-plus" aria-hidden="true"></i> &nbsp; Tambah
                    </Button>
                </div>
            }
            <div className="card-content">
                <div className="material-datatables">
                    <DataTable
                        columns={role && role.role_id !== 3 ? columns : columnsGuest}
                        data={rows}
                        progressPending={loading}
                        progressComponent={<Loader type="Watch" color="#00BFFF" height={100} width={100} />}
                        responsive={true}
                        customStyles={customStyles}
                        pagination />
                </div>
                <Chart
                    title="Kurva Data Pegawai"
                    labelX={labelChart}
                    valueOne={chartWna}
                    valueTwo={chartWni}
                    valueThree={chartTetap}
                    valueFour={chartPria}
                    valueFive={chartWanita}
                    valueSix={chartSmp}
                    valueSeven={chartSma}
                    valueEight={chartDiploma}
                    valueNine={chartStrata1}
                    valueTen={chartStrata2}
                    valueEleven={chartTidakTetap}
                    titleValueOne="Jumlah Pegawai WNA"
                    titleValueTwo="Jumlah Pegawai WNI"
                    titleValueThree="Jumlah Pegawai Tetap"
                    titleValueFour="Jumlah Pegawai Pria"
                    titleValueFive="Jumlah Pegawai Wanita"
                    titleValueSix="Jumlah Lulusan SMP"
                    titleValueSeven="Jumlah Lulusan SMA"
                    titleValueEight="Jumlah Lulusan Diploma"
                    titleValueNine="Jumlah Lulusan Strata 1"
                    titleValueTen="Jumlah Lulusan Strata 2"
                    titleValueEleven="Jumlah Pegawai Tidak Tetap"
                />
            </div>
            <ReactModal
                ariaHideApp={false}
                isOpen={showModalAddPegawai}
                preventScroll={true}
                scrollable={true}
                style={customStylesModal}
            >
                <Modal.Title style={{ fontWeight: 'bold' }}>Tambah Data Pegawai Baru</Modal.Title>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitUploadPegawai)}
                >
                    <h5 className="text-center">Import Data Pegawai {nama}</h5>
                    <input
                        placeholder="Nama Perusahaan"
                        type="file"
                        onChangeCapture={onUpload}
                        name="nama_file"
                    />
                    {
                        pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.nama_file}</span>
                    }
                    {
                        pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.message}</span>
                    }
                    <Link to="/download/pegawai.xlsx" target="_blank" style={{ float: 'right' }}>Download Template Upload Pegawai</Link><br />
                    <Button type="submit" variant="contained" color="primary">Upload</Button>
                </form>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitPegawai)}
                >
                    <input
                        type="hidden"
                        value={id}
                        {...register("data_umum_id")}
                    />
                    <input
                        type="hidden"
                        value={nama}
                        {...register("company_name")}
                    />
                    <Styles.InputForm>
                        <input
                            placeholder="Jumlah Karyawan Tetap"
                            type="text"
                            className="form-control"
                            {...register("tetap")}
                        />
                        {pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.tetap}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Jumlah Karyawan Tidak Tetap"
                            type="text"
                            className="form-control"
                            {...register("tidak_tetap")}
                        />
                        {pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.tidak_tetap}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Jumlah Karyawan Pria"
                            type="text"
                            className="form-control"
                            {...register("pria")}
                        />
                        {pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.pria}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Jumlah Karyawan Wanita"
                            type="text"
                            className="form-control"
                            {...register("wanita")}
                        />
                        {pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.wanita}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Jumlah Karyawan Lulusan SMP"
                            type="text"
                            className="form-control"
                            {...register("smp")}
                        />
                        {pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.smp}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Jumlah Karyawan Lulusan SMA"
                            type="text"
                            className="form-control"
                            {...register("sma")}
                        />
                        {pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.sma}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Jumlah Karyawan Lulusan Diploma"
                            type="text"
                            className="form-control"
                            {...register("diploma")}
                        />
                        {pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.diploma}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Jumlah Karyawan Lulusan Strata 1"
                            type="text"
                            className="form-control"
                            {...register("strata1")}
                        />
                        {pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.strata1}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Jumlah Karyawan Lulusan Strata 2"
                            type="text"
                            className="form-control"
                            {...register("strata2")}
                        />
                        {pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.strata2}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Jumlah Karyawan Warga Negara Indonesia"
                            type="text"
                            className="form-control"
                            {...register("wni")}
                        />
                        {pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.wni}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Jumlah Karyawan Warga negara Asing"
                            type="text"
                            className="form-control"
                            {...register("wna")}
                        />
                        {pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.wna}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="Tahun"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={tahun}
                                    placeholder="Tahun.."
                                    onChange={(e) => setInputTahun(e.value)}
                                />
                            )}
                        />
                        {pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.tahun}</span>}
                    </Styles.InputForm>
                    <Button variant="contained" color="error" onClick={setupShowModalAddPegawai} style={{ marginRight: 5 }}>Close</Button>
                    <Button type="submit" variant="contained" color="primary">Save</Button>
                </form>
            </ReactModal>
        </div>
    )
}

export default Pegawai

const Tahun = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= 1945; i--) {
        tahun.push(i);
    }
    return tahun;
}