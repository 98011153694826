import React from 'react';
import JaberselPenyellenggara from './JaberselPenyelenggara'
import { Spinner } from '../../components';
import DataTable from 'react-data-table-component';
import Loader from 'react-loader-spinner';

const ListJabersel = ({
    user,
    jaberselPelanggan,
    getUser,
    getAllJaberselPelanggan,
}) => {
    const [rows, setRows] = React.useState([]);
    // const [filterText, setFilterText] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    React.useEffect(() => {
        getAllJaberselPelanggan();
    }, [getAllJaberselPelanggan]);
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            jaberselPelanggan && jaberselPelanggan.jaberselPelanggan &&
                setRows(jaberselPelanggan.jaberselPelanggan.map(jabersel => ({
                    company_name: jabersel.company_name,
                    prabayar: jabersel.prabayar,
                    pascabayar: jabersel.pascabayar,
                    total_pelanggan_jasa_telepon: jabersel.total_pelanggan_jasa_telepon,
                    populasi: jabersel.populasi,
                    teledensitas: jabersel.teledensitas,
                    pelanggan_data: jabersel.pelanggan_data,
                    tahun: jabersel.tahun
                })));

            setLoading(false)
        }, 2000);
        return () => clearTimeout(timeout);
    }, [jaberselPelanggan]);
    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { minimumFractionDigits: 0 }
        ).format(money);
    }
    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none'
            },
        },
    };
    const columns = [
        {
            name: 'Nama Perusahaan',
            selector: 'company_name'
        },
        {
            name: 'Prabayar',
            selector: r => formatRupiah(r.prabayar)
        },
        {
            name: 'pascabayar',
            selector: r => formatRupiah(r.pascabayar),
        },
        {
            name: 'Total Pelanggan',
            selector: r => formatRupiah(r.total_pelanggan_jasa_telepon),
        },
        {
            name: 'Populasi',
            selector: r => formatRupiah(r.populasi),
        },
        {
            name: 'Teledensitas',
            selector: r => formatRupiah(r.teledensitas)
        },
        {
            name: 'Pelalanggan Data',
            selector: r => formatRupiah(r.pelanggan_data)
        },
        {
            name: 'Tahun',
            selector: r => r.tahun
        },
    ];
    return loading ? <Spinner /> : jaberselPelanggan.error === "Unauthenticated." ? (
        window.location.href = "/") : (
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header card-header-icon" data-background-color="blue">
                        <i className="material-icons">send</i>
                    </div>
                    <h3 className="title" style={{ fontWeight: 'bold', top: 0 }}>Jabersel</h3>
                    <div className="card-content">
                        <h3 className="text-center">Jabersel Pelanggan</h3>
                        <div className="material-datatables">
                            <DataTable
                                columns={columns}
                                data={rows}
                                progressPending={loading}
                                customStyles={customStyles}
                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100}
                                    width={100} />}
                                responsive={true}
                                pagination />
                        </div>
                    </div>
                    <JaberselPenyellenggara />
                </div>
            </div>
        </div>
    )
}

export default ListJabersel
