import JaberselPelanggan from './JaberselPelanggan';
import { connect } from 'react-redux';
import { getJaberselPelangganByCompany, addJaberselPel, deleteJaberselPel, uploadJaberselPelanggan } from '../../redux';

const mapStateToProps = state => {
    return {
        jaberselPelanggan: state.jaberselPelanggan,
        jaberselPelangganMessage: state.jaberselPelangganMessage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        uploadJaberselPelanggan: (data) => dispatch(uploadJaberselPelanggan(data)),
        getJaberselPelangganByCompany: (id) => dispatch(getJaberselPelangganByCompany(id)),
        addJaberselPel: (data) => dispatch(addJaberselPel(data)),
        deleteJaberselPel: (id) => dispatch(deleteJaberselPel(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JaberselPelanggan)