import {
    USERS_REQ,
    USERS_SUCCESS,
    USERS_FAIL,
    USERS_MESSAGE_REQ,
    USERS_MESSAGE_SUCCESS,
    USERS_MESSAGE_FAIL,
} from './usersTypes';

const userState = {
    loading: false,
    users: null,
    error: ''
}
const userMessageState = {
    loading: false,
    usersMessage: '',
    error: ''
}

export const usersMessageReducer = (state = userMessageState, action) => {
    switch (action.type) {
        case USERS_MESSAGE_REQ:
            return {
                ...state,
                loading: false
            }
        case USERS_MESSAGE_SUCCESS:
            return {
                loading: true,
                usersMessage: action.payload,
                error: ''
            }
        case USERS_MESSAGE_FAIL:
            return {
                loading: true,
                usersMessage: '',
                error: action.payload
            }
        default:
            return state
    }
}

export const usersReducer = (state = userState, action) => {
    switch (action.type) {
        case USERS_REQ:
            return {
                ...state,
                loading: true,
                users: null,
                error: ''
            }
        case USERS_SUCCESS:
            return {
                loading: false,
                users: action.payload,
                error: ''
            }
        case USERS_FAIL:
            return {
                loading: false,
                users: null,
                error: action.payload
            }
        default:
            return state
    }
}

export default usersReducer