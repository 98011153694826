import React from 'react';
import DataTable from 'react-data-table-component';
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import { getAllJaberselPenyelenggara } from '../../redux';

const JaberselPenyelenggara = ({
    jaberselPenyelenggara,
    getAllJaberselPenyelenggara
}) => {
    const [rowPenyelenggara, setRowPenyelenggara] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        getAllJaberselPenyelenggara();
    }, [getAllJaberselPenyelenggara]);
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            jaberselPenyelenggara.loading &&
                setRowPenyelenggara(jaberselPenyelenggara.jaberselPenyelenggara.map(jabersel => ({
                    company_name: jabersel.company_name,
                    kapasitas_terpasang: jabersel.kapasitas_terpasang,
                    vtv_out: jabersel.vtv_out,
                    vtv_in: jabersel.vtv_in,
                    vts_in: jabersel.vts_in,
                    vts_out: jabersel.vts_out,
                    vtd: jabersel.vtd,
                    arpu_prabayar: jabersel.arpu_prabayar,
                    arpu_pascabayar: jabersel.arpu_pascabayar,
                    arpu_blended: jabersel.arpu_blended,
                    tahun: jabersel.tahun
                })));
            setLoading(false)
        }, 2000);
        return () => clearTimeout(timeout);
    }, [jaberselPenyelenggara]);
    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { minimumFractionDigits: 0 }
        ).format(money);
    }
    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none'
            },
        },
    };
    const columns = [
        {
            name: 'Nama Perusahaan',
            selector: row => row.company_name
        },
        {
            name: 'Kapasitas Terpasang',
            selector: r => formatRupiah(r.kapasitas_terpasang)
        },
        {
            name: 'Voice Outgoing',
            selector: r => formatRupiah(r.vtv_out),
        },
        {
            name: 'Voice Incoming',
            selector: r => formatRupiah(r.vtv_in),
        },
        {
            name: 'Sms Outgoing',
            selector: r => formatRupiah(r.vts_in),
        },
        {
            name: 'Sms Incoming',
            selector: r => formatRupiah(r.vts_out),
        },
        {
            name: 'Traffic Data',
            selector: r => formatRupiah(r.vtd),
        },
        {
            name: 'ARPU PRABAYAR',
            selector: r => formatRupiah(r.arpu_prabayar),
        },
        {
            name: 'ARPU PASCA BAYAR',
            selector: r => formatRupiah(r.arpu_pascabayar),
        },
        {
            name: 'ARPU BLENDED',
            selector: r => formatRupiah(r.arpu_blended),
        },
        {
            name: 'Tahun',
            selector: r => r.tahun
        },
    ];
    return (
        <>

            <div className="card-content">
                <div className="material-datatables">
                    <h3 className="text-center">Jabersel Penyelenggara</h3>
                    <DataTable
                        columns={columns}
                        data={rowPenyelenggara}
                        progressPending={loading}
                        progressComponent={<Loader type="Watch" color="#00BFFF" height={100}
                            width={100} />}
                        responsive={true}
                        customStyles={customStyles}
                        pagination />
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        jaberselPenyelenggara: state.jaberselPenyelenggara,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAllJaberselPenyelenggara: () => dispatch(getAllJaberselPenyelenggara()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JaberselPenyelenggara)
