import Users from './Users';
import { connect } from 'react-redux';
import { getUsers, deleteUser, activateUser, getUser } from '../../redux';

const mapStateToProps = state => {
    return {
        user: state.user,
        users: state.users,
        usersMessage: state.usersMessage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUser: () => dispatch(getUser()),
        getUsers: () => dispatch(getUsers()),
        deleteUser: (id) => dispatch(deleteUser(id)),
        activateUser: (id) => dispatch(activateUser(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);