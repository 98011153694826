import Navbar from './Navbar';
import { logout } from '../../redux';
import { connect } from 'react-redux';

const mapDispatchToProps = dispatch => {
    return {
        toLogout: () => dispatch(logout())
    }

}
export default connect(null, mapDispatchToProps)(Navbar);