import React from 'react';
import {
    Container,
    Button
} from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import Select from "react-select";
import Loader from 'react-loader-spinner';
import { useForm, Controller } from 'react-hook-form';

import { Sidebar, Navbar, Styles } from '../../components';
import swal from 'sweetalert';

const EditJabersel = ({
    user,
    getUser,
    jaberselPelangganDetail,
    getJaberSelDetailById,
    updateJaberselPel,
    jaberselPelangganMessage,
    getJaberselPenyelelenggaraDetailById,
    jaberselPenyelenggaraDetail,
    jaberselPenyelenggaraMessage,
    updateJaberselPenyelenggara,
    jabertrunkMessage,
    jabertrunkDetail,
    getJabertrunkDetailById,
    updateJabertrunk,
    getjartapsliDetailById,
    jartapsliDetail,
    jartapsliMessage,
    updatejartapsli,
    getjartapsljjDetailById,
    jartapsljjDetail,
    jartapsljjMessage,
    updatejartapsljj,
    getjartaplokDetailById,
    jartaplokDetail,
    jartaplokMessage,
    updatejartaplok,
    jartaptupDetail,
    getjartaptupPelangganDetailById,
    jartaptupMessage,
    getJartaptupTech,
    jartaptupTech,
    updatejartaptupPelanggan,
    getjartaptupJaringanDetailById,
    getjartaplokpsDetailById,
    jartaplokpsDetail,
    jartaplokpsMessage,
    updatejartapokps,
    updatejartaptupJaringan,
    getJartaptupNetwork
}) => {
    const history = useHistory();
    const { register, handleSubmit, control } = useForm();
    const { id, nama, dataId, menu } = useParams();
    const [load, setLoad] = React.useState(true);
    const [userData, setUserData] = React.useState(null);
    const [inputTahun, setInputTahun] = React.useState('');
    const [tahun, setTahun] = React.useState([]);
    React.useEffect(() => {
        setTahun(Tahun().map(t => ({ value: t, label: t })));
    }, []);

    // state Jabersel Pelanggan
    const [jaberselPelangganValue, setJaberselPelangganValue] = React.useState(null);

    // state Jabersel Penyelenggara
    const [jaberselPenyelenggaraValue, setJaberselPenyelenggaraValue] = React.useState(null);

    // state Jabertrunk
    const [jabertrunkValue, setJabertrunkValue] = React.useState(null);

    // state Jartap Sli
    const [jartapsliValue, setJartapsliValue] = React.useState(null);

    // state Jartap Sljj
    const [jartapsljjValue, setJartapsljjValue] = React.useState(null);

    // state Jartaplok
    const [jartaplokValue, setJartaplokValue] = React.useState(null);

    //  state jartaptup pelanggan
    const [jartaptupPelangganValue, setJartaptupPelangganValue] = React.useState(null);

    const [jartaptupTechOption, setJartaptupTechOption] = React.useState([]);

    //  state jartaptup jaringan
    const [jartaptupJaringanValue, setJartaptupJaringanValue] = React.useState(null);
    const [jartaptupTechName, setJartaptupTechName] = React.useState([]);
    const [kapasitasValue, setKapasitasValue] = React.useState('');
    const [tahunInputJaringan, setTahunInputJaringan] = React.useState('');
    const [itemKapasitasValue, setItemKapasitasValue] = React.useState('')

    console.log(kapasitasValue, tahunInputJaringan, itemKapasitasValue, setJartaptupTechName, jartaptupJaringanValue);


    const [jartaptupTechInput, setJartaptupTechInput] = React.useState('');
    // state jartplok os
    const [jartaplokpsValue, setJartaplokpsValue] = React.useState(null);


    // notifikasi
    React.useEffect(() => {
        const notification = () => {
            if (menu === "jabersel pelanggan") {
                jaberselPelangganMessage.loading && jaberselPelangganMessage.jaberselPelangganMessage && swal(`${jaberselPelangganMessage.jaberselPelangganMessage.message}`, {
                    buttons: ["cancel", true]
                }).then((value) => {
                    if (value) {
                        window.location.href = `/penyelenggara-detail/${id}/${nama}`;
                    }
                });
            } else if (menu === "jabersel penyelenggara") {
                jaberselPenyelenggaraMessage.loading && jaberselPenyelenggaraMessage.jaberselPenyelenggaraMessage && swal(`${jaberselPenyelenggaraMessage.jaberselPenyelenggaraMessage.message}`, {
                    buttons: ["cancel", true]
                }).then((value) => {
                    if (value) {
                        window.location.href = `/penyelenggara-detail/${id}/${nama}`;
                    }
                });
            } else if (menu === "jabertrunk") {
                jabertrunkMessage.loading && jabertrunkMessage.jabertrunkMessage && swal(`${jabertrunkMessage.jabertrunkMessage.message}`, {
                    buttons: true
                }).then((value) => {
                    if (value) {
                        window.location.href = `/penyelenggara-detail/${id}/${nama}`;
                    }
                });
            } else if (menu === "jartap sli") {
                jartapsliMessage.loading && jartapsliMessage.jartapsliMessage && swal(`${jartapsliMessage.jartapsliMessage.message}`, {
                    buttons: true
                }).then((value) => {
                    if (value) {
                        window.location.href = `/penyelenggara-detail/${id}/${nama}`;
                    }
                });
            } else if (menu === "jartap sljj") {
                jartapsljjMessage.loading && jartapsljjMessage.jartapsljjMessage && swal(`${jartapsljjMessage.jartapsljjMessage.message}`, {
                    buttons: true
                }).then((value) => {
                    if (value) {
                        window.location.href = `/penyelenggara-detail/${id}/${nama}`;
                    }
                });
            } else if (menu === "jartaplok") {
                jartaplokMessage.loading && jartaplokMessage.jartaplokMessage && swal(`${jartaplokMessage.jartaplokMessage.message}`, {
                    buttons: true
                }).then((value) => {
                    if (value) {
                        window.location.href = `/penyelenggara-detail/${id}/${nama}`;
                    }
                });
            } else if (menu === "jartaptup pelanggan") {
                jartaptupMessage.loading && jartaptupMessage.jartaptupMessage && swal(`${jartaptupMessage.jartaptupMessage.message}`, {
                    buttons: true
                }).then((value) => {
                    if (value) {
                        window.location.href = `/penyelenggara-detail/${id}/${nama}`;
                    }
                });
            } else if (menu === "jartaptup jaringan") {
                jartaptupMessage.loading && jartaptupMessage.jartaptupMessage && swal(`${jartaptupMessage.jartaptupMessage.message}`, {
                    buttons: true
                }).then((value) => {
                    if (value) {
                        window.location.href = `/penyelenggara-detail/${id}/${nama}`;
                    }
                });
            } else if (menu === "jartaplok ps") {
                jartaplokpsMessage.loading && jartaplokpsMessage.jartaplokpsMessage && swal(`${jartaplokpsMessage.jartaplokpsMessage.message}`, {
                    buttons: true
                }).then((value) => {
                    if (value) {
                        window.location.href = `/penyelenggara-detail/${id}/${nama}`;
                    }
                });
            }
        }

        return notification();
    }, [history, id, jaberselPelangganMessage, jaberselPenyelenggaraMessage, jabertrunkMessage, jartaplokMessage, jartaplokpsMessage, jartapsliMessage, jartapsljjMessage, jartaptupMessage, menu, nama]);

    // value

    // kondisi menu
    React.useEffect(() => {
        getUser()
        if (menu === "jabersel pelanggan") {
            getJaberSelDetailById(dataId);
        } else if (menu === "jabersel penyelenggara") {
            getJaberselPenyelelenggaraDetailById(dataId);
        } else if (menu === "jabertrunk") {
            getJabertrunkDetailById(dataId)
        } else if (menu === "jartap sli") {
            getjartapsliDetailById(dataId)
        } else if (menu === "jartap sljj") {
            getjartapsljjDetailById(dataId)
        } else if (menu === "jartaplok") {
            getjartaplokDetailById(dataId)
        } else if (menu === "jartaptup pelanggan") {
            getjartaptupPelangganDetailById(dataId);
            getJartaptupTech();
        } else if (menu === "jartaptup jaringan") {
            getjartaptupJaringanDetailById(dataId);
            getJartaptupTech();
        } else if (menu === "jartaplok ps") {
            getjartaplokpsDetailById(dataId);
            getJartaptupTech();
        }
    }, [dataId, getJaberSelDetailById, getJaberselPenyelelenggaraDetailById, getJabertrunkDetailById, getJartaptupTech, getUser, getjartaplokDetailById, getjartaplokpsDetailById, getjartapsliDetailById, getjartapsljjDetailById, getjartaptupJaringanDetailById, getjartaptupPelangganDetailById, menu]);

    //  load State
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            user && user.user && user.user.map(u => setUserData(u));
            user && user.error === "Unauthenticated." && history.push('/');
            if (menu === "jabersel pelanggan") {
                jaberselPelangganDetail && setJaberselPelangganValue(jaberselPelangganDetail.jaberselPelangganDetail);
                setLoad(false);
            } else if (menu === "jabersel penyelenggara") {
                jaberselPenyelenggaraDetail.loading && setJaberselPenyelenggaraValue(jaberselPenyelenggaraDetail.jaberselPenyelenggaraDetail);
                setLoad(false);
            } else if (menu === "jabertrunk") {
                jabertrunkDetail && setJabertrunkValue(jabertrunkDetail.jabertrunkDetail);
                setLoad(false);
            } else if (menu === "jartap sli") {
                jartapsliDetail && setJartapsliValue(jartapsliDetail.jartapsliDetail);
                setLoad(false);
            } else if (menu === "jartap sljj") {
                jartapsljjDetail && setJartapsljjValue(jartapsljjDetail.jartapsljjDetail);
                setLoad(false);
            } else if (menu === "jartaplok") {
                jartaplokDetail && setJartaplokValue(jartaplokDetail.jartaplokDetail);
                setLoad(false);
            } else if (menu === "jartaptup pelanggan") {
                jartaptupDetail && setJartaptupPelangganValue(jartaptupDetail.jartaptupDetail);
                jartaptupTech.loading && setJartaptupTechOption(
                    jartaptupTech.jartaptupTech.map(tech => ({
                        label: tech.nama,
                        value: tech.nama
                    }))
                );
                setLoad(false);
            } else if (menu === "jartaptup jaringan") {
                jartaptupDetail && setJartaptupJaringanValue(jartaptupDetail.jartaptupDetail);
                jartaptupTech.loading && setJartaptupTechOption(
                    jartaptupTech.jartaptupTech.map(tech => ({
                        label: tech.nama,
                        value: tech.nama
                    }))
                );
                // setLoad(false);
            } else if (menu === "jartaplok ps") {
                jartaplokpsDetail && setJartaplokpsValue(jartaplokpsDetail.jartaplokpsDetail);
                jartaptupTech.loading && setJartaptupTechOption(
                    jartaptupTech.jartaptupTech.map(tech => ({
                        label: tech.nama,
                        value: tech.nama
                    }))
                );
                setLoad(false);
            }
        }, 3000);
        return () => clearTimeout(timeout);
    }, [getJartaptupTech, history, jaberselPelangganDetail, jaberselPenyelenggaraDetail.jaberselPenyelenggaraDetail, jaberselPenyelenggaraDetail.loading, jabertrunkDetail, jartaplokDetail, jartaplokpsDetail, jartapsliDetail, jartapsljjDetail, jartaptupDetail, jartaptupTech.jartaptupTech, jartaptupTech.loading, menu, user]);

    // console()

    const onSubmit = data => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            prabayar: data.prabayar,
            pascabayar: data.pascabayar,
            total_pelanggan_jasa_telepon: data.total_pelanggan_jasa_telepon,
            populasi: data.populasi,
            teledensitas: data.teledensitas,
            pelanggan_data: data.pelanggan_data,
            tahun: inputTahun === '' ? jaberselPelangganValue.tahun : inputTahun,
        }
        // console.log(input);
        updateJaberselPel(dataId, input);
    }

    const submitJaberselPenyelenggara = data => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            arpu_blended: data.arpu_blended,
            arpu_pascabayar: data.arpu_pascabayar,
            arpu_prabayar: data.arpu_prabayar,
            kapasitas_terpasang: data.kapasitas_terpasang,
            vtd: data.vtd,
            vts_in: data.vts_in,
            vts_out: data.vts_out,
            vtv_out: data.vtv_out,
            vtv_in: data.vtv_in,
            tahun: inputTahun === '' ? jaberselPenyelenggaraValue.tahun : inputTahun
        }
        // console.log(input);
        updateJaberselPenyelenggara(dataId, input);
    }
    const submitJabertrunk = data => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            frekuensi: data.frekuensi,
            pelanggan: data.pelanggan,
            switching: data.switching,
            bts: data.bts,
            kapasitas_pelanggan: data.kapasitas_pelanggan,
            tahun: inputTahun === '' ? jabertrunkValue.tahun : inputTahun
        }
        // console.log(input);
        updateJabertrunk(dataId, input);
    }

    const submitJartapsli = (data) => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            keterangan: data.keterangan,
            outgoing: data.outgoing,
            incoming: data.incoming,
            penomoran: data.penomoran,
            tahun: inputTahun === '' ? jabertrunkValue.tahun : inputTahun
        }
        updatejartapsli(dataId, input);
    }

    const submitJartapsljj = (data) => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            keterangan: data.keterangan,
            outgoing: data.outgoing,
            incoming: data.incoming,
            tahun: inputTahun === '' ? jabertrunkValue.tahun : inputTahun
        }
        updatejartapsljj(dataId, input);
    }

    const submitjartaplok = (data) => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            pelanggan: data.pelanggan,
            kab_kota: data.kab_kota,
            provinsi: data.provinsi,
            kapasitas_sistem_terpasang: data.kapasitas_sistem_terpasang,
            arpu: data.arpu,
            outgoing: data.outgoing,
            incoming: data.incoming,
            tahun: inputTahun
        }
        console.log(input);
        updatejartaplok(dataId, input);
    }

    const submitJartaptupPelanggan = (data) => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            teknologi: jartaptupTechInput === '' ? jartaptupPelangganValue.teknologi : jartaptupTechInput,
            korporasi: data.korporasi,
            perorangan: data.perorangan,
            tahun: inputTahun === "" ? jartaptupPelangganValue.tahun : inputTahun
        }
        updatejartaptupPelanggan(dataId, input);
    }

    const submitJartaptupJaringan = (data) => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            teknologi: jartaptupTechInput === '' ? jartaptupPelangganValue.teknologi : jartaptupTechInput,
            korporasi: data.korporasi,
            perorangan: data.perorangan,
            tahun: inputTahun === "" ? jartaptupPelangganValue.tahun : inputTahun
        }
        updatejartaptupJaringan(dataId, input);
    }

    const submitJartaplokpsEdit = (data) => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            teknologi: jartaptupTechInput === "" ? jartaplokpsValue.teknologi : jartaptupTechInput,
            korporasi: data.korporasi,
            perorangan: data.perorangan,
            tahun: inputTahun === "" ? jartaplokpsValue.tahun : inputTahun
        }
        updatejartapokps(dataId, input);
        // console.log(input)
    }

    return (
        <>
            <Sidebar menu="DATA PENYELENGGARAAN" />
            <div className="main-panel">
                <Navbar user={userData} />
                <div className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header card-header-icon" data-background-color="blue">
                                        <Styles.ButtonModal
                                            type="button"
                                            onClick={() => window.history.back()}>
                                            <i className="fa fa-chevron-circle-left fa-2x" aria-hidden="true"></i>
                                        </Styles.ButtonModal>
                                    </div>
                                    <h4 className="card-title">Edit {menu}</h4>
                                    <div className="card-content">
                                        {
                                            load ? <div className="d-flex align-content-center"><Loader type="Bars" color="#00BFFF" height={100} width={100} /></div> : menu === 'jartaplok ps' ? (<form className="form"
                                                onSubmit={handleSubmit(submitJartaplokpsEdit)}
                                            >
                                                <Styles.InputForm>
                                                    <Controller
                                                        control={control}
                                                        name="teknologi"
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                options={jartaptupTechOption}
                                                                placeholder="Teknologi.."
                                                                defaultValue={jartaptupTechOption.filter(opt => opt.value === jartaplokpsValue.teknologi)}
                                                                onChange={(e) => setJartaptupTechInput(e.value)}
                                                            />
                                                        )}
                                                    />
                                                </Styles.InputForm>
                                                {jartaplokpsMessage && jartaplokpsMessage.error && <span className="text-danger">{jartaplokpsMessage.error.teknologi}</span>}
                                                <Styles.InputForm>
                                                    <input
                                                        placeholder="Perorangan..."
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue={jartaplokpsValue.perorangan}
                                                        {...register("perorangan")}
                                                    />
                                                    {jartaplokpsMessage && jartaplokpsMessage.error && <span className="text-danger">{jartaplokpsMessage.error.perorangan}</span>}
                                                </Styles.InputForm>
                                                <Styles.InputForm>
                                                    <input
                                                        placeholder="Korporasi..."
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue={jartaplokpsValue.korporasi}
                                                        {...register("korporasi")}
                                                    />
                                                    {jartaplokpsMessage && jartaplokpsMessage.error && <span className="text-danger">{jartaplokpsMessage.error.korporasi}</span>}
                                                </Styles.InputForm>
                                                <Styles.InputForm>
                                                    <Controller
                                                        control={control}
                                                        name="tahun"
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                options={tahun}
                                                                placeholder="Tahun.."
                                                                defaultValue={tahun.filter(opt => opt.value === jartaplokpsValue.tahun)}
                                                                onChange={(e) => setInputTahun(e.value)}
                                                            />
                                                        )}
                                                    />
                                                    {jartaplokpsMessage && jartaplokpsMessage.error && <span className="text-danger">{jartaplokpsMessage.error.tahun}</span>}
                                                </Styles.InputForm>
                                                <Button variant="primary" type="submit">Save changes</Button>
                                            </form>) :
                                                menu === "jartaptup pelanggan" ? (<form className="form"
                                                    onSubmit={handleSubmit(submitJartaptupPelanggan)}
                                                >
                                                    <Styles.InputForm>
                                                        <Controller
                                                            control={control}
                                                            name="teknologi"
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    options={jartaptupTechOption}
                                                                    placeholder="Teknologi.."
                                                                    defaultValue={
                                                                        jartaptupTechOption.filter(option => option.value === jartaptupPelangganValue.teknologi)
                                                                    }
                                                                    onChange={(e) => setJartaptupTechInput(e.value)}
                                                                />
                                                            )}
                                                        />
                                                        {jartaptupMessage && jartaptupMessage.error && <span className="text-danger">{jartaptupMessage.error.teknologi}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <input
                                                            placeholder="Perorangan..."
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue={jartaptupPelangganValue.perorangan}
                                                            {...register("perorangan")}
                                                        />
                                                        {jartaptupMessage && jartaptupMessage.error && <span className="text-danger">{jartaptupMessage.error.perorangan}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <input
                                                            placeholder="Korporasi..."
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue={jartaptupPelangganValue.korporasi}
                                                            {...register("korporasi")}
                                                        />
                                                        {jartaptupMessage && jartaptupMessage.error && <span className="text-danger">{jartaptupMessage.error.korporasi}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <Controller
                                                            control={control}
                                                            name="tahun"
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    options={tahun}
                                                                    placeholder="Tahun.."
                                                                    defaultValue={
                                                                        tahun.filter(option => option.value === jartaptupPelangganValue.tahun)
                                                                    }
                                                                    onChange={(e) => setInputTahun(e.value)}
                                                                />
                                                            )}
                                                        />
                                                        {jartaptupMessage && jartaptupMessage.error && <span className="text-danger">{jartaptupMessage.error.tahun}</span>}
                                                    </Styles.InputForm>
                                                    <Button variant="primary" type="submit">Save changes</Button>
                                                </form>) :
                                                    menu === "jartaptup jaringan" ? (
                                                        <form className="form"
                                                            onSubmit={handleSubmit(submitJartaptupJaringan)}
                                                        >
                                                            <Styles.InputForm>
                                                                <Controller
                                                                    control={control}
                                                                    name="tahun"
                                                                    render={({ field }) => (
                                                                        <Select
                                                                            {...field}
                                                                            options={jartaptupTechInput}
                                                                            placeholder="Kapasitas"
                                                                            onChange={(e) => {
                                                                                getJartaptupNetwork(e.value);
                                                                                setKapasitasValue(e.value);
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                                {jartaptupMessage && jartaptupMessage.error && <span className="text-danger">{jartaptupMessage.error.kapasitas}</span>}
                                                            </Styles.InputForm>
                                                            <Styles.InputForm>
                                                                <Controller
                                                                    control={control}
                                                                    name="tahun"
                                                                    render={({ field }) => (
                                                                        <Select
                                                                            {...field}
                                                                            options={jartaptupTechName}
                                                                            placeholder="Item Kapasitas"
                                                                            onChange={(e) => setItemKapasitasValue(e.value)}
                                                                        />
                                                                    )}
                                                                />
                                                                {jartaptupMessage && jartaptupMessage.error && <span className="text-danger">{jartaptupMessage.error.item_kapasitas}</span>}
                                                            </Styles.InputForm>
                                                            <Styles.InputForm>
                                                                <input
                                                                    placeholder="Jumlah..."
                                                                    type="text"
                                                                    className="form-control"
                                                                    {...register("jumlah")}
                                                                />
                                                                {jartaptupMessage && jartaptupMessage.error && <span className="text-danger">{jartaptupMessage.error.jumlah}</span>}
                                                            </Styles.InputForm>
                                                            <Styles.InputForm>
                                                                <Controller
                                                                    control={control}
                                                                    name="tahun"
                                                                    render={({ field }) => (
                                                                        <Select
                                                                            {...field}
                                                                            options={tahun}
                                                                            placeholder="Tahun.."
                                                                            onChange={(e) => setTahunInputJaringan(e.value)}
                                                                        />
                                                                    )}
                                                                />
                                                                {jartaptupMessage && jartaptupMessage.error && <span className="text-danger">{jartaptupMessage.error.tahun}</span>}
                                                            </Styles.InputForm>
                                                            <Button type="submit" variant="contained" color="primary">Save changes</Button>
                                                        </form>)
                                                        : menu === "jartaplok" ? (<form className="form"
                                                            onSubmit={handleSubmit(submitjartaplok)}
                                                        >
                                                            <Styles.InputForm>
                                                                <input
                                                                    placeholder="Pelanggan..."
                                                                    type="text"
                                                                    className="form-control"
                                                                    defaultValue={`${jartaplokValue.pelanggan}`}
                                                                    {...register("pelanggan")}
                                                                />
                                                                {jartaplokMessage && jartaplokMessage.error && <span className="text-danger">{jartaplokMessage.error.pelanggan}</span>}
                                                            </Styles.InputForm>
                                                            <Styles.InputForm>
                                                                <input
                                                                    placeholder="Provinsi..."
                                                                    type="text"
                                                                    className="form-control"
                                                                    defaultValue={`${jartaplokValue.provinsi}`}
                                                                    {...register("provinsi")}
                                                                />
                                                                {jartaplokMessage && jartaplokMessage.error && <span className="text-danger">{jartaplokMessage.error.provinsi}</span>}
                                                            </Styles.InputForm>
                                                            <Styles.InputForm>
                                                                <input
                                                                    placeholder="Kabupaten / Kota..."
                                                                    type="text"
                                                                    className="form-control"
                                                                    defaultValue={`${jartaplokValue.kab_kota}`}
                                                                    {...register("kab_kota")}
                                                                />
                                                                {jartaplokMessage && jartaplokMessage.error && <span className="text-danger">{jartaplokMessage.error.kab_kota}</span>}
                                                            </Styles.InputForm>
                                                            <Styles.InputForm>
                                                                <input
                                                                    placeholder="Kapasitas Sistem Terpasang..."
                                                                    type="text"
                                                                    className="form-control"
                                                                    defaultValue={`${jartaplokValue.kapasitas_sistem_terpasang}`}
                                                                    {...register("kapasitas_sistem_terpasang")}
                                                                />
                                                                {jartaplokMessage && jartaplokMessage.error && <span className="text-danger">{jartaplokMessage.error.kapasitas_sistem_terpasang}</span>}
                                                            </Styles.InputForm>
                                                            <Styles.InputForm>
                                                                <input
                                                                    placeholder="Outgoing..."
                                                                    type="text"
                                                                    className="form-control"
                                                                    defaultValue={`${jartaplokValue.outgoing}`}
                                                                    {...register("outgoing")}
                                                                />
                                                                {jartaplokMessage && jartaplokMessage.error && <span className="text-danger">{jartaplokMessage.error.outgoing}</span>}
                                                            </Styles.InputForm>
                                                            <Styles.InputForm>
                                                                <input
                                                                    placeholder="Incoming..."
                                                                    type="text"
                                                                    className="form-control"
                                                                    defaultValue={`${jartaplokValue.incoming}`}
                                                                    {...register("incoming")}
                                                                />
                                                                {jartaplokMessage && jartaplokMessage.error && <span className="text-danger">{jartaplokMessage.error.incoming}</span>}
                                                            </Styles.InputForm>
                                                            <Styles.InputForm>
                                                                <input
                                                                    placeholder="Arpu..."
                                                                    type="text"
                                                                    className="form-control"
                                                                    defaultValue={`${jartaplokValue.arpu}`}
                                                                    {...register("arpu")}
                                                                />
                                                                {jartaplokMessage && jartaplokMessage.error && <span className="text-danger">{jartaplokMessage.error.arpu}</span>}
                                                            </Styles.InputForm>
                                                            <Styles.InputForm>
                                                                <Controller
                                                                    control={control}
                                                                    name="tahun"
                                                                    render={({ field }) => (
                                                                        <Select
                                                                            {...field}
                                                                            options={tahun}
                                                                            placeholder="Tahun.."
                                                                            defaultValue={
                                                                                tahun.filter(option => option.value === jartaplokValue.tahun)
                                                                            }
                                                                            onChange={(e) => setInputTahun(e.value)}
                                                                        />
                                                                    )}
                                                                />
                                                                {jartaplokMessage && jartaplokMessage.error && <span className="text-danger">{jartaplokMessage.error.tahun}</span>}
                                                            </Styles.InputForm>
                                                            <Button variant="primary" type="submit">Save changes</Button>
                                                        </form>) :
                                                            menu === "jartap sljj" ? (<form className="form"
                                                                onSubmit={handleSubmit(submitJartapsljj)}
                                                            >
                                                                <Styles.InputForm>
                                                                    <input
                                                                        placeholder="Keterangan..."
                                                                        type="text"
                                                                        className="form-control"
                                                                        defaultValue={`${jartapsljjValue.keterangan}`}
                                                                        {...register("keterangan")}
                                                                    />
                                                                    {jartapsljjMessage && jartapsljjMessage.error && <span className="text-danger">{jartapsljjMessage.error.keterangan}</span>}
                                                                </Styles.InputForm>
                                                                <Styles.InputForm>
                                                                    <input
                                                                        placeholder="Outgoing..."
                                                                        type="text"
                                                                        className="form-control"
                                                                        defaultValue={`${jartapsljjValue.outgoing}`}
                                                                        {...register("outgoing")}
                                                                    />
                                                                    {jartapsljjMessage && jartapsljjMessage.error && <span className="text-danger">{jartapsljjMessage.error.outgoing}</span>}
                                                                </Styles.InputForm>
                                                                <Styles.InputForm>
                                                                    <input
                                                                        placeholder="Incoming..."
                                                                        type="text"
                                                                        className="form-control"
                                                                        defaultValue={`${jartapsljjValue.incoming}`}
                                                                        {...register("incoming")}
                                                                    />
                                                                    {jartapsljjMessage && jartapsljjMessage.error && <span className="text-danger">{jartapsljjMessage.error.incoming}</span>}
                                                                </Styles.InputForm>
                                                                <Styles.InputForm>
                                                                    <Controller
                                                                        control={control}
                                                                        name="tahun"
                                                                        render={({ field }) => (
                                                                            <Select
                                                                                {...field}
                                                                                options={tahun}
                                                                                placeholder="Tahun.."
                                                                                defaultValue={
                                                                                    tahun.filter(option => option.value === jartapsljjValue.tahun)
                                                                                }
                                                                                onChange={(e) => setInputTahun(e.value)}
                                                                            />
                                                                        )}
                                                                    />
                                                                    {jartapsljjMessage && jartapsljjMessage.error && <span className="text-danger">{jartapsljjMessage.error.tahun}</span>}
                                                                </Styles.InputForm>
                                                                <Button variant="primary" type="submit">Save changes</Button>
                                                            </form>) :
                                                                menu === "jartap sli" ? (<form className="form"
                                                                    onSubmit={handleSubmit(submitJartapsli)}
                                                                >
                                                                    <Styles.InputForm>
                                                                        <input
                                                                            placeholder="Keterangan..."
                                                                            type="text"
                                                                            className="form-control"
                                                                            defaultValue={jartapsliValue.keterangan}
                                                                            {...register("keterangan")}
                                                                        />
                                                                        {jartapsliMessage && jartapsliMessage.error && <span className="text-danger">{jartapsliMessage.error.keterangan}</span>}
                                                                    </Styles.InputForm>
                                                                    <Styles.InputForm>
                                                                        <input
                                                                            placeholder="Outgoing..."
                                                                            type="text"
                                                                            className="form-control"
                                                                            defaultValue={`${jartapsliValue.outgoing}`}
                                                                            {...register("outgoing")}
                                                                        />
                                                                        {jartapsliMessage && jartapsliMessage.error && <span className="text-danger">{jartapsliMessage.error.outgoing}</span>}
                                                                    </Styles.InputForm>
                                                                    <Styles.InputForm>
                                                                        <input
                                                                            placeholder="Incoming..."
                                                                            type="text"
                                                                            className="form-control"
                                                                            defaultValue={`${jartapsliValue.incoming}`}
                                                                            {...register("incoming")}
                                                                        />
                                                                        {jartapsliMessage && jartapsliMessage.error && <span className="text-danger">{jartapsliMessage.error.incoming}</span>}
                                                                    </Styles.InputForm>
                                                                    <Styles.InputForm>
                                                                        <input
                                                                            placeholder="Penomoran..."
                                                                            type="text"
                                                                            className="form-control"
                                                                            defaultValue={`${jartapsliValue.penomoran}`}
                                                                            {...register("penomoran")}
                                                                        />
                                                                        {jartapsliMessage && jartapsliMessage.error && <span className="text-danger">{jartapsliMessage.error.penomoran}</span>}
                                                                    </Styles.InputForm>
                                                                    <Styles.InputForm>
                                                                        <Controller
                                                                            control={control}
                                                                            name="tahun"
                                                                            render={({ field }) => (
                                                                                <Select
                                                                                    {...field}
                                                                                    options={tahun}
                                                                                    placeholder="Tahun.."
                                                                                    defaultValue={
                                                                                        tahun.filter(option => option.value === jartapsliValue.tahun)
                                                                                    }
                                                                                    onChange={(e) => setInputTahun(e.value)}
                                                                                />
                                                                            )}
                                                                        />
                                                                        {jartapsliMessage && jartapsliMessage.error && <span className="text-danger">{jartapsliMessage.error.tahun}</span>}
                                                                    </Styles.InputForm>
                                                                    <Button variant="primary" type="submit">Save changes</Button>
                                                                </form>) :
                                                                    menu === "jabersel pelanggan" ? (<form className="form"
                                                                        onSubmit={handleSubmit(onSubmit)}
                                                                    >
                                                                        <Styles.InputForm>
                                                                            <div className="form-group">
                                                                                <label >Prabayar</label>
                                                                                <input
                                                                                    placeholder="Prabayar"
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    defaultValue={`${jaberselPelangganValue.prabayar}`}
                                                                                    {...register("prabayar")}
                                                                                />
                                                                            </div>
                                                                            {jaberselPelangganMessage && jaberselPelangganMessage.error && <span className="text-danger">{jaberselPelangganMessage.error.prabayar}</span>}
                                                                        </Styles.InputForm>
                                                                        <Styles.InputForm>
                                                                            <div className="form-group">
                                                                                <label >Pasca Bayar</label>
                                                                                <input
                                                                                    placeholder="Pasca Bayar.."
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    defaultValue={`${jaberselPelangganValue.pascabayar}`}
                                                                                    {...register("pascabayar")}
                                                                                />
                                                                            </div>
                                                                            {jaberselPelangganMessage && jaberselPelangganMessage.error && <span className="text-danger">{jaberselPelangganMessage.error.nama}</span>}
                                                                        </Styles.InputForm>
                                                                        <Styles.InputForm>
                                                                            <div className="form-group">
                                                                                <label >Total Pelanggan Jasa Telepon</label>
                                                                                <input
                                                                                    placeholder="Total Pelanggan Jasa Telepon..."
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    defaultValue={`${jaberselPelangganValue.total_pelanggan_jasa_telepon}`}
                                                                                    {...register("total_pelanggan_jasa_telepon")}
                                                                                />
                                                                            </div>
                                                                            {jaberselPelangganMessage && jaberselPelangganMessage.error && <span className="text-danger">{jaberselPelangganMessage.error.nama}</span>}
                                                                        </Styles.InputForm>
                                                                        <Styles.InputForm>
                                                                            <div className="form-group">
                                                                                <label >Populasi</label>
                                                                                <input
                                                                                    placeholder="Populasi..."
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    defaultValue={`${jaberselPelangganValue.populasi}`}
                                                                                    {...register("populasi")}
                                                                                />
                                                                            </div>
                                                                            {jaberselPelangganMessage && jaberselPelangganMessage.error && <span className="text-danger">{jaberselPelangganMessage.error.nama}</span>}
                                                                        </Styles.InputForm>
                                                                        <Styles.InputForm>
                                                                            <div className="form-group">
                                                                                <label >Teledensitas</label>
                                                                                <input
                                                                                    placeholder="Teledensitas..."
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    defaultValue={`${jaberselPelangganValue.teledensitas}`}
                                                                                    {...register("teledensitas")}
                                                                                />
                                                                            </div>
                                                                            {jaberselPelangganMessage && jaberselPelangganMessage.error && <span className="text-danger">{jaberselPelangganMessage.error.nama}</span>}
                                                                        </Styles.InputForm>
                                                                        <Styles.InputForm>
                                                                            <div className="form-group">
                                                                                <label >Pelanggan Data</label>
                                                                                <input
                                                                                    placeholder="Pelanggan Data..."
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    defaultValue={`${jaberselPelangganValue.pelanggan_data}`}
                                                                                    {...register("pelanggan_data")}
                                                                                />
                                                                            </div>
                                                                            {jaberselPelangganMessage && jaberselPelangganMessage.error && <span className="text-danger">{jaberselPelangganMessage.error.nama}</span>}
                                                                        </Styles.InputForm>
                                                                        <Styles.InputForm>
                                                                            <div className="form-group">
                                                                                <label >Tahun</label>
                                                                                <Controller
                                                                                    control={control}
                                                                                    name="tahun"
                                                                                    render={({ field }) => (
                                                                                        <Select
                                                                                            {...field}
                                                                                            options={tahun}
                                                                                            placeholder="Tahun.."
                                                                                            defaultValue={
                                                                                                tahun.filter(option => option.value === jaberselPelangganValue.tahun)
                                                                                            }
                                                                                            onChange={(e) => setInputTahun(e.value)}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                            {jaberselPelangganMessage && jaberselPelangganMessage.error && <span className="text-danger">{jaberselPelangganMessage.error.tahun}</span>}
                                                                        </Styles.InputForm>
                                                                        <Button variant="primary" type="submit">Save changes</Button>
                                                                    </form>) : menu === "jabersel penyelenggara" ? (
                                                                        <form className="form"
                                                                            onSubmit={handleSubmit(submitJaberselPenyelenggara)}
                                                                        >
                                                                            <Styles.InputForm>
                                                                                <input
                                                                                    placeholder="Kapasitas Terpasang..."
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    defaultValue={jaberselPenyelenggaraValue.kapasitas_terpasang}
                                                                                    {...register("kapasitas_terpasang")}
                                                                                />
                                                                                {jaberselPenyelenggaraMessage && jaberselPenyelenggaraMessage.error && <span className="text-danger">{jaberselPenyelenggaraMessage.error.kapasitas_terpasang}</span>}
                                                                            </Styles.InputForm>
                                                                            <Styles.InputForm>
                                                                                <input
                                                                                    placeholder="VTV OUT..."
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    defaultValue={jaberselPenyelenggaraValue.vtv_out}
                                                                                    {...register("vtv_out")}
                                                                                />
                                                                                {jaberselPenyelenggaraMessage && jaberselPenyelenggaraMessage.error && <span className="text-danger">{jaberselPenyelenggaraMessage.error.vtv_out}</span>}
                                                                            </Styles.InputForm>
                                                                            <Styles.InputForm>
                                                                                <input
                                                                                    placeholder="VTS IN..."
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    defaultValue={jaberselPenyelenggaraValue.vtv_in}
                                                                                    {...register("vts_in")}
                                                                                />
                                                                                {jaberselPenyelenggaraMessage && jaberselPenyelenggaraMessage.error && <span className="text-danger">{jaberselPenyelenggaraMessage.error.vts_in}</span>}
                                                                            </Styles.InputForm>
                                                                            <Styles.InputForm>
                                                                                <input
                                                                                    placeholder="VTS OUT..."
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    defaultValue={jaberselPenyelenggaraValue.vts_out}
                                                                                    {...register("vts_out")}
                                                                                />
                                                                                {jaberselPenyelenggaraMessage && jaberselPenyelenggaraMessage.error && <span className="text-danger">{jaberselPenyelenggaraMessage.error.vts_out}</span>}
                                                                            </Styles.InputForm>
                                                                            <Styles.InputForm>
                                                                                <input
                                                                                    placeholder="VTV IN..."
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    defaultValue={jaberselPenyelenggaraValue.vtv_in}
                                                                                    {...register("vtv_in")}
                                                                                />
                                                                                {jaberselPenyelenggaraMessage && jaberselPenyelenggaraMessage.error && <span className="text-danger">{jaberselPenyelenggaraMessage.error.vtv_in}</span>}
                                                                            </Styles.InputForm>
                                                                            <Styles.InputForm>
                                                                                <input
                                                                                    placeholder="VTD..."
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    defaultValue={jaberselPenyelenggaraValue.vtd}
                                                                                    {...register("vtd")}
                                                                                />
                                                                                {jaberselPenyelenggaraMessage && jaberselPenyelenggaraMessage.error && <span className="text-danger">{jaberselPenyelenggaraMessage.error.vtd}</span>}
                                                                            </Styles.InputForm>
                                                                            <Styles.InputForm>
                                                                                <input
                                                                                    placeholder="ARPU PRABAYAR..."
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    defaultValue={jaberselPenyelenggaraValue.arpu_prabayar}
                                                                                    {...register("arpu_prabayar")}
                                                                                />
                                                                                {jaberselPenyelenggaraMessage && jaberselPenyelenggaraMessage.error && <span className="text-danger">{jaberselPenyelenggaraMessage.error.arpu_prabayar}</span>}
                                                                            </Styles.InputForm>
                                                                            <Styles.InputForm>
                                                                                <input
                                                                                    placeholder="ARPU PASCA BAYAR..."
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    defaultValue={jaberselPenyelenggaraValue.arpu_pascabayar}
                                                                                    {...register("arpu_pascabayar")}
                                                                                />
                                                                                {jaberselPenyelenggaraMessage && jaberselPenyelenggaraMessage.error && <span className="text-danger">{jaberselPenyelenggaraMessage.error.arpu_pascabayar}</span>}
                                                                            </Styles.InputForm>
                                                                            <Styles.InputForm>
                                                                                <input
                                                                                    placeholder="ARPU Blended..."
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    defaultValue={jaberselPenyelenggaraValue.arpu_blended}
                                                                                    {...register("arpu_blended")}
                                                                                />
                                                                                {jaberselPenyelenggaraMessage && jaberselPenyelenggaraMessage.error && <span className="text-danger">{jaberselPenyelenggaraMessage.error.arpu_prabayar}</span>}
                                                                            </Styles.InputForm>
                                                                            <Styles.InputForm>
                                                                                <Controller
                                                                                    control={control}
                                                                                    name="tahun"
                                                                                    render={({ field }) => (
                                                                                        <Select
                                                                                            {...field}
                                                                                            options={tahun}
                                                                                            placeholder="Tahun.."
                                                                                            defaultValue={
                                                                                                tahun.filter(val => val.value === jaberselPenyelenggaraValue.tahun)
                                                                                            }
                                                                                            onChange={(e) => setInputTahun(e.value)}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                                {jaberselPenyelenggaraMessage && jaberselPenyelenggaraMessage.error && <span className="text-danger">{jaberselPenyelenggaraMessage.error.tahun}</span>}
                                                                            </Styles.InputForm>
                                                                            <Button variant="primary" type="submit">Save changes</Button>
                                                                        </form>
                                                                    ) : menu === "jabertrunk" ? (
                                                                        <form className="form"
                                                                            onSubmit={handleSubmit(submitJabertrunk)}
                                                                        >
                                                                            <Styles.InputForm>
                                                                                <input
                                                                                    placeholder="Frekuensi..."
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    defaultValue={`${jabertrunkValue.frekuensi}`}
                                                                                    {...register("frekuensi")}
                                                                                />
                                                                                {jabertrunkMessage && jabertrunkMessage.error && <span className="text-danger">{jabertrunkMessage.error.frekuensi}</span>}
                                                                            </Styles.InputForm>
                                                                            <Styles.InputForm>
                                                                                <input
                                                                                    placeholder="Pelanggan..."
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    defaultValue={`${jabertrunkValue.pelanggan}`}
                                                                                    {...register("pelanggan")}
                                                                                />
                                                                                {jabertrunkMessage && jabertrunkMessage.error && <span className="text-danger">{jabertrunkMessage.error.pelanggan}</span>}
                                                                            </Styles.InputForm>
                                                                            <Styles.InputForm>
                                                                                <input
                                                                                    placeholder="Swiching..."
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    defaultValue={`${jabertrunkValue.switching}`}
                                                                                    {...register("switching")}
                                                                                />
                                                                                {jabertrunkMessage && jabertrunkMessage.error && <span className="text-danger">{jabertrunkMessage.error.switching}</span>}
                                                                            </Styles.InputForm>
                                                                            <Styles.InputForm>
                                                                                <input
                                                                                    placeholder="BTS..."
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    defaultValue={`${jabertrunkValue.bts}`}
                                                                                    {...register("bts")}
                                                                                />
                                                                                {jabertrunkMessage && jabertrunkMessage.error && <span className="text-danger">{jabertrunkMessage.error.bts}</span>}
                                                                            </Styles.InputForm>
                                                                            <Styles.InputForm>
                                                                                <input
                                                                                    placeholder="Kapasitas Pelanggan..."
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    defaultValue={`${jabertrunkValue.kapasitas_pelanggan}`}
                                                                                    {...register("kapasitas_pelanggan")}
                                                                                />
                                                                                {jabertrunkMessage && jabertrunkMessage.error && <span className="text-danger">{jabertrunkMessage.error.kapasitas_pelanggan}</span>}
                                                                            </Styles.InputForm>
                                                                            <Styles.InputForm>
                                                                                <Controller
                                                                                    control={control}
                                                                                    name="tahun"
                                                                                    render={({ field }) => (
                                                                                        <Select
                                                                                            {...field}
                                                                                            options={tahun}
                                                                                            placeholder="Tahun.."
                                                                                            defaultValue={
                                                                                                tahun.filter(val => val.value === jabertrunkValue.tahun)
                                                                                            }
                                                                                            onChange={(e) => setInputTahun(e.value)}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                                {jabertrunkMessage && jabertrunkMessage.error && <span className="text-danger">{jabertrunkMessage.error.tahun}</span>}
                                                                            </Styles.InputForm>
                                                                            <Button variant="primary" type="submit">Save changes</Button>
                                                                        </form>) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default EditJabersel

const Tahun = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= 1945; i--) {
        tahun.push(i);
    }
    return tahun;
}