import {
    JARTAPTUP_PELANGGAN_REQ,
    JARTAPTUP_PELANGGAN_SUCCESS,
    JARTAPTUP_PELANGGAN_FAIL,
    JARTAPTUP_JARINGAN_REQ,
    JARTAPTUP_JARINGAN_SUCCESS,
    JARTAPTUP_JARINGAN_FAIL,
    JARTAPTUP_MESSAGE_REQ,
    JARTAPTUP_MESSAGE_SUCCESS,
    JARTAPTUP_MESSAGE_FAIL,
    JARTAPTUP_DETAIL_REQ,
    JARTAPTUP_DETAIL_SUCCESS,
    JARTAPTUP_DETAIL_FAIL,
    JARTAPTUP_TECHNAME_REQ,
    JARTAPTUP_TECHNAME_SUCCESS,
    JARTAPTUP_TECHNAME_FAIL,
    JARTAPTUP_NETWORKNAME_REQ,
    JARTAPTUP_NETWORKNAME_SUCCESS,
    JARTAPTUP_NETWORKNAME_FAIL,
} from './jartaptupTypes';

import api from '../services';
import { config } from '../config';

export const jartaptupJaringanReq = () => {
    return { type: JARTAPTUP_JARINGAN_REQ }
}

export const jartaptupJaringanSuccess = (data) => {
    return { type: JARTAPTUP_JARINGAN_SUCCESS, payload: data }
}

export const jartaptupJaringanFail = (err) => {
    return { type: JARTAPTUP_JARINGAN_FAIL, payload: err }
}
export const jartaptupPelangganReq = () => {
    return { type: JARTAPTUP_PELANGGAN_REQ }
}

export const jartaptupPelangganSuccess = (data) => {
    return { type: JARTAPTUP_PELANGGAN_SUCCESS, payload: data }
}

export const jartaptupPelangganFail = (err) => {
    return { type: JARTAPTUP_PELANGGAN_FAIL, payload: err }
}

export const jartaptupNetworkNameReq = () => {
    return { type: JARTAPTUP_NETWORKNAME_REQ }
}

export const jartaptupNetworkNameSuccess = (data) => {
    return { type: JARTAPTUP_NETWORKNAME_SUCCESS, payload: data }
}

export const jartaptupNetworkNameFail = (err) => {
    return { type: JARTAPTUP_NETWORKNAME_FAIL, payload: err }
}

export const jartaptupTechnameReq = () => {
    return { type: JARTAPTUP_TECHNAME_REQ }
}

export const jartaptupTechnameSuccess = (data) => {
    return { type: JARTAPTUP_TECHNAME_SUCCESS, payload: data }
}

export const jartaptupTechnameFail = (err) => {
    return { type: JARTAPTUP_TECHNAME_FAIL, payload: err }
}

export const jartaptupMessageReq = () => {
    return { type: JARTAPTUP_MESSAGE_REQ }
}

export const jartaptupMessageSuccess = (data) => {
    return { type: JARTAPTUP_MESSAGE_SUCCESS, payload: data }
}

export const jartaptupMessageFail = (err) => {
    return { type: JARTAPTUP_MESSAGE_FAIL, payload: err }
}

export const jartaptupDetailReq = () => {
    return { type: JARTAPTUP_DETAIL_REQ }
}

export const jartaptupDetailSuccess = (data) => {
    return { type: JARTAPTUP_DETAIL_SUCCESS, payload: data }
}

export const jartaptupDetailFail = (err) => {
    return { type: JARTAPTUP_DETAIL_FAIL, payload: err }
}

export const uploadJartaptupJaringan = (data) => {
    return async (dispatch) => {
        dispatch(jartaptupMessageReq);
        await api.post('/jartaptup-jaringan-create-import', data, config)
            .then(res => {
                dispatch(jartaptupMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartaptupMessageFail(err.response.data));
            })
    }
}

export const uploadJartaptupPelanggan = (data) => {
    return async (dispatch) => {
        dispatch(jartaptupMessageReq);
        await api.post('/jartaptup-jumlah-pelanggan-create-import', data, config)
            .then(res => {
                dispatch(jartaptupMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartaptupMessageFail(err.response.data));
            })
    }
}

export const updatejartaptupPelanggan = (id, data) => {
    return async (dispatch) => {
        dispatch(jartaptupMessageReq);
        await api.put(`/jartaptup-jumlah-pelanggan-update/${id}`, data, config)
            .then(res => {
                dispatch(jartaptupMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartaptupMessageFail(err.response.data));
            })
    }
}

export const updatejartaptupJaringan = (id, data) => {
    return async (dispatch) => {
        dispatch(jartaptupMessageReq);
        await api.put(`/jartaptup-jaringan-update/${id}`, data, config)
            .then(res => {
                dispatch(jartaptupMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartaptupMessageFail(err.response.data));
            })
    }
}

export const getjartaptupPelangganDetailById = (id) => {
    return async (dispatch) => {
        dispatch(jartaptupDetailReq);
        await api.get(`/jartaptup-jumlah-pelanggan/${id}`, config)
            .then(res => {
                dispatch(jartaptupDetailSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jartaptupDetailSuccess(err.response));
            })
    }
}
export const getjartaptupJaringanDetailById = (id) => {
    return async (dispatch) => {
        dispatch(jartaptupDetailReq);
        await api.get(`/jartaptup-jaringan/${id}`, config)
            .then(res => {
                dispatch(jartaptupDetailSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jartaptupDetailSuccess(err.response));
            })
    }
}

export const deletejartaptupPelanggan = (id) => {
    return async (dispatch) => {
        dispatch(jartaptupMessageReq);
        await api.delete(`/jartaptup-jumlah-pelanggan-delete/${id}`, config)
            .then(res => {
                dispatch(jartaptupMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartaptupMessageFail(err.response.data));
            })
    }
}
export const deletejartaptupJaringan = (id) => {
    return async (dispatch) => {
        dispatch(jartaptupMessageReq);
        await api.delete(`/jartaptup-jaringan-delete/${id}`, config)
            .then(res => {
                dispatch(jartaptupMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartaptupMessageFail(err.response.data));
            })
    }
}

export const getJartaptupJaringanByCompany = (id) => {
    return async (dispatch) => {
        dispatch(jartaptupJaringanReq);
        await api.get(`/jartaptup-jaringan-company/${id}`, config)
            .then(res => {
                dispatch(jartaptupJaringanSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jartaptupJaringanFail(err.response));
            })
    }
}

export const getJartaptupPelangganByCompany = (id) => {
    return async (dispatch) => {
        dispatch(jartaptupPelangganReq);
        await api.get(`/jartaptup-jumlah-pelanggan-company/${id}`, config)
            .then(res => {
                dispatch(jartaptupPelangganSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jartaptupPelangganFail(err.response));
            })
    }
}

export const addJartaptupPelanggan = (data) => {
    return async (dispatch) => {
        dispatch(jartaptupMessageReq);
        await api.post('/jartaptup-jumlah-pelanggan-create', data, config)
            .then(res => {
                dispatch(jartaptupMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartaptupMessageFail(err.response.data));
            })
    }
}

export const addJartaptupJaringan = (data) => {
    return async (dispatch) => {
        dispatch(jartaptupMessageReq);
        await api.post('/jartaptup-jaringan-create', data, config)
            .then(res => {
                dispatch(jartaptupMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartaptupMessageFail(err.response.data));
            })
    }
}

export const getJartaptupTech = () => {
    return async (dispatch) => {
        dispatch(jartaptupTechnameReq);
        await api.get(`/nama-teknologi`, config)
            .then(res => {
                dispatch(jartaptupTechnameSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jartaptupTechnameFail(err.response));
            })
    }
}

export const getJartaptupNetwork = (id) => {
    return async (dispatch) => {
        dispatch(jartaptupNetworkNameReq);
        await api.get(`/nama-item-jaringan/${id}`, config)
            .then(res => {
                dispatch(jartaptupNetworkNameSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jartaptupNetworkNameFail(err.response));
            })
    }
}