import React from 'react';
import { useParams } from 'react-router-dom';
import { config } from '../../redux/config';
import api from '../../redux/services';
import Loader from 'react-loader-spinner';
import { Spinner } from '../../components';
import DataTable from 'react-data-table-component';

const SellularAllTech = () => {
    const { id } = useParams();
    const [tahun, setTahun] = React.useState([]);
    const [teknologiState, setTeknologiState] = React.useState([]);
    console.log(teknologiState)
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            api.get(`/selular-all-tech/${id}`, config).then(res => {
                if (res.status === 204) {
                    setTahun([]);
                } else {
                    setTahun(Object.keys(res.data.data[0]));
                    setTeknologiState(res.data.data)
                }
            }).catch(err => {
                console.log(err)
            }).finally(() => setLoading(false))
        }, 2000);
        return () => clearTimeout(timeout);
    }, [id]);

    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none'
            },
        },
    };

    const teknologi = [
        {
            name: `Teknologi`,
            selector: row => row.teknologi
        },
        {
            name: `${tahun.length > 0 ? tahun[1].slice(6) : tahun}`,
            selector: tahun.length > 0 ? tahun[1] : tahun
        },
        {
            name: `${tahun.length > 0 ? tahun[2].slice(6) : tahun}`,
            selector: tahun.length > 0 ? tahun[2] : tahun
        },
        {
            name: `${tahun.length > 0 ? tahun[3].slice(6) : tahun}`,
            selector: tahun.length > 0 ? tahun[3] : tahun
        },
        {
            name: `${tahun.length > 0 ? tahun[4].slice(6) : tahun}`,
            selector: tahun.length > 0 ? tahun[4] : tahun
        },
        {
            name: `${tahun.length > 0 ? tahun[5].slice(6) : tahun}`,
            selector: tahun.length > 0 ? tahun[5] : tahun
        },
    ];

    return loading ? <Spinner /> : (
        <>
            <div className="card-content">
                <div className="material-datatables">
                    <DataTable
                        columns={teknologi}
                        data={teknologiState}
                        progressPending={loading}
                        customStyles={customStyles}
                        progressComponent={<Loader type="Watch" color="#00BFFF" height={300}
                            width={100} />}
                        responsive={true}
                    />
                </div>
            </div>
        </>
    )
}

export default SellularAllTech