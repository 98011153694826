import React from 'react';
import {
    Container,
    // Button
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
// import Select from "react-select";
// import Loader from 'react-loader-spinner';
// import { useForm, Controller } from 'react-hook-form';

import { Sidebar, Navbar, Styles } from '../../components';
// import swal from 'sweetalert';

const EditTeknologi = ({
    teknologi,
    getTeknologiDetail
}) => {
    // const { register, handleSubmit, control } = useForm();
    const { id, nama } = useParams();
    // const [valueEdit, setValueEdit] = React.useState(null);
    // const [inputTahun, setInputTahun] = React.useState('');
    // const [tahun, setTahun] = React.useState([]);
    // React.useEffect(() => {
    //     setTahun(Tahun().map(t => ({ value: t, label: t })));
    // }, []);

    // React.useEffect(() => {
    //     const timeout = setTimeout(() => {
    //         teknologi && teknologi.teknologi && setValueEdit(teknologi.teknologi);
    //         // setLoad(false)
    //     }, 3000);

    //     return () => clearTimeout(timeout);
    // }, [teknologi]);

    React.useEffect(() => {
        getTeknologiDetail(id);
    }, [getTeknologiDetail, id]);

    return (
        <>
            <Sidebar menu="TEKNOLOGI" />
            <div className="main-panel">
                <Navbar menu="TEKNOLOGI" user="Admin" />
                <div className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header card-header-icon" data-background-color="blue">
                                        <Styles.ButtonModal
                                            type="button"
                                            onClick={() => window.history.back()}>
                                            <i className="fa fa-chevron-circle-left fa-2x" aria-hidden="true"></i>
                                        </Styles.ButtonModal>
                                    </div>
                                    <h4 className="card-title">Edit Data Teknologi {nama}</h4>
                                    <div className="card-content">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default EditTeknologi

// const Tahun = () => {
//     const date = new Date();
//     const year = date.getFullYear();
//     const tahun = [];
//     for (let i = year; i >= 1945; i--) {
//         tahun.push(i);
//     }
//     return tahun;
// }