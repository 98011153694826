import ListIzin from './ListIzin';
import { connect } from 'react-redux';
import { getIzin, getProfilUmum, getUser } from '../../redux';

const mapStateToProps = state => {
    return {
        user: state.user,
        izin: state.izin,
        dataUmum: state.profilUmum,
        izinMessage: state.izinMessage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUser: () => dispatch(getUser()),
        getDataUmum: () => dispatch(getProfilUmum()),
        getIzin: (data) => dispatch(getIzin(data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ListIzin)