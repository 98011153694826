export const token = localStorage.getItem('token');
export const config = { headers: { Accept: 'application/json', Authorization: `Bearer ${token}` } };
export const configUpload = {
    headers: {
        "Content-Type": "multipart/form-data",
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
};

export const configDownload = {
    headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    },
    responseType: "blob",
    method: 'GET'
}
