import {
    ENODEB4G_REQ,
    ENODEB4G_SUCCESS,
    ENODEB4G_FAIL,
    ENODEB4G_CRUD_REQ,
    ENODEB4G_CRUD_SUCCESS,
    ENODEB4G_CRUD_FAIL,
    ENODEB4G_MESSAGE_REQ,
    ENODEB4G_MESSAGE_SUCCESS,
    ENODEB4G_MESSAGE_FAIL,
    ENODEB4G_DETAIL_REQ,
    ENODEB4G_DETAIL_SUCCESS,
    ENODEB4G_DETAIL_FAIL,
} from './enodeb4gTypes'

import api from '../services';
import { config } from '../config';
export const token = localStorage.getItem('token');

export const enodeb4gDetailReq = () => {
    return { type: ENODEB4G_DETAIL_REQ }
}

export const enodeb4gDetailSuccess = (data) => {
    return { type: ENODEB4G_DETAIL_SUCCESS, payload: data }
}

export const enodeb4gDetailFail = (err) => {
    return { type: ENODEB4G_DETAIL_FAIL, payload: err }
}

export const enodeb4gMessageReq = () => {
    return { type: ENODEB4G_MESSAGE_REQ }
}

export const enodeb4gMessageSuccess = (data) => {
    return { type: ENODEB4G_MESSAGE_SUCCESS, payload: data }
}

export const enodeb4gMessageFail = (err) => {
    return { type: ENODEB4G_MESSAGE_FAIL, payload: err }
}

export const enodeb4gReq = () => {
    return { type: ENODEB4G_REQ }
}

export const enodeb4gSuccess = (data) => {
    return { type: ENODEB4G_SUCCESS, payload: data }
}

export const enodeb4gFail = (err) => {
    return { type: ENODEB4G_FAIL, payload: err }
}

export const enodeb4gCrudReq = () => {
    return { type: ENODEB4G_CRUD_REQ }
}

export const enodeb4gCrudSuccess = (data) => {
    return { type: ENODEB4G_CRUD_SUCCESS, payload: data }
}

export const enodeb4gCrudFail = (err) => {
    return { type: ENODEB4G_CRUD_FAIL, payload: err }
}

export const uploadENodeb4g = (data) => {
    return async (dispatch) => {
        dispatch(enodeb4gMessageReq);
        await api.post('/enodeb4g-create-import', data, config)
            .then(res => {
                dispatch(enodeb4gMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(enodeb4gMessageFail(err.response.data));
            })
    }
}

export const updateenodeb4g = (id, data) => {
    return async (dispatch) => {
        await api.put(`/enodeb4g-update/${id}`, data, config)
            .then(res => dispatch(enodeb4gMessageSuccess(res.data)))
            .catch(err => dispatch(enodeb4gMessageFail(err.response.data)))
    }
}

export const addenodeb4g = (data) => {
    return async (dispatch) => {
        dispatch(enodeb4gMessageReq);
        await api.post('/enodeb4g-create', data, config)
            .then(res => {
                dispatch(enodeb4gMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(enodeb4gMessageFail(err.response.data));
            })
    }
}

export const deleteENodeb4g = (id) => {
    return async (dispatch) => {
        dispatch(enodeb4gMessageReq);
        await api.delete(`/enodeb4g-delete/${id}`, config)
            .then(res => {
                dispatch(enodeb4gMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(enodeb4gMessageFail(err.response.data));
            })
    }
}

export const getenodeb4gDetailById = (id) => {
    return async (dispatch) => {
        dispatch(enodeb4gDetailReq);
        await api.get(`/enodeb4g/${id}`, config)
            .then(res => {
                dispatch(enodeb4gDetailSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(enodeb4gDetailFail(err.response));
            })
    }
}

export const getenodeb4gPivot = (id, from, to) => {
    return async (dispatch) => {
        dispatch(enodeb4gReq);
        await api.get(`/enodeb4g-company-pivot/${id}`, {
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            params: {
                from: from,
                to: to
            }
        })
            .then(res => {
                dispatch(enodeb4gSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(enodeb4gFail(err.response));
            })
    }
}

export const getenodeb4gByCompany = (id) => {
    return async (dispatch) => {
        dispatch(enodeb4gCrudReq);
        await api.get(`/enodeb4g-company/${id}`, config)
            .then(res => {
                dispatch(enodeb4gCrudSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(enodeb4gCrudFail(err.response.data));
            })
    }
}