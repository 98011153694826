export const ENODEB4G_REQ = 'ENODEB4G_REQ';
export const ENODEB4G_SUCCESS = 'ENODEB4G_SUCCESS';
export const ENODEB4G_FAIL = 'ENODEB4G_FAIL';
export const ENODEB4G_CRUD_REQ = 'ENODEB4G_CRUD_REQ';
export const ENODEB4G_CRUD_SUCCESS = 'ENODEB4G_CRUD_SUCCESS';
export const ENODEB4G_CRUD_FAIL = 'ENODEB4G_CRUD_FAIL';
export const ENODEB4G_DETAIL_REQ = 'ENODEB4G_DETAIL_REQ';
export const ENODEB4G_DETAIL_SUCCESS = 'ENODEB4G_DETAIL_SUCCESS';
export const ENODEB4G_DETAIL_FAIL = 'ENODEB4G_DETAIL_FAIL';
export const ENODEB4G_MESSAGE_REQ = 'ENODEB4G_MESSAGE_REQ';
export const ENODEB4G_MESSAGE_SUCCESS = 'ENODEB4G_MESSAGE_SUCCESS';
export const ENODEB4G_MESSAGE_FAIL = 'ENODEB4G_MESSAGE_FAIL';