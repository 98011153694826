import React from 'react';
import styled from 'styled-components';
import { Spinner } from '../../components';
import { useParams } from 'react-router'

const Table = styled.table`
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    border: none !important;
}
`

const ProfilUmumDetail = ({
    dataUmumDetail,
    getDataUmumDetail
}) => {
    const { id, nama } = useParams();
    const [dataPerusahaan, setDataPerusahaan] = React.useState([]);
    const [load, setLoad] = React.useState(true);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            dataUmumDetail.loading &&
                setDataPerusahaan(dataUmumDetail.profilUmumDetail);
            setLoad(false);
        }, 5000);
        return () => clearTimeout(timeout);
    }, [dataPerusahaan.dataizin, dataUmumDetail]);

    React.useEffect(() => {
        getDataUmumDetail(id)
    }, [getDataUmumDetail, id]);

    return load ? <Spinner /> : dataUmumDetail.error === "Unauthenticated." ? (
        window.location.href = "/") : (
        <div className="card">
            <div className="card-content">
                <h4 className="card-title text-bold" style={{ marginBottom: 50, fontWeight: 'bold' }}>PROFIL PENYELENGGARA {nama}</h4>
                <div className="table">
                    <Table className="table table-borderless" style={{ borderTopColor: '#fff' }} >
                        {
                            dataPerusahaan.length > 0 ? dataPerusahaan.map((d, index) => (
                                <tbody key={index}>
                                    <tr className="mr-2">
                                        <td><span style={{ fontWeight: 'bold', marginRight: '10px' }}>Nama Perusahaan </span></td>
                                        <td ><span>: {d.company_name}</span></td>
                                        <td><span style={{ fontWeight: 'bold' }}>Telepon</span></td>
                                        <td><span>: {d.phone}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span style={{ fontWeight: 'bold' }}>Brand</span></td>{null}<td><span>: {d.brand}</span></td>
                                        <td><span style={{ fontWeight: 'bold' }}>Fax</span></td><td><span>: {d.fax}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span style={{ fontWeight: 'bold' }}>Status TBK</span></td><td><span>: {d.status_tbk}</span></td>
                                        <td><span style={{ fontWeight: 'bold' }}>Website</span></td><td><span>: {d.website}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span style={{ fontWeight: 'bold' }}>NPWP</span></td><td><span>: {d.npwp}</span></td>
                                        <td><span style={{ fontWeight: 'bold' }}>Email</span></td><td><span>: {d.email}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span style={{ fontWeight: 'bold' }}>alamat</span></td><td><span>: {d.address}&nbsp;</span></td>
                                        <td><span style={{ fontWeight: 'bold' }}>Latitude</span></td><td><span>: {d.lat}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span style={{ fontWeight: 'bold' }}>Kab/ Kota</span></td><td><span>: {d.cities}</span></td>
                                        <td><span style={{ fontWeight: 'bold' }}>Longitude</span></td><td><span>: {d.long}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span style={{ fontWeight: 'bold' }}>Propinsi</span></td><td><span>: {d.province}</span></td>
                                        <td><span></span></td><td><span></span></td>
                                    </tr>
                                    <tr>
                                        <td><span style={{ fontWeight: 'bold' }}>Kode Pos</span></td><td><span>: {d.zipcode}</span></td>
                                        <td><span></span></td><td><span></span></td>
                                    </tr>
                                    <tr><td colSpan={4}> <span>&nbsp; &nbsp; &nbsp;</span></td></tr>
                                    <tr>
                                        <td>
                                            <span style={{ fontWeight: 'bold' }}>Alamat Korespondensi</span>
                                        </td>
                                        <td>
                                            <span>: {d.address_core}</span>
                                        </td>
                                        <td>
                                            <span style={{ fontWeight: 'bold' }}>Telepon Korespondensi</span>
                                        </td>
                                        <td>
                                            <span>: {d.phone_core}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span style={{ fontWeight: 'bold' }}>Kab/ Kota Korespondensi</span>
                                        </td>
                                        <td>
                                            <span>: {d.cities_core}</span></td>
                                        <td>
                                            <span style={{ fontWeight: 'bold' }}>Fax Korespondensi</span>
                                        </td>
                                        <td>
                                            <span>: {d.fax_core}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span style={{ fontWeight: 'bold' }}>Propinsi Korespondensi</span></td><td><span>: {d.province_core}</span></td>
                                        <td><span style={{ fontWeight: 'bold' }}>Website Korespondensi</span></td><td><span>: {d.website_core}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span style={{ fontWeight: 'bold' }}>Kode Pos Korespondensi</span></td><td><span>: {d.zipcode_core}</span></td>
                                        <td>
                                            <span style={{ fontWeight: 'bold' }}>Email Korespondensi</span>
                                        </td>
                                        <td><span>
                                            : {d.email_core}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span> &nbsp; &nbsp; &nbsp;</span></td><td><span> &nbsp; &nbsp; &nbsp;</span></td>
                                        <td><span style={{ fontWeight: 'bold' }}>Latitude Korespondensi</span></td><td><span>: {d.lat_core}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span> &nbsp; &nbsp; &nbsp;</span></td><td><span> &nbsp; &nbsp; &nbsp;</span></td>
                                        <td><span style={{ fontWeight: 'bold' }}>Longitude Korespondensi</span></td><td><span>: {d.long_core}</span></td>
                                    </tr>
                                </tbody>
                            )) : <tbody><tr><td>Opppss.. sepertinya internet sedang ada gangguan..?</td></tr></tbody>
                        }
                    </Table>
                </div>
            </div>
        </div>
    )
}

export default ProfilUmumDetail
