import {
    BTS_REQ,
    BTS_SUCCESS,
    BTS_FAIL,
    BTS_MESSAGE_REQ,
    BTS_MESSAGE_SUCCESS,
    BTS_MESSAGE_FAIL,
    BTS_DETAIL_REQ,
    BTS_DETAIL_SUCCESS,
    BTS_DETAIL_FAIL,
    BTS_CRUD_REQ,
    BTS_CRUD_SUCCESS,
    BTS_CRUD_FAIL,
} from './btsTypes'

import api from '../services';
import { config } from '../config';
export const token = localStorage.getItem('token');

export const btsDetailReq = () => {
    return { type: BTS_DETAIL_REQ }
}

export const btsDetailSuccess = (data) => {
    return { type: BTS_DETAIL_SUCCESS, payload: data }
}

export const btsDetailFail = (err) => {
    return { type: BTS_DETAIL_FAIL, payload: err }
}

export const btsUploadReq = () => {
    return { type: BTS_MESSAGE_REQ }
}

export const btsUploadSuccess = (data) => {
    return { type: BTS_MESSAGE_SUCCESS, payload: data }
}

export const btsUploadFail = (err) => {
    return { type: BTS_MESSAGE_FAIL, payload: err }
}

export const btsMessageReq = () => {
    return { type: BTS_MESSAGE_REQ }
}

export const btsMessageSuccess = (data) => {
    return { type: BTS_MESSAGE_SUCCESS, payload: data }
}

export const btsMessageFail = (err) => {
    return { type: BTS_MESSAGE_FAIL, payload: err }
}

export const btsCrudReq = () => {
    return { type: BTS_CRUD_REQ }
}

export const btsCrudSuccess = (data) => {
    return { type: BTS_CRUD_SUCCESS, payload: data }
}

export const btsCrudFail = (err) => {
    return { type: BTS_CRUD_FAIL, payload: err }
}

export const btsReq = () => {
    return { type: BTS_REQ }
}

export const btsSuccess = (data) => {
    return { type: BTS_SUCCESS, payload: data }
}

export const btsFail = (err) => {
    return { type: BTS_FAIL, payload: err }
}

export const uploadBts = (data) => {
    return async (dispatch) => {
        dispatch(btsMessageSuccess);
        await api.post(`/bts2g-create-import`, data, config)
            .then(res => dispatch(btsMessageSuccess(res.data)))
            .catch(err => dispatch(btsMessageFail(err.response.data)))
    }
}

export const updateBts = (id, data) => {
    return async (dispatch) => {
        dispatch(btsMessageReq);
        await api.put(`/bts2g-update/${id}`, data, config)
            .then(res => dispatch(btsMessageSuccess(res.data)))
            .catch(err => dispatch(btsMessageFail(err.response.data)))
    }
}

export const addbts = (data) => {
    return async (dispatch) => {
        dispatch(btsMessageReq);
        await api.post('/bts2g-create', data, config)
            .then(res => {
                dispatch(btsMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(btsMessageFail(err.response.data));
            })
    }
}

export const deleteBts = (id) => {
    return async (dispatch) => {
        dispatch(btsMessageReq);
        await api.delete(`/bts2g-delete/${id}`, config)
            .then(res => {
                dispatch(btsMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(btsMessageFail(err.response.data));
            })
    }
}

export const getbtsDetail = (id) => {
    return async (dispatch) => {
        dispatch(btsDetailReq);
        await api.get(`/bts2g/${id}`, config)
            .then(res => {
                dispatch(btsDetailSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(btsDetailFail(err.response));
            })
    }
}

export const getbtsByCompanyCrud = (id) => {
    return async (dispatch) => {
        dispatch(btsCrudReq);
        await api.get(`/bts2g-company/${id}`, config)
            .then(res => {
                dispatch(btsCrudSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(btsCrudFail(err.response.data));
            })
    }
}
export const getbtsByCompanyPivot = (id, from, to) => {
    return async (dispatch) => {
        dispatch(btsReq);
        await api.get(`/bts2g-company-pivot/${id}`, {
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            params: {
                from: from,
                to: to
            }
        })
            .then(res => {
                dispatch(btsSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(btsFail(err.response.data));
            })
    }
}