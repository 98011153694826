import Jartaptup from './Jartaptup';
import { connect } from 'react-redux';
import {
    getJartaptupJaringanByCompany,
    getJartaptupNetwork,
    getJartaptupPelangganByCompany,
    getJartaptupTech,
    addJartaptupPelanggan,
    addJartaptupJaringan,
    deletejartaptupPelanggan,
    uploadJartaptupPelanggan,
    uploadJartaptupJaringan,
    deletejartaptupJaringan
} from '../../redux';

const mapStateToProps = state => {
    return {
        jartaptupJaringan: state.jartaptupJaringan,
        jartaptupPelanggan: state.jartaptupPelanggan,
        jartaptupMessage: state.jartaptupMessage,
        jartaptupTech: state.jartaptupTech,
        jartaptupNetwork: state.jartaptupNetwork
    }
}

const mapDispatchToProps = dispatch => {
    return {
        uploadJartaptupPelanggan: (data) => dispatch(uploadJartaptupPelanggan(data)),
        uploadJartaptupJaringan: (data) => dispatch(uploadJartaptupJaringan(data)),
        getJartaptupJaringanByCompany: (id) => dispatch(getJartaptupJaringanByCompany(id)),
        getJartaptupPelangganByCompany: (id) => dispatch(getJartaptupPelangganByCompany(id)),
        getJartaptupNetwork: (id) => dispatch(getJartaptupNetwork(id)),
        getJartaptupTech: () => dispatch(getJartaptupTech()),
        addJartaptupPelanggan: (data) => dispatch(addJartaptupPelanggan(data)),
        addJartaptupJaringan: (data) => dispatch(addJartaptupJaringan(data)),
        deletejartaptupPelanggan: (id) => dispatch(deletejartaptupPelanggan(id)),
        deletejartaptupJaringan: (id) => dispatch(deletejartaptupJaringan(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Jartaptup);