import {
    MARKETSHARE_PENDAPATAN_REQ,
    MARKETSHARE_PENDAPATAN_SUCCESS,
    MARKETSHARE_PENDAPATAN_FAIL,
    MARKETSHARE_PELANGGAN_REQ,
    MARKETSHARE_PELANGGAN_SUCCESS,
    MARKETSHARE_PELANGGAN_FAIL,
    PENILAIAN_KESEHATAN_REQ,
    PENILAIAN_KESEHATAN_SUCCESS,
    PENILAIAN_KESEHATAN_FAIL,
} from './marketshareTypes';

const penilaianKesehatanState = {
    loading: false,
    data: [],
    err: ''
}

const marketSharePendapatanState = {
    loading: false,
    data: [],
    err: ''
}

const marketSharePelangganState = {
    load: false,
    data: [],
    err: ''
}

export const penilaianKesehatanReducer = (state = penilaianKesehatanState, action) => {
    switch (action.type) {
        case PENILAIAN_KESEHATAN_REQ:
            return {
                ...state,
                loading: false
            }
        case PENILAIAN_KESEHATAN_SUCCESS:
            return {
                loading: true,
                data: action.payload,
                err: ''
            }
        case PENILAIAN_KESEHATAN_FAIL:
            return {
                loading: false,
                data: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const marketSharePelangganReducer = (state = marketSharePelangganState, action) => {
    switch (action.type) {
        case MARKETSHARE_PELANGGAN_REQ:
            return {
                ...state,
                loading: false
            }
        case MARKETSHARE_PELANGGAN_SUCCESS:
            return {
                loading: true,
                data: action.payload,
                err: ''
            }
        case MARKETSHARE_PELANGGAN_FAIL:
            return {
                loading: false,
                data: [],
                error: action.payload
            }
        default:
            return state
    }
}

const marketSharePendapatanReducer = (state = marketSharePendapatanState, action) => {
    switch (action.type) {
        case MARKETSHARE_PENDAPATAN_REQ:
            return {
                ...state,
                loading: false
            }
        case MARKETSHARE_PENDAPATAN_SUCCESS:
            return {
                loading: true,
                data: action.payload,
                err: ''
            }
        case MARKETSHARE_PENDAPATAN_FAIL:
            return {
                loading: false,
                data: [],
                error: action.payload
            }
        default:
            return state
    }
}

export default marketSharePendapatanReducer