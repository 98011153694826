import Pegawai from "./Pegawai";

import { connect } from 'react-redux';
import { addPegawai, getPegawai, deletePegawai, uploadPegawai } from '../../redux';

const mapStateToProps = state => {
    return {
        pegawai: state.pegawai,
        pegawaiMessage: state.pegawaiMessage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPegawai: (company_id) => dispatch(getPegawai(company_id)),
        deletePegawai: (id) => dispatch(deletePegawai(id)),
        addPegawai: (data) => dispatch(addPegawai(data)),
        uploadPegawai: (data) => dispatch(uploadPegawai(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pegawai)
