import JaberselPenyelenggara from './JaberselPenyelenggara';
import { connect } from 'react-redux';
import { addJaberselPenyelenggara, deleteJaberselPenyelenggara, getJaberselPenyelenggaraByCompany, uploadJaberselPenyelenggara } from '../../redux';

const mapStateToProps = state => {
    return {
        jaberselPenyelenggara: state.jaberselPenyelenggara,
        jaberselPenyelenggaraMessage: state.jaberselPenyelenggaraMessage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        uploadJaberselPenyelenggara: (data) => dispatch(uploadJaberselPenyelenggara(data)),
        getJaberselPenyelenggaraByCompany: (id) => dispatch(getJaberselPenyelenggaraByCompany(id)),
        addJaberselPenyelenggara: (data) => dispatch(addJaberselPenyelenggara(data)),
        deleteJaberselPenyelenggara: (id) => dispatch(deleteJaberselPenyelenggara(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JaberselPenyelenggara);