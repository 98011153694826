import EditProfilUmum from './EditProfilUmum';
import { connect } from 'react-redux';
import { getKodePos, getKodePosCore, getKota, getKotaCore, getProfilUmumDetail, getProv, getProvCore, getUser, profilUmumUpdate } from '../../redux';

const mapStateToProps = state => {
    return {
        user: state.user,
        profilUmumDetail: state.profilUmumDetail,
        profilUmumMessage: state.profilUmumMessage,
        provinsi: state.provinsi,
        provinsiCore: state.provinsiCore,
        kota: state.kota,
        kotaCore: state.kotaCore,
        kodePos: state.kodePos,
        kodePosCore: state.kodePosCore,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUser: () => dispatch(getUser()),
        getProfilUmumDetail: (id) => dispatch(getProfilUmumDetail(id)),
        getProvinsi: () => dispatch(getProv()),
        getProvinsiCore: () => dispatch(getProvCore()),
        getKota: (data) => dispatch(getKota(data)),
        getKotaCore: (data) => dispatch(getKotaCore(data)),
        getKodePos: (data) => dispatch(getKodePos(data)),
        getKodePosCore: (data) => dispatch(getKodePosCore(data)),
        profilUmumUpdate: (id, data) => dispatch(profilUmumUpdate(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfilUmum)