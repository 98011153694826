export const SAHAM_REQ = 'SAHAM_REQ';
export const SAHAM_SUCCESS = 'SAHAM_SUCCESS';
export const SAHAM_FAIL = 'SAHAM_FAIL';

export const SAHAM_DETAIL_REQ = 'SAHAM_DETAIL_REQ';
export const SAHAM_DETAIL_SUCCESS = 'SAHAM_DETAIL_SUCCESS';
export const SAHAM_DETAIL_FAIL = 'SAHAM_DETAIL_FAIL';


export const SAHAM_MESSAGE_REQ = 'SAHAM_MESSAGE_REQ';
export const SAHAM_MESSAGE_SUCCESS = 'SAHAM_MESSAGE_SUCCESS';
export const SAHAM_MESSAGE_FAIL = 'SAHAM_MESSAGE_FAIL';