import {
    NODEB3G_REQ,
    NODEB3G_SUCCESS,
    NODEB3G_FAIL,
    NODEB3G_MESSAGE_REQ,
    NODEB3G_MESSAGE_SUCCESS,
    NODEB3G_MESSAGE_FAIL,
    NODEB3G_DETAIL_REQ,
    NODEB3G_DETAIL_SUCCESS,
    NODEB3G_DETAIL_FAIL,
    NODEB3G_CRUD_REQ,
    NODEB3G_CRUD_SUCCESS,
    NODEB3G_CRUD_FAIL,
} from './nodeb3gTypes'

import api from '../services';
import { config } from '../config';
export const token = localStorage.getItem('token');

export const nodeb3gDetailReq = () => {
    return { type: NODEB3G_DETAIL_REQ }
}

export const nodeb3gDetailSuccess = (data) => {
    return { type: NODEB3G_DETAIL_SUCCESS, payload: data }
}

export const nodeb3gDetailFail = (err) => {
    return { type: NODEB3G_DETAIL_FAIL, payload: err }
}

export const nodeb3gMessageReq = () => {
    return { type: NODEB3G_MESSAGE_REQ }
}

export const nodeb3gMessageSuccess = (data) => {
    return { type: NODEB3G_MESSAGE_SUCCESS, payload: data }
}

export const nodeb3gMessageFail = (err) => {
    return { type: NODEB3G_MESSAGE_FAIL, payload: err }
}

export const uploadNodeb3gMessageReq = () => {
    return { type: NODEB3G_MESSAGE_REQ }
}

export const uploadNodeb3gMessageSuccess = (data) => {
    return { type: NODEB3G_MESSAGE_SUCCESS, payload: data }
}

export const uploadNodeb3gMessageFail = (err) => {
    return { type: NODEB3G_MESSAGE_FAIL, payload: err }
}

export const nodeb3gReq = () => {
    return { type: NODEB3G_REQ }
}

export const nodeb3gSuccess = (data) => {
    return { type: NODEB3G_SUCCESS, payload: data }
}

export const nodeb3gFail = (err) => {
    return { type: NODEB3G_FAIL, payload: err }
}

export const nodeb3gCrudReq = () => {
    return { type: NODEB3G_CRUD_REQ }
}

export const nodeb3gCrudSuccess = (data) => {
    return { type: NODEB3G_CRUD_SUCCESS, payload: data }
}

export const nodeb3gCrudFail = (err) => {
    return { type: NODEB3G_CRUD_FAIL, payload: err }
}

export const updatenodeb3g = (id, data) => {
    return async (dispatch) => {
        await api.put(`/nodeb3g-update/${id}`, data, config)
            .then(res => dispatch(nodeb3gMessageSuccess(res.data)))
            .catch(err => dispatch(nodeb3gMessageFail(err.response.data)))
    }
}

export const uploadNodeb3g = (data) => {
    return async (dispatch) => {
        dispatch(nodeb3gMessageReq);
        await api.post('/nodeb3g-create-import', data, config)
            .then(res => {
                dispatch(nodeb3gMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(nodeb3gMessageFail(err.response.data));
            })
    }
}

export const addnodeb3g = (data) => {
    return async (dispatch) => {
        dispatch(nodeb3gMessageReq);
        await api.post('/nodeb3g-create', data, config)
            .then(res => {
                dispatch(nodeb3gMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(nodeb3gMessageFail(err.response.data));
            })
    }
}

export const deleteNodeb3g = (id) => {
    return async (dispatch) => {
        dispatch(nodeb3gMessageReq);
        await api.delete(`/nodeb3g-delete/${id}`, config)
            .then(res => {
                dispatch(nodeb3gMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(nodeb3gMessageFail(err.response.data));
            })
    }
}

export const getnodeb3gDetailById = (id) => {
    return async (dispatch) => {
        dispatch(nodeb3gDetailReq);
        await api.get(`/nodeb3g/${id}`, config)
            .then(res => {
                dispatch(nodeb3gDetailSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(nodeb3gDetailFail(err.response));
            })
    }
}

export const getnodeb3gDetail = (id) => {
    return async (dispatch) => {
        dispatch(nodeb3gDetailReq);
        await api.get(`/nodeb3g-company/${id}`, config)
            .then(res => {
                dispatch(nodeb3gDetailSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(nodeb3gDetailFail(err.response));
            })
    }
}

export const getnodeb3gByCompany = (id) => {
    return async (dispatch) => {
        dispatch(nodeb3gCrudReq);
        await api.get(`/nodeb3g-company/${id}`, config)
            .then(res => {
                dispatch(nodeb3gCrudSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(nodeb3gCrudFail(err.response));
            })
    }
}

export const getnodeb3gPivot = (id, from, to) => {
    return async (dispatch) => {
        dispatch(nodeb3gReq);
        await api.get(`/nodeb3g-company-pivot/${id}`, {
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            params: {
                from: from,
                to: to
            }
        })
            .then(res => {
                dispatch(nodeb3gSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(nodeb3gFail(err.response));
            })
    }
}