import ListJabersel from './ListJabersel';
import { connect } from 'react-redux';
import { getUser, getAllJaberselPelanggan } from '../../redux';

const mapStateToProps = state => {
    return {
        user: state.user,
        jaberselPelanggan: state.jaberselPelanggan,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUser: (data) => dispatch(getUser(data)),
        getAllJaberselPelanggan: () => dispatch(getAllJaberselPelanggan()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListJabersel)