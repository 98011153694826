import NodeB3G from './NodeB3G';
import { connect } from 'react-redux';
import { addnodeb3g, getnodeb3gByCompany, getnodeb3gPivot, getProv, uploadNodeb3g, deleteNodeb3g } from '../../redux';

const mapStateToProps = state => {
    return {
        nodeb3g: state.nodeb3g,
        nodeb3gCrud: state.nodeb3gCrud,
        nodeb3gMessage: state.nodeb3gMessage,
        provinsi: state.provinsi
    }
}

const mapDispatchToProps = dispatch => {
    return {
        deleteNodeb3g: (id) => dispatch(deleteNodeb3g(id)),
        uploadNodeb3g: (data) => dispatch(uploadNodeb3g(data)),
        getnodeb3gPivot: (id, from, to) => dispatch(getnodeb3gPivot(id, from, to)),
        getnodeb3gByCompany: (id) => dispatch(getnodeb3gByCompany(id)),
        addnodeb3g: (data) => dispatch(addnodeb3g(data)),
        getProv: () => dispatch(getProv()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NodeB3G)