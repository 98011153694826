import {
    JABERSEL_PELANGGAN_REQ,
    JABERSEL_PELANGGAN_SUCCESS,
    JABERSEL_PELANGGAN_FAIL,
    JABERSEL_PELANGGAN_MESSAGE_REQ,
    JABERSEL_PELANGGAN_MESSAGE_SUCCESS,
    JABERSEL_PELANGGAN_MESSAGE_FAIL,
    JABERSEL_PELANGGAN_DETAIL_REQ,
    JABERSEL_PELANGGAN_DETAIL_SUCCESS,
    JABERSEL_PELANGGAN_DETAIL_FAIL,
} from './jaberselPelangganTypes'

import api from '../services';
import { config } from '../config';

export const jaberselPelDetailReq = () => {
    return { type: JABERSEL_PELANGGAN_DETAIL_REQ }
}

export const jaberselPelDetailSuccess = (data) => {
    return { type: JABERSEL_PELANGGAN_DETAIL_SUCCESS, payload: data }
}

export const jaberselPelDetailFail = (err) => {
    return { type: JABERSEL_PELANGGAN_DETAIL_FAIL, payload: err }
}

export const jaberselPelangganMessageReq = () => {
    return { type: JABERSEL_PELANGGAN_MESSAGE_REQ }
}

export const jaberselPelangganMessageSuccess = (data) => {
    return { type: JABERSEL_PELANGGAN_MESSAGE_SUCCESS, payload: data }
}

export const jaberselPelangganMessageFail = (err) => {
    return { type: JABERSEL_PELANGGAN_MESSAGE_FAIL, payload: err }
}


export const jaberselPelangganReq = () => {
    return { type: JABERSEL_PELANGGAN_REQ }
}

export const jaberselPelangganSuccess = (data) => {
    return { type: JABERSEL_PELANGGAN_SUCCESS, payload: data }
}

export const jaberselPelangganFail = (err) => {
    return { type: JABERSEL_PELANGGAN_FAIL, payload: err }
}

export const uploadJaberselPelanggan = (data) => {
    return async (dispatch) => {
        dispatch(jaberselPelangganMessageReq);
        await api.post(`/jabersel-pelanggan-create-import`, data, config)
            .then(res => dispatch(jaberselPelangganMessageSuccess(res.data)))
            .catch(err => dispatch(jaberselPelangganMessageFail(err.response.data)))
    }
}

export const getAllJaberselPelanggan = () => {
    return async (dispatch) => {
        dispatch(jaberselPelangganReq);
        await api.get('/jabersel-pelanggan', config)
            .then(res => dispatch(jaberselPelangganSuccess(res.data.data)))
            .catch(err => dispatch(jaberselPelangganFail(err.response.data)))
    }
}

export const getJaberSelDetailById = (id) => {
    return async (dispatch) => {
        dispatch(jaberselPelDetailReq);
        await api.get(`/jabersel-pelanggan/${id}`, config)
            .then(res => {
                dispatch(jaberselPelDetailSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jaberselPelDetailFail(err.response));
            })
    }
}

export const updateJaberselPel = (id, data) => {
    return async (dispatch) => {
        dispatch(jaberselPelangganMessageReq);
        await api.put(`/jabersel-pelanggan-update/${id}`, data, config)
            .then(res => {
                dispatch(jaberselPelangganMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jaberselPelangganMessageFail(err.response.data));
            })
    }
}
export const deleteJaberselPel = (id) => {
    return async (dispatch) => {
        dispatch(jaberselPelangganMessageReq);
        await api.delete(`/jabersel-pelanggan-delete/${id}`, config)
            .then(res => {
                dispatch(jaberselPelangganMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jaberselPelangganMessageFail(err.response.data));
            })
    }
}

export const addJaberselPel = (data) => {
    return async (dispatch) => {
        dispatch(jaberselPelangganMessageReq);
        await api.post('/jabersel-pelanggan-create', data, config)
            .then(res => {
                dispatch(jaberselPelangganMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jaberselPelangganMessageFail(err.response.data));
            })
    }
}

export const getJaberselPelangganByCompany = (id) => {
    return async (dispatch) => {
        dispatch(jaberselPelangganReq);
        await api.get(`/jabersel-pelanggan-company/${id}`, config)
            .then(res => {
                dispatch(jaberselPelangganSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jaberselPelangganFail(err.response.data));
            })
    }
}