import {
    JABERTRUNK_REQ,
    JABERTRUNK_SUCCESS,
    JABERTRUNK_FAIL,
    JABERTRUNK_MESSAGE_REQ,
    JABERTRUNK_MESSAGE_SUCCESS,
    JABERTRUNK_MESSAGE_FAIL,
    JABERTRUNK_DETAIL_REQ,
    JABERTRUNK_DETAIL_SUCCESS,
    JABERTRUNK_DETAIL_FAIL,
} from './jabertrunkTypes';

import api from '../services';
import { config } from '../config';

export const jabertrunkDetailReq = () => {
    return { type: JABERTRUNK_DETAIL_REQ }
}

export const jabertrunkDetailSuccess = (data) => {
    return { type: JABERTRUNK_DETAIL_SUCCESS, payload: data }
}

export const jabertrunkDetailFail = (err) => {
    return { type: JABERTRUNK_DETAIL_FAIL, payload: err }
}

export const jabertrunkReq = () => {
    return { type: JABERTRUNK_REQ }
}

export const jabertrunkMessageReq = () => {
    return { type: JABERTRUNK_MESSAGE_REQ }
}

export const jabertrunkMessageSuccess = (data) => {
    return { type: JABERTRUNK_MESSAGE_SUCCESS, payload: data }
}

export const jabertrunkMessageFail = (err) => {
    return { type: JABERTRUNK_MESSAGE_FAIL, payload: err }
}

export const jabertrunkSuccess = (data) => {
    return { type: JABERTRUNK_SUCCESS, payload: data }
}

export const jabertrunkFail = (err) => {
    return { type: JABERTRUNK_FAIL, payload: err }
}

export const uploladJabertrunk = (data) => {
    return async (dispatch) => {
        dispatch(jabertrunkMessageReq);
        await api.post(`/jabertrunk-create-import`, data, config)
            .then(res => {
                dispatch(jabertrunkMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jabertrunkMessageFail(err.response.data));
            })
    }
}

export const getAllJabertrunk = () => {
    return async (dispatch) => {
        dispatch(jabertrunkReq);
        await api.get(`/jabertrunk`, config)
            .then(res => {
                dispatch(jabertrunkSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jabertrunkFail(err.response.data));
            })
    }
}

export const updateJabertrunk = (id, data) => {
    return async (dispatch) => {
        dispatch(jabertrunkMessageReq);
        await api.put(`/jabertrunk-update/${id}`, data, config)
            .then(res => {
                dispatch(jabertrunkMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jabertrunkMessageFail(err.response.data));
            })
    }
}

export const getJabertrunkDetailById = (id) => {
    return async (dispatch) => {
        dispatch(jabertrunkDetailReq);
        await api.get(`/jabertrunk/${id}`, config)
            .then(res => {
                dispatch(jabertrunkDetailSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jabertrunkDetailSuccess(err.response));
            })
    }
}

export const deleteJabertrunk = (id) => {
    return async (dispatch) => {
        dispatch(jabertrunkMessageReq);
        await api.delete(`/jabertrunk-delete/${id}`, config)
            .then(res => {
                dispatch(jabertrunkMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jabertrunkMessageFail(err.response.data));
            })
    }
}


export const addjabertrunk = (data) => {
    return async (dispatch) => {
        dispatch(jabertrunkMessageReq);
        await api.post('/jabertrunk-create', data, config)
            .then(res => {
                dispatch(jabertrunkMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jabertrunkMessageFail(err.response.data));
            })
    }
}

export const getJabertrunkByCompany = (id) => {
    return async (dispatch) => {
        dispatch(jabertrunkReq);
        await api.get(`/jabertrunk-company/${id}`, config)
            .then(res => {
                dispatch(jabertrunkSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jabertrunkFail(err.response.data));
            })
    }
}



