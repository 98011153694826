import {
    PROVINSI_REQ,
    PROVINSI_OK,
    PROVINSI_FAIL,
    KOTA_REQ,
    KOTA_OK,
    KOTA_FAIL,
    POST_REQ,
    POST_OK,
    POST_FAIL,
    PROVINSI_CORE_REQ,
    PROVINSI_CORE_OK,
    PROVINSI_CORE_FAIL,
    KOTA_CORE_REQ,
    KOTA_CORE_OK,
    KOTA_CORE_FAIL,
    POST_CORE_REQ,
    POST_CORE_OK,
    POST_CORE_FAIL,
    NEGARA_REQ,
    NEGARA_OK,
    NEGARA_FAIL,
} from './wilayahTypes';

const negaraState = {
    loading: false,
    negara: [],
    error: ''
}

const kodePosCore = {
    loading: false,
    kodePosCore: [],
    error: ''
}

const kodePos = {
    loading: false,
    kodePos: [],
    error: ''
}

const kotaCoreState = {
    loading: false,
    kotaCore: [],
    error: ''
}

const kotaState = {
    loading: false,
    kota: [],
    error: ''
}

const provinsiCoreState = {
    loading: false,
    provinsiCore: [],
    error: ''
}

const provinsiState = {
    loading: false,
    provinsi: [],
    error: ''
}

export const negaraReducer = (state = negaraState, action) => {
    switch (action.type) {
        case NEGARA_REQ:
            return {
                ...state,
                loading: false,
            }
        case NEGARA_OK:
            return {
                loading: true,
                negara: action.payload,
                error: ''
            }
        case NEGARA_FAIL:
            return {
                loading: false,
                negara: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const kodePosCoreReducer = (state = kodePosCore, action) => {
    switch (action.type) {
        case POST_CORE_REQ:
            return {
                ...state,
                loading: false,
            }
        case POST_CORE_OK:
            return {
                loading: true,
                kodePosCore: action.payload,
                error: ''
            }
        case POST_CORE_FAIL:
            return {
                loading: false,
                kodePosCore: [],
                error: action.payload
            }
        default:
            return state
    }
}


export const kotaCoreReducer = (state = kotaCoreState, action) => {
    switch (action.type) {
        case KOTA_CORE_REQ:
            return {
                ...state,
                loading: false,
            }
        case KOTA_CORE_OK:
            return {
                loading: true,
                kotaCore: action.payload,
                error: ''
            }
        case KOTA_CORE_FAIL:
            return {
                loading: false,
                kotaCore: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const provinsiCoreReducer = (state = provinsiCoreState, action) => {
    switch (action.type) {
        case PROVINSI_CORE_REQ:
            return {
                ...state,
                loading: false,
            }
        case PROVINSI_CORE_OK:
            return {
                loading: true,
                provinsiCore: action.payload,
                error: ''
            }
        case PROVINSI_CORE_FAIL:
            return {
                loading: false,
                provinsiCore: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const kodePosReducer = (state = kodePos, action) => {
    switch (action.type) {
        case POST_REQ:
            return {
                ...state,
                loading: false,
            }
        case POST_OK:
            return {
                loading: true,
                kodePos: action.payload,
                error: ''
            }
        case POST_FAIL:
            return {
                loading: false,
                kodePos: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const kotaReducer = (state = kotaState, action) => {
    switch (action.type) {
        case KOTA_REQ:
            return {
                ...state,
                loading: false,
            }
        case KOTA_OK:
            return {
                loading: true,
                kota: action.payload,
                error: ''
            }
        case KOTA_FAIL:
            return {
                loading: false,
                kota: [],
                error: action.payload
            }
        default:
            return state
    }
}

const provinsiReducer = (state = provinsiState, action) => {
    switch (action.type) {
        case PROVINSI_REQ:
            return {
                ...state,
                loading: false,
            }
        case PROVINSI_OK:
            return {
                loading: true,
                provinsi: action.payload,
                error: ''
            }
        case PROVINSI_FAIL:
            return {
                loading: false,
                provinsi: [],
                error: action.payload
            }
        default:
            return state
    }
}


export default provinsiReducer