import React from 'react';
import { Link } from 'react-router-dom';
import { Spinner, FilterComponent } from '../../components';
import { Pie } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Loader from 'react-loader-spinner';
import DataTable from 'react-data-table-component';

const SectionDashboard = ({
    getProfilUmum,
    profilUmum,
    getDashboardIzin,
    dashboardIzin,
    getDashboardTeknologi,
    dashboardTeknologi,
    getMarketSharePendapatanChart,
    marketSharePendapatan,
    getMarketSharePelangganChart,
    marketSharePelanggan,
    penilaianKesehatan,
    getPenilaianKesehatan
}) => {

    const [loading, setLoading] = React.useState(true);
    const [jumlahProfilUmum, setJumlahProfilUmum] = React.useState('');
    const [dashboardIzinSummary, setDashboardIzinSummary] = React.useState(null);
    const [dashboardTeknologiSummary, setDashboardTeknologiSummary] = React.useState(null);
    const [dataPieChartMarketSharePendapatan, setDataPieChartMarketSharePendapatan] = React.useState(null);
    const [dataPieChartMarketSharePelanggan, setDataPieChartMarketSharePelanggan] = React.useState(null);
    const [penilaianKesehatanState, setPenilaianKesehatanState] = React.useState([]);
    const [tahunValue, setTahunValue] = React.useState([]);

    const [filterText, setFilterText] = React.useState('');
    const filteredItems = penilaianKesehatanState.filter(item => item.company_name.toLowerCase().includes(filterText.toLowerCase()));


    const subHeaderComponentMemo = React.useMemo(() => {
        return <FilterComponent onFilter={e => setFilterText(e.target.value)} filterText={filterText} placeholder="Cari Penyelenggara.." />;
    }, [filterText]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            function makeid() {
                var letters = '0123456789ABCDEF';
                var color = '#';
                for (var i = 0; i < 6; i++) {
                    color += letters[Math.floor(Math.random() * 16)];
                }
                return color;
            }
            penilaianKesehatan.loading && penilaianKesehatan.data && penilaianKesehatan.data.length > 0 && setTahunValue(Object.keys(penilaianKesehatan.data[0]));
            penilaianKesehatan.loading && penilaianKesehatan.data && penilaianKesehatan.data.length > 0 && setPenilaianKesehatanState(penilaianKesehatan.data)
            marketSharePendapatan && marketSharePendapatan.data && marketSharePendapatan.data.map((m, _, i) => setDataPieChartMarketSharePendapatan(
                {
                    maintainAspectRatio: true,
                    responsive: true,
                    labels: i.map(i => i.company_name),
                    datasets: [
                        {
                            data: i.map(i => i.jumlah),
                            backgroundColor: i.map(i => makeid()),
                            hoverBackgroundColor: i.map(i => makeid())
                        }
                    ],
                    options: {
                        plugins: {
                            datalabels: {
                                color: 'pink',
                                labels: {
                                    value: {},
                                    title: {
                                        color: 'blue'
                                    }
                                }
                            }
                        },
                        elements: {
                            arc: {
                                borderWidth: 0
                            }
                        }
                    }
                }));
            marketSharePelanggan && marketSharePelanggan.data && marketSharePelanggan.data.map((m, _, i) => setDataPieChartMarketSharePelanggan(
                {
                    maintainAspectRatio: true,
                    responsive: true,
                    labels: i.map(i => i.company_name),
                    datasets: [
                        {
                            data: i.map(i => i.jumlah),
                            backgroundColor: i.map(i => makeid()),
                            hoverBackgroundColor: i.map(i => makeid())
                        }
                    ],
                    legend: {
                        display: true,
                        position: "bottom",
                        legendCallback: function (chart) {
                            // Return the HTML string here.
                            console.log(chart);

                        }
                    },

                }));
            profilUmum.loading && setJumlahProfilUmum(profilUmum.profilUmum.length);
            dashboardIzin.loading && setDashboardIzinSummary(dashboardIzin.dashboardIzin);
            dashboardTeknologi.loading && setDashboardTeknologiSummary(dashboardTeknologi.dashboardTeknologi)
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [dashboardIzin, dashboardTeknologi, marketSharePelanggan, marketSharePendapatan, penilaianKesehatan, profilUmum]);

    React.useEffect(() => {
        getDashboardIzin();
        getProfilUmum();
        getDashboardTeknologi();
        getMarketSharePendapatanChart();
        getMarketSharePelangganChart();
        getPenilaianKesehatan();
    }, [
        getDashboardIzin,
        getProfilUmum,
        getDashboardTeknologi,
        getMarketSharePendapatanChart,
        getMarketSharePelangganChart,
        getPenilaianKesehatan
    ]);

    const columns = [
        {
            name: 'No',
            selector: (row, index) => index + 1,
            maxWidth: '40px'
        },
        {
            name: `Nama Perusahaan`,
            selector: row => row.company_name,
            minWidth: '250px'
        },
        {
            name: `${tahunValue.length > 0 ? tahunValue[1].slice(6) : tahunValue}`,
            selector: tahunValue.length > 0 ? tahunValue[1] : tahunValue
        },
        {
            name: `${tahunValue.length > 0 ? tahunValue[2].slice(6) : tahunValue}`,
            selector: tahunValue.length > 0 ? tahunValue[2] : tahunValue
        },
        {
            name: `${tahunValue.length > 0 ? tahunValue[3].slice(6) : tahunValue}`,
            selector: `${tahunValue.length > 0 ? tahunValue[3] : tahunValue}`
        },
        {
            name: `${tahunValue.length > 0 ? tahunValue[4].slice(6) : tahunValue}`,
            selector: `${tahunValue.length > 0 ? tahunValue[4] : tahunValue}`
        },
        {
            name: `${tahunValue.length > 0 ? tahunValue[5].slice(6) : tahunValue}`,
            selector: `${tahunValue.length > 0 ? tahunValue[5] : tahunValue}`
        },

    ];
    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none'
            },
        },
    };

    return loading ? (<Spinner />) : profilUmum.error.message === "Unauthenticated." ? (
        window.location.href = "/" &&
        localStorage.removeItem('token')
    ) : (
        <div className="container-fluid">
            <div className="row clearfix">
                <div className="col-lg-4">
                    <div className="card bg-success" style={{ height: 350 }}>
                        <div className="card-header" style={{ backgroundColor: ' rgba(0,0,0,0.2)' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 22, fontWeight: 'bold', paddingBottom: 10 }}>
                                <span>Total<br />Jumlah <br />Penyelenggara  </span>
                                <i className="fas fa-building" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div className="card-body" >
                            <h1 className="text-center" style={{ fontSize: 130 }}>{jumlahProfilUmum}</h1>
                        </div>
                        <div style={{ background: 'rgba(0, 0, 0, 0.2)', bottom: '0px', position: 'absolute', width: 'inherit', borderRadius: '0px 0px 5px 5px' }}>
                            {/* <div style={{ background: ' rgba(0,0,0,0.2)', paddingBotom: 0 }}> */}
                            <div className="card-footer">
                                <Link to="#"
                                    onClick={() => window.location.href = '/penyelenggara'}
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'none', color: '#fff' }}
                                >
                                    <span>Rincian <i className="fas fa-arrow-circle-right" aria-hidden="true"></i></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div >
                <div className="col-lg-4">
                    <div className="card bg-warning" style={{ height: 350, paddingBottom: 0 }}>
                        <div className="card-header" style={{ backgroundColor: ' rgba(0,0,0,0.2)' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 22, fontWeight: 'bold', paddingBottom: 10 }}>
                                <span>Total <br />Izin Efektif  Penyelenggara</span>
                                <i className="fas fa-paper-plane" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div className="card-body" style={{ paddingBottom: 0 }}>
                            <div className="row clearfix" style={{ marginLeft: 4, paddingTop: 10, paddingBottom: 20 }}>
                                <div className="col-md-6">
                                    <strong className="h5">JABERSEL</strong>
                                </div>
                                <div className="col-md-6">
                                    <span className="h5">: {dashboardIzinSummary !== null && dashboardIzinSummary.jabersel}</span>
                                </div>
                                <div className="col-md-6">
                                    <strong className="h5">JABERTRUNK</strong>
                                </div>
                                <div className="col-md-6">
                                    <span className="h5">: {dashboardIzinSummary !== null && dashboardIzinSummary.jabertrunk}</span>
                                </div>
                                <div className="col-md-6">
                                    <strong className="h5">JARTAPLOK</strong>
                                </div>
                                <div className="col-md-6">
                                    <span className="h5">: {dashboardIzinSummary !== null && dashboardIzinSummary.jartaplok}</span>
                                </div>
                                <div className="col-md-6">
                                    <strong className="h5">JARTAPLOK PS</strong>
                                </div>
                                <div className="col-md-6">
                                    <span className="h5">: {dashboardIzinSummary !== null && dashboardIzinSummary.jartaplokps}</span>
                                </div>
                                <div className="col-md-6">
                                    <strong className="h5">JARTAP SLI</strong>
                                </div>
                                <div className="col-md-6">
                                    <span className="h5">: {dashboardIzinSummary !== null && dashboardIzinSummary.jartapsli}</span>
                                </div>
                                <div className="col-md-6">
                                    <strong className="h5">JARTAP SLJJ</strong>
                                </div>
                                <div className="col-md-6">
                                    <span className="h5">: {dashboardIzinSummary !== null && dashboardIzinSummary.jartapsljj}</span>
                                </div>
                                <div className="col-md-6">
                                    <strong className="h5">JARTAPTUP</strong>
                                </div>
                                <div className="col-md-6">
                                    <span className="h5">: {dashboardIzinSummary !== null && dashboardIzinSummary.jartaptup}</span>
                                </div>
                                <div className="col-md-6">
                                    <strong className="h5">Total</strong>
                                </div>
                                <div className="col-md-6">
                                    <span className="h5">: {dashboardIzinSummary !== null && dashboardIzinSummary.total}</span>
                                </div>

                                {/* <h1 className="text-center">{jumlahIzin}</h1> */}
                            </div>
                        </div>
                        <div style={{ background: 'rgba(0, 0, 0, 0.2)', bottom: '0px', position: 'absolute', width: 'inherit', borderRadius: '0px 0px 5px 5px' }}>
                            <div className="card-footer">
                                <Link to="#" onClick={() => window.location.href = '/izin'}
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'none', color: '#fff' }}
                                >
                                    <span>Rincian <i className="fas fa-arrow-circle-right" aria-hidden="true"></i></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div >

                <div className="col-lg-4">
                    {/* <Link to="#" onClick={() => window.location.href = '/teknologi'}> */}
                    <div className="card bg-danger" style={{ height: 350 }}>
                        <div className="card-header" style={{ backgroundColor: ' rgba(0,0,0,0.2)' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 22, fontWeight: 'bold', paddingBottom: 10 }}>
                                <span>Total <br />Teknologi Jaringan<br />Penyelenggara </span>
                                <i className="fas fa-broadcast-tower" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div className="card-Body" >
                            <div className="row clearfix" style={{ marginLeft: 0, paddingTop: 10, paddingBottom: 15, fontSize: 16 }}>
                                <div className="col-md-6">
                                    <strong>SATELIT</strong>
                                </div>
                                <div className="col-md-6">
                                    <strong> : {dashboardTeknologiSummary !== null && dashboardTeknologiSummary.satelit} </strong>
                                </div>
                                <div className="col-md-6">
                                    <strong>VSAT</strong>
                                </div>
                                <div className="col-md-6">
                                    <strong>: {dashboardTeknologiSummary !== null && dashboardTeknologiSummary.vsat} </strong>
                                </div>
                                <div className="col-md-6">
                                    <strong>FIBER OPTIK</strong>
                                </div>
                                <div className="col-md-6">
                                    <strong>: {dashboardTeknologiSummary !== null && dashboardTeknologiSummary.fo} </strong>
                                </div>
                                <div className="col-md-6">
                                    <strong>MICROWAVE LINK</strong>
                                </div>
                                <div className="col-md-6">
                                    <strong>: {dashboardTeknologiSummary !== null && dashboardTeknologiSummary.microwavelink} </strong>
                                </div>
                                <div className="col-md-6">
                                    <strong>SELULER</strong>
                                </div>
                                <div className="col-md-6">
                                    <strong> : {dashboardTeknologiSummary !== null && dashboardTeknologiSummary.seluler} </strong>
                                </div>
                                <div className="col-md-6">
                                    <strong>BWA</strong>
                                </div>
                                <div className="col-md-6">
                                    <strong> : {dashboardTeknologiSummary !== null && dashboardTeknologiSummary.bwa} </strong>
                                </div>
                                <div className="col-md-6">
                                    <strong>TRUNKING</strong>
                                </div>
                                <div className="col-md-6">
                                    <strong> : {dashboardTeknologiSummary !== null && dashboardTeknologiSummary.trunking} </strong>
                                </div>

                                {/* <h1 className="text-center">{jumlahIzin}</h1> */}
                            </div>
                        </div>
                        <div style={{ background: 'rgba(0, 0, 0, 0.2)', bottom: '0px', position: 'absolute', width: 'inherit', borderRadius: '0px 0px 5px 5px' }}>
                            <div className="card-footer">
                                <Link to="#" onClick={() => window.location.href = '/teknologi'}
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'none', color: '#fff' }}
                                >
                                    <span>Rincian <i className="fas fa-arrow-circle-right" aria-hidden="true"></i></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="col-md-12">
                        <div className="card-content">
                            <div className="material-datatables">
                                <DataTable
                                    title="Kesehatan Keuangan"
                                    sortable={true}
                                    columns={columns}
                                    data={filteredItems}
                                    progressPending={loading}
                                    progressComponent={<Loader type="Watch" color="#00BFFF" height={100}
                                        width={100} />}
                                    customStyles={customStyles}
                                    responsive={true}
                                    paginationPerPage={10}
                                    paginationRowsPerPageOptions={[10, 50, 100]}
                                    pagination
                                    striped
                                    pointerOnHover
                                    subHeaderComponent={subHeaderComponentMemo}
                                    subHeader
                                />
                            </div>
                        </div>
                    </div>

                </div>

                <div className="card">
                    <div className="card-content">
                        <div className="row">
                            <div className="col-md-12">
                                <table className="table text-center" border="1">
                                    <tbody>
                                        <tr style={{
                                            border: '3px rgba(0, 50, 122, 1) solid',
                                            textAlign: 'center',
                                            borderRight: 'none',
                                            borderLeft: 'none'
                                        }}>
                                            <th align="center" scope="col">Kategori</th>
                                            <td align="center">AAA</td>
                                            <td align="center">AA</td>
                                            <td align="center">A</td>
                                            <td align="center">BBB</td>
                                            <td align="center">BB</td>
                                            <td align="center">B</td>
                                            <td align="center">CCC</td>
                                            <td align="center">CC</td>
                                            <td align="center">C</td>
                                        </tr>
                                        <tr style={{
                                            borderRight: 'none',
                                            borderLeft: 'none'
                                        }}>
                                            <th align="center" scope="row">Keterangan</th>
                                            <th align="center" scope="col" colSpan="3">Sehat</th>
                                            <th align="center" scope="col" colSpan="3">Kurang Sehat</th>
                                            <th align="center" scope="col" colSpan="3">Tidak Sehat</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-content">
                        <div className="row">
                            <div className="col-md-6">
                                <p className="text-center h4" style={{ fontWeight: 'bold' }}>Market Share Pendapatan (%)</p>
                                {
                                    dataPieChartMarketSharePendapatan === null ? <p className="text-center font-italic">Data belum tersedia</p> :
                                        <Pie
                                            data={dataPieChartMarketSharePendapatan}
                                            width={50} height={50}
                                            options={{
                                                responsive: true,
                                                title: {
                                                    display: true,
                                                    position: "top",
                                                    text: "Last Week Registered Users -  Day Wise Count",
                                                    fontSize: 18,
                                                    fontColor: "#111"
                                                },
                                                legend: {
                                                    display: true,
                                                    position: "top",
                                                    labels: {
                                                        render: 'percentage',
                                                    }
                                                },
                                                plugins: {
                                                    labels: [{
                                                        render: 'label',
                                                        fontSize: 20,
                                                        fontStyle: 'bold',
                                                        position: 'outside',
                                                        outsidePadding: 4,
                                                        precision: 0,
                                                        textShadow: true,
                                                        textMargin: 20

                                                    },
                                                    {
                                                        render: 'percentage',
                                                        fontSize: 25,
                                                        fontStyle: 'bold',
                                                        fontColor: '#000',
                                                        precision: 0,
                                                    }],
                                                }
                                            }}
                                            plugins={[ChartDataLabels]}
                                        />
                                }
                                <div id="legend" />
                            </div>
                            <div className="col-md-6">
                                <p className="text-center h4" style={{ fontWeight: 'bold' }}>Market Share Pelanggan (%)</p>
                                {
                                    dataPieChartMarketSharePelanggan === null ? <p className="text-center font-italic">Data belum tersedia</p> :
                                        <Pie
                                            data={dataPieChartMarketSharePelanggan}
                                            plugins={[ChartDataLabels]}
                                        />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default SectionDashboard