import {
    RASIO_KONVERSI_KESEHATAN_KEUANGAN_REQ,
    RASIO_KONVERSI_KESEHATAN_KEUANGAN_SUCCESS,
    RASIO_KONVERSI_KESEHATAN_KEUANGAN_FAIL,
    RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_REQ,
    RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_SUCCESS,
    RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_FAIL,
    RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_MESSAGE_REQ,
    RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_MESSAGE_SUCCESS,
    RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_MESSAGE_FAIL,
} from './rasioKonversiKesehatanKeuanganTypes';

const rasioKonversiKesehatanKeuanganMessageState = {
    loading: false,
    data: [],
    err: ''
}

const rasioKonversiKesehatanKeuanganState = {
    loading: false,
    data: [],
    err: ''
}

const rasioKonversiKesehatanKeuanganCrudState = {
    loading: false,
    data: [],
    err: ''
}


export const rasioKonversiKesehatanKeuanganCrudMessageReducer = (state = rasioKonversiKesehatanKeuanganMessageState, action) => {
    switch (action.type) {
        case RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_MESSAGE_REQ:
            return {
                ...state,
                loading: false,
            }
        case RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_MESSAGE_SUCCESS:
            return {
                loading: true,
                data: action.payload,
                error: ''
            }
        case RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_MESSAGE_FAIL:
            return {
                loading: false,
                data: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const rasioKonversiKesehatanKeuanganCrudReducer = (state = rasioKonversiKesehatanKeuanganCrudState, action) => {
    switch (action.type) {
        case RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_REQ:
            return {
                ...state,
                loading: false,
            }
        case RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_SUCCESS:
            return {
                loading: true,
                data: action.payload,
                error: ''
            }
        case RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_FAIL:
            return {
                loading: false,
                data: [],
                error: action.payload
            }
        default:
            return state
    }
}

const rasioKonversiKesehatanKeuanganReducer = (state = rasioKonversiKesehatanKeuanganState, action) => {
    switch (action.type) {
        case RASIO_KONVERSI_KESEHATAN_KEUANGAN_REQ:
            return {
                ...state,
                loading: false,
            }
        case RASIO_KONVERSI_KESEHATAN_KEUANGAN_SUCCESS:
            return {
                loading: true,
                data: action.payload,
                error: ''
            }
        case RASIO_KONVERSI_KESEHATAN_KEUANGAN_FAIL:
            return {
                loading: false,
                data: [],
                error: action.payload
            }
        default:
            return state
    }
}

export default rasioKonversiKesehatanKeuanganReducer