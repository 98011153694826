import React from 'react';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import Loader from 'react-loader-spinner';
import { Modal } from 'react-bootstrap';
import ReactModal from 'react-modal';
import { useForm, Controller } from 'react-hook-form';
import Select from "react-select";
import swal from 'sweetalert';

import { Spinner, ActionButton, Styles } from '../../components'
import getKonversiKesehatanKeuanganPivot, {
    getKonversiKesehatanKeuanganCrud,
    uploadRasioKonversiKesehatanKeuangan,
    deleteRasioKonversiKesehatanKeuangan,
    addRasioKonversiKesehatanKeuangan
} from '../../redux/rasioKonversiKesehatanKeuangan/rasioKonversiKesehatanKeuanganAction';
import { Link } from 'react-router-dom';
import { getKeteranganRasioKesehatan } from '../../redux';

const RasioKonversiKesahatanKeuangan = ({
    id,
    nama,
    rasioKonversiKesehatanKeuangan,
    getKonversiKesehatanKeuanganPivot,
    getKonversiKesehatanKeuanganCrud,
    rasioKonversiKesehatanKeuanganCrud,
    role,
    uploadRasioKonversiKesehatanKeuangan,
    rasioKonversiKesehatanKeuanganCrudMessage,
    deleteRasioKonversiKesehatanKeuangan,
    addRasioKonversiKesehatanKeuangan,
    getKeteranganRasioKesehatan,
    keteranganRasioKesehatan
}) => {
    const { register, handleSubmit, control } = useForm();
    const [crudRasioKonversiKesehatan, setCrudRasioKonversiKesehatan] = React.useState(true);
    const toCrudKonversiRasioKesehatan = () => setCrudRasioKonversiKesehatan(!crudRasioKonversiKesehatan);
    const [rowRasioKonversKesehatan, setRowRasioKonversiKesehatan] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [tahunRasioKonversiKesehatanValue, setTahunRasioKonversiKesehatanValue] = React.useState([]);
    const [rowRasioKonversiKesehatanCrud, setRowRasioKonversiKesehatanCrud] = React.useState([]);
    const [tahun, setTahun] = React.useState([]);
    const [keteranganRasioKesehatanState, setKeteranganRasioKesehatanState] = React.useState([]);
    const [fileUpload, setFileUpload] = React.useState(null);

    // value input
    const [tahunInput, setTahunInput] = React.useState('');
    const [keteranganRasioInput, setKeteranganRasioInput] = React.useState('');

    const [showModalAddRasioKonversiKesehatanKeuangan, setShowModalAddRasioKonversiKeseatanKeuangan] = React.useState(false);
    const setupShowModalRasioKonversiKesehatanKeuangan = () => setShowModalAddRasioKonversiKeseatanKeuangan(!showModalAddRasioKonversiKesehatanKeuangan);

    React.useEffect(() => {
        setTahun(Tahun().map(t => ({ value: t, label: t })));
    }, []);

    React.useEffect(() => {
        const notif = () => {
            rasioKonversiKesehatanKeuanganCrudMessage.loading && rasioKonversiKesehatanKeuanganCrudMessage.data && swal(`${rasioKonversiKesehatanKeuanganCrudMessage.data.message}`, {
                buttons: true
            }).then((value) => {
                if (value) {
                    getKonversiKesehatanKeuanganPivot(id);
                    getKonversiKesehatanKeuanganCrud(id);
                }
            });
        };

        return notif();
    }, [getKonversiKesehatanKeuanganCrud, getKonversiKesehatanKeuanganPivot, id, rasioKonversiKesehatanKeuanganCrudMessage]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            keteranganRasioKesehatan && keteranganRasioKesehatan.keteranganRasioKesehatan && setKeteranganRasioKesehatanState(keteranganRasioKesehatan.keteranganRasioKesehatan.map(ketraskes => ({ value: ketraskes.id, label: ketraskes.nama_keterangan })))
            rasioKonversiKesehatanKeuangan.loading && rasioKonversiKesehatanKeuangan.data && setRowRasioKonversiKesehatan(rasioKonversiKesehatanKeuangan.data);
            rasioKonversiKesehatanKeuangan.loading && rasioKonversiKesehatanKeuangan.data && rasioKonversiKesehatanKeuangan.data.length > 0 && setTahunRasioKonversiKesehatanValue(Object.keys(rasioKonversiKesehatanKeuangan.data[0]));
            rasioKonversiKesehatanKeuanganCrud.loading && rasioKonversiKesehatanKeuanganCrud.data && setRowRasioKonversiKesehatanCrud(rasioKonversiKesehatanKeuanganCrud.data.map(konversi => ({
                nama_keterangan: konversi.nama_keterangan,
                nilai_konversi_rasio: konversi.nilai_konversi_rasio,
                tahun: konversi.tahun,
                action: <>
                    <ActionButton
                        title="fa fa-pencil"
                        css="btn-warning"
                        fun={() => window.location.href = `/penyelenggara-detail-keuangan/${id}/${nama}/edit/${konversi.id}/rasio konversi kesehatan keuangan`}
                    />
                    &nbsp;
                    <ActionButton title="fa fa-trash" css="btn-danger"
                        fun={
                            () => swal(`Apakah Yakin Data Rasio Kesehatan Keuangan ${konversi.nama_keterangan} ${konversi.company_name} tahun ${konversi.tahun} ini akan dihapus?`, {
                                buttons: ["Batal", true],
                            }).then((value) => {
                                if (value) {
                                    deleteRasioKonversiKesehatanKeuangan(konversi.id);
                                }
                            })
                        }
                    />
                </>
            })))
            setLoading(false);
        }, 10000);
        return () => clearTimeout(timeout);
    }, [deleteRasioKonversiKesehatanKeuangan, id, keteranganRasioKesehatan, nama, rasioKonversiKesehatanKeuangan, rasioKonversiKesehatanKeuanganCrud]);

    React.useEffect(() => {
        getKonversiKesehatanKeuanganPivot(id);
        getKonversiKesehatanKeuanganCrud(id);
        getKeteranganRasioKesehatan();
    }, [getKeteranganRasioKesehatan, getKonversiKesehatanKeuanganCrud, getKonversiKesehatanKeuanganPivot, id]);


    const columnsRasioKonversiKesehatan = [
        {
            name: 'No',
            selector: (row, index) => index === row.length - 2 ? null : index + 1,
            maxWidth: '10px'
        },
        {
            name: `Nama Keterangan`,
            selector: row => row.nama_keterangan,
            minWidth: '300px'
        },
        {
            name: `${tahunRasioKonversiKesehatanValue.length > 0 ? tahunRasioKonversiKesehatanValue[1].slice(6) : tahunRasioKonversiKesehatanValue}`,
            selector: tahunRasioKonversiKesehatanValue.length > 0 ? tahunRasioKonversiKesehatanValue[1] : `${tahunRasioKonversiKesehatanValue}`
        },
        {
            name: `${tahunRasioKonversiKesehatanValue.length > 0 ? tahunRasioKonversiKesehatanValue[2].slice(6) : tahunRasioKonversiKesehatanValue}`,
            selector: tahunRasioKonversiKesehatanValue.length > 0 ? tahunRasioKonversiKesehatanValue[2] : `${tahunRasioKonversiKesehatanValue}`
        },
        {
            name: `${tahunRasioKonversiKesehatanValue.length > 0 ? tahunRasioKonversiKesehatanValue[3].slice(6) : tahunRasioKonversiKesehatanValue}`,
            selector: tahunRasioKonversiKesehatanValue.length > 0 ? tahunRasioKonversiKesehatanValue[3] : `${tahunRasioKonversiKesehatanValue}`
        },
        {
            name: `${tahunRasioKonversiKesehatanValue.length > 0 ? tahunRasioKonversiKesehatanValue[4].slice(6) : tahunRasioKonversiKesehatanValue}`,
            selector: tahunRasioKonversiKesehatanValue.length > 0 ? tahunRasioKonversiKesehatanValue[4] : `${tahunRasioKonversiKesehatanValue}`
        },
        {
            name: `${tahunRasioKonversiKesehatanValue.length > 0 ? tahunRasioKonversiKesehatanValue[5].slice(6) : tahunRasioKonversiKesehatanValue}`,
            selector: tahunRasioKonversiKesehatanValue.length > 0 ? tahunRasioKonversiKesehatanValue[5] : `${tahunRasioKonversiKesehatanValue}`
        },
    ];


    const columnsRasioKonversiKesehatanCrud = [
        {
            name: 'No',
            selector: (row, index) => index + 1,
            maxWidth: '10px'
        },
        {
            name: `Nama Keterangan`,
            selector: row => row.nama_keterangan
        },
        {
            name: `Nilai Konversi Rasio`,
            selector: row => row.nilai_konversi_rasio
        },
        {
            name: `Tahun`,
            selector: row => row.tahun
        },
        {
            name: ``,
            selector: row => row.action
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none'
            },
        },
    };

    const customStylesModal = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: '0',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '700px',
            height: '80%'
        },
    };

    const onUpload = (e) => setFileUpload(e.target.files[0]);

    const uploadNilaiKonversiRasioKesahatanKeuanganSubmit = () => {
        const formData = new FormData();
        formData.append("nama_file", fileUpload);
        uploadRasioKonversiKesehatanKeuangan(formData);
    }

    const submitRasioKonversiKesehatanKeuangan = (data) => {
        const input = {
            tahun: tahunInput,
            data_umum_id: id,
            company_name: nama,
            nama_ket_rasio_kesehatan_id: keteranganRasioInput,
            nilai_konversi_rasio: data.nilai_konversi_rasio
        }
        addRasioKonversiKesehatanKeuangan(input);
    }


    return loading ? <Spinner /> : (
        <>
            {
                role.role_id !== 3 &&
                <div className="toolbar" style={{ float: 'right', display: 'flex', flexDirection: 'row', }}>
                    <div className="card-header">
                        <Button
                            id="basic-button"
                            aria-controls="basic-menu"
                            className="btn btn"
                            size="large"
                            aria-haspopup="true"
                            onClick={setupShowModalRasioKonversiKesehatanKeuangan}
                            style={{ backgroundColor: 'rgba(0, 50, 122, 1)', color: '#fff', marginRight: '10px' }}

                        >
                            <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;Tambah
                        </Button>
                        <Button
                            id="basic-button"
                            aria-controls="basic-menu"
                            className={crudRasioKonversiKesehatan ? "btn btn-success" : "btn btn-danger"}
                            size="large"
                            aria-haspopup="true"
                            onClick={toCrudKonversiRasioKesehatan}
                        >
                            <i className={crudRasioKonversiKesehatan ? "fa fa-edit" : "fa fa-eye-slash"} aria-hidden="true"></i> {crudRasioKonversiKesehatan ? " Edit" : " View"}
                        </Button>
                    </div>
                </div>
            }
            <div className="card-content">
                <div className="material-datatables">
                    {
                        crudRasioKonversiKesehatan ?
                            <DataTable
                                columns={columnsRasioKonversiKesehatan}
                                data={rowRasioKonversKesehatan}
                                progressPending={loading}
                                customStyles={customStyles}
                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100}
                                    width={100} />}
                                responsive={true}
                                pagination
                            />

                            :
                            <DataTable
                                columns={columnsRasioKonversiKesehatanCrud}
                                data={rowRasioKonversiKesehatanCrud}
                                customStyles={customStyles}
                                progressPending={loading}
                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100}
                                    width={100} />}
                                responsive={true}
                                pagination
                            />
                    }
                    <div className="table-responsive">
                        <table className="table text-center" border="1">
                            <tbody>
                                <tr style={{
                                    border: '3px rgba(0, 50, 122, 1) solid',
                                    textAlign: 'center',
                                    borderRight: 'none',
                                    borderLeft: 'none'
                                }}>
                                    <th align="center" scope="col">Kategori</th>
                                    <td align="center">AAA</td>
                                    <td align="center">AA</td>
                                    <td align="center">A</td>
                                    <td align="center">BBB</td>
                                    <td align="center">BB</td>
                                    <td align="center">B</td>
                                    <td align="center">CCC</td>
                                    <td align="center">CC</td>
                                    <td align="center">C</td>
                                </tr>
                                <tr style={{
                                    borderRight: 'none',
                                    borderLeft: 'none'
                                }}>
                                    <th align="center" scope="row">Keterangan</th>
                                    <th align="center" scope="col" colSpan="3" className="text-center">Sehat</th>
                                    <th align="center" scope="col" colSpan="3" className="text-center">Kurang Sehat</th>
                                    <th align="center" scope="col" colSpan="3" className="text-center">Tidak Sehat</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <ReactModal
                ariaHideApp={false}
                isOpen={showModalAddRasioKonversiKesehatanKeuangan}
                preventScroll={true}
                scrollable={true}
                style={customStylesModal}
            >
                <div style={{ float: 'right' }}>
                    <Button id="basic-button"
                        aria-controls="basic-menu"
                        color="error"
                        onClick={setupShowModalRasioKonversiKesehatanKeuangan}
                        size="small">
                        <span className="material-icons">
                            close
                        </span>
                    </Button>
                </div>
                <Modal.Title style={{ fontWeight: 'bold' }}>Tambah Data Nilai Konversi Rasio Kesehatan Keuangan</Modal.Title>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(uploadNilaiKonversiRasioKesahatanKeuanganSubmit)}
                >
                    <h5 className="text-center">Import Data Nilai Konversi Rasio Kesehatan Keuangan {nama}</h5>
                    <input
                        type="file"
                        name="nama_file"
                        onChange={onUpload}
                    />
                    {
                        rasioKonversiKesehatanKeuanganCrudMessage && rasioKonversiKesehatanKeuanganCrudMessage.error && <span className="text-danger">{rasioKonversiKesehatanKeuanganCrudMessage.error.nama_file}</span>
                    }
                    <Link to="/download/nilai konversi rasio kesehatan.xlsx" style={{ float: 'right' }} target="_blank">Download Template Upload Nilai Konversi Rasio Kesehatan Keuangan</Link><br />
                    <Button variant="contained" color="primary" type="submit" >Upload</Button>
                    <br />
                    {
                        rasioKonversiKesehatanKeuanganCrudMessage && rasioKonversiKesehatanKeuanganCrudMessage.error && rasioKonversiKesehatanKeuanganCrudMessage.error.message && <span className="text-danger">{rasioKonversiKesehatanKeuanganCrudMessage.error.message.map(r => r)}</span>
                    }
                </form>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitRasioKonversiKesehatanKeuangan)}
                >
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="tahun"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={tahun}
                                    placeholder="Tahun.."
                                    onChange={(e) => setTahunInput(e.value)}
                                />
                            )}
                        />
                        {
                            rasioKonversiKesehatanKeuanganCrudMessage && rasioKonversiKesehatanKeuanganCrudMessage.error && <span className="text-danger">{rasioKonversiKesehatanKeuanganCrudMessage.error.tahun}</span>
                        }
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="tahun"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={keteranganRasioKesehatanState}
                                    placeholder="Ketangan Rasio Kesehatan Keuangan"
                                    onChange={(e) => setKeteranganRasioInput(e.value)}
                                />
                            )}
                        />
                        {
                            rasioKonversiKesehatanKeuanganCrudMessage && rasioKonversiKesehatanKeuanganCrudMessage.error && <span className="text-danger">{rasioKonversiKesehatanKeuanganCrudMessage.error.nama_ket_rasio_kesehatan_id}</span>
                        }
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Nilai Konversi Rasio"
                            type="text"
                            className="form-control"
                            {...register("nilai_konversi_rasio")}
                        />
                        {rasioKonversiKesehatanKeuanganCrudMessage && rasioKonversiKesehatanKeuanganCrudMessage.error && <span className="text-danger">{rasioKonversiKesehatanKeuanganCrudMessage.error.nilai_konversi_rasio}</span>}
                    </Styles.InputForm>

                    <Button variant="contained" color="error" onClick={setupShowModalRasioKonversiKesehatanKeuangan} style={{ marginRight: 5 }}>Close</Button>
                    <Button variant="contained" color="primary" type="submit">Save</Button>
                </form >
            </ReactModal >
        </>
    )
}

const Tahun = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= 1945; i--) {
        tahun.push(i);
    }
    return tahun;
}

const mapStateToProps = state => {
    return {
        rasioKonversiKesehatanKeuangan: state.rasioKonversiKesehatanKeuangan,
        rasioKonversiKesehatanKeuanganCrud: state.rasioKonversiKesehatanKeuanganCrud,
        rasioKonversiKesehatanKeuanganCrudMessage: state.rasioKonversiKesehatanKeuanganCrudMessage,
        keteranganRasioKesehatan: state.keteranganRasioKesehatan
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getKeteranganRasioKesehatan: () => dispatch(getKeteranganRasioKesehatan()),
        addRasioKonversiKesehatanKeuangan: (data) => dispatch(addRasioKonversiKesehatanKeuangan(data)),
        deleteRasioKonversiKesehatanKeuangan: (id) => dispatch(deleteRasioKonversiKesehatanKeuangan(id)),
        uploadRasioKonversiKesehatanKeuangan: (data) => dispatch(uploadRasioKonversiKesehatanKeuangan(data)),
        getKonversiKesehatanKeuanganPivot: (id) => dispatch(getKonversiKesehatanKeuanganPivot(id)),
        getKonversiKesehatanKeuanganCrud: (id) => dispatch(getKonversiKesehatanKeuanganCrud(id))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(RasioKonversiKesahatanKeuangan)