import {
    AUTH_REQ,
    AUTH_SUCCESS,
    AUTH_FAIL,
    FORGOT_PASS_REQ,
    FORGOT_PASS_SUCCESS,
    FORGOT_PASS_FAIL,
    RESET_PASS_REQ,
    RESET_PASS_SUCCESS,
    RESET_PASS_FAIL,
} from './authTypes';

const resetPassState = {
    loading: false,
    resetPass: '',
    error: ''
}

const forgotPassState = {
    loading: false,
    forgotPass: '',
    error: ''
}

const logoutState = {
    loading: false,
    logout: null,
    error: ''
}

export const resetPassReducer = (state = resetPassState, action) => {
    switch (action.type) {
        case RESET_PASS_REQ:
            return {
                ...state,
                loading: false,
            }
        case RESET_PASS_SUCCESS:
            return {
                loading: true,
                resetPass: action.payload,
                error: ''
            }
        case RESET_PASS_FAIL:
            return {
                loading: true,
                resetPass: null,
                error: action.payload
            }
        default:
            return state
    }
}

export const forgotPassReducer = (state = forgotPassState, action) => {
    switch (action.type) {
        case FORGOT_PASS_REQ:
            return {
                ...state,
                loading: false,
            }
        case FORGOT_PASS_SUCCESS:
            return {
                loading: true,
                forgotPass: action.payload,
                error: ''
            }
        case FORGOT_PASS_FAIL:
            return {
                loading: true,
                forgotPass: null,
                error: action.payload
            }
        default:
            return state
    }
}

const logoutReducer = (state = logoutState, action) => {
    switch (action.type) {
        case AUTH_REQ:
            return {
                ...state,
                loading: true,
                logout: null,
                error: ''
            }
        case AUTH_SUCCESS:
            return {
                loading: false,
                logout: action.payload,
                error: ''
            }
        case AUTH_FAIL:
            return {
                loading: false,
                logout: null,
                error: action.payload
            }
        default:
            return state
    }
}

export default logoutReducer