import logoPutih from './logo_kominfo_putih.svg';
import logo from './logo.svg';
import bgLogin from './bg-login.jpg';
import constructor from './under-constructor.png';
import notfound from './notfound.png';
import logoDashboard from './logo-kominfo-asli.png';

export const Images = {
    logoDashboard,
    notfound,
    constructor,
    bgLogin,
    logo,
    logoPutih
}
