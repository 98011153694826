import Dashboard from './Dashboard';
import { connect } from 'react-redux';
import { getUser } from '../../redux';

const mapStateToProps = state => {
    return {
        user: state.user,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUser: () => dispatch(getUser()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)