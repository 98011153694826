import React from 'react';
import DataTable from 'react-data-table-component';
import { useParams } from 'react-router';
import Loader from 'react-loader-spinner';
import ReactModal from 'react-modal';
import { ListGroup, Modal, TabContent } from 'react-bootstrap';
import Select from "react-select";
import swal from 'sweetalert';
import { useForm, Controller } from 'react-hook-form';
import Button from '@mui/material/Button';

import { Styles, ActionButton, /*Spinner*/ } from '../../components';
import { Link } from 'react-router-dom';
// import { Bar } from 'react-chartjs-2';

const Jartaptup = ({
    getJartaptupJaringanByCompany,
    getJartaptupPelangganByCompany,
    jartaptupJaringan,
    jartaptupPelanggan,
    jartaptupMessage,
    addJartaptupJaringan,
    jartaptupTech,
    addJartaptupPelanggan,
    getJartaptupTech,
    deletejartaptupPelanggan,
    role,
    uploadJartaptupPelanggan,
    uploadJartaptupJaringan,
    getJartaptupNetwork,
    jartaptupNetwork,
    deletejartaptupJaringan
}) => {
    const { id, nama } = useParams();
    const [loading, setLoading] = React.useState(true);
    const { register, handleSubmit, control } = useForm();
    const [jartptupPelangganData, setJartptupPelangganData] = React.useState([]);
    const [jartptupJaringanData, setJartptupJaringanData] = React.useState([]);
    const [fileUpload, setFileUpload] = React.useState(null);
    const [fileUploadJaringan, setFileUploadJaringan] = React.useState(null);
    const [tahun, setTahun] = React.useState([]);
    const [showModalAddJartaptupPelanggan, setShowModalAddJartaptupPelanggan] = React.useState(false);
    const [showModalAddJartaptupJaringan, setShowModalAddJartaptupJaringan] = React.useState(false);

    const [jartaptupTechData, setJartaptupTechData] = React.useState([]);
    const [jartaptupTechName, setJartaptupTechName] = React.useState([]);
    const [jartaptupTechInput, setJartaptupTechInput] = React.useState('');
    const [inputTahun, setInputTahun] = React.useState('');

    // value input jaringan
    const [tahunInputJaringan, setTahunInputJaringan] = React.useState('');
    const [kapasitasValue, setKapasitasValue] = React.useState('');
    const [itemKapasitasValue, setItemKapasitasValue] = React.useState('')

    const setupModalAddjartaptupPelanggan = () => setShowModalAddJartaptupPelanggan(!showModalAddJartaptupPelanggan);
    const setupModalAddjartaptupJaringan = () => setShowModalAddJartaptupJaringan(!showModalAddJartaptupJaringan);

    React.useEffect(() => {
        setTahun(Tahun().map(t => ({ value: t, label: t })));
    }, []);

    React.useEffect(() => {
        jartaptupNetwork && jartaptupNetwork.jartaptupNetwork && setJartaptupTechName(
            jartaptupNetwork.jartaptupNetwork.map(tech => ({
                label: tech.nama_item,
                value: tech.id
            }))
        )
    }, [jartaptupNetwork]);

    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none'
            },
        },
    };

    const columnPelanggan = [
        {
            name: 'Nama Perusahaan',
            selector: 'company_name'
        },
        {
            name: 'Teknologi',
            selector: 'teknologi'
        },
        {
            name: 'Perorangan',
            selector: 'perorangan'
        },
        {
            name: 'Korporasi',
            selector: 'korporasi'
        },
        {
            name: 'Tahun',
            selector: 'tahun',
        },
        {
            name: 'Action',
            selector: 'action'
        }
    ];
    const columnPelangganGuest = [
        {
            name: 'Nama Perusahaan',
            selector: 'company_name'
        },
        {
            name: 'Teknologi',
            selector: 'teknologi'
        },
        {
            name: 'Perorangan',
            selector: 'perorangan'
        },
        {
            name: 'Korporasi',
            selector: 'korporasi'
        },
        {
            name: 'Tahun',
            selector: 'tahun',
        }
    ];
    const columnJaringan = [
        {
            name: 'Kapasitas',
            selector: 'kapasitas'
        },
        {
            name: 'Item Kapasitas',
            selector: 'item_kapasitas'
        },
        {
            name: 'Jumlah',
            selector: 'jumlah'
        },
        {
            name: 'Nama Satelit',
            selector: 'nama_satelit'
        },
        {
            name: 'Tahun',
            selector: 'tahun',
        },
        {
            name: 'Action',
            selector: 'action'
        }
    ];
    const columnJaringanGuest = [
        {
            name: 'Kapasitas',
            selector: 'kapasitas'
        },
        {
            name: 'Item Kapasitas',
            selector: 'item_kapasitas'
        },
        {
            name: 'Jumlah',
            selector: 'jumlah'
        },
        {
            name: 'Nama Satelit',
            selector: 'nama_satelit',
        },
        {
            name: 'Tahun',
            selector: 'tahun',
        }
    ];
    React.useState(() => {
        getJartaptupJaringanByCompany(id);
        getJartaptupPelangganByCompany(id);
        getJartaptupTech();
    }, [getJartaptupJaringanByCompany]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            jartaptupTech.loading && jartaptupTech.jartaptupTech && setJartaptupTechData(
                jartaptupTech.jartaptupTech.map(tech => ({
                    label: tech.nama,
                    value: tech.id
                }))
            )
            jartaptupPelanggan.loading && jartaptupPelanggan.jartaptupPelanggan &&
                setJartptupPelangganData(jartaptupPelanggan.jartaptupPelanggan.map((trunk) => ({
                    company_name: trunk.company_name,
                    teknologi: trunk.teknologi,
                    perorangan: trunk.perorangan,
                    korporasi: trunk.korporasi,
                    tahun: trunk.tahun,
                    action: <>
                        <ActionButton
                            title="fa fa-pencil"
                            css="btn-warning"
                            fun={() => window.location.href = `/penyelenggara-detail-pelanggan-kapasitas-edit/${id}/${nama}/edit/${trunk.id}/jartaptup pelanggan`}
                        />
                        &nbsp;
                        <ActionButton title="fa fa-trash" css="btn-danger"
                            fun={
                                () => swal(`Apakah Yakin Data trunk ${trunk.company_name} ini akan dihapus?`, {
                                    buttons: ["Batal", true],
                                }).then((value) => {
                                    if (value) {
                                        deletejartaptupPelanggan(trunk.id);
                                    }
                                })
                            }
                        />
                    </>
                })));
            jartaptupJaringan.loading && jartaptupJaringan.jartaptupJaringan &&
                setJartptupJaringanData(jartaptupJaringan.jartaptupJaringan.map((trunk) => ({
                    company_name: trunk.company_name,
                    kapasitas: trunk.kapasitas,
                    item_kapasitas: trunk.item_kapasitas,
                    jumlah: trunk.jumlah,
                    nama_satelit: trunk.nama_satelit,
                    tahun: trunk.tahun,
                    action: <>
                        <ActionButton
                            title="fa fa-pencil"
                            css="btn-warning"
                            fun={() => window.location.href = `/penyelenggara-detail-pelanggan-kapasitas-edit/${id}/${nama}/edit/${trunk.id}/jartaptup jaringan`}
                        />
                        &nbsp;
                        <ActionButton title="fa fa-trash" css="btn-danger"
                            fun={
                                () => swal(`Apakah Yakin Data Jartaptup Jaringan ${trunk.company_name} ini akan dihapus?`, {
                                    buttons: ["Batal", true],
                                }).then((value) => {
                                    if (value) {
                                        deletejartaptupJaringan(trunk.id);
                                    }
                                })
                            }
                        />
                    </>
                })));

            setLoading(false);
        }, 4000);
        return () => clearTimeout(timeout);
    }, [deletejartaptupJaringan, deletejartaptupPelanggan, id, jartaptupJaringan, jartaptupPelanggan, jartaptupTech, nama]);

    React.useEffect(() => {
        const notifikasi = () => {
            jartaptupMessage.loading && jartaptupMessage.jartaptupMessage && swal(`${jartaptupMessage.jartaptupMessage.message}`, {
                buttons: ["cancel", true]
            }).then((value) => {
                if (value) {
                    getJartaptupPelangganByCompany(id);
                    getJartaptupJaringanByCompany(id);
                }
            });
        }
        return notifikasi()
    }, [getJartaptupJaringanByCompany, getJartaptupPelangganByCompany, id, jartaptupMessage]);


    const submitJartaptupJaringan = (data) => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            tahun: tahunInputJaringan,
            jumlah: data.jumlah,
            kapasitas: kapasitasValue,
            item_kapasitas: itemKapasitasValue,
            nama_satelit: "0"
        }
        // console.log(input)
        addJartaptupJaringan(input);
    }

    const submitJartaptupPelanggan = (data) => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            teknologi: jartaptupTechInput,
            perorangan: data.perorangan,
            korporasi: data.korporasi,
            tahun: inputTahun
        }
        addJartaptupPelanggan(input);
    }

    const customStylesModal = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: '0',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '700px',
            height: '85%'
        },
    };

    const onUpload = (e) => setFileUpload(e.target.files[0]);

    const submitUploadJartupPelanggan = (data) => {
        const formData = new FormData();
        formData.append("nama_file", fileUpload);
        uploadJartaptupPelanggan(formData)
    };

    const onUploadJaringan = (e) => setFileUploadJaringan(e.target.files[0]);

    const submitUploadJartupJaringan = (data) => {
        const formDataJaringan = new FormData();
        formDataJaringan.append("nama_file", fileUploadJaringan);
        uploadJartaptupJaringan(formDataJaringan)
    };

    return loading ? <Loader /> : jartaptupJaringan.error === "Unauthenticated." ? (
        window.location.href = "/") : (
        <>
            <ListGroup as="ul" className="nav nav-tabs" id="myTab" role="tablist">
                <ListGroup as="li" className="nav-item active" role="presentation">
                    <a className="nav-link active" id="kapasitas-jartaptup-tab" data-toggle="tab" href="#kapasitas-jartaptup" role="tab" aria-controls="kapasitas-jartaptup" aria-selected="true">Kapasitas Pelanggan</a>
                </ListGroup>
                {/* <ListGroup as="li" className="nav-item" role="presentation">
                    <a className="nav-link" id="existing-jartaptup-tab" data-toggle="tab" href="#existing-jartaptup" role="tab" aria-controls="existing-jartaptup" aria-selected="false">Existing</a>
                </ListGroup> */}
            </ListGroup>
            <TabContent>
                <div className="tab-pane active" id="kapasitas-jartaptup" role="tabpanel" aria-labelledby="kapasitas-jartaptup-tab">
                    {
                        role.role_id !== 3 &&
                        <div className="toolbar" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                            <Button
                                id="basic-button"
                                aria-controls="basic-menu"
                                className="btn btn-sm"
                                size="large"
                                aria-haspopup="true"
                                onClick={setupModalAddjartaptupPelanggan}
                                style={{ backgroundColor: 'rgba(0, 50, 122, 1)', color: '#fff', marginRight: '10px' }}
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i> &nbsp; Tambah
                            </Button>
                        </div>
                    }
                    <div className="card-content">
                        <div className="material-datatables">
                            <h4 className="card-title text-center">Pertumbuhan Pelanggan</h4>
                            <DataTable
                                columns={role.role_id !== 3 ? columnPelanggan : columnPelangganGuest}
                                data={jartptupPelangganData}
                                progressPending={loading}
                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100} width={100} />}
                                customStyles={customStyles}
                                responsive={true}
                                pagination />
                            {/* <Bar data={dataChartjartaptup} options={options} /> */}
                        </div>
                    </div>
                    {
                        role.role_id !== 3 &&
                        <div className="toolbar" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                            <Button
                                id="basic-button"
                                aria-controls="basic-menu"
                                className="btn btn-sm"
                                size="large"
                                aria-haspopup="true"
                                onClick={setupModalAddjartaptupJaringan}
                                style={{ backgroundColor: 'rgba(0, 50, 122, 1)', color: '#fff', marginRight: '10px' }}
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i> &nbsp; Tambah
                            </Button>
                        </div>
                    }
                    <div className="card-content">
                        <div className="material-datatables">
                            <h4 className="card-title text-center">Pertumbuhan Jaringan</h4>
                            <DataTable
                                columns={role.role_id !== 3 ? columnJaringan : columnJaringanGuest}
                                data={jartptupJaringanData}
                                progressPending={loading}
                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100} width={100} />}
                                customStyles={customStyles}
                                responsive={true}
                                pagination />
                            {/* <Bar data={dataChartjartaptup} options={options} /> */}
                        </div>
                    </div>
                </div>
                {/* <div className="tab-pane fade" id="existing-jartaptup" role="tabpanel" aria-labelledby="existing-jartaptup-tab">
                    <p>Existing Jartaptup</p>
                </div> */}
            </TabContent>
            <ReactModal
                ariaHideApp={false}
                isOpen={showModalAddJartaptupPelanggan}
                preventScroll={true}
                scrollable={true}
                style={customStylesModal}
            >
                <div style={{ float: 'right' }}>
                    <Button id="basic-button"
                        aria-controls="basic-menu"
                        color="error"
                        onClick={setupModalAddjartaptupPelanggan}
                        size="small">
                        <span className="material-icons">
                            close
                        </span>
                    </Button>
                </div>
                <Modal.Title style={{ fontWeight: 'bold' }}>Tambah Data Baru Jartaptup Pertumbuhan Pelanggan</Modal.Title>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitUploadJartupPelanggan)}
                >
                    <h5 className="text-center">Import Data JARTAPTUP Jumlah Pertumbuhan Pelanggan {nama}</h5>
                    <input
                        placeholder="Nama Perusahaan"
                        type="file"
                        name="nama_file"
                        onChangeCapture={onUpload}
                    />
                    <Link to="/download/jartaptup jumlah pelanggan.xlsx" target="_blank" style={{ float: 'right' }}>Download Template Upload JARTAPTUP Jumlah Pertumbuhan Pelanggan</Link>
                    <br />
                    {
                        jartaptupMessage && jartaptupMessage.error && <span className="text-danger">{jartaptupMessage.error.nama_file}</span>
                    }
                    <br />
                    <Button type="submit" variant="contained" color="primary">Upload</Button>
                    {
                        jartaptupMessage && jartaptupMessage.error && jartaptupMessage.error.message && jartaptupMessage.error.message.map((w, index) => (
                            <ol key={index}>
                                <span className="text-danger">{index + 1}.{w}</span>
                            </ol>
                        ))
                    }
                </form>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitJartaptupPelanggan)}
                >
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="teknologi"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={jartaptupTechData}
                                    placeholder="Teknologi.."
                                    onChange={(e) => setJartaptupTechInput(e.value)}
                                />
                            )}
                        />
                        {jartaptupMessage && jartaptupMessage.error && <span className="text-danger">{jartaptupMessage.error.teknologi}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Perorangan..."
                            type="text"
                            className="form-control"
                            {...register("perorangan")}
                        />
                        {jartaptupMessage && jartaptupMessage.error && <span className="text-danger">{jartaptupMessage.error.perorangan}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Korporasi..."
                            type="text"
                            className="form-control"
                            {...register("korporasi")}
                        />
                        {jartaptupMessage && jartaptupMessage.error && <span className="text-danger">{jartaptupMessage.error.korporasi}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="tahun"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={tahun}
                                    placeholder="Tahun.."
                                    onChange={(e) => setInputTahun(e.value)}
                                />
                            )}
                        />
                        {jartaptupMessage && jartaptupMessage.error && <span className="text-danger">{jartaptupMessage.error.tahun}</span>}
                    </Styles.InputForm>
                    <Button variant="contained" color="error" onClick={setupModalAddjartaptupPelanggan} style={{ marginRight: 5 }}>Close</Button>
                    <Button type="submit" variant="contained" color="primary">Save changes</Button>
                </form>
            </ReactModal>
            <ReactModal
                ariaHideApp={false}
                isOpen={showModalAddJartaptupJaringan}
                preventScroll={true}
                scrollable={true}
                style={customStylesModal}
            >
                <div style={{ float: 'right' }}>
                    <Button id="basic-button"
                        aria-controls="basic-menu"
                        color="error"
                        onClick={setupModalAddjartaptupJaringan}
                        size="small">
                        <span className="material-icons">
                            close
                        </span>
                    </Button>
                </div>
                <Modal.Title style={{ fontWeight: 'bold' }}>Tambah Data Jartaptup Jaringan Baru</Modal.Title>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitUploadJartupJaringan)}
                >
                    <h5 className="text-center">Import Data JARTAPTUP Jumlah Jaringan {nama}</h5>
                    <input
                        placeholder="Nama Perusahaan"
                        type="file"
                        name="nama_file"
                        onChangeCapture={onUploadJaringan}
                    />
                    <Link to="/download/jartaptup jaringan.xlsx" target="_blank" style={{ float: 'right' }}>Download Template Upload JARTAPTUP Jumlah Jaringan</Link>
                    <br />
                    {
                        jartaptupMessage && jartaptupMessage.error && <span className="text-danger">{jartaptupMessage.error.nama_file}</span>
                    }
                    <br />
                    <Button type="submit" variant="contained" color="primary">Upload</Button>
                    {
                        jartaptupMessage && jartaptupMessage.error && jartaptupMessage.error.message && jartaptupMessage.error.message.map((w, index) => (
                            <ol key={index}>
                                <span className="text-danger">{index + 1}.{w}</span>
                            </ol>
                        ))
                    }
                </form>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitJartaptupJaringan)}
                >
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="tahun"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={jartaptupTechData}
                                    placeholder="Kapasitas"
                                    onChange={(e) => {
                                        getJartaptupNetwork(e.value);
                                        setKapasitasValue(e.value);
                                    }}
                                />
                            )}
                        />
                        {jartaptupMessage && jartaptupMessage.error && <span className="text-danger">{jartaptupMessage.error.kapasitas}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="tahun"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={jartaptupTechName}
                                    placeholder="Item Kapasitas"
                                    onChange={(e) => setItemKapasitasValue(e.value)}
                                />
                            )}
                        />
                        {jartaptupMessage && jartaptupMessage.error && <span className="text-danger">{jartaptupMessage.error.item_kapasitas}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Jumlah..."
                            type="text"
                            className="form-control"
                            {...register("jumlah")}
                        />
                        {jartaptupMessage && jartaptupMessage.error && <span className="text-danger">{jartaptupMessage.error.jumlah}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="tahun"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={tahun}
                                    placeholder="Tahun.."
                                    onChange={(e) => setTahunInputJaringan(e.value)}
                                />
                            )}
                        />
                        {jartaptupMessage && jartaptupMessage.error && <span className="text-danger">{jartaptupMessage.error.tahun}</span>}
                    </Styles.InputForm>

                    <Button type="submit" variant="contained" color="error" onClick={setupModalAddjartaptupJaringan} style={{ marginRight: 5 }}>Close</Button>
                    <Button type="submit" variant="contained" color="primary">Save changes</Button>
                </form>
            </ReactModal>
        </>
    )
}

export default Jartaptup


const Tahun = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= 1945; i--) {
        tahun.push(i);
    }
    return tahun;
}