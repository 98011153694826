import {
    ENODEB4G_REQ,
    ENODEB4G_SUCCESS,
    ENODEB4G_FAIL,
    ENODEB4G_CRUD_REQ,
    ENODEB4G_CRUD_SUCCESS,
    ENODEB4G_CRUD_FAIL,
    ENODEB4G_MESSAGE_REQ,
    ENODEB4G_MESSAGE_SUCCESS,
    ENODEB4G_MESSAGE_FAIL,
    ENODEB4G_DETAIL_REQ,
    ENODEB4G_DETAIL_SUCCESS,
    ENODEB4G_DETAIL_FAIL,
} from './enodeb4gTypes';

const enodeb4gDetailState = {
    loading: false,
    enodeb4gDetail: [],
    error: ''
}

const enodeb4gCrudState = {
    loading: false,
    enodeb4gCrud: [],
    error: ''
}

const enodeb4gState = {
    loading: false,
    enodeb4g: [],
    error: ''
}

const enodeb4gMessageState = {
    loading: false,
    enodeb4gMessage: '',
    error: ''
}

export const enodeb4gDetailReducer = (state = enodeb4gDetailState, action) => {
    switch (action.type) {
        case ENODEB4G_DETAIL_REQ:
            return {
                ...state,
                loading: false
            }
        case ENODEB4G_DETAIL_SUCCESS:
            return {
                loading: true,
                enodeb4gDetail: action.payload,
                error: ''
            }
        case ENODEB4G_DETAIL_FAIL:
            return {
                loading: false,
                enodeb4gDetail: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const enodeb4gMessageReducer = (state = enodeb4gMessageState, action) => {
    switch (action.type) {
        case ENODEB4G_MESSAGE_REQ:
            return {
                ...state,
                loading: false,
            }
        case ENODEB4G_MESSAGE_SUCCESS:
            return {
                loading: true,
                enodeb4gMessage: action.payload,
                error: ''
            }
        case ENODEB4G_MESSAGE_FAIL:
            return {
                loading: true,
                enodeb4gMessage: "",
                error: action.payload
            }
        default:
            return state
    }
}

export const enodeb4CrudgReducer = (state = enodeb4gCrudState, action) => {
    switch (action.type) {
        case ENODEB4G_CRUD_REQ:
            return {
                ...state,
                loading: false,
            }
        case ENODEB4G_CRUD_SUCCESS:
            return {
                loading: false,
                enodeb4gCrud: action.payload,
                error: ''
            }
        case ENODEB4G_CRUD_FAIL:
            return {
                loading: false,
                enodeb4gCrud: [],
                error: action.payload
            }
        default:
            return state
    }
}

const enodeb4gReducer = (state = enodeb4gState, action) => {
    switch (action.type) {
        case ENODEB4G_REQ:
            return {
                ...state,
                loading: false,
            }
        case ENODEB4G_SUCCESS:
            return {
                loading: true,
                enodeb4g: action.payload,
                error: ''
            }
        case ENODEB4G_FAIL:
            return {
                loading: false,
                enodeb4g: [],
                error: action.payload
            }
        default:
            return state
    }
}

export default enodeb4gReducer