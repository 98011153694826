import {
    CAPEXOPEX_REQ,
    CAPEXOPEX_SUCCESS,
    CAPEXOPEX_FAIL,
    CAPEXOPEX_DETAIL_REQ,
    CAPEXOPEX_DETAIL_SUCCESS,
    CAPEXOPEX_DETAIL_FAIL,
    CAPEXOPEX_MESSAGE_REQ,
    CAPEXOPEX_MESSAGE_SUCCESS,
    CAPEXOPEX_MESSAGE_FAIL,
} from './capexOpexTypes';

const capexOpexDetailState = {
    loading: false,
    capexOpexDetail: [],
    error: ''
}

const capexOpexState = {
    loading: false,
    capexOpex: [],
    error: ''
}

const capexOpexMessageState = {
    loading: false,
    capexOpexMessage: '',
    error: ''
}

export const capexOpexDetailReducer = (state = capexOpexDetailState, action) => {
    switch (action.type) {
        case CAPEXOPEX_DETAIL_REQ:
            return {
                ...state,
                loading: false,
            }
        case CAPEXOPEX_DETAIL_SUCCESS:
            return {
                loading: true,
                capexOpexDetail: action.payload,
                error: ''
            }
        case CAPEXOPEX_DETAIL_FAIL:
            return {
                loading: true,
                capexOpexDetail: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const capexOpexMessageReducer = (state = capexOpexMessageState, action) => {
    switch (action.type) {
        case CAPEXOPEX_MESSAGE_REQ:
            return {
                ...state,
                loading: false,
            }
        case CAPEXOPEX_MESSAGE_SUCCESS:
            return {
                loading: true,
                capexOpexMessage: action.payload,
                error: ''
            }
        case CAPEXOPEX_MESSAGE_FAIL:
            return {
                loading: false,
                capexOpexMessage: '',
                error: action.payload
            }
        default:
            return state
    }
}

const capexOpexReducer = (state = capexOpexState, action) => {
    switch (action.type) {
        case CAPEXOPEX_REQ:
            return {
                ...state,
                loading: true,
                capexOpex: null,
                error: ''
            }
        case CAPEXOPEX_SUCCESS:
            return {
                loading: false,
                capexOpex: action.payload,
                error: ''
            }
        case CAPEXOPEX_FAIL:
            return {
                loading: false,
                capexOpex: [],
                error: action.payload
            }
        default:
            return state
    }
}

export default capexOpexReducer