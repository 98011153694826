import {
    JARTAPLOK_REQ,
    JARTAPLOK_SUCCESS,
    JARTAPLOK_FAIL,
    JARTAPLOK_MESSAGE_REQ,
    JARTAPLOK_MESSAGE_SUCCESS,
    JARTAPLOK_MESSAGE_FAIL,
    JARTAPLOK_DETAIL_REQ,
    JARTAPLOK_DETAIL_SUCCESS,
    JARTAPLOK_DETAIL_FAIL,
} from './jartaplokTypes';

const jartaplokMessageState = {
    loading: false,
    jartaplokMessage: '',
    error: ''
}

const jartaplokState = {
    loading: false,
    jartaplok: [],
    error: ''
}

const jartaplokDetailState = {
    loading: false,
    jartaplokDetail: null,
    error: ''
}

export const jartaplokDetailReducer = (state = jartaplokDetailState, action) => {
    switch (action.type) {
        case JARTAPLOK_DETAIL_REQ:
            return {
                ...state,
                loading: false
            }
        case JARTAPLOK_DETAIL_SUCCESS:
            return {
                loading: true,
                jartaplokDetail: action.payload,
                error: ''
            }
        case JARTAPLOK_DETAIL_FAIL:
            return {
                loading: true,
                jartaplokDetail: null,
                error: action.payload
            }
        default:
            return state
    }
}

export const jartaplokMessageReducer = (state = jartaplokMessageState, action) => {
    switch (action.type) {
        case JARTAPLOK_MESSAGE_REQ:
            return {
                ...state,
                loading: false,
            }
        case JARTAPLOK_MESSAGE_SUCCESS:
            return {
                loading: true,
                jartaplokMessage: action.payload,
                error: ''
            }
        case JARTAPLOK_MESSAGE_FAIL:
            return {
                loading: true,
                jartaplokMessage: "",
                error: action.payload
            }
        default:
            return state
    }
}

const jartaplokReducer = (state = jartaplokState, action) => {
    switch (action.type) {
        case JARTAPLOK_REQ:
            return {
                ...state,
                loading: false,
            }
        case JARTAPLOK_SUCCESS:
            return {
                loading: true,
                jartaplok: action.payload,
                error: ''
            }
        case JARTAPLOK_FAIL:
            return {
                loading: true,
                jartaplok: null,
                error: action.payload
            }
        default:
            return state
    }
}

export default jartaplokReducer