import React from 'react';
import DataTable from 'react-data-table-component';
import Select from "react-select";
import ReactModal from 'react-modal';
import { Modal } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import { Styles, ActionButton, Spinner, FilterComponent } from '../../components';
import { useForm, Controller } from 'react-hook-form';
import swal from 'sweetalert';
import { createHashHistory } from 'history'
import { Link } from 'react-router-dom';
// import AccountCircle from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';

function ProfilUmum({
    profilUmum, getProfilUmum, profilUmumMessage, addProfilUmum, getProvinsi, getProvinsiCore, provinsi, provinsiCore, kota, getKota, kodePos, getKodePos, kotaCore, getKotaCore, kodePosCore, getKodePosCore, deleteProfilUmum, uploadProfilUmum, profilUmumUpload, role
}) {
    const history = createHashHistory();
    const { register, handleSubmit, control, reset, formState } = useForm();
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [showModalAddDataUmum, setShowModalAddDataUmum] = React.useState(false);
    const setUpModalAddDataUmum = () => setShowModalAddDataUmum(!showModalAddDataUmum);
    const [prov, setProv] = React.useState([]);

    const [provinceValue, setProvinceValue] = React.useState('');
    const [kot, setKot] = React.useState([]);
    const [kotaValue, setKotaValue] = React.useState('');
    const [longitudeValue, setLongitudeValue] = React.useState('');
    const [latitudeValue, setLatitudeValue] = React.useState('');
    const [websiteValue, setWebsiteValue] = React.useState('');
    const [zippCode, setZippCode] = React.useState([]);
    const [valuePostCode, setValuePostCode] = React.useState('');
    const [valueAlamat, setValueAlamat] = React.useState("");
    const [provCore, setProvCore] = React.useState([]);
    const [provinceCoreValue, setProvinceCoreValue] = React.useState('');
    const [kotCore, setKotCore] = React.useState([]);
    const [kotaCoreValue, setKotaCoreValue] = React.useState('');
    const [zippCodeCore, setZippCodeCore] = React.useState([]);
    const [zippCodeCoreValue, setZippCodeCoreVaue] = React.useState([]);
    const [valueEmail, setValueEmail] = React.useState("");
    const [fax, setFax] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [autoFill, setAutoFill] = React.useState(false);
    const [fileUpload, setFileUpload] = React.useState(null);
    const [crud, setCrud] = React.useState(true);
    const setupCrud = () => setCrud(!crud);

    const [filterText, setFilterText] = React.useState('');
    const filteredItems = rows.filter(
        (item) => (
            item.company_name.toLowerCase().includes(filterText.toLowerCase()) ||
            item.brand.toLowerCase().match(filterText.toLowerCase())) ||
            item.status_tbk.toLowerCase().match(filterText.toLowerCase())
    );

    const subHeaderComponentMemo = React.useMemo(() => {
        return <FilterComponent onFilter={e => setFilterText(e.target.value)} filterText={filterText} placeholder="Cari Perusahaan.." />;
    }, [filterText]);
    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                fontSize: '14px',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none',
                minWidth: 'auto',
            },
        },
    };

    const columnsEdit = [
        {
            id: row => row.id,
            name: 'No.',
            selector: row => row.number,
            maxWidth: '10px',
        },
        {
            name: 'Nama Perusahaan',
            selector: row => row.company_name,
            minWidth: '210px',
        },
        {
            name: 'Brand',
            selector: row => row.brand,
            minWidth: '150px',
        },
        {
            name: 'Status Tbk',
            selector: row => row.status_tbk,
            minWidth: '100px',
        },
        {
            name: 'Alamat',
            selector: row => row.address,
            minWidth: '300px',
        },
        {
            name: 'Kota',
            selector: row => row.cities,
            minWidth: '210px',
        },
        {
            name: 'Action',
            selector: 'action',
            minWidth: '150px',
        },
    ];
    const columns = [
        {
            name: 'No.',
            selector: row => row.number,
            maxWidth: '10px',
        },
        {
            name: 'Nama Perusahaan',
            selector: row => row.company_name,
            minWidth: '210px',
        },
        {
            name: 'Brand',
            selector: row => row.brand,
            minWidth: '150px',
        },
        {
            name: 'Status Tbk',
            selector: row => row.status_tbk,
            minWidth: '100px',
        },
        {
            name: 'Alamat',
            selector: row => row.address,
            minWidth: '300px',
        },
        {
            name: 'Kota',
            selector: row => row.cities,
            minWidth: '210px',
        },
    ];
    React.useEffect(() => {
        getProfilUmum();
        getProvinsi();
        getProvinsiCore();
    }, [getProfilUmum, getProvinsi, getProvinsiCore]);
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            profilUmum && profilUmum.profilUmum &&
                setRows(
                    profilUmum.profilUmum.map((p, index) => ({
                        id: p.id,
                        number: index + 1,
                        company_name: p.company_name,
                        brand: p.brand,
                        status_tbk: p.status_tbk,
                        npwp: p.npwp,
                        address: p.address,
                        cities: p.cities,
                        action: <>
                            <ActionButton
                                title="fa fa-pencil"
                                css="btn-warning"
                                fun={() => window.location.href = `/edit-penyelenggara/${p.id}/${p.company_name}`} />
                            &nbsp;
                            <ActionButton title="fa fa-trash" css="btn-danger" fun={() => swal(`Apakah Yakin Data ${p.company_name} akan dihapus?`, {
                                buttons: ["Batal", true],
                            }).then((value) => {
                                if (value) {
                                    deleteProfilUmum(p.id);
                                }
                            })} />
                        </>
                    }))
                );
            provinsi && provinsi.provinsi &&
                setProv(
                    provinsi.provinsi.map((p) => ({ value: p.id, label: p.nama_provinsi })));
            provinsiCore && provinsiCore.provinsiCore &&
                setProvCore(provinsiCore.provinsiCore.map(pc => ({ value: pc.id, label: pc.nama_provinsi })));
            setLoading(false);
        }, 5000);
        return () => clearTimeout(timeout);
    }, [profilUmum, provinsi, provinsiCore, deleteProfilUmum, history]);

    React.useEffect(() => {
        const notif = () => {
            if (profilUmumMessage.loading && profilUmumMessage.profilUmumMessage) {
                swal(`${profilUmumMessage.profilUmumMessage.message}`, {
                    buttons: true
                }).then((value) => {
                    if (value) {
                        getProfilUmum();
                    }
                });
            }

            if (profilUmumUpload.loading && profilUmumUpload.profilUmumUpload) {
                swal(`${profilUmumUpload.profilUmumUpload.message}`, {
                    buttons: true
                }).then((value) => {
                    if (value) {
                        getProfilUmum();
                    }
                });
            }
        };

        return notif();
    }, [getProfilUmum, profilUmumMessage, profilUmumUpload]);
    React.useEffect(() => {
        kota && kota.kota && setKot(kota.kota.map(k => ({ value: k.id, label: k.nama_kota })));
    }, [kota]);
    React.useEffect(() => {
        kodePos && kodePos.kodePos && setZippCode(kodePos.kodePos.map(k => ({ value: k.id, label: k.kode_pos })));
    }, [kodePos]);
    React.useEffect(() => {
        kotaCore && kotaCore.kotaCore && setKotCore(kotaCore.kotaCore.map(k => ({ value: k.id, label: k.nama_kota })));
    }, [kotaCore]);
    React.useEffect(() => {
        kodePosCore && kodePosCore.kodePosCore && setZippCodeCore(kodePosCore.kodePosCore.map(k => ({ value: k.id, label: k.kode_pos })));
    }, [kodePosCore]);

    React.useEffect(() => {
        if (formState.isSubmitSuccessful) {
            reset(setFileUpload(null));
        }
    }, [formState, reset]);

    const tambahDataUmum = (data) => {
        let input = {
            company_name: data.company_name,
            brand: data.brand,
            status_tbk: data.status_tbk,
            fax: fax,
            npwp: data.npwp,
            phone: phone,
            lat: latitudeValue,
            long: longitudeValue,
            website: websiteValue,
            province: provinceValue,
            cities: kotaValue,
            zipcode: valuePostCode,
            address: valueAlamat,
            email: valueEmail,
            phone_core: autoFill ? phone : data.phone_core,
            fax_core: autoFill ? fax : data.fax_core,
            lat_core: autoFill ? latitudeValue : data.lat_core,
            long_core: autoFill ? longitudeValue : data.long_core,
            website_core: autoFill ? websiteValue : data.website_core,
            province_core: autoFill ? provinceValue : provinceCoreValue,
            cities_core: autoFill ? kotaValue : kotaCoreValue,
            zipcode_core: autoFill ? valuePostCode : zippCodeCoreValue,
            address_core: autoFill ? valueAlamat : data.address_core,
            email_core: autoFill ? valueEmail : data.email_core
        };
        console.log(input)
        addProfilUmum(input);
    };

    const onUpload = (e) => setFileUpload(e.target.files[0]);

    const submitUploadProfilUmum = () => {
        const formData = new FormData();
        formData.append("nama_file", fileUpload);
        uploadProfilUmum(formData);
    };
    const customStylesModal = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: '0',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '700px',
            height: '90%'
        },
    };

    return loading ? <Spinner /> : profilUmum.error.message === "Unauthenticated." ? (
        window.location.href = "/" && localStorage.removeItem('token')) : (
        <div className="row">
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header card-header-icon" data-background-color="blue">
                        <i className="material-icons">widgets</i>
                    </div>
                    <h3 className="title" style={{ fontWeight: 'bold' }}>Penyelenggara</h3>
                    <div className="card-content">
                        <div className="toolbar" style={{ display: 'flex', flexDirection: 'row-reverse', bottom: 0, marginBottom: 0 }}>
                            {
                                role.role_id !== 3 && <>
                                    <Button
                                        id="basic-button"
                                        aria-controls="basic-menu"
                                        size="large"
                                        className={crud ? "btn btn-success" : "btn btn-danger"}
                                        onClick={setupCrud}
                                    >
                                        <i className={crud ? "fa fa-edit" : "fa fa-undo-alt"} aria-hidden="true"> </i>&nbsp;{crud ? " Edit" : " Kembali"}
                                    </Button>
                                    <Button
                                        id="basic-button"
                                        aria-controls="basic-menu"
                                        className="btn btn-sm"
                                        size="large"
                                        aria-haspopup="true" onClick={setUpModalAddDataUmum}
                                        style={{ backgroundColor: 'rgba(0, 50, 122, 1)', color: '#fff', marginRight: '10px' }}
                                    >
                                        <i className="fa fa-plus" aria-hidden="true"></i> &nbsp; Tambah
                                    </Button>
                                </>
                            }
                        </div>
                        <div>
                            <DataTable
                                columns={crud ? columns : columnsEdit}
                                data={filteredItems}
                                progressPending={loading}
                                striped={true}
                                highlightOnHover={true}
                                pointerOnHover={true}
                                progressComponent={<Loader type="Rings" color="#00BFFF" height={100} width={100} />}
                                responsive={true}
                                customStyles={customStyles}
                                subHeader
                                subHeaderComponent={subHeaderComponentMemo}
                                onRowClicked={(row) => window.location.href = `/penyelenggara-detail/${row.id}/${row.company_name}`}
                                pagination />
                        </div>
                        <ReactModal
                            ariaHideApp={false}
                            isOpen={showModalAddDataUmum}
                            preventScroll={true}
                            scrollable={true}
                            onHide={setUpModalAddDataUmum}
                            style={customStylesModal}
                        >
                            <div style={{ float: 'right' }}>
                                <Button id="basic-button"
                                    aria-controls="basic-menu"
                                    // variant="outlined"
                                    // className="btn"
                                    color="error"
                                    onClick={setUpModalAddDataUmum}
                                    size="small">
                                    <span className="material-icons">
                                        close
                                    </span>
                                </Button>
                            </div>
                            <Modal.Title style={{ fontWeight: 'bold' }}>Tambah Penyelenggara</Modal.Title>
                            <hr />
                            <form className="form"
                                onSubmit={handleSubmit(submitUploadProfilUmum)}
                            >
                                <h5 className="text-center">Import Data Umum</h5>
                                <input
                                    type="file"
                                    onChangeCapture={onUpload}
                                    name="nama_file" />
                                {profilUmumMessage && profilUmumMessage.error && <span className="text-danger">{profilUmumMessage.error.nama_file}</span>}
                                {profilUmumMessage && profilUmumMessage.error && <span className="text-danger">{profilUmumMessage.error.message}</span>}
                                <Link to="/download/data umum.xlsx" style={{ float: 'right' }} target="_blank">Download File Template Upload Data Umum</Link><br />
                                <Button variant="contained" color="primary" type="submit">Upload</Button>
                            </form>
                            <hr />
                            <form className="form" onSubmit={handleSubmit(tambahDataUmum)}>
                                <div className="row clearfix">
                                    <div className="col-md-6">
                                        <Styles.InputForm>
                                            <input
                                                placeholder="Nama Perusahaan"
                                                type="text"
                                                className="form-control"
                                                {...register("company_name")} />
                                            {profilUmumMessage && profilUmumMessage.error && <span className="text-danger">{profilUmumMessage.error.company_name}</span>}
                                        </Styles.InputForm>
                                        <Styles.InputForm>
                                            <input
                                                placeholder="Brand..."
                                                type="text"
                                                className="form-control"
                                                {...register("brand")} />
                                        </Styles.InputForm>
                                        <Styles.InputForm>
                                            <select
                                                className="form-control mb-2"
                                                {...register("status_tbk")}
                                            >
                                                <option value=""> Status Tbk </option>
                                                <option value="TBK"> TBK </option>
                                                <option value="Non TBK"> NON Tbk </option>
                                            </select>
                                            {profilUmumMessage && profilUmumMessage.error && <span className="text-danger">{profilUmumMessage.error.status_tbk}</span>}
                                        </Styles.InputForm>
                                        <Styles.InputForm>
                                            <Controller
                                                control={control}
                                                name="province"
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        options={prov}
                                                        isDisabled={autoFill}
                                                        placeholder="Pilih Provinsi"
                                                        onChange={((e) => {
                                                            setProvinceValue(e.value);
                                                            getKota(e.value);
                                                        })} />
                                                )} />
                                            {profilUmumMessage && profilUmumMessage.error && <span className="text-danger" style={{ marginTop: '20px' }}>{profilUmumMessage.error.province}</span>}
                                        </Styles.InputForm>
                                        <Styles.InputForm>
                                            <Controller
                                                control={control}
                                                name="cities"
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        options={kot}
                                                        isDisabled={autoFill}
                                                        placeholder="Pilih Kota"
                                                        onChange={e => {
                                                            setKotaValue(e.value);
                                                            getKodePos(e.value);
                                                        }} />
                                                )} />
                                            {profilUmumMessage && profilUmumMessage.error && <span className="text-danger">{profilUmumMessage.error.cities}</span>}
                                        </Styles.InputForm>
                                        <Styles.InputForm>
                                            <Controller
                                                control={control}
                                                name="zipcode"
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        options={zippCode}
                                                        isDisabled={autoFill}
                                                        placeholder="Pilih Kode Pos"
                                                        onChange={e => setValuePostCode(e.value)} />
                                                )} />
                                            {profilUmumMessage && profilUmumMessage.error && <span className="text-danger">{profilUmumMessage.error.cities}</span>}
                                        </Styles.InputForm>
                                        <Styles.InputForm>
                                            <input
                                                placeholder="Website"
                                                type="text"
                                                className="form-control mb-2"
                                                disabled={autoFill}
                                                defaultValue={websiteValue}
                                                onChangeCapture={e => setWebsiteValue(e.target.value)}
                                                {...register("website")} />
                                        </Styles.InputForm>
                                        <Styles.InputForm>
                                            <textarea
                                                placeholder="Alamat"
                                                className="form-control mt-2 mb-2"
                                                disabled={autoFill}
                                                value={valueAlamat}
                                                onChangeCapture={((e) => setValueAlamat(e.target.value))}
                                                {...register("address")} />
                                            {profilUmumMessage && profilUmumMessage.error && <span className="text-danger">{profilUmumMessage.error.address}</span>}
                                        </Styles.InputForm>
                                        <Styles.InputForm>
                                            <input
                                                placeholder="Latitude.."
                                                type="text"
                                                className="form-control mb-2"
                                                defaultValue={latitudeValue}
                                                disabled={autoFill}
                                                onChangeCapture={e => setLatitudeValue(e.target.value)}
                                                {...register("lat")} />
                                        </Styles.InputForm>
                                        <Styles.InputForm>
                                            <input
                                                placeholder="Longitude.."
                                                type="text"
                                                className="form-control mb-2"
                                                dafaultvalue={longitudeValue}
                                                disabled={autoFill}
                                                onChangeCapture={e => setLongitudeValue(e.target.value)}
                                                {...register("long")} />
                                        </Styles.InputForm>
                                        <Styles.InputForm>
                                            <input
                                                placeholder="Email"
                                                type="text"
                                                className="form-control mb-2"
                                                disabled={autoFill}
                                                defaultValue={valueEmail}
                                                onChangeCapture={(e) => setValueEmail(e.target.value)}
                                                {...register("email")} />
                                            {profilUmumMessage && profilUmumMessage.error && <span className="text-danger">{profilUmumMessage.error.email}</span>}
                                        </Styles.InputForm>
                                    </div>
                                    <div className="col-md-6">
                                        <Styles.InputForm>
                                            <input
                                                placeholder="fax.."
                                                type="text"
                                                className="form-control mb-2"
                                                defaultValue={fax}
                                                onChangeCapture={e => setFax(e.target.value)}
                                                {...register("fax")} />
                                        </Styles.InputForm>
                                        <Styles.InputForm>
                                            <input
                                                placeholder="Nomor Telepon"
                                                type="text"
                                                className="form-control mb-2"
                                                onChangeCapture={e => setPhone(e.target.value)}
                                                {...register("phone")} />
                                            {profilUmumMessage && profilUmumMessage.error && <span className="text-danger">{profilUmumMessage.error.phone}</span>}
                                        </Styles.InputForm>
                                        <Styles.InputForm>
                                            <input
                                                placeholder="NPWP"
                                                type="text"
                                                className="form-control mb-2"
                                                {...register("npwp")} />
                                            {profilUmumMessage && profilUmumMessage.error && <span className="text-danger">{profilUmumMessage.error.npwp}</span>}
                                        </Styles.InputForm>
                                        {valueEmail !== "" ?
                                            <>
                                                <Styles.InputForm>
                                                    <input
                                                        type="checkbox"
                                                        id="autofill"
                                                        defaultValue={autoFill}
                                                        onChangeCapture={() => setAutoFill(!autoFill)} />
                                                    <label htmlFor="autofill" style={{ marginLeft: 10 }}>Isi Otomatis Data = Data Korespondensi</label>
                                                </Styles.InputForm>
                                                {autoFill ? <>
                                                    <Styles.InputForm>
                                                        <input
                                                            className="form-control mb-2"
                                                            disabled={true}
                                                            defaultValue={prov.filter(f => f.value === provinceValue).map(v => `${v.label}`)} />
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <input
                                                            className="form-control mb-2"
                                                            disabled={true}
                                                            defaultValue={kot.filter(f => f.value === kotaValue).map(v => `${v.label}`)} />
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <input
                                                            className="form-control mb-2"
                                                            disabled={true}
                                                            defaultValue={zippCode.filter(f => f.value === valuePostCode).map(v => `${v.label}`)} />
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <textarea
                                                            className="form-control mt-2 mb-2"
                                                            disabled={true}
                                                            defaultValue={valueAlamat} />
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <input
                                                            placeholder="Latitude.."
                                                            type="text"
                                                            className="form-control mb-2"
                                                            disabled={autoFill}
                                                            {...register("lat")} />
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <input
                                                            placeholder="Longitude.."
                                                            type="text"
                                                            className="form-control mb-2"
                                                            disabled={autoFill}
                                                            {...register("long")} />
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <input
                                                            placeholder="Website"
                                                            type="text"
                                                            className="form-control mb-2"
                                                            defaultValue={websiteValue}
                                                            disabled={autoFill}
                                                            onChangeCapture={e => setWebsiteValue(e.target.value)}
                                                            {...register("website")} />
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <input
                                                            placeholder="Email"
                                                            type="text"
                                                            className="form-control mb-2"
                                                            disabled={autoFill}
                                                            defaultValue={valueEmail}
                                                            onChangeCapture={(e) => setValueEmail(e.target.value)}
                                                            {...register("email")} />
                                                        {profilUmumMessage && profilUmumMessage.error && <span className="text-danger">{profilUmumMessage.error.email}</span>}
                                                    </Styles.InputForm>
                                                </> : <>
                                                    <Styles.InputForm>
                                                        <Controller
                                                            control={control}
                                                            name="province_core"
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    options={provCore}
                                                                    placeholder="Pilih Provinsi Korespondensi"
                                                                    onChange={(e) => {
                                                                        setProvinceCoreValue(e.value);
                                                                        getKotaCore(e.value);
                                                                    }} />
                                                            )} />
                                                        {profilUmumMessage && profilUmumMessage.error && <span className="text-danger">{profilUmumMessage.error.province_core}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <Controller
                                                            control={control}
                                                            name="cities_core"
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    options={kotCore}
                                                                    placeholder="Pilih Kota Korespondensi"
                                                                    onChange={e => {
                                                                        setKotaCoreValue(e.value);
                                                                        getKodePosCore(e.value);
                                                                    }} />
                                                            )} />
                                                        {profilUmumMessage && profilUmumMessage.error && <span className="text-danger">{profilUmumMessage.error.cities_core}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <Controller
                                                            control={control}
                                                            name="zipcode_core"
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    options={zippCodeCore}
                                                                    placeholder="Pilih Kode Pos Korepondensi"
                                                                    onChange={e => setZippCodeCoreVaue(e.value)} />
                                                            )} />
                                                        {profilUmumMessage && profilUmumMessage.error && <span className="text-danger">{profilUmumMessage.error.zipcode_core}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <textarea
                                                            placeholder="Alamat Korespondensi"
                                                            className="form-control mb-2"
                                                            {...register("address_core")} />
                                                        {profilUmumMessage && profilUmumMessage.error && <span className="text-danger">{profilUmumMessage.error.address_core}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <input
                                                            placeholder="Latitude Kotespondensi"
                                                            type="text"
                                                            className="form-control mb-2"
                                                            {...register("lat_core")} />
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <input
                                                            placeholder="Longitude Core"
                                                            type="text"
                                                            className="form-control mb-2"
                                                            {...register("long_core")} />
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <input
                                                            placeholder="Phone Core"
                                                            type="text"
                                                            className="form-control mb-2"
                                                            {...register("phone_core")} />
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <input
                                                            placeholder="Fax Core"
                                                            type="text"
                                                            className="form-control mb-2"
                                                            {...register("fax_core")} />
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <input
                                                            placeholder="Website Core"
                                                            type="text"
                                                            className="form-control mb-2"
                                                            {...register("website_core")} />
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <input
                                                            placeholder="Email Core"
                                                            type="email"
                                                            className="form-control mb-2"
                                                            {...register("email_core")} />
                                                        {profilUmumMessage && profilUmumMessage.error && <span className="text-danger">{profilUmumMessage.error.email_core}</span>}
                                                    </Styles.InputForm>
                                                </>}
                                            </> : null}
                                    </div>
                                </div>
                                <Button className="btn btn-danger" style={{ marginRight: 5 }} variant="contained" onClick={setUpModalAddDataUmum}>Close</Button>
                                <Button className="btn btn-secondary" variant="contained" type="submit">Save</Button>
                            </form>
                        </ReactModal>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ProfilUmum
