import {
    PROVINSI_REQ,
    PROVINSI_OK,
    PROVINSI_FAIL,
    KOTA_REQ,
    KOTA_OK,
    KOTA_FAIL,
    POST_REQ,
    POST_OK,
    POST_FAIL,
    PROVINSI_CORE_REQ,
    PROVINSI_CORE_OK,
    PROVINSI_CORE_FAIL,
    KOTA_CORE_REQ,
    KOTA_CORE_OK,
    KOTA_CORE_FAIL,
    POST_CORE_REQ,
    POST_CORE_OK,
    POST_CORE_FAIL,
    NEGARA_REQ,
    NEGARA_OK,
    NEGARA_FAIL,
} from './wilayahTypes';

import api from '../services';
import { config } from '../config';

export const nagaraReq = () => {
    return { type: NEGARA_REQ }
}

export const nagaraSuccess = (data) => {
    return { type: NEGARA_OK, payload: data }
}

export const nagaraFail = (err) => {
    return { type: NEGARA_FAIL, payload: err }
}

export const postCoreReq = () => {
    return { type: POST_CORE_REQ }
}

export const postCoreSuccess = (data) => {
    return { type: POST_CORE_OK, payload: data }
}

export const postCoreFail = (err) => {
    return { type: POST_CORE_FAIL, payload: err }
}

export const kotaCoreReq = () => {
    return { type: KOTA_CORE_REQ }
}

export const kotaCoreSuccess = (data) => {
    return { type: KOTA_CORE_OK, payload: data }
}

export const kotaCoreFail = (err) => {
    return { type: KOTA_CORE_FAIL, payload: err }
}

export const provCoreReq = () => {
    return { type: PROVINSI_CORE_REQ }
}

export const provCoreSuccess = (data) => {
    return { type: PROVINSI_CORE_OK, payload: data }
}

export const provCoreFail = (err) => {
    return { type: PROVINSI_CORE_FAIL, payload: err }
}

export const postReq = () => {
    return { type: POST_REQ }
}

export const postSuccess = (data) => {
    return { type: POST_OK, payload: data }
}

export const postFail = (err) => {
    return { type: POST_FAIL, payload: err }
}

export const kotaReq = () => {
    return { type: KOTA_REQ }
}

export const kotaSuccess = (data) => {
    return { type: KOTA_OK, payload: data }
}

export const kotaFail = (err) => {
    return { type: KOTA_FAIL, payload: err }
}

export const provReq = () => {
    return { type: PROVINSI_REQ }
}

export const provSuccess = (data) => {
    return { type: PROVINSI_OK, payload: data }
}

export const provFail = (err) => {
    return { type: PROVINSI_FAIL, payload: err }
}



export const getNegara = () => {
    return async (dispatch) => {
        dispatch(nagaraReq);
        await api.get('/data-negara', config)
            .then(res => {
                dispatch(nagaraSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(nagaraFail(err.response.data));
            })
    }
}

export const getProvCore = () => {
    return async (dispatch) => {
        dispatch(provCoreReq);
        await api.get('/data-provinsi', config)
            .then(res => {
                dispatch(provCoreSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(provCoreFail(err.response.data));
            })
    }
}

export const getProv = () => {
    return async (dispatch) => {
        dispatch(provReq);
        await api.get('/data-provinsi', config)
            .then(res => {
                dispatch(provSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(provFail(err.response.data));
            })
    }
}

export const getKota = (id_prov) => {
    return async (dispatch) => {
        dispatch(kotaReq);
        await api.get(`/data-kota/${id_prov}`, config)
            .then(res => {
                dispatch(kotaSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(kotaFail(err.response.data));
            })
    }
}

export const getKotaCore = (id_prov_core) => {
    return async (dispatch) => {
        dispatch(kotaCoreReq);
        await api.get(`/data-kota/${id_prov_core}`, config)
            .then(res => {
                dispatch(kotaCoreSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(kotaCoreFail(err.response.data));
            })
    }
}

export const getKodePosCore = (id_kota_core) => {
    return async (dispatch) => {
        dispatch(postCoreReq);
        await api.get(`/data-kodepos/${id_kota_core}`, config)
            .then(res => {
                dispatch(postCoreSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(postCoreFail(err.response.data));
            })
    }
}
export const getKodePos = (id_kota) => {
    return async (dispatch) => {
        dispatch(postReq);
        await api.get(`/data-kodepos/${id_kota}`, config)
            .then(res => {
                dispatch(postSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(postFail(err.response.data));
            })
    }
}