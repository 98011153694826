export const JARTAPLOKPS_REQ = 'JARTAPLOKPS_REQ';
export const JARTAPLOKPS_SUCCESS = 'JARTAPLOKPS_SUCCESS';
export const JARTAPLOKPS_FAIL = 'JARTAPLOKPS_FAIL';
export const JARTAPLOKPS_DETAIL_REQ = 'JARTAPLOKPS_DETAIL_REQ';
export const JARTAPLOKPS_DETAIL_SUCCESS = 'JARTAPLOKPS_DETAIL_SUCCESS';
export const JARTAPLOKPS_DETAIL_FAIL = 'JARTAPLOKPS_DETAIL_FAIL';
export const JARTAPLOKPS_MESSAGE_REQ = 'JARTAPLOKPS_MESSAGE_REQ';
export const JARTAPLOKPS_MESSAGE_SUCCESS = 'JARTAPLOKPS_MESSAGE_SUCCESS';
export const JARTAPLOKPS_MESSAGE_FAIL = 'JARTAPLOKPS_MESSAGE_FAIL';

export const JARTAPLOKPS_JARINGAN_REQ = 'JARTAPLOKPS_JARINGAN_REQ';
export const JARTAPLOKPS_JARINGAN_SUCCESS = 'JARTAPLOKPS_JARINGAN_SUCCESS';
export const JARTAPLOKPS_JARINGAN_FAIL = 'JARTAPLOKPS_JARINGAN_FAIL';
export const JARTAPLOKPS_JARINGAN_DETAIL_REQ = 'JARTAPLOKPS_JARINGAN_DETAIL_REQ';
export const JARTAPLOKPS_JARINGAN_DETAIL_SUCCESS = 'JARTAPLOKPS_JARINGAN_DETAIL_SUCCESS';
export const JARTAPLOKPS_JARINGAN_DETAIL_FAIL = 'JARTAPLOKPS_JARINGAN_DETAIL_FAIL';

export const JARTAPLOKPS_TEKNOLOGI_REQ = 'JARTAPLOKPS_TEKNOLOGI_REQ';
export const JARTAPLOKPS_TEKNOLOGI_SUCCESS = 'JARTAPLOKPS_TEKNOLOGI_SUCCESS';
export const JARTAPLOKPS_TEKNOLOGI_FAIL = 'JARTAPLOKPS_TEKNOLOGI_FAIL';

export const JARTAPLOKPS_TEKNOLOGI_ITEM_REQ = 'JARTAPLOKPS_TEKNOLOGI_ITEM_REQ';
export const JARTAPLOKPS_TEKNOLOGI_ITEM_SUCCESS = 'JARTAPLOKPS_TEKNOLOGI_ITEM_SUCCESS';
export const JARTAPLOKPS_TEKNOLOGI_ITEM_FAIL = 'JARTAPLOKPS_TEKNOLOGI_ITEM_FAIL';

