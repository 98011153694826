import {
    REPORT_REQ,
    REPORT_SUCCESS,
    REPORT_FAIL,
} from './reportTypes'

import api from '../services';
import { configDownload } from '../config';

export const reportReq = () => {
    return { type: REPORT_REQ }
}

export const reportSuccess = (data) => {
    return { type: REPORT_SUCCESS, payload: data }
}

export const reportFail = (err) => {
    return { type: REPORT_FAIL, payload: err }
}

export const reportExport = (data) => {

    return async (dispatch) => {
        dispatch(reportReq);
        await api.post(`/export-data`, data, configDownload)
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'download.xls');
                document.body.appendChild(link);
                link.click();
                dispatch(reportSuccess(res.data.blob()));
                console.log(res)
            })
            .catch(err => {
                dispatch(reportFail(err.response));
            })
    }
}