import TeknologiSection from './TeknologiSection';
import { connect } from 'react-redux';
import {
    addTeknologi,
    getTeknologi,
    getTeknologiCrud,
    getTeknologiIzin,
    getTeknologiIzinTanggal,
    getTeknologiPenyelenggara,
    getNamaTeknologi,
    deleteTeknologi,
    uploadteknologi
} from '../../redux';

const mapStateToProps = state => {
    return {
        teknologi: state.teknologi,
        teknologiCrud: state.teknologiCrud,
        teknologiMessage: state.teknologiMessage,
        teknologiPenyelenggara: state.teknologiPenyelenggara,
        teknologiIzin: state.teknologiIzin,
        teknologiIzinTanggal: state.teknologiIzinTanggal,
        namaTeknologi: state.namaTeknologi
    }
}

const mapDispatchToProps = dispatch => {
    return {
        uploadteknologi: (data) => dispatch(uploadteknologi(data)),
        getTeknologiCrud: () => dispatch(getTeknologiCrud()),
        getTeknologiPenyelenggara: () => dispatch(getTeknologiPenyelenggara()),
        getTeknologiIzin: (id) => dispatch(getTeknologiIzin(id)),
        deleteTeknologi: (id) => dispatch(deleteTeknologi(id)),
        getTeknologiIzinTanggal: (namaizin, company) => dispatch(getTeknologiIzinTanggal(namaizin, company)),
        getNamaTeknologi: (data) => dispatch(getNamaTeknologi(data)),
        getTeknologi: (data) => dispatch(getTeknologi(data)),
        addTeknologi: (data) => dispatch(addTeknologi(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeknologiSection);