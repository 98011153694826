export const PROVINSI_REQ = 'PROVINSI_REQ';
export const PROVINSI_OK = 'PROVINSI_OK';
export const PROVINSI_FAIL = 'PROVINSI_FAIL';

export const KOTA_REQ = 'KOTA_REQ';
export const KOTA_OK = 'KOTA_OK';
export const KOTA_FAIL = 'KOTA_FAIL';

export const POST_REQ = 'POST_REQ';
export const POST_OK = 'POST_OK';
export const POST_FAIL = 'POST_FAIL';

export const PROVINSI_CORE_REQ = 'PROVINSI_CORE_REQ';
export const PROVINSI_CORE_OK = 'PROVINSI_CORE_OK';
export const PROVINSI_CORE_FAIL = 'PROVINSI_CORE_FAIL';

export const KOTA_CORE_REQ = 'KOTA_CORE_REQ';
export const KOTA_CORE_OK = 'KOTA_CORE_OK';
export const KOTA_CORE_FAIL = 'KOTA_CORE_FAIL';

export const POST_CORE_REQ = 'POST_CORE_REQ';
export const POST_CORE_OK = 'POST_CORE_OK';
export const POST_CORE_FAIL = 'POST_CORE_FAIL';

export const NEGARA_REQ = 'NEGARA_REQ';
export const NEGARA_OK = 'NEGARA_OK';
export const NEGARA_FAIL = 'NEGARA_FAIL';