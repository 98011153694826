import React from 'react';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import DataTable from 'react-data-table-component';
import Loader from 'react-loader-spinner';
import ReactModal from 'react-modal';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import Select from "react-select";
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';

import { ActionButton, Spinner, Styles } from '../../components';
import getRasioKesehatanKeuanganPivot from '../../redux/rasioKesehatanKeuangan/rasioKesehatanKeuanganAction';
import {
    addRasioKesehatanKeuangan,
    getKeteranganRasioKesehatan,
    deleteRasioKesehatanKeuangan,
    getRasioKesehatanKeuanganCrud,
    uploadRasioKesehatanKeuangan
} from '../../redux';

const RasioKesehatanKeuangan = ({
    id,
    nama,
    rasioKesehatanKeuangan,
    getRasioKesehatanKeuanganPivot,
    getRasioKesehatanKeuanganCrud,
    rasioKesehatanKeuanganCrud,
    deleteRasioKesehatanKeuangan,
    role,
    uploadRasioKesehatanKeuangan,
    rasioKesehatanKeuanganMessage,
    addRasioKesehatanKeuangan,
    getKeteranganRasioKesehatan,
    keteranganRasioKesehatan
}) => {
    const { register, handleSubmit, control } = useForm();
    const [crudRasioKesehatan, setCrudRasioKesehatan] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    const toCrudRasioKesehatan = () => setCrudRasioKesehatan(!crudRasioKesehatan);
    const [tahunRasioKesehatanValue, setTahunRasioKesehatanValue] = React.useState([]);
    const [rowRasioKesehatanCrud, setRowRasioKesehatanCrud] = React.useState([]);
    const [rowRasioKesehatan, setRowRasioKesehatan] = React.useState([]);
    const [showModalAddRasioKesehatanKeuangan, setShowModalAddRasioKeseatanKeuangan] = React.useState(false);
    const [tahun, setTahun] = React.useState([]);
    const [keteranganRasioKesehatanState, setKeteranganRasioKesehatanState] = React.useState([]);
    const [fileUpload, setFileUpload] = React.useState(null);

    // value inputs
    const [tahunInput, setTahunInput] = React.useState('');
    const [rasioKeteranganValue, setRasioKeteranganValue] = React.useState('');

    // chart state
    const [dataChart, setDataChart] = React.useState([]);
    const [perputaranPiutangChart, setPerputaranPiutangChart] = React.useState([]);
    // const [roeChartState, setRoeChartState] = React.useState([]);
    // const [roiChartState, setRoiChartState] = React.useState([]);
    // const [cashRatioChartState, setcashRatioChartState] = React.useState([]);

    React.useEffect(() => {
        function makeid() {
            var letters = '0123456789ABCDEF';
            var color = '#';
            for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        }

        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }

        rasioKesehatanKeuanganCrud && rasioKesehatanKeuanganCrud.rasioKesehatanKeuanganCrud && setDataChart({
            labels: rasioKesehatanKeuanganCrud.rasioKesehatanKeuanganCrud.filter(filter => filter.nama_keterangan !== 'Perputaran Piutang').map(p => p.tahun).filter(onlyUnique),
            datasets: Object.values(_.groupBy(rasioKesehatanKeuanganCrud.rasioKesehatanKeuanganCrud.filter(filter => filter.nama_keterangan !== 'Perputaran Piutang'), 'nama_keterangan')).map(e => ({
                label: e.filter(filter => filter.nama_keterangan !== 'Perputaran Piutang').map(p => p.nama_keterangan).filter(onlyUnique),
                data: e.filter(filter => filter.nama_keterangan !== 'Perputaran Piutang').map((f, index) => f.nilai_rasio),
                backgroundColor: makeid()
            }))
        });

        rasioKesehatanKeuanganCrud.loading && rasioKesehatanKeuanganCrud.rasioKesehatanKeuanganCrud &&
            rasioKesehatanKeuanganCrud.rasioKesehatanKeuanganCrud.filter(e => e.nama_keterangan === 'Perputaran Piutang').map((data, _, i) =>
                setPerputaranPiutangChart({
                    labels: i.map(t => t.tahun),
                    datasets: [{
                        label: 'Perputaran Piutang',
                        data: i.map(r => (r.nilai_rasio)),
                        backgroundColor: makeid()
                    }]
                }))
    }, [rasioKesehatanKeuanganCrud])

    React.useEffect(() => {
        setTahun(Tahun().map(t => ({ value: t, label: t })));
    }, []);



    React.useEffect(() => {
        const notif = () => {
            rasioKesehatanKeuanganMessage.loading && rasioKesehatanKeuanganMessage.rasioKesehatanKeuanganMessage && swal(`${rasioKesehatanKeuanganMessage.rasioKesehatanKeuanganMessage.message}`, {
                buttons: true
            }).then((value) => {
                if (value) {
                    getRasioKesehatanKeuanganPivot(id);
                    getRasioKesehatanKeuanganCrud(id);
                }
            });
        };

        return notif();
    }, [getRasioKesehatanKeuanganCrud, getRasioKesehatanKeuanganPivot, id, rasioKesehatanKeuanganMessage]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            keteranganRasioKesehatan && keteranganRasioKesehatan.keteranganRasioKesehatan && setKeteranganRasioKesehatanState(keteranganRasioKesehatan.keteranganRasioKesehatan.map(ketraskes => ({ value: ketraskes.id, label: ketraskes.nama_keterangan })))
            rasioKesehatanKeuangan.loading && rasioKesehatanKeuangan.rasioKesehatanKeuangan && rasioKesehatanKeuangan.rasioKesehatanKeuangan.length > 0 && setTahunRasioKesehatanValue(Object.keys(rasioKesehatanKeuangan.rasioKesehatanKeuangan[0]));

            rasioKesehatanKeuangan.loading && rasioKesehatanKeuangan.rasioKesehatanKeuangan && setRowRasioKesehatan(rasioKesehatanKeuangan.rasioKesehatanKeuangan);

            rasioKesehatanKeuanganCrud.loading && rasioKesehatanKeuanganCrud.rasioKesehatanKeuanganCrud && setRowRasioKesehatanCrud(rasioKesehatanKeuanganCrud.rasioKesehatanKeuanganCrud.map(raskeu => ({
                keterangan: raskeu.nama_keterangan,
                nilai_rasio: raskeu.nilai_rasio,
                tahun: raskeu.tahun,
                action: <>
                    <ActionButton
                        title="fa fa-pencil"
                        css="btn-warning"
                        fun={() => window.location.href = `/penyelenggara-detail-keuangan/${id}/${nama}/edit/${raskeu.id}/rasio kesehatan keuangan`}
                    />
                    &nbsp;
                    <ActionButton title="fa fa-trash" css="btn-danger"
                        fun={
                            () => swal(`Apakah Yakin Data Rasio Keuangan ${raskeu.nama_keterangan} ${raskeu.company_name} tahun ${raskeu.tahun} ini akan dihapus?`, {
                                buttons: ["Batal", true],
                            }).then((value) => {
                                if (value) {
                                    deleteRasioKesehatanKeuangan(raskeu.id);
                                }
                            })
                        }
                    />
                </>
            })));
            setLoading(false);
        }, 5000);
        return () => clearTimeout(timeout);
    }, [deleteRasioKesehatanKeuangan, id, keteranganRasioKesehatan, nama, rasioKesehatanKeuangan, rasioKesehatanKeuanganCrud]);

    React.useEffect(() => {
        getRasioKesehatanKeuanganPivot(id);
        getRasioKesehatanKeuanganCrud(id);
        getKeteranganRasioKesehatan();
    }, [getKeteranganRasioKesehatan, getRasioKesehatanKeuanganCrud, getRasioKesehatanKeuanganPivot, id]);

    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none'
            },
        },
    };

    const columnsRasioKesehatan = [
        {
            name: 'No',
            selector: (row, index) => index + 1,
            maxWidth: '10px'
        },
        {
            name: `Nama Keterangan`,
            selector: row => row.nama_keterangan,
            minWidth: '300px'
        },
        {
            name: `${tahunRasioKesehatanValue.length > 0 ? tahunRasioKesehatanValue[1].slice(6) : tahunRasioKesehatanValue}`,
            selector: tahunRasioKesehatanValue.length > 0 ? tahunRasioKesehatanValue[1] : tahunRasioKesehatanValue
        },
        {
            name: `${tahunRasioKesehatanValue.length > 0 ? tahunRasioKesehatanValue[2].slice(6) : tahunRasioKesehatanValue}`,
            selector: tahunRasioKesehatanValue.length > 0 ? tahunRasioKesehatanValue[2] : tahunRasioKesehatanValue
        },
        {
            name: `${tahunRasioKesehatanValue.length > 0 ? tahunRasioKesehatanValue[3].slice(6) : tahunRasioKesehatanValue}`,
            selector: tahunRasioKesehatanValue.length > 0 ? tahunRasioKesehatanValue[3] : tahunRasioKesehatanValue
        },
        {
            name: `${tahunRasioKesehatanValue.length > 0 ? tahunRasioKesehatanValue[4].slice(6) : tahunRasioKesehatanValue}`,
            selector: tahunRasioKesehatanValue.length > 0 ? tahunRasioKesehatanValue[4] : tahunRasioKesehatanValue
        },
        {
            name: `${tahunRasioKesehatanValue.length > 0 ? tahunRasioKesehatanValue[5].slice(6) : tahunRasioKesehatanValue}`,
            selector: tahunRasioKesehatanValue.length > 0 ? tahunRasioKesehatanValue[5] : tahunRasioKesehatanValue
        },
    ];

    const columnsRasioKesehatanCrud = [
        {
            name: 'No',
            selector: (row, index) => index + 1,
            maxWidth: '10px'
        },
        {
            name: `Nama Keterangan`,
            selector: row => row.keterangan
        },
        {
            name: `Nilai Rasio`,
            selector: row => row.nilai_rasio
        },
        {
            name: `Tahun`,
            selector: row => row.tahun
        },
        {
            name: `Action`,
            selector: `action`
        },
    ];

    const customStylesModal = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: '0',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '700px',
            height: '80%'
        },
    };
    const setupShowModalRasioKesehatanKeuangan = () => setShowModalAddRasioKeseatanKeuangan(!showModalAddRasioKesehatanKeuangan);

    const onUpload = (e) => setFileUpload(e.target.files[0]);

    const uploadRasioKesahatanKeuanganSubmit = () => {
        const formData = new FormData();
        formData.append("nama_file", fileUpload);
        uploadRasioKesehatanKeuangan(formData);
    }

    const submitRasioKesehatanKeuangan = (data) => {
        const input = {
            tahun: tahunInput,
            data_umum_id: id,
            company_name: nama,
            nama_ket_rasio_kesehatan_id: rasioKeteranganValue,
            nilai_rasio: data.nilai_rasio
        }
        addRasioKesehatanKeuangan(input);
    }

    return loading ? <Spinner /> : (
        <>
            {
                role.role_id !== 3 &&
                <div className="toolbar" style={{ float: 'right', display: 'flex', flexDirection: 'row', }}>
                    <div className="card-header">
                        <Button
                            id="basic-button"
                            aria-controls="basic-menu"
                            className="btn btn"
                            size="large"
                            aria-haspopup="true"
                            onClick={setupShowModalRasioKesehatanKeuangan}
                            style={{ backgroundColor: 'rgba(0, 50, 122, 1)', color: '#fff', marginRight: '10px' }}
                        >
                            <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;Tambah
                        </Button>
                        <Button
                            id="basic-button"
                            aria-controls="basic-menu"
                            size="large" className={crudRasioKesehatan ? "btn btn-success" : "btn btn-danger"}
                            onClick={toCrudRasioKesehatan}
                        >
                            <i className={crudRasioKesehatan ? "fa fa-edit" : "fa fa-eye"} aria-hidden="true"></i> {crudRasioKesehatan ? " Edit" : " View"}
                        </Button>
                    </div>
                </div>
            }
            <div className="card-content">
                <div className="material-datatables">
                    {
                        crudRasioKesehatan ?
                            <DataTable
                                columns={columnsRasioKesehatan}
                                data={rowRasioKesehatan}
                                progressPending={loading}
                                customStyles={customStyles}
                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100}
                                    width={100} />}
                                responsive={true}
                                pagination
                            /> :
                            <DataTable
                                columns={columnsRasioKesehatanCrud}
                                data={rowRasioKesehatanCrud}
                                progressPending={loading}
                                customStyles={customStyles}
                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100}
                                    width={100} />}
                                responsive={true}
                                pagination
                            />
                    }
                    {/* <Bar data={dataChart} />
                    <Bar data={perputaranPiutangChart} /> */}
                    {/* <Bar data={roiChartState} />
                    <Bar data={cashRatioChartState} /> */}
                </div>
            </div>
            <ReactModal
                ariaHideApp={false}
                isOpen={showModalAddRasioKesehatanKeuangan}
                preventScroll={true}
                scrollable={true}
                style={customStylesModal}
            >
                <div style={{ float: 'right' }}>
                    <Button id="basic-button"
                        aria-controls="basic-menu"
                        color="error"
                        onClick={setupShowModalRasioKesehatanKeuangan}
                        size="small">
                        <span className="material-icons">
                            close
                        </span>
                    </Button>
                </div>
                <Modal.Title style={{ fontWeight: 'bold' }}>Tambah Data Rasio Kesehatan Keuangan</Modal.Title>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(uploadRasioKesahatanKeuanganSubmit)}
                >
                    <h5 className="text-center">Import Data Rasio Kesehatan Keuangan {nama}</h5>
                    <input
                        type="file"
                        name="nama_file"
                        onChange={onUpload}
                    />
                    {
                        rasioKesehatanKeuanganMessage && rasioKesehatanKeuanganMessage.error && <span className="text-danger">{rasioKesehatanKeuanganMessage.error.nama_file}</span>
                    }
                    <Link to="/download/rasio kesehatan keuangan.xlsx" style={{ float: 'right' }} target="_blank">Download Template Upload Rasio Kesehatan Keuangan</Link><br />
                    <Button variant="contained" color="primary" type="submit" >Upload</Button>
                    <br />
                    {
                        rasioKesehatanKeuanganMessage && rasioKesehatanKeuanganMessage.error && rasioKesehatanKeuanganMessage.error.message && <span className="text-danger">{rasioKesehatanKeuanganMessage.error.message.map(r => r)}</span>
                    }
                </form>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitRasioKesehatanKeuangan)}
                >
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="tahun"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={tahun}
                                    placeholder="Tahun.."
                                    onChange={(e) => setTahunInput(e.value)}
                                />
                            )}
                        />
                        {
                            rasioKesehatanKeuanganMessage && rasioKesehatanKeuanganMessage.error && <span className="text-danger">{rasioKesehatanKeuanganMessage.error.tahun}</span>
                        }
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="nama_ket_rasio_kesehatan_id"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={keteranganRasioKesehatanState}
                                    placeholder="Nama Keterangan Rasio Kesehatan"
                                    onChange={(e) => setRasioKeteranganValue(e.value)}
                                />
                            )}
                        />
                        {
                            rasioKesehatanKeuanganMessage && rasioKesehatanKeuanganMessage.error && <span className="text-danger">{rasioKesehatanKeuanganMessage.error.nama_ket_rasio_kesehatan_id}</span>
                        }
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Nilai Rasio"
                            type="text"
                            className="form-control"
                            {...register("nilai_rasio")}
                        />
                        {rasioKesehatanKeuanganMessage && rasioKesehatanKeuanganMessage.error && <span className="text-danger">{rasioKesehatanKeuanganMessage.error.nilai_rasio}</span>}
                    </Styles.InputForm>
                    <Button variant="contained" color="error" onClick={setupShowModalRasioKesehatanKeuangan} style={{ marginRight: 5 }}>Close</Button>
                    <Button variant="contained" color="primary" type="submit">Save</Button>
                </form >
            </ReactModal >
        </>
    )
}

const Tahun = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= 1945; i--) {
        tahun.push(i);
    }
    return tahun;
}

const mapStateToProps = state => {
    return {
        rasioKesehatanKeuangan: state.rasioKesehatanKeuangan,
        rasioKesehatanKeuanganCrud: state.rasioKesehatanKeuanganCrud,
        rasioKesehatanKeuanganMessage: state.rasioKesehatanKeuanganMessage,
        keteranganRasioKesehatan: state.keteranganRasioKesehatan,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        uploadRasioKesehatanKeuangan: (data) => dispatch(uploadRasioKesehatanKeuangan(data)),
        getRasioKesehatanKeuanganPivot: (id) => dispatch(getRasioKesehatanKeuanganPivot(id)),
        getRasioKesehatanKeuanganCrud: (id) => dispatch(getRasioKesehatanKeuanganCrud(id)),
        addRasioKesehatanKeuangan: (data) => dispatch(addRasioKesehatanKeuangan(data)),
        getKeteranganRasioKesehatan: () => dispatch(getKeteranganRasioKesehatan()),
        deleteRasioKesehatanKeuangan: (id) => dispatch(deleteRasioKesehatanKeuangan(id)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(RasioKesehatanKeuangan)