import React from 'react';
import Loader from 'react-loader-spinner';
import DataTable from 'react-data-table-component';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import { ActionButton, Spinner, Sidebar, Navbar } from '../../components';

const Users = ({
    user,
    users,
    getUsers,
    getUser,
    deleteUser,
    usersMessage,
    activateUser
}) => {
    const history = useHistory()
    const [dataUsers, setDataUsers] = React.useState([]);
    const [userdata, setUserData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none',
                minWidth: 'auto',
                fontSize: '14px'
            },
        },
    };
    const columns = [
        {
            name: 'No.',
            selector: row => row.nomor,
            minWidth: '10px'
        },
        {
            name: 'Username',
            selector: row => row.username,
            minWidth: '100px'
        },
        {
            name: 'Nama Lengkap',
            selector: row => row.fullName,
            minWidth: '200px'
        },
        {
            name: 'Kontak',
            selector: row => row.phone
        },
        {
            name: 'Email',
            selector: row => row.email,
            minWidth: '300px'
        },
        {
            name: 'Jabatan',
            selector: row => row.jabatan
        },
        {
            name: 'Aktivasi',
            selector: row => row.active_action
        },
        {
            name: 'Action',
            selector: row => row.action
        }
    ];

    React.useEffect(() => {
        document.title = "Users | PPI"
    }, []);

    React.useEffect(() => {
        getUser();
        getUsers();
    }, [getUser, getUsers]);

    React.useEffect(() => {
        const notification = () => {
            usersMessage && usersMessage.usersMessage && swal(`${usersMessage.usersMessage.message}`, {
                buttons: ["Batal", true],
            }).then((ok) => {
                if (ok) {
                    getUsers();
                }
            })
        }
        return notification();
    }, [getUsers, usersMessage])

    React.useEffect(() => {
        const timeOut = setTimeout(() => {
            user.loading && user.user.map(u => setUserData(u));
            user && user.error === "Unauthenticated." && history.push('/');
            users && users.users &&
                setDataUsers(
                    users.users.filter(u => u.id !== 1).map((user, index) => ({
                        nomor: index + 1,
                        username: user.username,
                        email: user.email,
                        jabatan: user.details.jabatan,
                        fullName: user.details.fname + " " + user.details.lname,
                        phone: user.details.phone,
                        active_action: <button
                            className={user.stat === 1 ? "btn-sm btn-success" : "btn-sm btn-danger"}
                            onClick={() => {
                                activateUser(user.id);
                            }} >{user.stat === 1 ? "Aktif" : "Tidak Aktif"}</button>,
                        action: <><ActionButton
                            title="fa fa-pencil"
                            css="btn-warning" />
                            &nbsp;

                            <ActionButton title="fa fa-trash" css="btn-danger"
                                fun={() => swal(`Apakah Yakin Data dengan username ${user.username} ini akan dihapus?`, {
                                    buttons: ["Batal", true],
                                }).then((ok) => {
                                    if (ok) {
                                        deleteUser(user.id);
                                    }
                                })
                                }
                            />
                        </>
                    }))
                );
            setLoading(false);
        }, 3000);
        return () => clearTimeout(timeOut);
    }, [activateUser, deleteUser, getUsers, history, user, users]);

    React.useEffect(() => {
        if (userdata && userdata.role_id === 3) {
            history.push('/dashboard');
        }
    }, [userdata]);

    return users.loading ? <Spinner /> : users.error === "Unauthenticated." ? (
        window.location.href = "/") : (
        <>
            <Sidebar menu="USERS" />
            <div className="main-panel">
                <Navbar menu="USERS" user={userdata} />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header card-header-icon" data-background-color="blue">
                                        <i className="material-icons">person</i>
                                    </div>
                                    <h3 className="title" style={{ fontWeight: 'bold', marginTop: '0px' }}>All Users</h3>
                                    <div className="card-content">
                                        <div className="material-datatables">
                                            <DataTable
                                                columns={columns}
                                                data={dataUsers}
                                                progressPending={loading}
                                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100} width={100} />}
                                                responsive={true}
                                                customStyles={customStyles}
                                                striped={true}
                                                highlightOnHover={true}
                                                pagination />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Users
