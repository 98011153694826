import React from 'react';
import { Sidebar, Navbar, Spinner } from '../../components';
import {
    Container,
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';


const Report = ({
    user,
    getUser,
    profilUmum,
    getProfilUmum,
    izinDetail,
    getIzinDetail,
    reportExport
}) => {
    const history = useHistory()
    const { control, handleSubmit, register } = useForm()
    const [loading, setLoading] = React.useState(true);
    const [userData, setUserData] = React.useState(null);
    const [profilUmumState, setProfilUmumState] = React.useState([]);
    const [izinState, setIzinState] = React.useState([]);

    const [dataUmumState, setDataUmumState] = React.useState('');

    // console.log(izinState)

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    React.useEffect(() => {
        document.title = "Report | PPI"
    }, []);

    React.useEffect(() => {
        izinDetail && izinDetail.izinDetail.length > 0 && setIzinState(izinDetail.izinDetail.map(u => u.permit_name).filter(onlyUnique))
    }, [izinDetail])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            user && user.user && user.user.map(u => setUserData(u));
            user && user.error === "Unauthenticated." && history.push('/');
            profilUmum.loading && setProfilUmumState(profilUmum.profilUmum.map(pu => ({
                value: pu.id,
                label: pu.company_name
            })));
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [history, profilUmum, user]);

    React.useEffect(() => {
        getUser();
        getProfilUmum()
    }, [getProfilUmum, getUser]);

    const ambilIzin = (e) => {
        getIzinDetail(e)
    }

    const exportReport = (data) => {
        const input = {
            ...data,
            data_umum_id: dataUmumState
        };
        console.log(input);
        reportExport(input)
    }

    return loading ? <Spinner /> : (<>
        <Sidebar menu="REPORT" />
        <div className="main-panel">
            <Navbar menu="DASHBOARD" user={userData} />
            <div className="content">
                <Container fluid>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header card-header-icon" data-background-color="red">
                                    <i className="material-icons">assessment</i>
                                </div>
                                <h3 className="title" style={{ fontWeight: 'bold' }}>Report</h3>
                                <div className="card-content">
                                    <form onSubmit={handleSubmit(exportReport)}>
                                        <Controller
                                            control={control}
                                            name="data_umum_id"
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    options={profilUmumState}
                                                    placeholder="Pilih Penyelenggara"
                                                    onChange={(e) => {
                                                        ambilIzin(e.value);
                                                        setDataUmumState(e.value)
                                                    }}
                                                />
                                            )}
                                        />
                                        {
                                            izinState.length > 0 && <>
                                                <p style={{ margin: '10px' }}>Pilih Perizinan</p>
                                                {
                                                    izinState.map((i, index) => (
                                                        <FormControlLabel
                                                            key={index}
                                                            label={i}
                                                            {...register(i.toLowerCase())}
                                                            control={<Checkbox defaultChecked color="success" />}
                                                        />
                                                    ))
                                                }
                                                <p style={{ margin: '10px' }}>Pilih Keuangan</p>
                                                <FormControlLabel
                                                    label="Keuangan umum"
                                                    control={<Checkbox defaultChecked color="success"  {...register('keuanganumum')} />}
                                                />
                                                <FormControlLabel
                                                    label="Rasio Keuangan"
                                                    control={<Checkbox defaultChecked color="success" {...register("rasiokeuangan")} />}
                                                />
                                                <FormControlLabel
                                                    label="Rasio Kesehatan Keuangan"
                                                    control={<Checkbox defaultChecked color="success" {...register('rasiokesehatankeuangan')} />}
                                                />
                                                <FormControlLabel
                                                    label="Rasio Konversi Kesehatan Keuangan"
                                                    control={<Checkbox defaultChecked color="success" {...register('rasiokonversikesehatankeuangan')} />}
                                                />
                                                <br />
                                                <div>
                                                    {/* <Button
                                                        className="btn btn-danger"
                                                        style={{ margin: '10px' }}
                                                    >
                                                        Export Pdf
                                                    </Button> */}
                                                    <Button className="btn btn-success"
                                                        style={{ margin: '10px' }}
                                                        type="submit"
                                                    >
                                                        Export excel
                                                    </Button>
                                                </div>
                                            </>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    </>);
}

export default Report
