import Jartaptup from "./Jartaptup"
import { getAlljartapsljj, getUser } from "../../redux";
import { connect } from 'react-redux';

const mapStateToProps = state => {
    return {
        user: state.user,
        jartapsljj: state.jartapsljj
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUser: () => dispatch(getUser()),
        getAlljartapsljj: () => dispatch(getAlljartapsljj())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Jartaptup);
