import {
    SUBTABIZIN_REQ,
    SUBTABIZIN_SUCCESS,
    SUBTABIZIN_FAIL,
} from './subtabizinTypes';

const subtabIzinState = {
    loading: false,
    data: [],
    error: ''
}

const subtabizinReducer = (state = subtabIzinState, action) => {
    switch (action.type) {
        case SUBTABIZIN_REQ:
            return {
                ...state,
                loading: false,
            }
        case SUBTABIZIN_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case SUBTABIZIN_FAIL:
            return {
                loading: false,
                data: [],
                error: action.payload
            }
        default:
            return state
    }
}

export default subtabizinReducer