import PerizinanDetail from './PerizinanDetail';
import { connect } from 'react-redux';
import { addIzin, deleteIzinDetail, getIzinDetail, getProfilUmum, uploadIzin } from '../../redux';

const mapStateToProps = state => {
    return {
        izinDetail: state.izinDetail,
        dataUmum: state.profilUmum,
        izinMessage: state.izinMessage,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDataUmum: () => dispatch(getProfilUmum()),
        getIzinDetail: (id) => dispatch(getIzinDetail(id)),
        deleteIzinDetail: (id) => dispatch(deleteIzinDetail(id)),
        addIzin: (data) => dispatch(addIzin(data)),
        uploadIzin: (data) => dispatch(uploadIzin(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PerizinanDetail)