import Jartaplok from "./Jartaplok";
import { connect } from 'react-redux';
import { getAlljartaplok, getUser } from "../../redux";

const mapStateToProps = state => {
    return {
        jartaplok: state.jartaplok,
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUser: () => dispatch(getUser()),
        getAlljartaplok: () => dispatch(getAlljartaplok())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Jartaplok);
