import {
    JABERTRUNK_REQ,
    JABERTRUNK_SUCCESS,
    JABERTRUNK_FAIL,
    JABERTRUNK_MESSAGE_REQ,
    JABERTRUNK_MESSAGE_SUCCESS,
    JABERTRUNK_MESSAGE_FAIL,
    JABERTRUNK_DETAIL_REQ,
    JABERTRUNK_DETAIL_SUCCESS,
    JABERTRUNK_DETAIL_FAIL,
} from './jabertrunkTypes';

const jabertrunkDetailState = {
    loading: false,
    jabertrunkDetail: null,
    error: ''
}

const jabertrunkMessageState = {
    loading: false,
    jabertrunkMessage: '',
    error: ''
}

const jabertrunkState = {
    loading: false,
    jabertrunk: [],
    error: ''
}

export const jabertrunkDetailReducer = (state = jabertrunkDetailState, action) => {
    switch (action.type) {
        case JABERTRUNK_DETAIL_REQ:
            return {
                ...state,
                loading: false
            }
        case JABERTRUNK_DETAIL_SUCCESS:
            return {
                loading: true,
                jabertrunkDetail: action.payload,
                error: ''
            }
        case JABERTRUNK_DETAIL_FAIL:
            return {
                loading: true,
                jabertrunkDetail: null,
                error: action.payload
            }
        default:
            return state
    }
}

export const jabertrunkMessageReducer = (state = jabertrunkMessageState, action) => {
    switch (action.type) {
        case JABERTRUNK_MESSAGE_REQ:
            return {
                ...state,
                loading: false,
            }
        case JABERTRUNK_MESSAGE_SUCCESS:
            return {
                loading: true,
                jabertrunkMessage: action.payload,
                error: ''
            }
        case JABERTRUNK_MESSAGE_FAIL:
            return {
                loading: true,
                jabertrunkMessage: "",
                error: action.payload
            }
        default:
            return state
    }
}

const jabertrunkReducer = (state = jabertrunkState, action) => {
    switch (action.type) {
        case JABERTRUNK_REQ:
            return {
                ...state,
                loading: false,
            }
        case JABERTRUNK_SUCCESS:
            return {
                loading: true,
                jabertrunk: action.payload,
                error: ''
            }
        case JABERTRUNK_FAIL:
            return {
                loading: true,
                jabertrunk: null,
                error: action.payload
            }
        default:
            return state
    }
}

export default jabertrunkReducer