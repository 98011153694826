import {
    IZIN_REQ,
    IZIN_SUCCESS,
    IZIN_FAIL,
    IZIN_MESSAGE_REQ,
    IZIN_MESSAGE_SUCCESS,
    IZIN_MESSAGE_FAIL,
    IZIN_DETAIL_REQ,
    IZIN_DETAIL_SUCCESS,
    IZIN_DETAIL_FAIL,
} from './izinTypes'

import api from '../services';
import { config } from '../config';
export const token = localStorage.getItem('token');

export const izinDetailReq = () => {
    return { type: IZIN_DETAIL_REQ }
}

export const izinDetailSuccess = (data) => {
    return { type: IZIN_DETAIL_SUCCESS, payload: data }
}

export const izinDetailFail = (err) => {
    return { type: IZIN_DETAIL_FAIL, payload: err }
}

export const izinMessageReq = () => {
    return { type: IZIN_MESSAGE_REQ }
}

export const izinMessageSuccess = (data) => {
    return { type: IZIN_MESSAGE_SUCCESS, payload: data }
}

export const izinMessageFail = (err) => {
    return { type: IZIN_MESSAGE_FAIL, payload: err }
}

export const izinReq = () => {
    return { type: IZIN_REQ }
}

export const izinSuccess = (data) => {
    return { type: IZIN_SUCCESS, payload: data }
}

export const izinFail = (err) => {
    return { type: IZIN_FAIL, payload: err }
}

export const uploadIzin = (data) => {
    return async (dispatch) => {
        await api.post(`/izin-create-import`, data, config)
            .then(res => dispatch(izinMessageSuccess(res.data)))
            .catch(err => dispatch(izinMessageFail(err.response.data)))
    }
}

export const updateIzin = (id, data) => {
    return async (dispatch) => {
        await api.put(`/izin-update/${id}`, data, config)
            .then(res => dispatch(izinMessageSuccess(res.data)))
            .catch(err => dispatch(izinMessageFail(err.response.data)))
    }
}

export const addIzin = (data) => {
    return async (dispatch) => {
        dispatch(izinMessageReq);
        await api.post('/izin-create', data, config)
            .then(res => {
                dispatch(izinMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(izinMessageFail(err.response.data));
            })
    }
}

export const deleteIzinDetail = (id) => {
    return async (dispatch) => {
        dispatch(izinMessageReq);
        await api.delete(`/izin-delete/${id}`, config)
            .then(res => {
                dispatch(izinMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(izinMessageFail(err.response.data));
            })
    }
}

export const getIzinDetailById = (id) => {
    return async (dispatch) => {
        dispatch(izinDetailReq);
        await api.get(`/izin/${id}`, config)
            .then(res => {
                dispatch(izinDetailSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(izinDetailFail(err.response));
            })
    }
}

export const getIzinDetail = (id) => {
    return async (dispatch) => {
        dispatch(izinDetailReq);
        await api.get(`/izin-company/${id}`, config)
            .then(res => {
                dispatch(izinDetailSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(izinDetailFail(err.response));
            })
    }
}

export const getIzin = (cari) => {
    return async (dispatch) => {
        dispatch(izinReq);
        await api.get('/izin', {
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            },
            params: {
                jenis: cari,
            }
        })
            .then(res => {
                dispatch(izinSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(izinFail(err.response.data));
            })
    }
}