import React from 'react';
import { Navbar, Sidebar, Styles, Spinner } from '../../components';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import Select from "react-select";
import { useForm, Controller } from 'react-hook-form';
import swal from 'sweetalert';


const EditProfilUmum = ({
    user,
    getUser,
    getProfilUmumDetail,
    profilUmumDetail,
    getProvinsi,
    provinsi,
    getKota,
    kota,
    getKodePos,
    kodePos,
    provinsiCore,
    kotaCore,
    kodePosCore,
    getKotaCore,
    getProvinsiCore,
    getKodePosCore,
    profilUmumMessage,
    profilUmumUpdate
}) => {

    const { register, handleSubmit, control } = useForm();
    const history = useHistory();
    const { id, nama } = useParams();
    const [provinsiState, setProvinsiState] = React.useState([]);
    const [kotaState, setKotaState] = React.useState([]);
    const [kodePosState, setKodePosState] = React.useState([]);
    const [provinsiCoreState, setProvinsiCoreState] = React.useState([]);
    const [kotaCoreState, setKotaCoreState] = React.useState([]);
    const [kodePosCoreState, setKodePosCoreState] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [userData, setUserData] = React.useState(null);
    const [profilUmumState, setProfilUmumState] = React.useState(null);

    const [citiesValue, setCitiesValue] = React.useState('');
    const [citiesCoreValue, setCitiesCoreValue] = React.useState('');
    const [provinceValue, setProvinceValue] = React.useState('');
    const [provinceCoreValue, setProvinceCoreValue] = React.useState('');
    const [zippCodeValue, setZippCodeValue] = React.useState('');
    const [zippCodeCoreValue, setZippCodeCoreValue] = React.useState('');


    React.useEffect(() => {
        document.title = "Edit Profil Umum | PPI"
    }, []);

    React.useEffect(() => {
        const notif = () => {
            if (profilUmumMessage.loading && profilUmumMessage.profilUmumMessage) {
                swal(`${profilUmumMessage.profilUmumMessage.message}`, {
                    buttons: true
                }).then((value) => {
                    if (value) {
                        history.goBack();
                    }
                });
            }
        }
        return notif();
    }, [history, profilUmumMessage]);

    React.useEffect(() => {
        profilUmumDetail && profilUmumDetail.profilUmumDetail && profilUmumDetail.profilUmumDetail.map(u => setProfilUmumState(u))
    }, [profilUmumDetail]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            user && user.error === "Unauthenticated." && history.push('/');
            user && user.user && user.user.map(u => setUserData(u));
            provinsi && provinsi.provinsi && setProvinsiState(provinsi.provinsi.map(p => ({ label: p.nama_provinsi, value: p.id })));
            kota && kota.kota && setKotaState(kota.kota.map(k => ({ value: k.id, label: k.nama_kota })));
            kodePos && kodePos.kodePos && setKodePosState(kodePos.kodePos.map(k => ({ label: k.kode_pos, value: k.id })));
            provinsiCore && provinsiCore.provinsiCore && setProvinsiCoreState(provinsiCore.provinsiCore.map(p => ({ label: p.nama_provinsi, value: p.id })));
            kotaCore && kotaCore.kotaCore && setKotaCoreState(kotaCore.kotaCore.map(k => ({ value: k.id, label: k.nama_kota })));
            kodePosCore && kodePosCore.kodePosCore && setKodePosCoreState(kodePosCore.kodePosCore.map(k => ({ label: k.kode_pos, value: k.id })));
            setLoading(false);
        }, 4000);
        return () => clearTimeout(timeout);
    }, [history, kodePos, kodePosCore, kota, kotaCore, provinsi, provinsiCore, user]);

    React.useEffect(() => {
        getUser();
        getProfilUmumDetail(id);
        getProvinsi();
        getProvinsiCore();
    }, [getProvinsi, getProfilUmumDetail, getUser, id, getProvinsiCore]);

    React.useEffect(() => {
        getKota(profilUmumState && profilUmumState.provinsi_id);
        getKodePos(profilUmumState && profilUmumState.kota_id);
        getKotaCore(profilUmumState && profilUmumState.province_core_id);
        getKodePosCore(profilUmumState && profilUmumState.cities_core_id);
    }, [profilUmumState, getKota, getKodePos, getKotaCore, getKodePosCore]);


    const submitEditDataUmum = (data) => {
        let input = {
            company_name: nama,
            brand: data.brand,
            status_tbk: data.status_tbk,
            fax: data.fax,
            npwp: data.npwp,
            phone: data.phone,
            lat: data.lat,
            long: data.long,
            website: data.website,
            address: data.address,
            address_core: data.address_core,
            email: data.email,
            email_core: data.email_core,
            phone_core: data.phone_core,
            fax_core: data.fax_core,
            lat_core: data.lat_core,
            long_core: data.long_core,
            website_core: data.website_core,

            province: provinceValue === "" ? profilUmumState.provinsi_id : provinceValue,
            cities: citiesValue === "" ? profilUmumState.kota_id : citiesValue,
            zipcode: zippCodeValue === "" ? profilUmumState.kodepos_id : zippCodeValue,
            province_core: provinceCoreValue === "" ? profilUmumState.province_core_id : provinceCoreValue,
            cities_core: citiesCoreValue === "" ? profilUmumState.province_core_id : citiesCoreValue,
            zipcode_core: zippCodeCoreValue === "" ? profilUmumState.zipcode_core_id : zippCodeCoreValue,
        };
        console.log(input);
        profilUmumUpdate(id, input);
    }

    return loading ? <Spinner /> : (
        <React.Fragment>
            <Sidebar menu="DATA PENYELENGGARAAN" />
            <div className="main-panel">
                <Navbar user={userData} />
                <div className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header card-header-icon" data-background-color="blue">
                                        <Styles.ButtonModal
                                            type="button"
                                            onClick={() => history.goBack()}>
                                            <i className="fa fa-chevron-circle-left fa-2x" aria-hidden="true"></i>
                                        </Styles.ButtonModal>
                                    </div>
                                    <h4 className="card-title">Edit Data Umum {nama}</h4>
                                    <div className="card-content">
                                        <div className="row">
                                            <form className="form"
                                                onSubmit={handleSubmit(submitEditDataUmum)}>
                                                <div className="col-sm-12">
                                                    <div className="input-group">
                                                        <input
                                                            className="form-control"
                                                            placeholder="NPWP"
                                                            defaultValue={profilUmumState.npwp}
                                                            {...register('npwp')}
                                                        />
                                                        <span className="input-group-addon">NPWP</span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="input-group mb-3">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Brand"
                                                            defaultValue={profilUmumState.brand}
                                                            {...register('brand')}
                                                        />
                                                        <span className="input-group-addon">Brand</span>
                                                    </div>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Phone"
                                                            defaultValue={profilUmumState.phone}
                                                            {...register('phone')}
                                                        />
                                                        <span className="input-group-addon">Telepon</span>
                                                    </div>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Fax"
                                                            defaultValue={profilUmumState.fax}
                                                            {...register('fax')}
                                                        />
                                                        <span className="input-group-addon">Fax</span>
                                                    </div>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Email"
                                                            defaultValue={profilUmumState.email}
                                                            {...register('email')}
                                                        />
                                                        <span className="input-group-addon">Email</span>
                                                    </div>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Latitude"
                                                            defaultValue={profilUmumState.lat}
                                                            {...register('lat')}
                                                        />
                                                        <span className="input-group-addon">Latitude</span>
                                                    </div>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Longitude"
                                                            defaultValue={profilUmumState.long}
                                                            {...register('long')}
                                                        />
                                                        <span className="input-group-addon">Logitude</span>
                                                    </div>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Website"
                                                            defaultValue={profilUmumState.website}
                                                            {...register('website')}
                                                        />
                                                        <span className="input-group-addon">Website</span>
                                                    </div>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Alamat"
                                                            defaultValue={profilUmumState.address}
                                                            {...register('address')}
                                                        />
                                                        <span className="input-group-addon">Alamat</span>
                                                    </div>
                                                    <div className="input-group mb-3">
                                                        <Controller
                                                            control={control}
                                                            name="provinsi_id" render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    options={provinsiState}
                                                                    name="provinsi_id"
                                                                    placeholder="Pilih Provinsi"
                                                                    defaultValue={provinsiState.filter(e => e.value === profilUmumState.provinsi_id)}
                                                                    onChange={e => {
                                                                        setProvinceValue(e.value);
                                                                        getKota(e.value);
                                                                    }}
                                                                />
                                                            )} />
                                                        <span className="input-group-addon">Provinsi</span>
                                                    </div>
                                                    <div className="input-group mb-3">
                                                        <Controller
                                                            control={control}
                                                            name="kota_id"
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    options={kotaState}
                                                                    placeholder="Pilih Kota"
                                                                    defaultValue={kotaState.filter(option => option.value === profilUmumState.kota_id)}
                                                                    onChange={e => {
                                                                        setCitiesValue(e.value);
                                                                        getKodePos(e.value);
                                                                    }}
                                                                />
                                                            )} />
                                                        <span className="input-group-addon">Kota</span>
                                                    </div>
                                                    <div className="input-group mb-3">
                                                        <Controller
                                                            control={control}
                                                            name="zipcode"
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    options={kodePosState}
                                                                    placeholder="Pilih Kode Pos"
                                                                    defaultValue={kodePosState.filter(option => option.value === profilUmumState.kodepos_id)}
                                                                    onChange={e => {
                                                                        setZippCodeValue(e.value)
                                                                    }}
                                                                />
                                                            )} />
                                                        <span className="input-group-addon">Kode Pos</span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="input-group">
                                                        <select
                                                            className="form-control mb-2"
                                                            {...register("status_tbk")}
                                                            defaultValue={profilUmumState.status_tbk}
                                                        >
                                                            <option value=""> Status Tbk </option>
                                                            <option value="TBK"> TBK </option>
                                                            <option value="Non TBK"> NON Tbk </option>
                                                        </select>
                                                        <span className="input-group-addon">Status TBK</span>
                                                    </div>
                                                    <div className="input-group">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Phone Korespondensi"
                                                            defaultValue={profilUmumState.phone_core}
                                                            {...register('phone_core')}
                                                        />
                                                        <span className="input-group-addon">Telpon Korespondensi</span>
                                                    </div>
                                                    <div className="input-group">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Fax Korespondensi"
                                                            defaultValue={profilUmumState.fax_core}
                                                            {...register('fax_core')}
                                                        />
                                                        <span className="input-group-addon">Fax Korespondensi</span>
                                                    </div>
                                                    <div className="input-group">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Email Korespondensi"
                                                            defaultValue={profilUmumState.email_core}
                                                            {...register('email_core')}
                                                        />
                                                        <span className="input-group-addon">Email Korespondensi</span>
                                                    </div>
                                                    <div className="input-group">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Latitude Korespondensi"
                                                            defaultValue={profilUmumState.lat_core}
                                                            {...register('lat_core')}

                                                        />
                                                        <span className="input-group-addon">Latitude Korespondensi</span>
                                                    </div>
                                                    <div className="input-group">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Longitude Korespondensi"
                                                            defaultValue={profilUmumState.long_core}
                                                            {...register('long_core')}
                                                        />
                                                        <span className="input-group-addon">Logitude Korespondensi</span>
                                                    </div>
                                                    <div className="input-group">
                                                        <input
                                                            className="form-control"
                                                            placeholder="Website Korespondensi"
                                                            defaultValue={profilUmumState.website_core}
                                                            {...register('website_core')}
                                                        />
                                                        <span className="input-group-addon">Website Korespondensi</span>
                                                    </div>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue={profilUmumState.address_core}
                                                            {...register('address_core')}
                                                        />
                                                        <span className="input-group-addon">Alamat Korespondensi</span>
                                                    </div>
                                                    <div className="input-group">
                                                        <Controller
                                                            control={control}
                                                            name="provinsi_core_id"
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    options={provinsiCoreState}
                                                                    placeholder="Pilih Provinsi Korespondensi"
                                                                    defaultValue={provinsiCoreState.filter(option => option.value === profilUmumState.province_core_id)}
                                                                    onChange={e => {
                                                                        setProvinceCoreValue(e.value);
                                                                        getKotaCore(e.value);
                                                                    }}
                                                                />
                                                            )} />
                                                        <span className="input-group-addon">Provinsi Korespondensi</span>
                                                    </div>
                                                    <div className="input-group">
                                                        <Controller
                                                            control={control}
                                                            name="cities_core_id"
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    options={kotaCoreState}
                                                                    placeholder="Pilih Kota Korespondensi"
                                                                    defaultValue={kotaCoreState.filter(e => e.value === profilUmumState.cities_core_id)}
                                                                    onChange={e => {
                                                                        setCitiesCoreValue(e.value);
                                                                        getKodePosCore(e.value);
                                                                    }}
                                                                />
                                                            )} />
                                                        <span className="input-group-addon">Kota  Korespondensi</span>
                                                    </div>
                                                    <div className="input-group">
                                                        <Controller
                                                            control={control}
                                                            name="zipcode_core"
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    options={kodePosCoreState}
                                                                    placeholder="Pilih Kode Pos"
                                                                    defaultValue={kodePosCoreState.filter(e => e.value === profilUmumState.zipcode_core_id)}
                                                                    onChange={e => {
                                                                        setZippCodeCoreValue(e.value);
                                                                    }}
                                                                />
                                                            )} />
                                                        <span className="input-group-addon">Kode Pos korespondensi</span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <Button variant="primary" type="submit">Save changes</Button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EditProfilUmum
