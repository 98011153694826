import {
    JARTAPSLJJ_REQ,
    JARTAPSLJJ_SUCCESS,
    JARTAPSLJJ_FAIL,
    JARTAPSLJJ_MESSAGE_REQ,
    JARTAPSLJJ_MESSAGE_SUCCESS,
    JARTAPSLJJ_MESSAGE_FAIL,
    JARTAPSLJJ_DETAIL_REQ,
    JARTAPSLJJ_DETAIL_SUCCESS,
    JARTAPSLJJ_DETAIL_FAIL,
} from './jartapsljjTypes';

import api from '../services';
import { config } from '../config';

export const jartapsljjReq = () => {
    return { type: JARTAPSLJJ_REQ }
}

export const jartapsljjSuccess = (data) => {
    return { type: JARTAPSLJJ_SUCCESS, payload: data }
}

export const jartapsljjFail = (err) => {
    return { type: JARTAPSLJJ_FAIL, payload: err }
}

export const jartapsljjMessageReq = () => {
    return { type: JARTAPSLJJ_MESSAGE_REQ }
}

export const jartapsljjMessageSuccess = (data) => {
    return { type: JARTAPSLJJ_MESSAGE_SUCCESS, payload: data }
}

export const jartapsljjMessageFail = (err) => {
    return { type: JARTAPSLJJ_MESSAGE_FAIL, payload: err }
}

export const jartapsljjDetailReq = () => {
    return { type: JARTAPSLJJ_DETAIL_REQ }
}

export const jartapsljjDetailSuccess = (data) => {
    return { type: JARTAPSLJJ_DETAIL_SUCCESS, payload: data }
}

export const jartapsljjDetailFail = (err) => {
    return { type: JARTAPSLJJ_DETAIL_FAIL, payload: err }
}

export const uploadJartapsljj = (data) => {
    return async (dispatch) => {
        dispatch(jartapsljjMessageReq);
        await api.post('/jartapsljj-create-import', data, config)
            .then(res => {
                dispatch(jartapsljjMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartapsljjMessageFail(err.response.data));
            })
    }
}

export const getAlljartapsljj = () => {
    return async (dispatch) => {
        dispatch(jartapsljjReq);
        await api.get(`/jartapsljj`, config)
            .then(res => {
                dispatch(jartapsljjSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jartapsljjFail(err.response.data));
            })
    }
}

export const updatejartapsljj = (id, data) => {
    return async (dispatch) => {
        dispatch(jartapsljjMessageReq);
        await api.put(`/jartapsljj-update/${id}`, data, config)
            .then(res => {
                dispatch(jartapsljjMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartapsljjMessageFail(err.response.data));
            })
    }
}

export const getjartapsljjDetailById = (id) => {
    return async (dispatch) => {
        dispatch(jartapsljjDetailReq);
        await api.get(`/jartapsljj/${id}`, config)
            .then(res => {
                dispatch(jartapsljjDetailSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jartapsljjDetailSuccess(err.response));
            })
    }
}

export const deleteJartapsljj = (id) => {
    return async (dispatch) => {
        dispatch(jartapsljjMessageReq);
        await api.delete(`/jartapsljj-delete/${id}`, config)
            .then(res => {
                dispatch(jartapsljjMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartapsljjMessageFail(err.response.data));
            })
    }
}

export const addJartapsljj = (data) => {
    return async (dispatch) => {
        dispatch(jartapsljjMessageReq);
        await api.post('/jartapsljj-create', data, config)
            .then(res => {
                dispatch(jartapsljjMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartapsljjMessageFail(err.response.data));
            })
    }
}

export const getjartapsljjByCompany = (id) => {
    return async (dispatch) => {
        dispatch(jartapsljjReq);
        await api.get(`/jartapsljj-company/${id}`, config)
            .then(res => {
                dispatch(jartapsljjSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jartapsljjFail(err.response.data));
            })
    }
}