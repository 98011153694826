import React from 'react';
import {
    Container,
} from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import Select from "react-select";
import Button from '@mui/material/Button';
import { useForm, Controller } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import swal from 'sweetalert';

import { Sidebar, Navbar, Styles, Spinner } from '../../components';
// import swal from 'sweetalert';

const EditBts = ({
    user,
    getUser,
    provinsi,
    getProvinsi,
    btsMessage,
    btsDetail,
    getbtsDetail,
    updateBts
}) => {

    const { register, handleSubmit, control } = useForm();
    const history = useHistory();
    const { id, nama } = useParams();
    const [userData, setUserData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [tahun, setTahun] = React.useState([]);
    const [prov, setProv] = React.useState([]);
    const [valueEdit, setValueEdit] = React.useState([]);

    const [inputTahun, setInputTahun] = React.useState('');
    const [valueProvinsi, setValueProvinsi] = React.useState('');

    React.useEffect(() => {
        setTahun(Tahun().map(t => ({ value: t, label: t })));
    }, []);

    React.useEffect(() => {
        const notification = () => {
            btsMessage.loading && btsMessage.btsMessage && swal(`${btsMessage.btsMessage.message}`, {
                buttons: true
            }).then((value) => {
                if (value) {
                    history.goBack();
                }
            });
        }

        return notification();
    }, [btsMessage, history, id, nama]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            user && user.user && user.user.map(u => setUserData(u));
            user && user.error === "Unauthenticated." && history.push('/');
            provinsi && provinsi.provinsi && setProv(provinsi.provinsi.map(p => ({ value: p.id, label: p.nama_provinsi })));
            btsDetail && btsDetail.btsDetail && setValueEdit(btsDetail.btsDetail);
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [btsDetail, history, provinsi, user]);

    React.useEffect(() => {
        getUser();
        getProvinsi();
        getbtsDetail(id)
    }, [getProvinsi, getUser, getbtsDetail, id]);

    const updateSubmitBts = (data) => {
        const input = {
            data_umum_id: valueEdit.data_umum_id,
            company_name: nama,
            tahun: inputTahun === "" ? valueEdit.tahun : inputTahun,
            jumlah: data.jumlah,
            provinsi: valueProvinsi === "" ? valueEdit.provinsi_id : valueProvinsi
        }
        console.log(input);
        updateBts(id, input)
    }
    return loading ? <Spinner /> : (
        <>
            <Sidebar menu="DATA PENYELENGGARAAN" />
            <div className="main-panel">
                <Navbar user={userData} />
                <div className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header card-header-icon" data-background-color="blue">
                                        <Styles.ButtonModal
                                            type="button"
                                            onClick={() => history.goBack()}>
                                            <i className="fa fa-chevron-circle-left fa-2x" aria-hidden="true"></i>
                                        </Styles.ButtonModal>
                                    </div>
                                    <h4 className="card-title">Edit Data BTS 2G {nama}</h4>
                                    <div className="card-content">
                                        {
                                            valueEdit.length = 0 ? <div className="d-flex align-content-center"><Loader type="Bars" color="#00BFFF" height={100} width={100} /></div> :
                                                <form className="form"
                                                    onSubmit={handleSubmit(updateSubmitBts)}
                                                >
                                                    <Styles.InputForm>
                                                        <Controller
                                                            control={control}
                                                            name="tahun"
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    options={tahun}
                                                                    placeholder="Tahun.."
                                                                    defaultValue={tahun.filter(t => t.value === valueEdit.tahun)}
                                                                    onChange={(e) => setInputTahun(e.value)}
                                                                />
                                                            )}
                                                        />
                                                        {btsMessage && btsMessage.error && <span className="text-danger">{btsMessage.error.tahun}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <Controller
                                                            control={control}
                                                            name="provinsi"
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    options={prov}
                                                                    placeholder="Provinsi.."
                                                                    defaultValue={prov.filter(t => t.value === valueEdit.provinsi_id)}
                                                                    onChange={(e) => setValueProvinsi(e.value)}
                                                                />
                                                            )}
                                                        />
                                                        {btsMessage && btsMessage.error && <span className="text-danger">{btsMessage.error.provinsi}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <input
                                                            placeholder="Jumlah..."
                                                            type="text"
                                                            className="form-control"
                                                            {...register("jumlah")}
                                                            defaultValue={valueEdit.jumlah}
                                                        />
                                                        {btsMessage && btsMessage.error && <span className="text-danger">{btsMessage.error.jumlah}</span>}
                                                    </Styles.InputForm>
                                                    <Button color="primary" variant="contained" type="submit">Save changes</Button>
                                                </form>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default EditBts

const Tahun = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= 1945; i--) {
        tahun.push(i);
    }
    return tahun;
}