import {
    JARTAPSLI_REQ,
    JARTAPSLI_SUCCESS,
    JARTAPSLI_FAIL,
    JARTAPSLI_MESSAGE_REQ,
    JARTAPSLI_MESSAGE_SUCCESS,
    JARTAPSLI_MESSAGE_FAIL,
    JARTAPSLI_DETAIL_REQ,
    JARTAPSLI_DETAIL_SUCCESS,
    JARTAPSLI_DETAIL_FAIL,
} from './jartapsliTypes';

const jartapsliMessageState = {
    loading: false,
    jartapsliMessage: '',
    error: ''
}

const jartapsliState = {
    loading: false,
    jartapsli: [],
    error: ''
}

const jartapsliDetailState = {
    loading: false,
    jartapsliDetail: null,
    error: ''
}

export const jartapsliDetailReducer = (state = jartapsliDetailState, action) => {
    switch (action.type) {
        case JARTAPSLI_DETAIL_REQ:
            return {
                ...state,
                loading: false
            }
        case JARTAPSLI_DETAIL_SUCCESS:
            return {
                loading: true,
                jartapsliDetail: action.payload,
                error: ''
            }
        case JARTAPSLI_DETAIL_FAIL:
            return {
                loading: true,
                jartapsliDetail: null,
                error: action.payload
            }
        default:
            return state
    }
}

export const jartapsliMessageReducer = (state = jartapsliMessageState, action) => {
    switch (action.type) {
        case JARTAPSLI_MESSAGE_REQ:
            return {
                ...state,
                loading: false,
            }
        case JARTAPSLI_MESSAGE_SUCCESS:
            return {
                loading: true,
                jartapsliMessage: action.payload,
                error: ''
            }
        case JARTAPSLI_MESSAGE_FAIL:
            return {
                loading: true,
                jartapsliMessage: "",
                error: action.payload
            }
        default:
            return state
    }
}

const jartapsliReducer = (state = jartapsliState, action) => {
    switch (action.type) {
        case JARTAPSLI_REQ:
            return {
                ...state,
                loading: false,
            }
        case JARTAPSLI_SUCCESS:
            return {
                loading: true,
                jartapsli: action.payload,
                error: ''
            }
        case JARTAPSLI_FAIL:
            return {
                loading: true,
                jartapsli: null,
                error: action.payload
            }
        default:
            return state
    }
}

export default jartapsliReducer