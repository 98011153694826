import ProfilUmumDetail from './ProfilUmumDetail';
import { connect } from 'react-redux';
import { getProfilUmumDetail } from '../../redux';

const mapStateToProps = state => {
    return {
        dataUmumDetail: state.profilUmumDetail,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDataUmumDetail: (id) => dispatch(getProfilUmumDetail(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilUmumDetail);