export const BTS_REQ = 'BTS_REQ';
export const BTS_SUCCESS = 'BTS_SUCCESS';
export const BTS_FAIL = 'BTS_FAIL';
export const BTS_CRUD_REQ = 'BTS_CRUD_REQ';
export const BTS_CRUD_SUCCESS = 'BTS_CRUD_SUCCESS';
export const BTS_CRUD_FAIL = 'BTS_CRUD_FAIL';
export const BTS_DETAIL_REQ = 'BTS_DETAIL_REQ';
export const BTS_DETAIL_SUCCESS = 'BTS_DETAIL_SUCCESS';
export const BTS_DETAIL_FAIL = 'BTS_DETAIL_FAIL';
export const BTS_MESSAGE_REQ = 'BTS_MESSAGE_REQ';
export const BTS_MESSAGE_SUCCESS = 'BTS_MESSAGE_SUCCESS';
export const BTS_MESSAGE_FAIL = 'BTS_MESSAGE_FAIL';