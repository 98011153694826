import React from 'react';
import Loader from 'react-loader-spinner';

const Spinner = () => {
    return (
        <div className="d-flex justify-content-center align-items-center text-center h-100">
            <div style={{ marginTop: '200px' }}>
                <Loader type="Rings" color="#00BFFF" height={100} width={100} />
            </div>
        </div>
    )
}

export default Spinner
