import {
    JARTAPLOKPS_REQ,
    JARTAPLOKPS_SUCCESS,
    JARTAPLOKPS_FAIL,
    JARTAPLOKPS_MESSAGE_REQ,
    JARTAPLOKPS_MESSAGE_SUCCESS,
    JARTAPLOKPS_MESSAGE_FAIL,
    JARTAPLOKPS_DETAIL_REQ,
    JARTAPLOKPS_DETAIL_SUCCESS,
    JARTAPLOKPS_DETAIL_FAIL,

    JARTAPLOKPS_JARINGAN_REQ,
    JARTAPLOKPS_JARINGAN_SUCCESS,
    JARTAPLOKPS_JARINGAN_FAIL,
    JARTAPLOKPS_TEKNOLOGI_REQ,
    JARTAPLOKPS_TEKNOLOGI_SUCCESS,
    JARTAPLOKPS_TEKNOLOGI_FAIL,
    JARTAPLOKPS_TEKNOLOGI_ITEM_REQ,
    JARTAPLOKPS_TEKNOLOGI_ITEM_SUCCESS,
    JARTAPLOKPS_TEKNOLOGI_ITEM_FAIL,
    // JARTAPLOKPS_JARINGAN_DETAIL_REQ,
    // JARTAPLOKPS_JARINGAN_DETAIL_SUCCESS,
    // JARTAPLOKPS_JARINGAN_DETAIL_FAIL,
    // JARTAPLOKPS_JARINGAN_MESSAGE_REQ,
    // JARTAPLOKPS_JARINGAN_MESSAGE_SUCCESS,
    // JARTAPLOKPS_JARINGAN_MESSAGE_FAIL
} from './jartaplokpsTypes';

const teknologiItemJartaplokpsState = {
    loading: false,
    data: [],
    error: ''
}

export const teknologiItemJartaplokpsReducer = (state = teknologiItemJartaplokpsState, action) => {
    switch (action.type) {
        case JARTAPLOKPS_TEKNOLOGI_ITEM_REQ:
            return {
                ...state,
                loading: false,
            }
        case JARTAPLOKPS_TEKNOLOGI_ITEM_SUCCESS:
            return {
                loading: true,
                data: action.payload,
                error: ''
            }
        case JARTAPLOKPS_TEKNOLOGI_ITEM_FAIL:
            return {
                loading: true,
                data: [],
                error: action.payload
            }
        default:
            return state
    }
}

const jartaplokpsTeknologiState = {
    loading: false,
    data: [],
    error: ''
}

export const jartaplokpsTeknologiReducer = (state = jartaplokpsTeknologiState, action) => {
    switch (action.type) {
        case JARTAPLOKPS_TEKNOLOGI_REQ:
            return {
                ...state,
                loading: false,
            }
        case JARTAPLOKPS_TEKNOLOGI_SUCCESS:
            return {
                loading: true,
                data: action.payload,
                error: ''
            }
        case JARTAPLOKPS_TEKNOLOGI_FAIL:
            return {
                loading: true,
                data: [],
                error: action.payload
            }
        default:
            return state
    }
}



const jartaplokpsJaringanState = {
    loading: false,
    jartaplokpsJaringan: [],
    error: ''
}

export const jartaplokpsJaringanReducer = (state = jartaplokpsJaringanState, action) => {
    switch (action.type) {
        case JARTAPLOKPS_JARINGAN_REQ:
            return {
                ...state,
                loading: false,
            }
        case JARTAPLOKPS_JARINGAN_SUCCESS:
            return {
                loading: true,
                jartaplokpsJaringan: action.payload,
                error: ''
            }
        case JARTAPLOKPS_JARINGAN_FAIL:
            return {
                loading: true,
                jartaplokpsJaringan: [],
                error: action.payload
            }
        default:
            return state
    }
}


const jartaplokpsState = {
    loading: false,
    jartaplokps: [],
    error: ''
}

const jartaplokpsReducer = (state = jartaplokpsState, action) => {
    switch (action.type) {
        case JARTAPLOKPS_REQ:
            return {
                ...state,
                loading: false,
            }
        case JARTAPLOKPS_SUCCESS:
            return {
                loading: true,
                jartaplokps: action.payload,
                error: ''
            }
        case JARTAPLOKPS_FAIL:
            return {
                loading: true,
                jartaplokps: null,
                error: action.payload
            }
        default:
            return state
    }
}

const jartaplokpsDetailState = {
    loading: false,
    jartaplokpsDetail: null,
    error: ''
}

const jartaplokpsMessageState = {
    loading: false,
    jartaplokpsMessage: '',
    error: ''
}

export const jartaplokpsDetailReducer = (state = jartaplokpsDetailState, action) => {
    switch (action.type) {
        case JARTAPLOKPS_DETAIL_REQ:
            return {
                ...state,
                loading: false
            }
        case JARTAPLOKPS_DETAIL_SUCCESS:
            return {
                loading: true,
                jartaplokpsDetail: action.payload,
                error: ''
            }
        case JARTAPLOKPS_DETAIL_FAIL:
            return {
                loading: true,
                jartaplokpsDetail: null,
                error: action.payload
            }
        default:
            return state
    }
}

export const jartaplokpsMessageReducer = (state = jartaplokpsMessageState, action) => {
    switch (action.type) {
        case JARTAPLOKPS_MESSAGE_REQ:
            return {
                ...state,
                loading: false,
            }
        case JARTAPLOKPS_MESSAGE_SUCCESS:
            return {
                loading: true,
                jartaplokpsMessage: action.payload,
                error: ''
            }
        case JARTAPLOKPS_MESSAGE_FAIL:
            return {
                loading: true,
                jartaplokpsMessage: "",
                error: action.payload
            }
        default:
            return state
    }
}

export default jartaplokpsReducer