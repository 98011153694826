import SectionProfil from './SectionProfil';
import { connect } from 'react-redux';
import { getUser, updateUser } from '../../redux';

const mapStateToProps = state => {
    return {
        user: state.user,
        userMessage: state.userMessage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUser: () => dispatch(getUser()),
        updateUser: (data) => dispatch(updateUser(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionProfil)