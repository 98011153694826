import {
    AUTH_REQ,
    AUTH_SUCCESS,
    AUTH_FAIL,
    FORGOT_PASS_REQ,
    FORGOT_PASS_SUCCESS,
    FORGOT_PASS_FAIL,
    RESET_PASS_REQ,
    RESET_PASS_SUCCESS,
    RESET_PASS_FAIL,
} from './authTypes'

import api from '../services';
import { config } from '../config';

export const resetPassReq = () => {
    return { type: RESET_PASS_REQ }
}

export const resetPassSuccess = (data) => {
    return { type: RESET_PASS_SUCCESS, payload: data }
}

export const resetPassFail = (err) => {
    return { type: RESET_PASS_FAIL, payload: err }
}

export const forgotPassReq = () => {
    return { type: FORGOT_PASS_REQ }
}

export const forgotPassSuccess = (data) => {
    return { type: FORGOT_PASS_SUCCESS, payload: data }
}

export const forgotPassFail = (err) => {
    return { type: FORGOT_PASS_FAIL, payload: err }
}

export const authReq = () => {
    return { type: AUTH_REQ }
}

export const authSuccess = (data) => {
    return { type: AUTH_SUCCESS, payload: data }
}

export const authFail = (err) => {
    return { type: AUTH_FAIL, payload: err }
}

export const resetPassword = (data) => {
    return async (dispatch) => {
        dispatch(resetPassReq);
        await api.post('reset-password', data, config)
            .then(res => {
                dispatch(resetPassSuccess(res.data));
                localStorage.removeItem('email-reset-validation');
            })
            .catch(err => dispatch(resetPassFail(err.response.data.errors)))
    }
}

export const forgotPassword = (email) => {
    return async (dispatch) => {
        dispatch(forgotPassReq);
        await api.post('/forgot-password', email, config)
            .then(res => {
                dispatch(forgotPassSuccess(res.data));
            })
            .catch(err => {
                dispatch(forgotPassFail(err.response.data.errors));
            })
    }
}

export const logout = () => {
    return async (dispatch) => {
        dispatch(authReq);
        await api.post('/logout', null, config)
            .then(res => {
                dispatch(authSuccess(res.data));
                localStorage.removeItem('token');
                window.location.href = "/";
            })
            .catch(err => {
                dispatch(authFail(err.response.data));
            })
    }
}