import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Dashboard from './Dashboard';
import Izin from './Izin';
import Login from './Login';
import Users from './Users';
import Jabersel from './Jabersel';
import Jabertrunk from './Jabertrunk';
import Jartaplok from './Jartaplok';
import JartaplokPs from './JartaplokPs';
import JartaplokSli from './JartaplokSli';
import JartaplokSljj from './JartaplokSljj';
import DataPenyelenggaraan from './DataPenyelengggaraan';
import Profil from './Profil';
import AddNew from './AddNew';
import DataUmum from './DataUmum';
import EditDataPenyelenggaraan from './EditDetailPenyelenggara';
import EditJabersel from './EditJabersel';
import ResetPassword from './ResetPassword';
import Jartaptup from './Jartaptup';
import { Images } from '../components';
import EditKeuangan from './EditKeuangan';
import Teknologi from './Teknologi';
import EditTeknologi from './EditTeknologi';
import Report from './Report';
import EditProfilUmum from './EditProfilUmum';
import EditBts from './EditBts';
import EditNodeb3g from './EditNodeb3g';
import EditENodeb4g from './EditENodeb4g';
import Test from './Test';

const Routes = () => {
    return (
        <Switch>
            <Route exact path='/' component={Login} />
            <Route path='/test' component={Test} />
            <Route path='/reset-password/token=:token' component={ResetPassword} />
            <Route path='/dashboard' component={Dashboard} />
            <Route path='/izin' component={Izin} />
            <Route path='/penyelenggara' component={DataUmum} />
            <Route path='/edit-penyelenggara/:id/:nama' component={EditProfilUmum} />
            <Route path='/penyelenggara-detail/:id/:nama' component={DataPenyelenggaraan} />
            <Route path='/penyelenggara-detail-edit/:id/:nama/edit/:dataId/:menu' component={EditDataPenyelenggaraan} />
            <Route path='/penyelenggara-detail-pelanggan-kapasitas-edit/:id/:nama/edit/:dataId/:menu' component={EditJabersel} />
            <Route path='/penyelenggara-detail-keuangan/:id/:nama/edit/:dataId/:menu' component={EditKeuangan} />
            <Route path='/teknologi-detail-edit/:id/:nama' component={EditTeknologi} />
            <Route path='/users' component={Users} />
            <Route path='/jabersel' component={Jabersel} />
            <Route path='/jabertrunk' component={Jabertrunk} />
            <Route path='/jartaplok' component={Jartaplok} />
            <Route path='/jartaplok-ps' component={JartaplokPs} />
            <Route path='/jartap-sli' component={JartaplokSli} />
            <Route path='/jartap-sljj' component={JartaplokSljj} />
            <Route path='/jartaptup' component={Jartaptup} />
            <Route path='/report' component={Report} />
            <Route path='/edit-bts/:id/:nama' component={EditBts} />
            <Route path='/edit-node-b-3-g/:id/:nama' component={EditNodeb3g} />
            <Route path='/edit-enode-b-4-g/:id/:nama' component={EditENodeb4g} />
            <Route path='/Profil' component={Profil} />
            <Route path='/add-new' component={AddNew} />
            <Route path='/teknologi' component={Teknologi} />
            <Route
                render={function () {
                    return <div style={{ flex: 1, margin: 'auto' }}>
                        <div style={{ textAlign: 'center' }}>
                            <h3>This page could not be found 404 ~</h3>
                            <img src={Images.notfound} alt="notfound" style={{ width: 500, height: 'auto', }} />
                        </div>
                    </div >;
                }}
            />
        </Switch>
    );
}

export default Routes