import {
    JABERSEL_PENYELENGGARA_REQ,
    JABERSEL_PENYELENGGARA_SUCCESS,
    JABERSEL_PENYELENGGARA_FAIL,
    JABERSEL_PENYELENGGARA_MESSAGE_REQ,
    JABERSEL_PENYELENGGARA_MESSAGE_SUCCESS,
    JABERSEL_PENYELENGGARA_MESSAGE_FAIL,
    JABERSEL_PENYELENGGARA_DETAIL_REQ,
    JABERSEL_PENYELENGGARA_DETAIL_SUCCESS,
    JABERSEL_PENYELENGGARA_DETAIL_FAIL,
} from './jaberselPenyelenggaraTypes';

import api from '../services';
import { config } from '../config';

export const jaberselPenyelenggaraDetailReq = () => {
    return { type: JABERSEL_PENYELENGGARA_DETAIL_REQ }
}

export const jaberselPenyelenggaraDetailSuccess = (data) => {
    return { type: JABERSEL_PENYELENGGARA_DETAIL_SUCCESS, payload: data }
}

export const jaberselPenyelenggaraDetailFail = (err) => {
    return { type: JABERSEL_PENYELENGGARA_DETAIL_FAIL, payload: err }
}

export const jaberselPenyelenggaraMessageReq = () => {
    return { type: JABERSEL_PENYELENGGARA_MESSAGE_REQ }
}

export const jaberselPenyelenggaraMessageSuccess = (data) => {
    return { type: JABERSEL_PENYELENGGARA_MESSAGE_SUCCESS, payload: data }
}

export const jaberselPenyelenggaraMessageFail = (err) => {
    return { type: JABERSEL_PENYELENGGARA_MESSAGE_FAIL, payload: err }
}


export const jaberselPenyelenggaraReq = () => {
    return { type: JABERSEL_PENYELENGGARA_REQ }
}

export const jaberselPenyelenggaraSuccess = (data) => {
    return { type: JABERSEL_PENYELENGGARA_SUCCESS, payload: data }
}

export const jaberselPenyelenggaraFail = (err) => {
    return { type: JABERSEL_PENYELENGGARA_FAIL, payload: err }
}

export const uploadJaberselPenyelenggara = (data) => {
    return async (dispatch) => {
        dispatch(jaberselPenyelenggaraMessageReq);
        await api.post(`/jabersel-penyelenggara-create-import`, data, config)
            .then(res => dispatch(jaberselPenyelenggaraMessageSuccess(res.data)))
            .catch(err => dispatch(jaberselPenyelenggaraMessageFail(err.response.data)))
    }
}

export const getAllJaberselPenyelenggara = () => {
    return async (dispatch) => {
        dispatch(jaberselPenyelenggaraReq);
        await api.get('/jabersel-penyelenggara', config)
            .then(res => dispatch(jaberselPenyelenggaraSuccess(res.data.data)))
            .catch(err => dispatch(jaberselPenyelenggaraFail(err.response.data)))
    }
}

export const updateJaberselPenyelenggara = (id, data) => {
    return async (dispatch) => {
        dispatch(jaberselPenyelenggaraMessageReq);
        await api.put(`/jabersel-penyelenggara-update/${id}`, data, config)
            .then(res => {
                dispatch(jaberselPenyelenggaraMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jaberselPenyelenggaraMessageFail(err.response.data));
            })
    }
}

export const deleteJaberselPenyelenggara = (id) => {
    return async (dispatch) => {
        dispatch(jaberselPenyelenggaraMessageReq);
        await api.delete(`/jabersel-penyelenggara-delete/${id}`, config)
            .then(res => {
                dispatch(jaberselPenyelenggaraMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jaberselPenyelenggaraMessageFail(err.response.data));
            })
    }
}

export const addJaberselPenyelenggara = (data) => {
    return async (dispatch) => {
        dispatch(jaberselPenyelenggaraMessageReq);
        await api.post('/jabersel-penyelenggara-create', data, config)
            .then(res => {
                dispatch(jaberselPenyelenggaraMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jaberselPenyelenggaraMessageFail(err.response.data));
            })
    }
}

export const getJaberselPenyelenggaraByCompany = (id) => {
    return async (dispatch) => {
        dispatch(jaberselPenyelenggaraReq);
        await api.get(`/jabersel-penyelenggara-company/${id}`, config)
            .then(res => {
                dispatch(jaberselPenyelenggaraSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jaberselPenyelenggaraFail(err.response.data));
            })
    }
}

export const getJaberselPenyelelenggaraDetailById = (id) => {
    return async (dispatch) => {
        dispatch(jaberselPenyelenggaraDetailReq);
        await api.get(`/jabersel-penyelenggara/${id}`, config)
            .then(res => {
                dispatch(jaberselPenyelenggaraDetailSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jaberselPenyelenggaraDetailSuccess(err.response));
            })
    }
}



