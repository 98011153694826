import Jabertrunk from './Jabertrunk';
import { connect } from 'react-redux';
import { addjabertrunk, deleteJabertrunk, getJabertrunkByCompany, uploladJabertrunk } from '../../redux';

const mapStateToProps = state => {
    return {
        jabertrunk: state.jabertrunk,
        jabertrunkMessage: state.jabertrunkMessage,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        uploladJabertrunk: (data) => dispatch(uploladJabertrunk(data)),
        getJabertrunkByCompany: (id) => dispatch(getJabertrunkByCompany(id)),
        addJabertrunk: (data) => dispatch(addjabertrunk(data)),
        deleteJabertrunk: (data) => dispatch(deleteJabertrunk(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Jabertrunk);