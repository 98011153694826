import JartaplokPs from "./JartaplokPs"
import { connect } from 'react-redux';
import { getAllJartaplokps, getUser } from "../../redux";

const mapStateToProps = state => {
    return {
        jartaplokps: state.jartaplokps,
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUser: () => dispatch(getUser()),
        getAllJartaplokps: (data) => dispatch(getAllJartaplokps(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JartaplokPs);
