export const PROFIL_UMUM_REQ = 'PROFIL_UMUM_REQ';
export const PROFIL_UMUM_SUCCESS = 'PROFIL_UMUM_SUCCESS';
export const PROFIL_UMUM_FAIL = 'PROFIL_UMUM_FAIL';
export const PROFIL_UMUM_MESSAGE_REQ = 'PROFIL_UMUM_MESSAGE_REQ';
export const PROFIL_UMUM_MESSAGE_SUCCESS = 'PROFIL_UMUM_MESSAGE_SUCCESS';
export const PROFIL_UMUM_MESSAGE_FAIL = 'PROFIL_UMUM_MESSAGE_FAIL';
export const PROFIL_UMUM_DETAIL_REQ = 'PROFIL_UMUM_DETAIL_REQ';
export const PROFIL_UMUM_DETAIL_SUCCESS = 'PROFIL_UMUM_DETAIL_SUCCESS';
export const PROFIL_UMUM_DETAIL_FAIL = 'PROFIL_UMUM_DETAIL_FAIL';
export const PROFIL_UMUM_UPLOAD_REQ = 'PROFIL_UMUM_UPLOAD_REQ';
export const PROFIL_UMUM_UPLOAD_SUCCESS = 'PROFIL_UMUM_UPLOAD_SUCCESS';
export const PROFIL_UMUM_UPLOAD_FAIL = 'PROFIL_UMUM_UPLOAD_FAIL';