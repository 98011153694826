import {
    SAHAM_REQ,
    SAHAM_SUCCESS,
    SAHAM_FAIL,
    SAHAM_DETAIL_REQ,
    SAHAM_DETAIL_SUCCESS,
    SAHAM_DETAIL_FAIL,
    SAHAM_MESSAGE_REQ,
    SAHAM_MESSAGE_SUCCESS,
    SAHAM_MESSAGE_FAIL,
} from './sahamTypes';

const sahamMessageState = {
    loading: false,
    sahamMessage: '',
    error: ''
}

const sahamDetailState = {
    loading: false,
    sahamDetail: [],
    error: ''
}

const sahamState = {
    loading: false,
    saham: null,
    error: ''
}

export const sahamDetailReducer = (state = sahamDetailState, action) => {
    switch (action.type) {
        case SAHAM_DETAIL_REQ:
            return {
                ...state,
                loading: true,
            }
        case SAHAM_DETAIL_SUCCESS:
            return {
                loading: true,
                sahamDetail: action.payload,
                error: ''
            }
        case SAHAM_DETAIL_FAIL:
            return {
                loading: true,
                sahamDetail: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const sahamMessageReducer = (state = sahamMessageState, action) => {
    switch (action.type) {
        case SAHAM_MESSAGE_REQ:
            return {
                ...state,
                loading: true,
                sahamMessage: '',
                error: ''
            }
        case SAHAM_MESSAGE_SUCCESS:
            return {
                loading: true,
                sahamMessage: action.payload,
                error: ''
            }
        case SAHAM_MESSAGE_FAIL:
            return {
                loading: false,
                sahamMessage: '',
                error: action.payload
            }
        default:
            return state
    }
}

const sahamReducer = (state = sahamState, action) => {
    switch (action.type) {
        case SAHAM_REQ:
            return {
                ...state,
                loading: true,
                saham: null,
                error: ''
            }
        case SAHAM_SUCCESS:
            return {
                loading: false,
                saham: action.payload,
                error: ''
            }
        case SAHAM_FAIL:
            return {
                loading: false,
                saham: null,
                error: action.payload
            }
        default:
            return state
    }
}

export default sahamReducer