import React from 'react';
import { Modal, ListGroup, TabContent } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import { useHistory, useParams, Link } from 'react-router-dom';
import swal from '@sweetalert/with-react';
import DataTable from 'react-data-table-component';
import ReactModal from 'react-modal';
import { useForm, Controller } from 'react-hook-form';
import Select from "react-select";

import Button from '@mui/material/Button';

import { Spinner, FilterComponent, ActionButton, Styles } from '../../components';
import {
    Jartapsli,
    Jartapsljj,
    Jartaplok,
    Jartaplokps,
    Jabertrunk,
    JaberselPenyelenggara,
    Jartaptup,
} from '..'

const PerizinanDetail = ({
    getIzinDetail,
    izinDetail,
    izinMessage,
    addIzin,
    role,
    deleteIzinDetail,
    uploadIzin,
    //     subtabIzin,
}) => {
    const { id, nama } = useParams();
    const history = useHistory();
    const { register, handleSubmit, control, reset, formState } = useForm();

    const [rows, setRows] = React.useState([]);
    const [subTab, setSubTab] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [filterText, setFilterText] = React.useState('');
    const [valuePermitName, setValuePermitName] = React.useState('')
    const [valuePermitType, setValuePermiType] = React.useState('')
    const permitName = [
        { value: 'JABERSEL', label: 'JABERSEL' },
        { value: 'JABERTRUNK', label: 'JABERTRUNK' },
        { value: 'JARTAPLOK', label: 'JARTAPLOK' },
        { value: 'JARTAPLOK PS', label: 'JARTAPLOK PS' },
        { value: 'JARTAPLOK SLI', label: 'JARTAPLOK SLI' },
        { value: 'JARTAPLOK SLJJ', label: 'JARTAPLOK SLJJ' },
        { value: 'JARTAPLOK SLJJ', label: 'JARTAPLOK SLJJ' },
        { value: 'JARTAPTUP', label: 'JARTAPTUP' },
    ];
    const permitType = [
        { value: 'Penyelenggaraan', label: 'Penyelenggaraan' },
        { value: 'Perubahan', label: 'Perubahan' }
    ];
    const [tahun, setTahun] = React.useState([]);
    const [inputTahun, setInputTahun] = React.useState('');
    const [showModalAddIzin, setShowModalAddIzin] = React.useState(false);
    const [fileUpload, setFileUpload] = React.useState(null);
    React.useEffect(() => {
        setTahun(Tahun().map(t => ({ value: t, label: t })));
    }, []);

    React.useEffect(() => {
        izinMessage && izinMessage.izinMessage && swal(`${izinMessage.izinMessage.message}`, {
            buttons: ["cancel", true]
        }).then((value) => {
            if (value) {
                getIzinDetail(id);
            }
        });
    }, [getIzinDetail, id, izinMessage]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            function onlyUnique(value, index, self) {
                return self.indexOf(value) === index;
            }
            izinDetail && izinDetail.izinDetail && setSubTab(izinDetail.izinDetail.map(u => u.permit_name).filter(onlyUnique));
            izinDetail && izinDetail.izinDetail &&
                setRows(izinDetail.izinDetail.map((izin, index) => ({
                    number: index + 1,
                    permit: izin.permit,
                    permit_name: izin.permit_name,
                    permit_number: izin.permit_number,
                    permit_date: izin.permit_date,
                    action: <>
                        <ActionButton
                            title="fa fa-pencil"
                            css="btn-warning"
                            fun={() => window.location.href = `/penyelenggara-detail-edit/${id}/${nama}/edit/${izin.id}/izin`} />
                        &nbsp;

                        <ActionButton title="fa fa-trash" css="btn-danger" fun={
                            () => swal(`Apakah Yakin Data ${izin.company_name} - ${izin.permit_name} ini akan dihapus?`, {
                                buttons: ["Batal", true],
                            }).then((value) => {
                                if (value) {
                                    deleteIzinDetail(izin.id);
                                }
                            })
                        } />
                    </>
                })));
            setLoading(false);
        }, 3000);
        return () => clearTimeout(timeout);
    }, [izinDetail, deleteIzinDetail, history, id, nama]);

    React.useEffect(() => {
        if (formState.isSubmitSuccessful) {
            reset(setFileUpload(null));
        }
    }, [formState, reset]);

    React.useEffect(() => {
        getIzinDetail(id);
    }, [getIzinDetail, id]);
    const customStylesModal = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: '0',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '700px',
            height: '90%'
        },
    };

    const setUpModalAddIzin = () => setShowModalAddIzin(!showModalAddIzin);

    const filteredItems = rows.filter(
        item => (
            (item.company_name && item.company_name.toLowerCase().match(filterText.toLowerCase())) ||
            (item.permit && item.permit.toLowerCase().match(filterText.toLowerCase())) ||
            (item.permit_name && item.permit_name.toLowerCase().match(filterText.toLowerCase())) ||
            (item.permit_date && item.permit_date.toLowerCase().match(filterText.toLowerCase())) ||
            (item.signer && item.signer.toLowerCase().match(filterText.toLowerCase()))
        )
    );
    const subHeaderComponentMemo = React.useMemo(() => {
        return <FilterComponent onFilter={e => setFilterText(e.target.value)} filterText={filterText} placeholder="Cari.." />
    }, [filterText]);
    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none'
            },
        },
    };
    const columns = [
        {
            name: 'No',
            selector: row => row.number,
            maxWidth: '10px',
        },
        {
            name: 'Izin',
            selector: row => row.permit,
            minWidth: '140px',
        },
        {
            name: 'Nama Izin',
            selector: 'permit_name',
            minWidth: '150px',
        },
        {
            name: 'Nomor',
            selector: 'permit_number',
            minWidth: '320px',
        },
        {
            name: 'Tanggal',
            selector: 'permit_date',
        },
        {
            name: 'Action',
            selector: 'action',
            minWidth: '140px',
        }
    ];
    const columnsGuest = [
        {
            name: 'No',
            selector: row => row.number,
            maxWidth: '10px',
        },
        {
            name: 'Izin',
            selector: row => row.permit,
            minWidth: '140px',
        },
        {
            name: 'Nama Izin',
            selector: 'permit_name',
            minWidth: '120px',
        },
        {
            name: 'Nomor',
            selector: 'permit_number',
            minWidth: '320px',
        },
        {
            name: 'Tanggal',
            selector: 'permit_date',
        }
    ];

    const tambahIzin = async (data) => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            tahun: inputTahun,
            permit: valuePermitType,
            permit_number: data.permit_number,
            permit_name: valuePermitName,
            permit_date: data.permit_date,
            regarding: data.regarding,
            signer: data.signer,
        }
        addIzin(input);
    }

    const onUpload = (e) => setFileUpload(e.target.files[0]);

    const submitUploadiIzn = (data) => {
        const formData = new FormData();
        formData.append("nama_file", fileUpload);
        uploadIzin(formData)
    };

    return loading ? <Spinner /> : izinDetail.error === "Unauthenticated." ? (
        window.location.href = "/") : (
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title text-bold" style={{ marginBottom: 50, fontWeight: 'bold' }}>IZIN {nama}</h4>
                        <ListGroup as="ul" className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <ListGroup as="li" className="nav-item active">
                                <a className="nav-link" id="pills-list-izin-tab" data-toggle="pill" href="#pills-list-izin" role="tab" aria-controls="pills-list-izin" aria-selected="false">LIST IZIN</a>
                            </ListGroup>
                            {
                                subTab.length > 0 ? subTab.map((sub, index) => (
                                    <ListGroup as="li" className="nav-item" key={index}>
                                        <a className="nav-link" id={`pills-${sub.toLowerCase().replace(' ', '-')}-tab`} data-toggle="pill" href={`#pills-${sub.toLowerCase().replace(' ', '-')}`} role="tab" aria-controls={`pills-${sub.toLowerCase()}`} aria-selected="true">{sub}</a>
                                    </ListGroup>
                                )) : <p className="text-center">Belum ada izin yang ditambahkan</p>
                            }
                        </ListGroup>
                        <TabContent>
                            <div className="tab-pane active" id="pills-list-izin" role="tabpanel" aria-labelledby="list-izin">
                                {
                                    role.role_id !== 3 &&
                                    <div className="header">
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', alignContent: 'flex-end', justifyContent: 'flex-end' }}>
                                            <Button
                                                id="basic-button"
                                                aria-controls="basic-menu"
                                                className="btn btn-sm"
                                                size="large"
                                                aria-haspopup="true"
                                                style={{ backgroundColor: 'rgba(0, 50, 122, 1)', color: '#fff', marginRight: '10px' }}
                                                onClick={setUpModalAddIzin}
                                            >
                                                <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;Tambah
                                            </Button>
                                        </div>
                                    </div>
                                }
                                <div className="card-content">
                                    <div className="material-datatables">
                                        <DataTable
                                            columns={role.role_id !== 3 ? columns : columnsGuest}
                                            data={filteredItems}
                                            progressPending={loading}
                                            progressComponent={<Loader type="Watch" color="#00BFFF" height={100}
                                                width={100} />}
                                            striped={true}
                                            highlightOnHover={true}
                                            responsive={true}
                                            pagination
                                            subHeader
                                            customStyles={customStyles}
                                            subHeaderComponent={subHeaderComponentMemo} />
                                    </div>
                                </div>
                            </div>
                            {
                                loading ? <Loader type="Watch" color="#00BFFF" height={50}
                                    width={50} /> : subTab.length > 0 ? subTab.map((sub, index) => (
                                        <div className="tab-pane" id={`pills-${sub.toLowerCase().replace(' ', '-')}`} role="tabpanel" aria-labelledby={`pills-${sub.toLowerCase().replace(' ', '-')}-tab`} key={index}>
                                            {
                                                sub === "JABERSEL" &&
                                                <JaberselPenyelenggara role={role} />
                                            }
                                            {
                                                sub === "JARTAP SLI" && <Jartapsli role={role} />
                                            }
                                            {
                                                sub === "JARTAP SLJJ" && <Jartapsljj role={role} />
                                            }
                                            {
                                                sub === "JARTAPLOK" && <Jartaplok role={role} />
                                            }
                                            {
                                                sub === "JARTAPTUP" && <Jartaptup role={role} />
                                            }
                                            {
                                                sub === "JARTAPLOK PS" && <Jartaplokps role={role} />
                                            }
                                            {
                                                sub === "JABERTRUNK" &&
                                                <Jabertrunk role={role} />
                                            }
                                        </div>
                                    )) : <div className="text-center"><Loader type="Watch" color="#00BFFF" height={50}
                                        width={50} /><p>Belum ada Izin yang ditambahkan</p></div>
                            }
                        </TabContent>
                    </div>
                </div>
                <ReactModal
                    ariaHideApp={false}
                    preventScroll={true}
                    scrollable={true}
                    isOpen={showModalAddIzin}
                    onHide={setUpModalAddIzin}
                    style={customStylesModal}
                >
                    <div style={{ float: 'right' }}>
                        <Button id="basic-button"
                            aria-controls="basic-menu"
                            // variant="outlined"
                            // className="btn"
                            color="error"
                            onClick={setUpModalAddIzin}
                            size="small">
                            <span className="material-icons">
                                close
                            </span>
                        </Button>
                    </div>
                    <Modal.Title style={{ fontWeight: 'bold' }}>Tambah Data Izin Baru</Modal.Title>
                    <hr />
                    <form className="form"
                        onSubmit={handleSubmit(submitUploadiIzn)}
                    >
                        <h5 className="text-center">Import Data Izin {nama}</h5>
                        <input
                            placeholder="Nama Perusahaan"
                            type="file"
                            onChangeCapture={onUpload}
                            name="nama_file"
                        />
                        {
                            izinMessage && izinMessage.error && <span className="text-danger">{izinMessage.error.nama_file}</span>
                        }
                        {
                            izinMessage && izinMessage.error && izinMessage.error.message && izinMessage.error.message.map((w, index) => (
                                <ol key={index}>
                                    <span className="text-danger">{index + 1}.{w}</span>
                                </ol>
                            ))
                        }
                        <Link to="/download/izin.xlsx" target="_blank" style={{ float: 'right' }}>Download Template Upload Izin</Link><br />
                        <Button type="submit" variant="contained" color="primary">Upload</Button>
                    </form>
                    <hr />
                    <form className="form"
                        onSubmit={handleSubmit(tambahIzin)}
                    >
                        <Styles.InputForm>
                            <Controller
                                control={control}
                                name="tahun"
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={tahun}
                                        placeholder="Tahun.."
                                        onChange={(e) => setInputTahun(e.value)}
                                    />
                                )}
                            />
                            {izinMessage && izinMessage.error && <span className="text-danger">{izinMessage.error.tahun}</span>}
                        </Styles.InputForm>
                        <Styles.InputForm>
                            <Controller
                                control={control}
                                name="tahun"
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={permitType}
                                        placeholder="Permit.."
                                        onChange={(e) => setValuePermiType(e.value)}
                                    />
                                )}
                            />
                            {izinMessage && izinMessage.error && <span className="text-danger">{izinMessage.error.permit}</span>}
                        </Styles.InputForm>
                        <Styles.InputForm>
                            <input
                                placeholder="Nomor Permit..."
                                type="text"
                                className="form-control"
                                {...register("permit_number")}
                            />
                            {izinMessage && izinMessage.error && <span className="text-danger">{izinMessage.error.permit_number}</span>}
                        </Styles.InputForm>
                        <Styles.InputForm>
                            <input
                                placeholder="Tanggal Permit..."
                                type="date"
                                className="form-control"
                                {...register("permit_date")}
                            />
                            {izinMessage && izinMessage.error && <span className="text-danger">{izinMessage.error.permit_date}</span>}
                        </Styles.InputForm>
                        <Styles.InputForm>
                            <Controller
                                control={control}
                                name="permit_name"
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={permitName}
                                        placeholder="Nama Permit..."
                                        onChange={(e) => setValuePermitName(e.value)}
                                    />
                                )}
                            />
                            {izinMessage && izinMessage.error && <span className="text-danger">{izinMessage.error.permit_name}</span>}
                        </Styles.InputForm>
                        <Styles.InputForm>
                            <input
                                placeholder="Regarding..."
                                type="text"
                                className="form-control mb-2"
                                {...register("regarding")} />
                            {izinMessage && izinMessage.error && <span className="text-danger">{izinMessage.error.regarding}</span>}
                        </Styles.InputForm>
                        <Styles.InputForm>
                            <input
                                placeholder="Penandatangan..."
                                type="text"
                                className="form-control mb-2"
                                {...register("signer")} />
                            {izinMessage && izinMessage.error && <span className="text-danger">{izinMessage.error.signer}</span>}
                        </Styles.InputForm>
                        <Button className="btn btn-danger" style={{ marginRight: 5 }} variant="contained" onClick={setUpModalAddIzin}>Close</Button>
                        <Button className="btn btn-secondary" variant="contained" type="submit">Save changes</Button>
                    </form>
                </ReactModal>
            </div>
        </div>
    );
}

export default PerizinanDetail

const Tahun = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= 1945; i--) {
        tahun.push(i);
    }
    return tahun;
}