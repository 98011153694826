import React from 'react';
import DataTable from 'react-data-table-component';
import { useParams } from 'react-router';
import Loader from 'react-loader-spinner';
import ReactModal from 'react-modal';
import { ListGroup, Modal, TabContent } from 'react-bootstrap';
import Select from "react-select";
import swal from 'sweetalert';
import { useForm, Controller } from 'react-hook-form';
import Button from '@mui/material/Button';

import { Styles, ActionButton } from '../../components';
import { Link } from 'react-router-dom';

const Jartapsljj = ({
    jartapsljj,
    getjartapsljjByCompany,
    addJartapsljj,
    jartapsljjMessage,
    deleteJartapsljj,
    role,
    uploadJartapsljj
}) => {
    const { id, nama } = useParams();
    const { register, handleSubmit, control } = useForm();
    const [loading, setLoading] = React.useState(true);
    const [jartapsljjData, setJartapsljjData] = React.useState([]);
    // const [dataChartValue, setDataChartValue] = React.useState([]);
    const [tahun, setTahun] = React.useState([]);
    const [inputTahun, setInputTahun] = React.useState('');
    const [showModalAddJartapsljj, setShowModalAddJartapsljj] = React.useState(false);
    const [fileUpload, setFileUpload] = React.useState(null);

    const setupModalAddjartapsljj = () => setShowModalAddJartapsljj(!showModalAddJartapsljj);
    React.useEffect(() => {
        setTahun(Tahun().map(t => ({ value: t, label: t })));
    }, []);
    React.useState(() => {
        getjartapsljjByCompany(id);
    }, [getjartapsljjByCompany]);
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            jartapsljj.loading && jartapsljj.jartapsljj &&
                setJartapsljjData(jartapsljj.jartapsljj.map((sljj) => ({
                    keterangan: sljj.keterangan,
                    outgoing: sljj.outgoing,
                    incoming: sljj.incoming,
                    tahun: sljj.tahun,
                    action: <>
                        <ActionButton
                            title="fa fa-pencil"
                            css="btn-warning"
                            fun={() => window.location.href = `/penyelenggara-detail-pelanggan-kapasitas-edit/${id}/${nama}/edit/${sljj.id}/jartap sljj`}
                        />
                        &nbsp;
                        <ActionButton title="fa fa-trash" css="btn-danger"
                            fun={
                                () => swal(`Apakah Yakin Data sljj ${sljj.company_name} ini akan dihapus?`, {
                                    buttons: ["Batal", true],
                                }).then((value) => {
                                    if (value) {
                                        deleteJartapsljj(sljj.id);
                                    }
                                })
                            }
                        />
                    </>
                })));
            setLoading(false);
        }, 3000);
        return () => clearTimeout(timeout);
    }, [deleteJartapsljj, id, jartapsljj, nama]);

    React.useEffect(() => {
        const notification = () => {
            jartapsljjMessage.loading && jartapsljjMessage.jartapsljjMessage && swal(`${jartapsljjMessage.jartapsljjMessage.message}`, {
                buttons: true
            }).then((value) => {
                if (value) {
                    getjartapsljjByCompany(id);
                }
            });
        }

        return notification()
    }, [getjartapsljjByCompany, id, jartapsljjMessage]);
    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none'
            },
        },
    };
    const columns = [
        {
            name: 'Keterangan',
            selector: 'keterangan'
        },
        {
            name: 'Outgoing',
            selector: 'outgoing',
        },
        {
            name: 'Incoming',
            selector: 'incoming',
        },
        {
            name: 'Tahun',
            selector: 'tahun',
        },
        {
            name: 'Action',
            selector: 'action'
        }
    ];
    const columnsGuest = [
        {
            name: 'Keterangan',
            selector: 'keterangan'
        },
        {
            name: 'Outgoing',
            selector: 'outgoing',
        },
        {
            name: 'Incoming',
            selector: 'incoming',
        },
        {
            name: 'Tahun',
            selector: 'tahun',
        }
    ];

    const customStylesModal = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: '0',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '700px',
            height: '90%'
        },
    };

    const submitjartapsljj = (data) => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            keterangan: data.keterangan,
            outgoing: data.outgoing,
            incoming: data.incoming,
            tahun: inputTahun
        }
        addJartapsljj(input);
    }

    const onUpload = (e) => setFileUpload(e.target.files[0]);

    const submitUploadJartapSljj = (data) => {
        const formData = new FormData();
        formData.append("nama_file", fileUpload);
        uploadJartapsljj(formData)
    };

    return loading ? <Loader /> : jartapsljj.error === "Unauthenticated." ? (
        window.location.href = "/") : (
        <>
            <ListGroup as="ul" className="nav nav-tabs" id="myTab" role="tablist">
                <ListGroup as="li" className="nav-item active" role="presentation">
                    <a className="nav-link active" id="kapasitas-jartapsljj-tab" data-toggle="tab" href="#kapasitas-jartapsljj" role="tab" aria-controls="kapasitas-jartapsljj" aria-selected="true">Kapasitas Pelanggan</a>
                </ListGroup>
                {/* <ListGroup as="li" className="nav-item" role="presentation">
                    <a className="nav-link" id="existing-jaratpsljj-tab" data-toggle="tab" href="#existing-jaratpsljj" role="tab" aria-controls="existing-jaratpsljj" aria-selected="false">Existing</a>
                </ListGroup> */}
            </ListGroup>
            <TabContent>
                <div className="tab-pane active" id="kapasitas-jartapsljj" role="tabpanel" aria-labelledby="kapasitas-jartapsljj-tab">
                    {
                        role.role_id !== 3 &&
                        <div className="toolbar" style={{ display: 'flex', flexDirection: 'row-reverse', }}>
                            <Button
                                id="basic-button"
                                aria-controls="basic-menu"
                                className="btn btn-sm"
                                size="large"
                                aria-haspopup="true"
                                onClick={setupModalAddjartapsljj}
                                style={{ backgroundColor: 'rgba(0, 50, 122, 1)', color: '#fff', marginRight: '10px' }}
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i> &nbsp; Tambah
                            </Button>
                        </div>
                    }
                    <div className="card-content">
                        <div className="material-datatables">
                            <DataTable
                                columns={role.role_id !== 3 ? columns : columnsGuest}
                                data={jartapsljjData}
                                progressPending={loading}
                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100} width={100} />}
                                responsive={true}
                                customStyles={customStyles}
                                pagination />
                            {/* <Bar data={dataChartjartapsljj} options={options} /> */}
                        </div>
                    </div>
                </div>
                {/* <div className="tab-pane fade" id="existing-jaratpsljj" role="tabpanel" aria-labelledby="existing-jaratpsljj-tab">
                    <p>Existing Sljj</p>
                </div> */}
            </TabContent>

            <ReactModal
                ariaHideApp={false}
                isOpen={showModalAddJartapsljj}
                preventScroll={true}
                scrollable={true}
                style={customStylesModal}
            >
                <div style={{ float: 'right' }}>
                    <Button id="basic-button"
                        aria-controls="basic-menu"
                        color="error"
                        onClick={setupModalAddjartapsljj}
                        size="small">
                        <span className="material-icons">
                            close
                        </span>
                    </Button>
                </div>
                <Modal.Title style={{ fontWeight: 'bold' }}>Tambah Jartap SLJJ Baru</Modal.Title>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitUploadJartapSljj)}
                >
                    <h5 className="text-center">Import Data JARTAP SLI {nama}</h5>
                    <input
                        placeholder="Nama Perusahaan"
                        type="file"
                        name="nama_file"
                        onChangeCapture={onUpload}
                    />
                    <Link to="/download/jartap sljj.xlsx" target="_blank" style={{ float: 'right' }}>Download Template Upload JARTAP SLJJ</Link>
                    <br />
                    {
                        jartapsljjMessage && jartapsljjMessage.error && <span className="text-danger">{jartapsljjMessage.error.nama_file}</span>
                    }
                    <br />
                    <Button type="submit" variant="contained" color="primary">Upload</Button>
                    {
                        jartapsljjMessage && jartapsljjMessage.error && jartapsljjMessage.error.message && jartapsljjMessage.error.message.map((w, index) => (
                            <ol key={index}>
                                <span className="text-danger">{index + 1}.{w}</span>
                            </ol>
                        ))
                    }
                </form>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitjartapsljj)}
                >
                    <Styles.InputForm>
                        <input
                            placeholder="Keterangan..."
                            type="text"
                            className="form-control"
                            {...register("keterangan")}
                        />
                        {jartapsljjMessage && jartapsljjMessage.error && <span className="text-danger">{jartapsljjMessage.error.keterangan}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Outgoing..."
                            type="text"
                            className="form-control"
                            {...register("outgoing")}
                        />
                        {jartapsljjMessage && jartapsljjMessage.error && <span className="text-danger">{jartapsljjMessage.error.outgoing}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Incoming..."
                            type="text"
                            className="form-control"
                            {...register("incoming")}
                        />
                        {jartapsljjMessage && jartapsljjMessage.error && <span className="text-danger">{jartapsljjMessage.error.incoming}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="tahun"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={tahun}
                                    placeholder="Tahun.."
                                    onChange={(e) => setInputTahun(e.value)}
                                />
                            )}
                        />
                        {jartapsljjMessage && jartapsljjMessage.error && <span className="text-danger">{jartapsljjMessage.error.tahun}</span>}
                    </Styles.InputForm>
                    <Button color="error" variant="contained" onClick={setupModalAddjartapsljj} style={{ marginRight: 5 }}>Close</Button>
                    <Button color="primary" variant="contained" type="submit">Save</Button>
                </form>
            </ReactModal>
        </>
    )
}

export default Jartapsljj

const Tahun = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= 1945; i--) {
        tahun.push(i);
    }
    return tahun;
}