import {
    PEGAWAI_REQ,
    PEGAWAI_SUCCESS,
    PEGAWAI_FAIL,
    PEGAWAI_MESSAGE_REQ,
    PEGAWAI_MESSAGE_SUCCESS,
    PEGAWAI_MESSAGE_FAIL,
    PEGAWAI_DETAIL_REQ,
    PEGAWAI_DETAIL_SUCCESS,
    PEGAWAI_DETAIL_FAIL,
} from './pegawaiTypes'

import api from '../services';
import { config } from '../config';

export const pegawaiDetailReq = () => {
    return { type: PEGAWAI_DETAIL_REQ }
}

export const pegawaiDetailSuccess = (data) => {
    return { type: PEGAWAI_DETAIL_SUCCESS, payload: data }
}

export const pegawaiDetailFail = (err) => {
    return { type: PEGAWAI_DETAIL_FAIL, payload: err }
}

export const pegawaiMessageReq = () => {
    return { type: PEGAWAI_MESSAGE_REQ }
}

export const pegawaiMessageSuccess = (data) => {
    return { type: PEGAWAI_MESSAGE_SUCCESS, payload: data }
}

export const pegawaiMessageFail = (err) => {
    return { type: PEGAWAI_MESSAGE_FAIL, payload: err }
}

export const pegawaiReq = () => {
    return { type: PEGAWAI_REQ }
}

export const pegawaiSuccess = (data) => {
    return { type: PEGAWAI_SUCCESS, payload: data }
}

export const pegawaiFail = (err) => {
    return { type: PEGAWAI_FAIL, payload: err }
}

export const uploadPegawai = (data) => {
    return async (dispatch) => {
        dispatch(pegawaiMessageReq);
        await api.post(`/data-pegawai-create-import`, data, config)
            .then(res => {
                dispatch(pegawaiMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(pegawaiMessageFail(err.response.data));
            })
    }
}

export const updatePegawai = (id, data) => {
    return async (dispatch) => {
        dispatch(pegawaiMessageReq);
        await api.put(`/data-pegawai-update/${id}`, data, config)
            .then(res => {
                dispatch(pegawaiMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(pegawaiMessageFail(err.response.data));
            })
    }
}

export const getPegawaiDetail = (id) => {
    return async (dispatch) => {
        dispatch(pegawaiDetailReq);
        await api.get(`/data-pegawai/${id}`, config)
            .then(res => {
                dispatch(pegawaiDetailSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(pegawaiDetailFail(err.response.data));
            })
    }
}

export const deletePegawai = (id) => {
    return async (dispatch) => {
        dispatch(pegawaiMessageReq);
        await api.delete(`/data-pegawai-delete/${id}`, config)
            .then(res => {
                dispatch(pegawaiMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(pegawaiMessageFail(err.response.data));
            })
    }
}

export const getPegawai = (company_id) => {
    return async (dispatch) => {
        dispatch(pegawaiReq);
        await api.get(`/data-pegawai-company/${company_id}`, config)
            .then(res => {
                dispatch(pegawaiSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(pegawaiFail(err.response.data));
            })
    }
}

export const addPegawai = (data) => {
    return async (dispatch) => {
        dispatch(pegawaiMessageReq);
        await api.post(`/data-pegawai-create`, data, config)
            .then(res => {
                dispatch(pegawaiMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(pegawaiMessageFail(err.response.data));
            })
    }
}
