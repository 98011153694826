import {
    RASIO_KESEHATAN_KEUANGAN_REQ,
    RASIO_KESEHATAN_KEUANGAN_SUCCESS,
    RASIO_KESEHATAN_KEUANGAN_FAIL,
    RASIO_KESEHATAN_KEUANGAN_CRUD_REQ,
    RASIO_KESEHATAN_KEUANGAN_CRUD_SUCCESS,
    RASIO_KESEHATAN_KEUANGAN_CRUD_FAIL,
    RASIO_KESEHATAN_KEUANGAN_CRUD_MESSAGE_REQ,
    RASIO_KESEHATAN_KEUANGAN_CRUD_MESSAGE_SUCCESS,
    RASIO_KESEHATAN_KEUANGAN_CRUD_MESSAGE_FAIL,
    KETERANGAN_RASIO_KESEHATAN_REQ,
    KETERANGAN_RASIO_KESEHATAN_SUCCESS,
    KETERANGAN_RASIO_KESEHATAN_FAIL,
} from './rasioKesehatanKeuanganTypes';



// state rasio kesehatan keuangan
const keteranganRasioKesehatanState = {
    loading: false,
    keteranganRasioKesehatan: [],
    error: ''
}

const rasioKesehatanKeuanganMessageState = {
    loading: false,
    rasioKesehatanKeuanganMessage: '',
    error: ''
}

const rasioKesehatanKeuanganCrudState = {
    loading: false,
    rasioKesehatanKeuanganCrud: [],
    error: ''
}

const rasioKesehatanKeuanganState = {
    loading: false,
    rasioKesehatanKeuangan: [],
    error: ''
}

// function rasio kesehatan keuangan
export const keteranganRasioKesehatanReducer = (state = keteranganRasioKesehatanState, action) => {
    switch (action.type) {
        case KETERANGAN_RASIO_KESEHATAN_REQ:
            return {
                ...state,
                loading: false,
            }
        case KETERANGAN_RASIO_KESEHATAN_SUCCESS:
            return {
                loading: true,
                keteranganRasioKesehatan: action.payload,
                error: ''
            }
        case KETERANGAN_RASIO_KESEHATAN_FAIL:
            return {
                loading: false,
                keteranganRasioKesehatan: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const rasioKesehatanKeuanganMessageReducer = (state = rasioKesehatanKeuanganMessageState, action) => {
    switch (action.type) {
        case RASIO_KESEHATAN_KEUANGAN_CRUD_MESSAGE_REQ:
            return {
                ...state,
                loading: false,
            }
        case RASIO_KESEHATAN_KEUANGAN_CRUD_MESSAGE_SUCCESS:
            return {
                loading: true,
                rasioKesehatanKeuanganMessage: action.payload,
                error: ''
            }
        case RASIO_KESEHATAN_KEUANGAN_CRUD_MESSAGE_FAIL:
            return {
                loading: false,
                rasioKesehatanKeuanganMessage: '',
                error: action.payload
            }
        default:
            return state
    }
}

export const rasioKesehatanKeuanganCrudReducer = (state = rasioKesehatanKeuanganCrudState, action) => {
    switch (action.type) {
        case RASIO_KESEHATAN_KEUANGAN_CRUD_REQ:
            return {
                ...state,
                loading: false,
            }
        case RASIO_KESEHATAN_KEUANGAN_CRUD_SUCCESS:
            return {
                loading: true,
                rasioKesehatanKeuanganCrud: action.payload,
                error: ''
            }
        case RASIO_KESEHATAN_KEUANGAN_CRUD_FAIL:
            return {
                loading: false,
                rasioKesehatanKeuanganCrud: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const rasioKesehatanKeuanganReducer = (state = rasioKesehatanKeuanganState, action) => {
    switch (action.type) {
        case RASIO_KESEHATAN_KEUANGAN_REQ:
            return {
                ...state,
                loading: false,
            }
        case RASIO_KESEHATAN_KEUANGAN_SUCCESS:
            return {
                loading: true,
                rasioKesehatanKeuangan: action.payload,
                error: ''
            }
        case RASIO_KESEHATAN_KEUANGAN_FAIL:
            return {
                loading: false,
                rasioKesehatanKeuangan: [],
                error: action.payload
            }
        default:
            return state
    }
}