import React from 'react';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { Sidebar, Navbar, Spinner } from '../../components';
import { ListJabersel } from '../../parts';

const Jabersel = ({
    user,
    getUser
}) => {
    const history = useHistory();
    const [loading, setLoading] = React.useState(true);
    const [userData, setUserData] = React.useState(null);

    React.useEffect(() => {
        document.title = "Izin | PPI";
    }, []);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            user && user.user && user.user.map(u => setUserData(u));
            user && user.error === "Unauthenticated." && history.push('/');
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [history, user]);

    React.useEffect(() => {
        document.title = 'Jabersel | PPI'
    }, []);

    return loading ? <Spinner /> : (
        <>
            <Sidebar menu="JABERSEL" />
            <div className="main-panel">
                <Navbar menu="JABERSEL" user={userData} />
                <div className="content">
                    <Container fluid>
                        <ListJabersel />
                    </Container>
                </div>
            </div>
        </>
    )
}

export default Jabersel
