// 
export const RASIO_KESEHATAN_KEUANGAN_CRUD_MESSAGE_REQ = 'RASIO_KESEHATAN_KEUANGAN_CRUD_MESSAGE_REQ';
export const RASIO_KESEHATAN_KEUANGAN_CRUD_MESSAGE_SUCCESS = 'RASIO_KESEHATAN_KEUANGAN_CRUD_MESSAGE_SUCCESS';
export const RASIO_KESEHATAN_KEUANGAN_CRUD_MESSAGE_FAIL = 'RASIO_KESEHATAN_KEUANGAN_CRUD_MESSAGE_FAIL';

export const RASIO_KESEHATAN_KEUANGAN_REQ = 'RASIO_KESEHATAN_KEUANGAN_REQ';
export const RASIO_KESEHATAN_KEUANGAN_SUCCESS = 'RASIO_KESEHATAN_KEUANGAN_SUCCESS';
export const RASIO_KESEHATAN_KEUANGAN_FAIL = 'RASIO_KESEHATAN_KEUANGAN_FAIL';

export const RASIO_KESEHATAN_KEUANGAN_CRUD_REQ = 'RASIO_KESEHATAN_KEUANGAN_CRUD_REQ';
export const RASIO_KESEHATAN_KEUANGAN_CRUD_SUCCESS = 'RASIO_KESEHATAN_KEUANGAN_CRUD_SUCCESS';
export const RASIO_KESEHATAN_KEUANGAN_CRUD_FAIL = 'RASIO_KESEHATAN_KEUANGAN_CRUD_FAIL';

export const KETERANGAN_RASIO_KESEHATAN_REQ = 'KETERANGAN_RASIO_KESEHATAN_REQ';
export const KETERANGAN_RASIO_KESEHATAN_SUCCESS = 'KETERANGAN_RASIO_KESEHATAN_SUCCESS';
export const KETERANGAN_RASIO_KESEHATAN_FAIL = 'KETERANGAN_RASIO_KESEHATAN_FAIL';