export const MARKETSHARE_PENDAPATAN_REQ = 'MARKETSHARE_PENDAPATAN_REQ';
export const MARKETSHARE_PENDAPATAN_SUCCESS = 'MARKETSHARE_PENDAPATAN_SUCCESS';
export const MARKETSHARE_PENDAPATAN_FAIL = 'MARKETSHARE_PENDAPATAN_FAIL';

export const MARKETSHARE_PELANGGAN_REQ = 'MARKETSHARE_PELANGGAN_REQ';
export const MARKETSHARE_PELANGGAN_SUCCESS = 'MARKETSHARE_PELANGGAN_SUCCESS';
export const MARKETSHARE_PELANGGAN_FAIL = 'MARKETSHARE_PELANGGAN_FAIL';

export const PENILAIAN_KESEHATAN_REQ = 'PENILAIAN_KESEHATAN_REQ';
export const PENILAIAN_KESEHATAN_SUCCESS = 'PENILAIAN_KESEHATAN_SUCCESS';
export const PENILAIAN_KESEHATAN_FAIL = 'PENILAIAN_KESEHATAN_FAIL';