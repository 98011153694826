import SectionDashboard from './SectionDashboard';
import { connect } from 'react-redux';
import {
    getDashboardIzin,
    getDashboardTeknologi,
    getMarketSharePelangganChart,
    getMarketSharePendapatanChart,
    getProfilUmum,
    getUser,
    getPenilaianKesehatan
} from '../../redux';

const mapStateToProps = state => {
    return {
        user: state.user,
        profilUmum: state.profilUmum,
        dashboardIzin: state.dashboardIzin,
        dashboardTeknologi: state.dashboardTeknologi,
        marketSharePendapatan: state.marketSharePendapatan,
        marketSharePelanggan: state.marketSharePelanggan,
        penilaianKesehatan: state.penilaianKesehatan
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPenilaianKesehatan: () => dispatch(getPenilaianKesehatan()),
        getMarketSharePelangganChart: () => dispatch(getMarketSharePelangganChart()),
        getMarketSharePendapatanChart: () => dispatch(getMarketSharePendapatanChart()),
        getUser: () => dispatch(getUser()),
        getProfilUmum: () => dispatch(getProfilUmum()),
        getDashboardIzin: () => dispatch(getDashboardIzin()),
        getDashboardTeknologi: () => dispatch(getDashboardTeknologi()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionDashboard)