import React from 'react';
import {
    Container,
    ListGroup,
    TabContent
} from 'react-bootstrap';
import {
    Sidebar,
    Navbar,
    Spinner
} from '../../components';
import {
    Keuangan,
    ProfilUmumDetail,
    Saham,
    Pegawai,
    CapexOpex,
    PerizinanDetail,
} from '../../parts';

const DataPenyelenggaraan = ({
    user,
    getUser
}) => {
    const [loading, setLoading] = React.useState(true);
    const [userData, setUserData] = React.useState(null);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            user && user.user && user.user.map(u => setUserData(u));
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [user]);

    React.useEffect(() => {
        getUser();
    }, [getUser]);
    React.useEffect(() => {
        document.title = "Detail Penyelenggaraan | PPI";
    }, []);

    return loading ? <Spinner /> : (
        <>
            <Sidebar menu="DATA PENYELENGGARAAN" />
            <div className="main-panel">
                <Navbar user={userData} />
                <div className="content">
                    <Container fluid>
                        <ListGroup as="ul" className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <ListGroup as="li" className="nav-item active">
                                <a className="nav-link" id="pills-data-umum-tab" data-toggle="pill" href="#pills-data-umum" role="tab" aria-controls="pills-data-umum" aria-selected="false">Data Umum</a>
                            </ListGroup>
                            <ListGroup as="li" className="nav-item">
                                <a className="nav-link" id="pills-izin-tab" data-toggle="pill" href="#pills-izin" role="tab" aria-controls="pills-izin" aria-selected="true">Izin</a>
                            </ListGroup>
                            <ListGroup as="li" className="nav-item">
                                <a className="nav-link" id="pills-saham-tab" data-toggle="pill" href="#pills-saham" role="tab" aria-controls="pills-saham" aria-selected="false">Saham</a>
                            </ListGroup>
                            <ListGroup as="li" className="nav-item">
                                <a className="nav-link" id="pills-pegawai-tab" data-toggle="pill" href="#pills-pegawai" role="tab" aria-controls="pills-pegawai" aria-selected="false">Pegawai</a>
                            </ListGroup >
                            <ListGroup as="li" className="nav-item">
                                <a className="nav-link" id="pills-capex-opex-tab" data-toggle="pill" href="#pills-capex-opex" role="tab" aria-controls="pills-capex-opex" aria-selected="false">CAPEX OPEX</a>
                            </ListGroup>
                            <ListGroup as="li" className="nav-item">
                                <a className="nav-link" id="pills-keuangan-tab" data-toggle="pill" href="#pills-keuangan" role="tab" aria-controls="pills-keuangan" aria-selected="false">Keuangan</a>
                            </ListGroup>
                        </ListGroup>
                        <TabContent>
                            <div className="tab-pane active" id="pills-data-umum" role="tabpanel" aria-labelledby="pills-data-umum-tab">
                                <ProfilUmumDetail />
                            </div>
                            <div className="tab-pane" id="pills-izin" role="tabpanel" aria-labelledby="pills-izin-tab">
                                <PerizinanDetail role={userData} />
                            </div>
                            <div className="tab-pane" id="pills-saham" role="tabpanel" aria-labelledby="pills-saham-tab">
                                <Saham role={userData} />
                            </div>
                            <div className="tab-pane" id="pills-pegawai" role="tabpanel" aria-labelledby="pills-pegawai-tab">
                                <Pegawai role={userData} />
                            </div>
                            <div className="tab-pane" id="pills-capex-opex" role="tabpanel" aria-labelledby="pills-capex-opex-tab">
                                <CapexOpex role={userData} />
                            </div>
                            <div className="tab-pane" id="pills-keuangan" role="tabpanel" aria-labelledby="pills-keuangan">
                                <Keuangan role={userData} />
                            </div>
                        </TabContent>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default DataPenyelenggaraan
