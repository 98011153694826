import Jartaplok from './Jartaplok';
import { connect } from 'react-redux';
import { addJartaplok, deletejartaplok, getjartaplokByCompany, uploadJartaplok } from '../../redux';

const mapStateToProps = state => {
    return {
        jartaplok: state.jartaplok,
        jartaplokMessage: state.jartaplokMessage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        uploadJartaplok: (data) => dispatch(uploadJartaplok(data)),
        getjartaplokByCompany: (id) => dispatch(getjartaplokByCompany(id)),
        addjartaplok: (data) => dispatch(addJartaplok(data)),
        deletejartaplok: (id) => dispatch(deletejartaplok(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Jartaplok);