import Jartapsljj from './Jartapsljj';
import { connect } from 'react-redux';
import { addJartapsljj, deleteJartapsljj, getjartapsljjByCompany, uploadJartapsljj } from '../../redux';

const mapStateToProps = state => {
    return {
        jartapsljj: state.jartapsljj,
        jartapsljjMessage: state.jartapsljjMessage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        uploadJartapsljj: (data) => dispatch(uploadJartapsljj(data)),
        getjartapsljjByCompany: (id) => dispatch(getjartapsljjByCompany(id)),
        deleteJartapsljj: (id) => dispatch(deleteJartapsljj(id)),
        addJartapsljj: (data) => dispatch(addJartapsljj(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Jartapsljj);