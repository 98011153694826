import EditBts from './EditBts';
import { connect } from 'react-redux';
import {
    getbtsDetail,
    getProv,
    getUser,
    updateBts
} from '../../redux';

const mapStateToProps = state => {
    return {
        user: state.user,
        provinsi: state.provinsi,
        btsMessage: state.btsMessage,
        btsDetail: state.btsDetail
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getbtsDetail: (id) => dispatch(getbtsDetail(id)),
        updateBts: (id, data) => dispatch(updateBts(id, data)),
        getUser: () => dispatch(getUser()),
        getProvinsi: () => dispatch(getProv()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditBts);
