import React from 'react'
import { Bar } from 'react-chartjs-2';
import DataTable from 'react-data-table-component'
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import Button from '@mui/material/Button';
import ReactModal from 'react-modal';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Select from "react-select";
import _ from 'lodash';

import { ActionButton, Spinner, Styles } from '../../components';

import {
    getRasioKeuanganPivot,
    getRasioKeuanganCrud,
    addRasioKeuangan,
    getRasio,
    getNamaRasio,
    getRumusRasio,
    deleletRasioKeuangan,
    uploadRasioKeuangan,
} from '../../redux';

const RasioKeuangan = ({
    id,
    nama,
    getRasioKeuanganPivot,
    getRasioKeuanganCrud,
    rasioKeuangan,
    rasioKeuanganCrud,
    role,
    uploadRasioKeuangan,
    addRasioKeuangan,
    rasioKeuanganCrudMessage,
    getRasio,
    rasio,
    getNamaRasio,
    keteranganRasio,
    getRumusRasio,
    rumusRasio,
    deleletRasioKeuangan
}) => {
    const { register, handleSubmit, control } = useForm();
    const [crudRasio, setCrudRasio] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    const [rasioKeuanganState, setRasioKeuanganState] = React.useState([]);
    const [rasioKeuanganCrudState, setRasioKeuanganCrudState] = React.useState([]);
    const [rasioState, setRasioState] = React.useState([]);
    const [keteranganRasioState, setKeteranganRasioState] = React.useState([]);
    const [rumusState, setRumusState] = React.useState(null);
    const [tahunHeader, setTahunHeader] = React.useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const [fileUpload, setFileUpload] = React.useState(null);
    const [tahun, setTahun] = React.useState([]);

    // data chart
    const [liquidityRatioChart, setLiquidityRatioChart] = React.useState([]);
    const [activityRatioChart, setActivityRatioChart] = React.useState([]);
    const [solvabilitasRatio, setSolvabilitasRatio] = React.useState([]);
    const [profitabilityRatioChart, setProfitabilityRatioChart] = React.useState([]);
    // console.log(liquidityRatioChart)

    // value Input
    const [tahunInput, setTahunInput] = React.useState('');
    const [keteranganInput, setKeteranganInput] = React.useState('');
    const [namaRasioInput, setNamaRasioInput] = React.useState('');

    const toCrudRasio = () => setCrudRasio(!crudRasio);
    const setupModal = () => setShowModal(!showModal);

    React.useEffect(() => {
        setTahun(Tahun().map(t => ({ value: t, label: t })));
    }, []);

    React.useEffect(() => {
        rumusRasio && rumusRasio.rumusRasio && rumusRasio.rumusRasio.map(rr => setRumusState({ value: rr.id, label: rr.rumus }));
    }, [rumusRasio])

    React.useEffect(() => {
        keteranganRasio && keteranganRasio.keteranganRasio && setKeteranganRasioState(keteranganRasio.keteranganRasio.map(kr => ({ value: kr.id, label: kr.nama_rasio })));
    }, [keteranganRasio]);

    React.useEffect(() => {
        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }
        function makeid() {
            var letters = '0123456789ABCDEF';
            var color = '#';
            for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        }

        rasioKeuanganCrud && rasioKeuanganCrud.rasioKeuanganCrud &&
            rasioKeuanganCrud.rasioKeuanganCrud.filter(e => e.nama_keterangan === 'Liquidity Ratio').map((maping, i, data) => setLiquidityRatioChart(({
                labels: data.map(p => p.tahun).filter(onlyUnique),
                datasets: Object.values(_.groupBy(data, 'nama_rasio')).map(d => ({
                    label: d.map(p => p.nama_rasio).filter(onlyUnique),
                    data: d.map(f => f.total_rasio),
                    backgroundColor: makeid()
                }))
            })));

        rasioKeuanganCrud && rasioKeuanganCrud.rasioKeuanganCrud &&
            rasioKeuanganCrud.rasioKeuanganCrud.filter(e => e.nama_keterangan === 'Activity Ratio').map((maping, i, data) => setActivityRatioChart(({
                labels: data.map(p => p.tahun).filter(onlyUnique),
                datasets: Object.values(_.groupBy(data, 'nama_rasio')).map(d => ({
                    label: d.map(p => p.nama_rasio).filter(onlyUnique),
                    data: d.map(f => f.total_rasio),
                    backgroundColor: makeid()
                }))
            })));

        rasioKeuanganCrud && rasioKeuanganCrud.rasioKeuanganCrud &&
            rasioKeuanganCrud.rasioKeuanganCrud.filter(e => e.nama_keterangan === 'Solvabilitas Ratio').map((maping, i, data) => setSolvabilitasRatio(({
                labels: data.map(p => p.tahun).filter(onlyUnique),
                datasets: Object.values(_.groupBy(data, 'nama_rasio')).map(d => ({
                    label: d.map(p => p.nama_rasio).filter(onlyUnique),
                    data: d.map(f => f.total_rasio),
                    backgroundColor: makeid()
                }))
            })));

        rasioKeuanganCrud && rasioKeuanganCrud.rasioKeuanganCrud &&
            rasioKeuanganCrud.rasioKeuanganCrud.filter(e => e.nama_keterangan === 'Profitability Ratio').map((maping, i, data) => setProfitabilityRatioChart(({
                labels: data.map(p => p.tahun).filter(onlyUnique),
                datasets: Object.values(_.groupBy(data, 'nama_rasio')).map(d => ({
                    label: d.map(p => p.nama_rasio).filter(onlyUnique),
                    data: d.map(f => f.total_rasio),
                    backgroundColor: makeid()
                }))
            })));
    }, [rasioKeuanganCrud])

    React.useEffect(() => {
        const notif = () => {
            rasioKeuanganCrudMessage.loading && rasioKeuanganCrudMessage.rasioKeuanganCrudMessage && swal(`${rasioKeuanganCrudMessage.rasioKeuanganCrudMessage.message}`, {
                buttons: true
            }).then((value) => {
                if (value) {
                    getRasioKeuanganPivot(id);
                    getRasioKeuanganCrud(id);
                }
            });
        };

        return notif();
    }, [getRasioKeuanganCrud, getRasioKeuanganPivot, id, rasioKeuanganCrudMessage]);
    React.useEffect(() => {


        const timeout = setTimeout(() => {

            // nama rasio
            rasio && rasio.rasio && setRasioState(rasio.rasio.map(r => ({ value: r.id, label: r.nama_keterangan })));

            rasioKeuangan.loading && rasioKeuangan.rasioKeuangan && rasioKeuangan.rasioKeuangan.length > 0 && setTahunHeader(Object.keys(rasioKeuangan.rasioKeuangan[0]));
            rasioKeuangan.loading && rasioKeuangan.rasioKeuangan && setRasioKeuanganState(rasioKeuangan.rasioKeuangan);

            rasioKeuanganCrud && rasioKeuanganCrud.rasioKeuanganCrud && setRasioKeuanganCrudState(rasioKeuanganCrud.rasioKeuanganCrud.map((crd, index) => ({
                number: index + 1,
                nama_rasio: crd.nama_rasio,
                total_rasio: crd.total_rasio.toFixed(2),
                nama_keterangan: crd.nama_keterangan,
                rumus: crd.rumus,
                tahun: crd.tahun,
                action: <>
                    <ActionButton
                        title="fa fa-pencil"
                        css="btn-warning"
                        fun={() => window.location.href = `/penyelenggara-detail-keuangan/${id}/${nama}/edit/${crd.id}/rasio keuangan`}
                    />
                    &nbsp;
                    <ActionButton title="fa fa-trash" css="btn-danger"
                        fun={
                            () => swal(`Apakah Yakin Data Rasio Keuangan ${crd.nama_keterangan} ${crd.company_name} tahun ${crd.tahun} ini akan dihapus?`, {
                                buttons: ["Batal", true],
                            }).then((value) => {
                                if (value) {
                                    deleletRasioKeuangan(crd.id);
                                }
                            })
                        }
                    />
                </>,
            })));

            setLoading(false);
        }, 3000);
        return () => clearTimeout(timeout);
    }, [deleletRasioKeuangan, id, nama, rasio, rasioKeuangan, rasioKeuanganCrud]);
    React.useEffect(() => {
        getRasioKeuanganPivot(id);
        getRasioKeuanganCrud(id);
        getRasio();
    }, [getRasio, getRasioKeuanganCrud, getRasioKeuanganPivot, id]);

    const customStylesModal = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: '0',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '700px',
            height: '100%'
        },
    };

    const columns = [
        {
            name: 'No',
            selector: (row, index) => index + 1,
            minWidth: '10px'
        },
        {
            name: `Keterangan`,
            selector: row => row.nama_keterangan,
            minWidth: '200px'
        },
        {
            name: `Nama Rasio`,
            selector: row => row.nama_rasio,
            minWidth: '350px'
        },
        {
            name: `${tahunHeader.length > 0 ? tahunHeader[1].slice(6) : tahunHeader}`,
            selector: tahunHeader.length > 0 ? tahunHeader[1] : tahunHeader
        },
        {
            name: `${tahunHeader.length > 0 ? tahunHeader[2].slice(6) : tahunHeader}`,
            selector: tahunHeader.length > 0 ? tahunHeader[2] : tahunHeader
        },
        {
            name: `${tahunHeader.length > 0 ? tahunHeader[3].slice(6) : tahunHeader}`,
            selector: `${tahunHeader.length > 0 ? tahunHeader[3] : tahunHeader}`
        },
        {
            name: `${tahunHeader.length > 0 ? tahunHeader[4].slice(6) : tahunHeader}`,
            selector: `${tahunHeader.length > 0 ? tahunHeader[4] : tahunHeader}`
        },
        {
            name: `${tahunHeader.length > 0 ? tahunHeader[5].slice(6) : tahunHeader}`,
            selector: `${tahunHeader.length > 0 ? tahunHeader[5] : tahunHeader}`
        },
    ];
    const columnsCrud = [
        {
            name: `No`,
            selector: row => row.number,
            minWidth: '10px'
        },
        {
            name: `Nama Rasio`,
            selector: row => row.nama_rasio,
            minWidth: '310px'
        },
        {
            name: `Total Rasio`,
            selector: row => row.total_rasio,
        },
        {
            name: `Nama Keterangan`,
            selector: row => row.nama_keterangan,
            minWidth: '150px'
        },
        {
            name: `Rumus`,
            selector: row => row.rumus,
            minWidth: '410px'
        },
        {
            name: `Tahun`,
            selector: row => row.tahun,
            minWidth: '80px'
        },
        {
            name: ``,
            selector: row => row.action,
        },

    ];
    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none'
            },
        },
    };


    const onUpload = (e) => setFileUpload(e.target.files[0]);

    const uploadRasiokeuanganSubmit = () => {
        const formData = new FormData();
        formData.append("nama_file", fileUpload);
        uploadRasioKeuangan(formData);
    }

    const submitRasioKeuangan = (data) => {
        const input = {
            tahun: tahunInput,
            data_umum_id: id,
            company_name: nama,
            keterangan_id: keteranganInput,
            nama_rasio_id: namaRasioInput,
            rumus_id: rumusState !== null ? rumusState.value : rumusState,
            total_rasio: data.total_rasio
        }
        console.log(input);
        addRasioKeuangan(input);
    }


    return loading ? <Spinner /> : (
        <>
            {
                role.role_id !== 3 &&
                <div className="toolbar" style={{ float: 'right', display: 'flex', flexDirection: 'row', }}>
                    <div className="card-header">
                        <Button
                            id="basic-button"
                            aria-controls="basic-menu"
                            className="btn btn"
                            size="large"
                            aria-haspopup="true"
                            onClick={setupModal}
                            style={{ backgroundColor: 'rgba(0, 50, 122, 1)', color: '#fff', marginRight: '10px' }}
                        >
                            <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;Tambah
                        </Button>
                        <Button
                            id="basic-button"
                            aria-controls="basic-menu"
                            size="large"
                            aria-haspopup="true" className={crudRasio ? "btn btn-success" : "btn btn-danger"}
                            onClick={toCrudRasio}
                        >
                            <i className={crudRasio ? "fa fa-edit" : "fa fa-eye"} aria-hidden="true"></i> {crudRasio ? 'Edit' : 'View'}
                        </Button>
                    </div>
                </div>
            }
            <div className="card-content">
                <div className="material-datatables">
                    {
                        crudRasio ?
                            <DataTable
                                columns={columns}
                                data={rasioKeuanganState}
                                progressPending={loading}
                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100}
                                    width={100} />}
                                responsive={true}
                                customStyles={customStyles}
                                pagination
                            /> : <DataTable
                                columns={columnsCrud}
                                data={rasioKeuanganCrudState}
                                progressPending={loading}
                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100}
                                    width={100} />}
                                responsive={true}
                                customStyles={customStyles}
                                pagination
                            />
                    }
                    {/* <p className="h4 text-center">Liquidity Ratio</p>
                    <Bar data={liquidityRatioChart} />
                    <p className="h4 text-center">Activity Ratio</p>
                    <Bar data={activityRatioChart} />
                    <p className="h4 text-center">Solvabilitas Ratio</p>
                    <Bar data={solvabilitasRatio} />
                    <p className="h4 text-center">Profitability Ratio</p>
                    <Bar data={profitabilityRatioChart} /> */}
                </div>
            </div>
            <ReactModal
                ariaHideApp={false}
                isOpen={showModal}
                preventScroll={true}
                scrollable={true}
                style={customStylesModal}
            >
                <div style={{ float: 'right' }}>
                    <Button id="basic-button"
                        aria-controls="basic-menu"
                        color="error"
                        onClick={setupModal}
                        size="small">
                        <span className="material-icons">
                            close
                        </span>
                    </Button>
                </div>
                <Modal.Title style={{ fontWeight: 'bold' }}>Tambah Data Rasio Keuangan</Modal.Title>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(uploadRasiokeuanganSubmit)}
                >
                    <h5 className="text-center">Import Data Rasio Keuangan {nama}</h5>
                    <input
                        type="file"
                        name="nama_file"
                        onChange={onUpload}
                    />
                    {
                        rasioKeuanganCrudMessage && rasioKeuanganCrudMessage.error && <span className="text-danger">{rasioKeuanganCrudMessage.error.nama_file}</span>
                    }
                    <Link to="/download/rasio keuangan.xlsx" style={{ float: 'right' }} target="_blank">Download Template Upload Rasio Keuangan</Link><br />
                    <Button variant="contained" color="primary" type="submit" >Upload</Button>
                    <br />
                    {
                        rasioKeuanganCrudMessage && rasioKeuanganCrudMessage.error && <span className="text-danger">{JSON.stringify(rasioKeuanganCrudMessage.error.message)}</span>
                    }
                </form>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitRasioKeuangan)}
                >
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="tahun"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={tahun}
                                    placeholder="Tahun.."
                                    onChange={(e) => setTahunInput(e.value)}
                                />
                            )}
                        />
                        {rasioKeuanganCrudMessage && rasioKeuanganCrudMessage.error && <span className="text-danger">{rasioKeuanganCrudMessage.error.tahun}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="keterangan_id"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={rasioState}
                                    placeholder="Keterangan.."
                                    onChange={(e) => {
                                        getNamaRasio(e.value);
                                        setKeteranganInput(e.value);
                                    }}
                                />
                            )}
                        />
                        {rasioKeuanganCrudMessage && rasioKeuanganCrudMessage.error && <span className="text-danger">{rasioKeuanganCrudMessage.error.keterangan_id}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="nama_rasio_id"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={keteranganRasioState}
                                    placeholder="Nama_rasio"
                                    onChange={(e) => {
                                        setNamaRasioInput(e.value);
                                        getRumusRasio(e.value);
                                    }}
                                />
                            )}
                        />
                        {rasioKeuanganCrudMessage && rasioKeuanganCrudMessage.error && <span className="text-danger">{rasioKeuanganCrudMessage.error.nama_rasio_id}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder={`${rumusState !== null ? rumusState.label : "Rums"}`}
                            type="text"
                            className="form-control"
                            {...register("rumus_id")}
                            disabled
                        />
                        {rasioKeuanganCrudMessage && rasioKeuanganCrudMessage.error && <span className="text-danger">{rasioKeuanganCrudMessage.error.rumus_id}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Total Rasio"
                            type="text"
                            className="form-control"
                            {...register("total_rasio")}
                        />
                        {rasioKeuanganCrudMessage && rasioKeuanganCrudMessage.error && <span className="text-danger">{rasioKeuanganCrudMessage.error.total_rasio}</span>}
                    </Styles.InputForm>
                    <Button variant="contained" color="error" onClick={setupModal} style={{ marginRight: 5 }}>Close</Button>
                    <Button variant="contained" color="primary" type="submit">Save changes</Button>
                </form >
            </ReactModal >
        </>
    )
}

const Tahun = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= 1945; i--) {
        tahun.push(i);
    }
    return tahun;
}

const mapStateToProps = state => {
    return {
        rasioKeuangan: state.rasioKeuangan,
        rasioKeuanganCrud: state.rasioKeuanganCrud,
        rasioKeuanganCrudMessage: state.rasioKeuanganCrudMessage,
        rasio: state.rasio,
        keteranganRasio: state.keteranganRasio,
        rumusRasio: state.rumusRasio,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        uploadRasioKeuangan: (data) => dispatch(uploadRasioKeuangan(data)),
        getRasioKeuanganPivot: (id) => dispatch(getRasioKeuanganPivot(id)),
        getRasioKeuanganCrud: (id) => dispatch(getRasioKeuanganCrud(id)),
        addRasioKeuangan: (data) => dispatch(addRasioKeuangan(data)),
        getRasio: () => dispatch(getRasio()),
        getNamaRasio: (id) => dispatch(getNamaRasio(id)),
        getRumusRasio: (id) => dispatch(getRumusRasio(id)),
        deleletRasioKeuangan: (id) => dispatch(deleletRasioKeuangan(id)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(RasioKeuangan)
