import { combineReducers } from 'redux';
import loginReducer from './login/loginReducer';
import userReducer, { userMessageReducer } from './user/userReducer';
import usersReducer, { usersMessageReducer } from './users/usersReducer';
import izinReducer, { izinMessageReducer, izinDetailReducer } from './izin/izinReducer';
import profilUmumReducer, { profilUmumMessageReducer, profilUmumDetailReducer, profilUmumUploadReducer } from './profilUmum/profilUmumReducer';
import sahamReducer, { sahamDetailReducer, sahamMessageReducer } from './saham/sahamReducer';
import capexOpexReducer, { capexOpexDetailReducer, capexOpexMessageReducer } from './capexOpex/capexOpexReducer';
import authReducer, { forgotPassReducer, resetPassReducer } from './auth/authReducer';
import pegawaiReducer, { pegawaiDetailReducer, pegawaiMessageReducer } from './pegawai/pegawaiReducer';
import provinsiReducer, {
    kotaReducer,
    kodePosReducer,
    provinsiCoreReducer,
    kotaCoreReducer,
    kodePosCoreReducer,
    negaraReducer
} from './wilayah/wilayahReducer';
import jaberselPelangganReducer, {
    jaberselPelangganDetailReducer,
    jaberselPelangganMessageReducer
} from './jaberselPelanggan/jaberselPelangganReducer';
import jaberselPenyelenggaraReducer, {
    jaberselPenyelenggaraDetailReducer,
    jaberselPenyelenggaraMessageReducer
} from './jaberselPenyelenggara/jaberselPenyelenggaraReducer';
import jabertrunkReducer, { jabertrunkDetailReducer, jabertrunkMessageReducer } from './jabertrunk/jabertrunkReducer';
import subtabizinReducer from './subtabizin/subtabizinReducer';
import jartaplokpsReducer, { jartaplokpsDetailReducer, jartaplokpsMessageReducer, jartaplokpsJaringanReducer, jartaplokpsTeknologiReducer, teknologiItemJartaplokpsReducer } from './jartaplokps/jartaplokpsReducer';
import jartaptupPelangganReducer, { jartaptupDetailReducer, jartaptupJaringanReducer, jartaptupMessageReducer, jartaptupNetworkReducer, jartaptupTechReducer } from './jartaptup/jartaptupReducer';
import jartapsliReducer, { jartapsliDetailReducer, jartapsliMessageReducer } from './jartapsli/jartapsliReducer';
import jartapsljjReducer, { jartapsljjDetailReducer, jartapsljjMessageReducer } from './jartapsljj/jartapsljjReducer';
import jartaplokReducer, { jartaplokDetailReducer, jartaplokMessageReducer } from './jartaplok/jartaplokReducer';
import btsReducer, { btsCrudReducer, btsDetailReducer, btsMessageReducer } from './bts/btsReducer';
import nodeb3gReducer, { nodeb3gCrudReducer, nodeb3gDetailReducer, nodeb3gMessageReducer } from './nodeb3g/nodeb3gReducer';
import enodeb4gReducer, { enodeb4CrudgReducer, enodeb4gDetailReducer, enodeb4gMessageReducer } from './enodeb4g/enodeb4gReducer';
import keuanganReducer, {
    keteranganRasioReducer,
    keuanganCrudMessageReducer,
    keuanganCrudReducer,
    optionKeuanganReducer,
    rasioKeuanganCrudMessageReducer,
    rasioKeuanganCrudReducer,
    rasioKeuanganReducer,
    rasioReducer,
    rumusRasioReducer
} from './keuangan/keuanganReducer';
import {
    keteranganRasioKesehatanReducer,
    rasioKesehatanKeuanganCrudReducer,
    rasioKesehatanKeuanganMessageReducer,
    rasioKesehatanKeuanganReducer,
} from './rasioKesehatanKeuangan/rasioKesehatanKeuanganReducer';
import teknologiReducer, {
    namaTeknologiReducer,
    teknologiCrudReducer,
    teknologiIzinReducer,
    teknologiIzinTanggalReducer,
    teknologiMessageReducer,
    teknologiPenyelenggaraReducer
} from './teknologi/teknologiReducer';
import dashboardIzinReducer, { dashboardTeknologiReducer } from './dashboard/dashboardReducer';
import marketSharePendapatanReducer, { marketSharePelangganReducer, penilaianKesehatanReducer } from './marketshare/marketshareReducer';
import rasioKonversiKesehatanKeuanganReducer, { rasioKonversiKesehatanKeuanganCrudMessageReducer, rasioKonversiKesehatanKeuanganCrudReducer } from './rasioKonversiKesehatanKeuangan/rasioKonversiKesehatanKeuanganReducer';
import selulerReducer from './seluler/selulerReducer';
import reportReducer from './report/reportReducer';

const rootReducer = combineReducers({
    penilaianKesehatan: penilaianKesehatanReducer,
    report: reportReducer,
    seluler: selulerReducer,
    login: loginReducer,
    user: userReducer,
    userMessage: userMessageReducer,
    users: usersReducer,
    usersMessage: usersMessageReducer,
    dashboardIzin: dashboardIzinReducer,
    dashboardTeknologi: dashboardTeknologiReducer,
    provinsi: provinsiReducer,
    kota: kotaReducer,
    negara: negaraReducer,
    kodePos: kodePosReducer,
    provinsiCore: provinsiCoreReducer,
    kotaCore: kotaCoreReducer,
    kodePosCore: kodePosCoreReducer,
    pegawai: pegawaiReducer,
    pegawaiDetail: pegawaiDetailReducer,
    pegawaiMessage: pegawaiMessageReducer,
    izin: izinReducer,
    izinDetail: izinDetailReducer,
    izinMessage: izinMessageReducer,
    subtabIzin: subtabizinReducer,
    profilUmum: profilUmumReducer,
    profilUmumDetail: profilUmumDetailReducer,
    profilUmumMessage: profilUmumMessageReducer,
    profilUmumUpload: profilUmumUploadReducer,
    saham: sahamReducer,
    sahamDetail: sahamDetailReducer,
    sahamMessage: sahamMessageReducer,
    capexOpex: capexOpexReducer,
    capexOpexDetail: capexOpexDetailReducer,
    capexOpexMessage: capexOpexMessageReducer,
    jaberselPelanggan: jaberselPelangganReducer,
    jaberselPelangganMessage: jaberselPelangganMessageReducer,
    jaberselPelangganDetail: jaberselPelangganDetailReducer,
    jaberselPenyelenggara: jaberselPenyelenggaraReducer,
    jaberselPenyelenggaraDetail: jaberselPenyelenggaraDetailReducer,
    jaberselPenyelenggaraMessage: jaberselPenyelenggaraMessageReducer,
    jabertrunk: jabertrunkReducer,
    jabertrunkDetail: jabertrunkDetailReducer,
    jabertrunkMessage: jabertrunkMessageReducer,
    jartaplokps: jartaplokpsReducer,
    jartaplokpsTeknologi: jartaplokpsTeknologiReducer,
    teknologiItemJartaplokps: teknologiItemJartaplokpsReducer,
    jartaplokpsDetail: jartaplokpsDetailReducer,
    jartaplokpsMessage: jartaplokpsMessageReducer,
    jartaplokpsJaringan: jartaplokpsJaringanReducer,
    jartaptupPelanggan: jartaptupPelangganReducer,
    jartaptupJaringan: jartaptupJaringanReducer,
    jartaptupMessage: jartaptupMessageReducer,
    jartaptupDetail: jartaptupDetailReducer,
    jartaptupTech: jartaptupTechReducer,
    jartaptupNetwork: jartaptupNetworkReducer,
    jartapsli: jartapsliReducer,
    jartapsliMessage: jartapsliMessageReducer,
    jartapsliDetail: jartapsliDetailReducer,
    jartapsljj: jartapsljjReducer,
    jartapsljjMessage: jartapsljjMessageReducer,
    jartapsljjDetail: jartapsljjDetailReducer,
    jartaplok: jartaplokReducer,
    jartaplokMessage: jartaplokMessageReducer,
    jartaplokDetail: jartaplokDetailReducer,
    bts: btsReducer,
    btsCrud: btsCrudReducer,
    btsMessage: btsMessageReducer,
    btsDetail: btsDetailReducer,
    marketSharePendapatan: marketSharePendapatanReducer,
    marketSharePelanggan: marketSharePelangganReducer,
    auth: authReducer,
    nodeb3g: nodeb3gReducer,
    nodeb3gCrud: nodeb3gCrudReducer,
    nodeb3gMessage: nodeb3gMessageReducer,
    nodeb3gDetail: nodeb3gDetailReducer,
    enodeb4g: enodeb4gReducer,
    enodeb4gCrud: enodeb4CrudgReducer,
    enodeb4gMessage: enodeb4gMessageReducer,
    enodeb4gDetail: enodeb4gDetailReducer,
    keuangan: keuanganReducer,
    keuanganCrud: keuanganCrudReducer,
    keuanganCrudMessage: keuanganCrudMessageReducer,
    optionKeuangan: optionKeuanganReducer,
    rasioKeuangan: rasioKeuanganReducer,
    rasioKeuanganCrud: rasioKeuanganCrudReducer,
    rasioKeuanganCrudMessage: rasioKeuanganCrudMessageReducer,
    rasio: rasioReducer,
    keteranganRasio: keteranganRasioReducer,
    rumusRasio: rumusRasioReducer,
    teknologi: teknologiReducer,
    teknologiCrud: teknologiCrudReducer,
    teknologiMessage: teknologiMessageReducer,
    teknologiPenyelenggara: teknologiPenyelenggaraReducer,
    teknologiIzin: teknologiIzinReducer,
    teknologiIzinTanggal: teknologiIzinTanggalReducer,
    namaTeknologi: namaTeknologiReducer,
    rasioKesehatanKeuangan: rasioKesehatanKeuanganReducer,
    rasioKesehatanKeuanganCrud: rasioKesehatanKeuanganCrudReducer,
    rasioKesehatanKeuanganMessage: rasioKesehatanKeuanganMessageReducer,
    rasioKonversiKesehatanKeuangan: rasioKonversiKesehatanKeuanganReducer,
    rasioKonversiKesehatanKeuanganCrud: rasioKonversiKesehatanKeuanganCrudReducer,
    rasioKonversiKesehatanKeuanganCrudMessage: rasioKonversiKesehatanKeuanganCrudMessageReducer,
    keteranganRasioKesehatan: keteranganRasioKesehatanReducer,
    forgotPass: forgotPassReducer,
    resetPass: resetPassReducer,
})

export default rootReducer