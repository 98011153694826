export const PEGAWAI_REQ = 'PEGAWAI_REQ';
export const PEGAWAI_SUCCESS = 'PEGAWAI_SUCCESS';
export const PEGAWAI_FAIL = 'PEGAWAI_FAIL';

export const PEGAWAI_DETAIL_REQ = 'PEGAWAI_DETAIL_REQ';
export const PEGAWAI_DETAIL_SUCCESS = 'PEGAWAI_DETAIL_SUCCESS';
export const PEGAWAI_DETAIL_FAIL = 'PEGAWAI_DETAIL_FAIL';

export const PEGAWAI_MESSAGE_REQ = 'PEGAWAI_MESSAGE_REQ';
export const PEGAWAI_MESSAGE_SUCCESS = 'PEGAWAI_MESSAGE_SUCCESS';
export const PEGAWAI_MESSAGE_FAIL = 'PEGAWAI_MESSAGE_FAIL';