import AddNew from "./AddNew";

import { connect } from 'react-redux';
import { addUsers, getUser } from '../../redux';

const mapStateToProps = state => {
    return {
        usersMessage: state.usersMessage,
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUser: () => dispatch(getUser()),
        addUsers: (data) => dispatch(addUsers(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNew)
