import ProfilUmum from './ProfilUmum';
import { connect } from 'react-redux';
import {
    getProfilUmum,
    addProfilUmum,
    getProv,
    getKota,
    getKodePos,
    getProvCore,
    getKotaCore,
    getKodePosCore,

    deleteProfilUmum,
    uploadProfilUmum,
} from '../../redux';

const mapStateToProps = state => {
    return {
        profilUmum: state.profilUmum,
        profilUmumMessage: state.profilUmumMessage,
        provinsi: state.provinsi,
        provinsiCore: state.provinsiCore,
        kota: state.kota,
        kotaCore: state.kotaCore,
        kodePos: state.kodePos,
        kodePosCore: state.kodePosCore,
        profilUmumUpload: state.profilUmumUpload
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getProfilUmum: () => dispatch(getProfilUmum()),
        getProvinsi: () => dispatch(getProv()),
        getProvinsiCore: () => dispatch(getProvCore()),
        getKota: (data) => dispatch(getKota(data)),
        getKotaCore: (data) => dispatch(getKotaCore(data)),
        getKodePos: (data) => dispatch(getKodePos(data)),
        getKodePosCore: (data) => dispatch(getKodePosCore(data)),
        addProfilUmum: (data) => dispatch(addProfilUmum(data)),
        deleteProfilUmum: (data) => dispatch(deleteProfilUmum(data)),
        uploadProfilUmum: (data) => dispatch(uploadProfilUmum(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilUmum);