import EditJabersel from "./EditJabersel";
import { connect } from 'react-redux';
import {
    getJaberSelDetailById,
    updateJaberselPel,
    getJaberselPenyelelenggaraDetailById,
    updateJaberselPenyelenggara,
    getJabertrunkDetailById,
    updateJabertrunk,
    getjartapsliDetailById,
    updatejartapsli,
    getjartapsljjDetailById,
    updatejartapsljj,
    getjartaplokDetailById,
    updatejartaplok,
    getjartaptupPelangganDetailById,
    getjartaptupJaringanDetailById,
    getJartaptupNetwork,
    getJartaptupTech,
    updatejartaptupPelanggan,
    getjartaplokpsDetailById,
    updatejartapokps,
    getUser,
    updatejartaptupJaringan
} from '../../redux';

const mapStateToProps = state => {
    return {
        user: state.user,
        jaberselPelangganDetail: state.jaberselPelangganDetail,
        jaberselPelangganMessage: state.jaberselPelangganMessage,
        jaberselPenyelenggaraDetail: state.jaberselPenyelenggaraDetail,
        jaberselPenyelenggaraMessage: state.jaberselPenyelenggaraMessage,
        jabertrunkMessage: state.jabertrunkMessage,
        jabertrunkDetail: state.jabertrunkDetail,
        jartapsliDetail: state.jartapsliDetail,
        jartapsliMessage: state.jartapsliMessage,
        jartapsljjDetail: state.jartapsljjDetail,
        jartapsljjMessage: state.jartapsljjMessage,
        jartaplokDetail: state.jartaplokDetail,
        jartaplokMessage: state.jartaplokMessage,
        jartaptupDetail: state.jartaptupDetail,
        jartaptupMessage: state.jartaptupMessage,
        jartaptupTech: state.jartaptupTech,
        jartaptupNetwork: state.jartaptupNetwork,
        jartaplokpsMessage: state.jartaplokpsMessage,
        jartaplokpsDetail: state.jartaplokpsDetail
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUser: () => dispatch(getUser()),
        getJaberselPenyelelenggaraDetailById: (id) => dispatch(getJaberselPenyelelenggaraDetailById(id)),
        getJaberSelDetailById: (id) => dispatch(getJaberSelDetailById(id)),
        getJabertrunkDetailById: (id) => dispatch(getJabertrunkDetailById(id)),
        getjartapsliDetailById: (id) => dispatch(getjartapsliDetailById(id)),
        getjartapsljjDetailById: (id) => dispatch(getjartapsljjDetailById(id)),
        getjartaplokDetailById: (id) => dispatch(getjartaplokDetailById(id)),
        getjartaptupPelangganDetailById: (id) => dispatch(getjartaptupPelangganDetailById(id)),
        getjartaptupJaringanDetailById: (id) => dispatch(getjartaptupJaringanDetailById(id)),
        getjartaplokpsDetailById: (id) => dispatch(getjartaplokpsDetailById(id)),
        updatejartaplok: (id, data) => dispatch(updatejartaplok(id, data)),
        updateJaberselPel: (id, data) => dispatch(updateJaberselPel(id, data)),
        updateJaberselPenyelenggara: (id, data) => dispatch(updateJaberselPenyelenggara(id, data)),
        updateJabertrunk: (id, data) => dispatch(updateJabertrunk(id, data)),
        updatejartapsli: (id, data) => dispatch(updatejartapsli(id, data)),
        updatejartapsljj: (id, data) => dispatch(updatejartapsljj(id, data)),
        updatejartaptupPelanggan: (id, data) => dispatch(updatejartaptupPelanggan(id, data)),
        updatejartaptupJaringan: (id, data) => dispatch(updatejartaptupJaringan(id, data)),
        updatejartapokps: (id, data) => dispatch(updatejartapokps(id, data)),

        getJartaptupNetwork: (id) => dispatch(getJartaptupNetwork(id)),
        getJartaptupTech: () => dispatch(getJartaptupTech()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditJabersel);
