import React from 'react';
import {
    Container,
} from 'react-bootstrap';
import { Sidebar, Navbar, Spinner } from '../../components';
import { ListIzin } from '../../parts';
import { useHistory } from 'react-router-dom';


const Izin = ({
    user,
    getUser
}) => {
    const history = useHistory();
    const [loading, setLoading] = React.useState(true);
    const [userData, setUserData] = React.useState(null);

    React.useEffect(() => {
        document.title = "Izin | PPI";
    }, []);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            user && user.user && user.user.map(u => setUserData(u));
            user && user.error === "Unauthenticated." && history.push('/');
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [history, user]);

    React.useEffect(() => {
        getUser();
    }, [getUser]);

    return loading ? <Spinner /> : (
        <>
            <Sidebar menu="IZIN" />
            <div className="main-panel">
                <Navbar menu="IZIN" user={userData} />
                <div className="content">
                    <Container fluid>
                        <ListIzin />
                    </Container>
                </div>
            </div>
        </>
    )
}

export default Izin