import React from 'react';
import {
    Container,
    Button
} from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import Select from "react-select";
import Loader from 'react-loader-spinner';
import { useForm, Controller } from 'react-hook-form';

import { Sidebar, Navbar, Styles } from '../../components';
import swal from 'sweetalert';

const EditDataPenyelenggaraan = ({
    user,
    getUser,
    sahamDetail,
    getDetailSaham,
    sahamMessage,
    getNegara,
    negara,
    updateDetailSaham,
    pegawaiDetail,
    getPegawaiDetail,
    pegawaiMessage,
    updatePegawai,
    getCapexOpexDetail,
    capexOpexDetail,
    capexOpexMessage,
    updateCapexOpex,
    izinDetail,
    getIzinDetailById,
    izinMessage,
    updateIzin
}) => {
    const history = useHistory();
    const { id, nama, dataId, menu } = useParams();
    const { register, handleSubmit, control } = useForm();
    // state izin
    const [izinInputDetail, setIzinInputDetail] = React.useState(null);
    const [valuePermitName, setValuePermitName] = React.useState('');
    const [valuePermitType, setValuePermitType] = React.useState('');
    const permitName = [
        { value: 'JABERSEL', label: 'JABERSEL' },
        { value: 'JABERTRUNK', label: 'JABERTRUNK' },
        { value: 'JARTAPLOK', label: 'JARTAPLOK' },
        { value: 'JARTAPLOK PS', label: 'JARTAPLOK PS' },
        { value: 'JARTAPLOK SLI', label: 'JARTAPLOK SLI' },
        { value: 'JARTAPLOK SLJJ', label: 'JARTAPLOK SLJJ' },
        { value: 'JARTAPLOK SLJJ', label: 'JARTAPLOK SLJJ' },
        { value: 'JARTAPTUP', label: 'JARTAPTUP' },
    ];
    const permitType = [
        { value: "Penyelenggaraan", label: "Penyelenggaraan" },
        { value: 'Perubahan', label: 'Perubahan' }
    ];
    const [userData, setUserData] = React.useState(null);
    // state saham
    const [tahun, setTahun] = React.useState([]);
    const [sahamInputDetail, setSahamInputDetail] = React.useState(null);
    // state pegawai
    const [pegawaiInputDetail, setPeagawaiInputDetail] = React.useState(null);
    const [namaNegara, setNamaNegara] = React.useState([]);
    // state capex opex
    const [capexOpexInputDetail, setCapexOpexInputDetail] = React.useState(null);
    const [tipe, setTipe] = React.useState([]);
    const [load, setLoad] = React.useState(true);
    const [inputTahun, setInputTahun] = React.useState('');
    const [inputNegara, setInputNegara] = React.useState('');
    const [inputTipe, setInputTipe] = React.useState('');
    // kondisi menu
    React.useEffect(() => {
        getUser();
        if (menu === 'saham') {
            getDetailSaham(dataId);
        } else if (menu === 'pegawai') {
            getPegawaiDetail(dataId)
        } else if (menu === 'capex opex') {
            getCapexOpexDetail(dataId)
        } else if (menu === 'izin') {
            getIzinDetailById(dataId)
        }
        getNegara();
    }, [dataId, getCapexOpexDetail, getDetailSaham, getIzinDetailById, getNegara, getPegawaiDetail, getUser, menu]);
    // setup notifikasi
    React.useEffect(() => {
        user && user.user && user.user.map(u => setUserData(u));
        user && user.error === "Unauthenticated." && history.push('/');
        if (menu === 'pegawai') {
            pegawaiMessage.loading && pegawaiMessage.pegawaiMessage && swal(`${pegawaiMessage.pegawaiMessage.message}`, {
                buttons: ["cancel", true]
            }).then((value) => {
                if (value) {
                    history.goBack();
                }
            });
        } else if (menu === 'capex opex') {
            capexOpexMessage.loading && capexOpexMessage.capexOpexMessage && swal(`${capexOpexMessage.capexOpexMessage.message}`, {
                buttons: ["cancel", true]
            }).then((value) => {
                if (value) {
                    history.goBack();
                }
            });
        } else if (menu === 'izin') {
            izinMessage.loading && izinMessage.izinMessage && swal(`${izinMessage.izinMessage.message}`, {
                buttons: ["cancel", true]
            }).then((value) => {
                if (value) {
                    history.goBack();
                }
            });
        }
    }, [capexOpexMessage, history, id, menu, pegawaiMessage, izinMessage, user]);
    // setup state input yang akan di update
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            if (menu === 'saham') {
                sahamDetail.loading && sahamDetail.sahamDetail.map(sh => setSahamInputDetail({
                    id: sh.id,
                    data_umum_id: sh.data_umum_id,
                    nama: sh.nama,
                    negara: sh.negara,
                    tahun: sh.tahun,
                    nominal: sh.nominal,
                    persentase: sh.persentase,
                    tipe: sh.tipe
                }));
                negara.loading && setNamaNegara(negara.negara.map(n => ({
                    value: n.nama,
                    label: n.nama
                })));
                setTipe([
                    { value: "Publik", label: "Publik" },
                    { value: "Nasional", label: "Nasional" },
                    { value: "Asing", label: "Asing" }
                ]);
                setLoad(false);
            } else if (menu === 'pegawai') {
                pegawaiDetail.loading && setPeagawaiInputDetail(pegawaiDetail.pegawaiDetail);
                setLoad(false);
            } else if (menu === 'capex opex') {
                capexOpexDetail.loading && setCapexOpexInputDetail(capexOpexDetail.capexOpexDetail);
                setLoad(false);
            } else if (menu === 'izin') {
                izinDetail.loading && setIzinInputDetail(izinDetail.izinDetail);
                setLoad(false);
            }
        }, 2000);
        return () => clearTimeout(timeout);
    }, [sahamDetail, negara, menu, pegawaiDetail.loading, pegawaiDetail.pegawaiDetail, capexOpexDetail.loading, capexOpexDetail.capexOpexDetail, izinDetail.loading, izinDetail.izinDetail]);

    const onSubmit = (data) => {
        const input = {
            tahun: inputTahun === '' ? sahamInputDetail.tahun : `${inputTahun}`,
            company_name: nama,
            dataumum_id: id,
            nama: data.nama,
            negara: inputNegara === '' ? sahamInputDetail.negara : inputNegara,
            nominal: data.nominal,
            persentase: data.persentase,
            tipe_id: inputTipe === '' ? sahamInputDetail.tipe : inputTipe,
        }
        updateDetailSaham(dataId, input);
    }

    const onSubmitUpdatePegawai = (data) => {
        const input = {
            diploma: data.diploma,
            pria: data.pria,
            wanita: data.wanita,
            sma: data.sma,
            smp: data.smp,
            strata1: data.strata1,
            strata2: data.strata2,
            tetap: data.tetap,
            tidak_tetap: data.tidak_tetap,
            wna: data.wna,
            wni: data.wni,
            tahun: inputTahun === '' ? pegawaiInputDetail.tahun : `${inputTahun}`,
        }
        updatePegawai(dataId, input);
    }

    const onSubmitUpdateCapexOpex = (data) => {
        const input = {
            capex: data.capex,
            opex: data.opex,
            tahun: inputTahun === '' ? capexOpexInputDetail.tahun : `${inputTahun}`
        }
        updateCapexOpex(dataId, input);
    }

    const onSubmitUpdateIzin = (data) => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            permit_date: data.permit_date,
            permit_name: valuePermitName === '' ? izinInputDetail.permit_name : valuePermitName,
            permit: valuePermitType === '' ? izinInputDetail.permit : valuePermitType,
            permit_number: data.permit_number,
            regarding: data.regarding,
            tahun: inputTahun === '' ? izinInputDetail.tahun : `${inputTahun}`,
            signer: data.signer,
        }
        updateIzin(dataId, input);
    }

    React.useEffect(() => {
        setTahun(Tahun().map(t => ({ value: t, label: t })));
    }, []);

    return (
        <>
            <Sidebar menu="DATA PENYELENGGARAAN" />
            <div className="main-panel">
                <Navbar user={userData} />
                <div className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header card-header-icon" data-background-color="blue">
                                        <Styles.ButtonModal
                                            type="button"
                                            onClick={() => history.goBack()}>
                                            <i className="fa fa-chevron-circle-left fa-2x" aria-hidden="true"></i>
                                        </Styles.ButtonModal>
                                    </div>
                                    <h4 className="card-title">Edit {menu}</h4>
                                    <div className="card-content">
                                        {
                                            load ? <div className="d-flex align-content-center"><Loader type="Bars" color="#00BFFF" height={100} width={100} /></div> :
                                                menu === "saham" ? (<form className="form"
                                                    onSubmit={handleSubmit(onSubmit)}
                                                >
                                                    <Styles.InputForm>
                                                        <div className="form-group">
                                                            <label >Nama Saham</label>
                                                            <input
                                                                placeholder="Nama"
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={`${sahamInputDetail.nama}`}
                                                                {...register("nama")}
                                                            />
                                                        </div>
                                                        {sahamMessage && sahamMessage.error && <span className="text-danger">{sahamMessage.error.nama}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <div className="form-group">
                                                            <label >Negara</label>
                                                            <Controller
                                                                control={control}
                                                                name="negara"
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        options={namaNegara}
                                                                        placeholder="Pilih Negara.."
                                                                        defaultValue={
                                                                            namaNegara.filter(option => option.value = sahamInputDetail.negara)
                                                                        }
                                                                        onChange={(e) => setInputNegara(e.value)}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        {sahamMessage && sahamMessage.error && <span className="text-danger">{sahamMessage.error.negara}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <div className="form-group">
                                                            <label >Tipe Saham</label>
                                                            <Controller
                                                                control={control}
                                                                name="tipe"
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        options={tipe}
                                                                        placeholder="Tipe.."
                                                                        defaultValue={
                                                                            tipe.filter(option => option.value === sahamInputDetail.tipe)
                                                                        }
                                                                        onChange={(e) => setInputTipe(e.value)}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        {sahamMessage && sahamMessage.error && <span className="text-danger">{sahamMessage.error.tipe}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <div className="form-group">
                                                            <label >Tahun</label>
                                                            <Controller
                                                                control={control}
                                                                name="tahun"
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        options={tahun}
                                                                        placeholder="Tahun.."
                                                                        defaultValue={
                                                                            tahun.filter(option => option.value === sahamInputDetail.tahun)
                                                                        }
                                                                        onChange={(e) => setInputTahun(e.value)}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        {sahamMessage && sahamMessage.error && <span className="text-danger">{sahamMessage.error.tahun}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <div className="form-group">
                                                            <label >Nominal</label>
                                                            <input
                                                                placeholder="Nominal"
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={`${sahamInputDetail.nominal}`}
                                                                {...register("nominal")}
                                                            />
                                                        </div>
                                                        {sahamMessage && sahamMessage.error && <span className="text-danger">{sahamMessage.error.nominal}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <div className="form-group">
                                                            <label >Persentase</label>
                                                            <input
                                                                placeholder="Persentase"
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={`${sahamInputDetail.persentase}`}
                                                                {...register("persentase")}
                                                            />
                                                        </div>
                                                        {sahamMessage && sahamMessage.error && <span className="text-danger">{sahamMessage.error.persentase}</span>}
                                                    </Styles.InputForm>
                                                    <Button variant="primary" type="submit">Save changes</Button>
                                                </form>) : menu === "pegawai" ? (
                                                    <form className="form"
                                                        onSubmit={handleSubmit(onSubmitUpdatePegawai)}
                                                    >
                                                        <Styles.InputForm>
                                                            <div className="form-group">
                                                                <label >Jumlah Karyawan Tetap</label>
                                                                <input
                                                                    placeholder="Jumlah Karyawan Tetap..."
                                                                    type="text"
                                                                    className="form-control"
                                                                    defaultValue={pegawaiInputDetail.tetap}
                                                                    {...register("tetap")}
                                                                />
                                                            </div>
                                                            {pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.tetap}</span>}
                                                        </Styles.InputForm>
                                                        <Styles.InputForm>
                                                            <div className="form-group">
                                                                <label >Jumlah Karyawan Tidak Tetap</label>
                                                                <input
                                                                    placeholder="Jumlah Karyawan Tidak Tetap..."
                                                                    type="text"
                                                                    className="form-control"
                                                                    defaultValue={pegawaiInputDetail.tidak_tetap}
                                                                    {...register("tidak_tetap")}
                                                                />
                                                            </div>
                                                            {pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.tidak_tetap}</span>}
                                                        </Styles.InputForm>
                                                        <Styles.InputForm>
                                                            <div className="form-group">
                                                                <label >Jumlah Karyawan Pria</label>
                                                                <input
                                                                    placeholder="Jumlah Karyawan Pria"
                                                                    type="text"
                                                                    className="form-control"
                                                                    defaultValue={pegawaiInputDetail.pria}
                                                                    {...register("pria")}
                                                                />
                                                            </div>
                                                            {pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.pria}</span>}
                                                        </Styles.InputForm>
                                                        <Styles.InputForm>
                                                            <div className="form-group">
                                                                <label >Jumlah Karyawan Wanita</label>
                                                                <input
                                                                    placeholder="Jumlah Karyawan Wanita"
                                                                    type="text"
                                                                    className="form-control"
                                                                    defaultValue={pegawaiInputDetail.wanita}
                                                                    {...register("wanita")}
                                                                />
                                                            </div>
                                                            {pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.wanita}</span>}
                                                        </Styles.InputForm>
                                                        <Styles.InputForm>
                                                            <div className="form-group">
                                                                <label >Jumlah Karyawan Lulusan SMP</label>
                                                                <input
                                                                    placeholder="Jumlah Karyawan Lulusan SMP"
                                                                    type="text"
                                                                    className="form-control"
                                                                    defaultValue={pegawaiInputDetail.smp}
                                                                    {...register("smp")}
                                                                />
                                                            </div>
                                                            {pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.smp}</span>}
                                                        </Styles.InputForm>
                                                        <Styles.InputForm>
                                                            <div className="form-group">
                                                                <label >Jumlah Karyawan Lulusan SMA</label>
                                                                <input
                                                                    placeholder="Jumlah Karyawan Lulusan SMA"
                                                                    type="text"
                                                                    className="form-control"
                                                                    defaultValue={pegawaiInputDetail.sma}
                                                                    {...register("sma")}
                                                                />
                                                            </div>
                                                            {pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.sma}</span>}
                                                        </Styles.InputForm>
                                                        <Styles.InputForm>
                                                            <div className="form-group">
                                                                <label >Jumlah Karyawan Lulusan Diploma</label>
                                                                <input
                                                                    placeholder="Jumlah Karyawan Lulusan Diploma"
                                                                    type="text"
                                                                    className="form-control"
                                                                    defaultValue={pegawaiInputDetail.diploma}
                                                                    {...register("diploma")}
                                                                />
                                                            </div>
                                                            {pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.diploma}</span>}
                                                        </Styles.InputForm>
                                                        <Styles.InputForm>
                                                            <div className="form-group">
                                                                <label >Jumlah Karyawan Lulusan Strata 1</label>
                                                                <input
                                                                    placeholder="Jumlah Karyawan Lulusan Strata 1"
                                                                    type="text"
                                                                    className="form-control"
                                                                    defaultValue={pegawaiInputDetail.strata1}
                                                                    {...register("strata1")}
                                                                />
                                                            </div>
                                                            {pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.strata1}</span>}
                                                        </Styles.InputForm>
                                                        <Styles.InputForm>
                                                            <div className="form-group">
                                                                <label >Jumlah Karyawan Lulusan Strata 2</label>
                                                                <input
                                                                    placeholder="Jumlah Karyawan Lulusan Strata 2"
                                                                    type="text"
                                                                    className="form-control"
                                                                    defaultValue={pegawaiInputDetail.strata2}
                                                                    {...register("strata2")}
                                                                />
                                                            </div>
                                                            {pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.strata2}</span>}
                                                        </Styles.InputForm>
                                                        <Styles.InputForm>
                                                            <div className="form-group">
                                                                <label >Jumlah Karyawan Warga Negara Indonesia</label>
                                                                <input
                                                                    placeholder="Jumlah Karyawan Warga Neagara Indonesia"
                                                                    type="text"
                                                                    className="form-control"
                                                                    defaultValue={pegawaiInputDetail.wni}
                                                                    {...register("wni")}
                                                                />
                                                            </div>
                                                            {pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.wni}</span>}
                                                        </Styles.InputForm>
                                                        <Styles.InputForm>
                                                            <div className="form-group">
                                                                <label >Jumlah Karyawan Warga Negara Asing</label>
                                                                <input
                                                                    placeholder="Jumlah Karyawan Warga Neagara Asing"
                                                                    type="text"
                                                                    className="form-control"
                                                                    defaultValue={pegawaiInputDetail.wna}
                                                                    {...register("wna")}
                                                                />
                                                            </div>
                                                            {pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.wna}</span>}
                                                        </Styles.InputForm>
                                                        <Styles.InputForm>
                                                            <div className="form-group">
                                                                <label >Tahun</label>
                                                                <Controller
                                                                    control={control}
                                                                    name="tahun"
                                                                    render={({ field }) => (
                                                                        <Select
                                                                            {...field}
                                                                            options={tahun}
                                                                            placeholder="Tahun.."
                                                                            defaultValue={
                                                                                tahun.filter(option => option.value === pegawaiInputDetail.tahun)
                                                                            }
                                                                            onChange={(e) => setInputTahun(e.value)}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                            {pegawaiMessage && pegawaiMessage.error && <span className="text-danger">{pegawaiMessage.error.tahun}</span>}
                                                        </Styles.InputForm>
                                                        <Button variant="primary" type="submit">Save changes</Button>
                                                    </form>
                                                ) : menu === "capex" ? (<form className="form"
                                                    onSubmit={handleSubmit(onSubmitUpdateCapexOpex)}
                                                >
                                                    <Styles.InputForm>
                                                        <div className="form-group">
                                                            <label >Capex</label>
                                                            <input
                                                                placeholder="Capex..."
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={capexOpexInputDetail.capex}
                                                                {...register("capex")}
                                                            />
                                                        </div>
                                                        {capexOpexMessage && capexOpexMessage.error && <span className="text-danger">{capexOpexMessage.error.capex}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <div className="form-group">
                                                            <label >Opex</label>
                                                            <input
                                                                placeholder="Opex..."
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={capexOpexInputDetail.opex}
                                                                {...register("opex")}
                                                            />
                                                        </div>
                                                        {capexOpexMessage && capexOpexMessage.error && <span className="text-danger">{capexOpexMessage.error.opex}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <div className="form-group">
                                                            <label >Tahun</label>
                                                            <Controller
                                                                control={control}
                                                                name="tahun"
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        options={tahun}
                                                                        placeholder="Tahun.."
                                                                        defaultValue={
                                                                            tahun.filter(option => option.value === capexOpexInputDetail.tahun)
                                                                        }
                                                                        onChange={(e) => setInputTahun(e.value)}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        {capexOpexMessage && capexOpexMessage.error && <span className="text-danger">{capexOpexMessage.error.tahun}</span>}
                                                    </Styles.InputForm>
                                                    <Button variant="primary" type="submit">Save changes</Button>
                                                </form>) : <form className="form"
                                                    onSubmit={handleSubmit(onSubmitUpdateIzin)}
                                                >
                                                    <Styles.InputForm>
                                                        <Controller
                                                            control={control}
                                                            name="tahun"
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    options={tahun}
                                                                    placeholder="Tahun.."
                                                                    defaultValue={
                                                                        tahun.filter(option => option.value === izinInputDetail.tahun)
                                                                    }
                                                                    onChange={(e) => setInputTahun(e.value)}
                                                                />
                                                            )}
                                                        />
                                                        {izinMessage && izinMessage.error && <span className="text-danger">{izinMessage.error.tahun}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <Controller
                                                            control={control}
                                                            name="tahun"
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    options={permitType}
                                                                    placeholder="Permit.."
                                                                    defaultValue={
                                                                        permitType.filter(opt => opt.value === izinInputDetail.permit)
                                                                    }
                                                                    onChange={(e) => setValuePermitType(e.value)}
                                                                />
                                                            )}
                                                        />
                                                        {izinMessage && izinMessage.error && <span className="text-danger">{izinMessage.error.permit}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <input
                                                            placeholder="Nomor Permit..."
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue={izinInputDetail.permit_number}
                                                            {...register("permit_number")}
                                                        />
                                                        {izinMessage && izinMessage.error && <span className="text-danger">{izinMessage.error.permit_number}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <input
                                                            placeholder="Tanggal Permit..."
                                                            type="date"
                                                            className="form-control"
                                                            defaultValue={izinInputDetail.permit_date}
                                                            {...register("permit_date")}
                                                        />
                                                        {izinMessage && izinMessage.error && <span className="text-danger">{izinMessage.error.permit_date}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <Controller
                                                            control={control}
                                                            name="permit_name"
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    options={permitName}
                                                                    placeholder="Nama Permit..."
                                                                    defaultValue={
                                                                        permitName.filter(opt => opt.value === izinInputDetail.permit_name)
                                                                    }
                                                                    onChange={(e) => setValuePermitName(e.value)}
                                                                />
                                                            )}
                                                        />
                                                        {izinMessage && izinMessage.error && <span className="text-danger">{izinMessage.error.permit_name}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <input
                                                            placeholder="Regarding..."
                                                            type="text"
                                                            className="form-control mb-2"
                                                            defaultValue={izinInputDetail.regarding}
                                                            {...register("regarding")} />
                                                        {izinMessage && izinMessage.error && <span className="text-danger">{izinMessage.error.regarding}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <input
                                                            placeholder="Penandatangan..."
                                                            type="text"
                                                            className="form-control mb-2"
                                                            defaultValue={izinInputDetail.signer}
                                                            {...register("signer")} />
                                                        {izinMessage && izinMessage.error && <span className="text-danger">{izinMessage.error.signer}</span>}
                                                    </Styles.InputForm>

                                                    <Button variant="primary" type="submit">Save changes</Button>
                                                </form>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default EditDataPenyelenggaraan

const Tahun = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= 1945; i--) {
        tahun.push(i);
    }
    return tahun;
}