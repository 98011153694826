import {
    DASHBOARD_IZIN_REQ,
    DASHBOARD_IZIN_SUCCESS,
    DASHBOARD_IZIN_FAIL,
    DASHBOARD_TEKNOLOGI_REQ,
    DASHBOARD_TEKNOLOGI_SUCCESS,
    DASHBOARD_TEKNOLOGI_FAIL,
} from './dashboardType';

const dashboardIzinState = {
    loading: false,
    dashboardIzin: '',
    error: ''
}

const dashboardTeknologiState = {
    loading: false,
    dashboardTeknologi: '',
    error: ''
}

export const dashboardTeknologiReducer = (state = dashboardTeknologiState, action) => {
    switch (action.type) {
        case DASHBOARD_TEKNOLOGI_REQ:
            return {
                ...state,
                loading: true,
            }
        case DASHBOARD_TEKNOLOGI_SUCCESS:
            return {
                loading: true,
                dashboardTeknologi: action.payload,
                error: ''
            }
        case DASHBOARD_TEKNOLOGI_FAIL:
            return {
                loading: false,
                dashboardTeknologi: '',
                error: action.payload
            }
        default:
            return state
    }
}

const dashboardIzinReducer = (state = dashboardIzinState, action) => {
    switch (action.type) {
        case DASHBOARD_IZIN_REQ:
            return {
                ...state,
                loading: true,
            }
        case DASHBOARD_IZIN_SUCCESS:
            return {
                loading: true,
                dashboardIzin: action.payload,
                error: ''
            }
        case DASHBOARD_IZIN_FAIL:
            return {
                loading: false,
                dashboardIzin: '',
                error: action.payload
            }
        default:
            return state
    }
}

export default dashboardIzinReducer