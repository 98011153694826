import Jartapsli from './Jartapsli';
import { connect } from 'react-redux';
import { addJartapsli, deletejartapsli, getjartapsliByCompany, uploadJartapsli } from '../../redux';

const mapStateToProps = state => {
    return {
        jartapsli: state.jartapsli,
        jartapsliMessage: state.jartapsliMessage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        uploadJartapsli: (data) => dispatch(uploadJartapsli(data)),
        getjartapsliByCompany: (id) => dispatch(getjartapsliByCompany(id)),
        deletejartapsli: (id) => dispatch(deletejartapsli(id)),
        addJartapsli: () => dispatch(addJartapsli()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Jartapsli);