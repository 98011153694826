import React from 'react';
import DataTable from 'react-data-table-component';
import { useParams } from 'react-router';
import Loader from 'react-loader-spinner';
import ReactModal from 'react-modal';
import { ListGroup, Modal, TabContent } from 'react-bootstrap';
import Select from "react-select";
import swal from 'sweetalert';
import { useForm, Controller } from 'react-hook-form';
import Button from '@mui/material/Button';

import { Styles, ActionButton, Spinner } from '../../components';
import { Link } from 'react-router-dom';
// import { Bar } from 'react-chartjs-2';

const Jartaplokps = ({
    getJartaplokpsByCompany,
    jartaplokps,
    jartaplokpsMessage,
    addjartaplokps,
    deletejartaplokps,
    role,
    getJartaplokpsJaringanByCompany,
    jartaplokpsJaringan,
    uploadJartaplokps,
    uploadJartaplokpsJaringan,
    deletejartaplokpsJaringan,
    addjartaplokpsJaringan,
    getTeknologiJartaplokps,
    jartaplokpsTeknologi,
    teknologiItemJartaplokps,
    getTeknologiItemJartaplokps
}) => {

    const { id, nama } = useParams();
    const { register, handleSubmit, control } = useForm();
    const [loading, setLoading] = React.useState(true);
    const [jartaplokPsData, setJartaplokPsData] = React.useState([]);
    // const [dataChartValue, setDataChartValue] = React.useState([]);
    const [tahun, setTahun] = React.useState([]);
    const [jartaplokpsSelect, setJartaplokpsSelect] = React.useState([]);

    const [jartaplokPsJaringanData, setJartaplokPsJaringanData] = React.useState([]);
    const [teknologiItemState, setTeknologiItemState] = React.useState([]);
    const [fileUpload, setFileUpload] = React.useState(null);
    const [fileUploadJaringan, setFileUploadJaringan] = React.useState(null);

    // input value
    const [teknologiValue, setTeknologiValue] = React.useState('');
    const [inputTahun, setInputTahun] = React.useState('');
    const [teknologiJaringanValue, setTeknologiJaringanValue] = React.useState('');
    const [namaItemValue, setNamaItemValue] = React.useState('');

    const [showModalAddJartaplokps, setShowModalAddjartaplokps] = React.useState(false);
    const setupModalAddjartaplokps = () => setShowModalAddjartaplokps(!showModalAddJartaplokps);

    const [showModalAddJartaplokpsJaringan, setShowModalAddjartaplokpsJaringan] = React.useState(false);
    const setupModalAddjartaplokpsJaringan = () => setShowModalAddjartaplokpsJaringan(!showModalAddJartaplokpsJaringan);


    React.useEffect(() => {
        teknologiItemJartaplokps && teknologiItemJartaplokps.data && setTeknologiItemState(teknologiItemJartaplokps.data.map(t => ({ value: t.id, label: t.nama_item })))
    }, [teknologiItemJartaplokps])

    React.useEffect(() => {
        setTahun(Tahun().map(t => ({ value: t, label: t })));
    }, []);
    React.useState(() => {
        getJartaplokpsByCompany(id);
        getJartaplokpsJaringanByCompany(id);
        getTeknologiJartaplokps();
    }, [getJartaplokpsByCompany]);
    React.useEffect(() => {
        const timeout = setTimeout(() => {

            jartaplokpsJaringan.loading && jartaplokpsJaringan.jartaplokpsJaringan &&
                setJartaplokPsJaringanData(jartaplokpsJaringan.jartaplokpsJaringan.map((ps) => ({
                    nama_teknologi: ps.nama_teknologi,
                    nama_item: ps.nama_item,
                    jumlah: ps.jumlah,
                    tahun: ps.tahun,
                    action: <>
                        <ActionButton
                            title="fa fa-pencil"
                            css="btn-warning"
                        // fun={() => window.location.href = `/penyelenggara-detail-pelanggan-kapasitas-edit/${id}/${nama}/edit/${ps.id}/jartaplok ps`}
                        />
                        &nbsp;
                        <ActionButton title="fa fa-trash" css="btn-danger"
                            fun={
                                () => swal(`Apakah Yakin Data JARTAPLOK PS Jaringan ${ps.company_name} ini akan dihapus?`, {
                                    buttons: ["Batal", true],
                                }).then((value) => {
                                    if (value) {
                                        deletejartaplokpsJaringan(ps.id);
                                    }
                                })
                            }
                        />
                    </>
                })));
            jartaplokps.loading && jartaplokps.jartaplokps &&
                setJartaplokPsData(jartaplokps.jartaplokps.map((ps) => ({
                    teknologi: ps.teknologi,
                    perorangan: ps.perorangan,
                    korporasi: ps.korporasi,
                    tahun: ps.tahun,
                    action: <>
                        <ActionButton
                            title="fa fa-pencil"
                            css="btn-warning"
                            fun={() => window.location.href = `/penyelenggara-detail-pelanggan-kapasitas-edit/${id}/${nama}/edit/${ps.id}/jartaplok ps`}
                        />
                        &nbsp;
                        <ActionButton title="fa fa-trash" css="btn-danger"
                            fun={
                                () => swal(`Apakah Yakin Data JARTAPLOK PS Pelanggan ${ps.company_name} ini akan dihapus?`, {
                                    buttons: ["Batal", true],
                                }).then((value) => {
                                    if (value) {
                                        deletejartaplokps(ps.id);
                                    }
                                })
                            }
                        />
                    </>
                })));
            jartaplokpsTeknologi && jartaplokpsTeknologi.data && setJartaplokpsSelect(
                jartaplokpsTeknologi.data.map(jartech => ({
                    value: jartech.id,
                    label: jartech.nama_teknologi
                }))
            )
            setLoading(false);
        }, 3000);
        return () => clearTimeout(timeout);
    }, [deletejartaplokps, deletejartaplokpsJaringan, id, jartaplokps, jartaplokpsJaringan, jartaplokpsTeknologi, nama]);

    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none',
            },
        },
    };
    const columnsJaringan = [
        {
            name: 'No.',
            selector: (_, index) => index + 1,
        },
        {
            name: 'Teknologi',
            selector: row => row.nama_teknologi,
            minWidth: '400px'
        },
        {
            name: 'Nama Item Teknologi',
            selector: row => row.nama_item,
            minWidth: '300px'
        },
        {
            name: 'Jumlah',
            selector: row => row.jumlah,
            maxWidth: 100
        },
        {
            name: 'Tahun',
            selector: row => row.tahun,
            maxWidth: 100
        },
        {
            name: 'Action',
            selector: row => row.action,
            maxWidth: 100
        }
    ];
    const columnsJaringanGuest = [
        {
            name: 'Teknologi',
            selector: row => row.nama_teknologi,
            maxWidth: 300
        },
        {
            name: 'Nama Item Teknologi',
            selector: row => row.nama_item,
            maxWidth: 300
        },
        {
            name: 'Jumlah',
            selector: row => row.jumlah,
            maxWidth: 100
        },
        {
            name: 'Tahun',
            selector: row => row.tahun,
            maxWidth: 100
        },
    ];
    const columns = [
        {
            name: 'No.',
            selector: (_, index) => index + 1,
        },
        {
            name: 'Teknologi',
            selector: 'teknologi',
            minWidth: '400px'
        },
        {
            name: 'Perorangan',
            selector: 'perorangan',
        },
        {
            name: 'Korporasi',
            selector: 'korporasi',
        },
        {
            name: 'Tahun',
            selector: 'tahun',
        },
        {
            name: 'Action',
            selector: 'action'
        }
    ];
    const columnsGuest = [
        {
            name: 'Teknologi',
            selector: 'teknologi',
        },
        {
            name: 'Perorangan',
            selector: 'perorangan',
        },
        {
            name: 'Korporasi',
            selector: 'korporasi',
        },
        {
            name: 'Tahun',
            selector: 'tahun',
        }
    ];
    React.useEffect(() => {
        const notifikasi = () => {
            jartaplokpsMessage.loading && jartaplokpsMessage.jartaplokpsMessage && swal(`${jartaplokpsMessage.jartaplokpsMessage.message}`, {
                buttons: ["cancel", true]
            }).then((value) => {
                if (value) {
                    getJartaplokpsByCompany(id);
                    getJartaplokpsJaringanByCompany(id);
                }
            });
        };
        return notifikasi();
    }, [getJartaplokpsByCompany, getJartaplokpsJaringanByCompany, id, jartaplokpsMessage]);

    const submitJaartaplok = (data) => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            teknologi: teknologiValue,
            perorangan: data.perorangan,
            korporasi: data.korporasi,
            tahun: inputTahun
        }
        addjartaplokps(input);
    }

    const submitJartaplokpsJaringan = (data) => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            nama_teknologi: teknologiJaringanValue,
            nama_item: namaItemValue,
            jumlah: data.jumlah,
            tahun: inputTahun
        }
        console.log(input)
        addjartaplokpsJaringan(input);
    }

    const customStylesModal = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: '0',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '700px',
            height: '90%'
        },
    };

    const onUpload = (e) => setFileUpload(e.target.files[0]);

    const submitUploadJartaplokpsPelanggan = (data) => {
        const formData = new FormData();
        formData.append("nama_file", fileUpload);
        uploadJartaplokps(formData)
    };
    const onUploadJartaplokJaringan = (e) => setFileUploadJaringan(e.target.files[0]);

    const submitUploadJartaplokpsJaringan = (data) => {
        const formDataJaringan = new FormData();
        formDataJaringan.append("nama_file", fileUploadJaringan);
        uploadJartaplokpsJaringan(formDataJaringan)
    };

    return loading ? <Spinner /> : jartaplokps.error === "Unauthenticated." ? (
        window.location.href = "/") : (
        <>
            <ListGroup as="ul" className="nav nav-tabs" id="myTab" role="tablist">
                <ListGroup as="li" className="nav-item active" role="presentation">
                    <a className="nav-link active" id="kapasitas-jartaplokps-tab" data-toggle="tab" href="#kapasitas-jartaplokps" role="tab" aria-controls="kapasitas-jartaplokps" aria-selected="true">Kapasitas Pelanggan</a>
                </ListGroup>
                {/* <ListGroup as="li" className="nav-item" role="presentation">
                    <a className="nav-link" id="existing-jartaplokps-tab" data-toggle="tab" href="#existing-jartaplokps" role="tab" aria-controls="existing-jartaplokps" aria-selected="false">Existing</a>
                </ListGroup> */}
            </ListGroup>
            <TabContent>
                <div className="tab-pane active" id="kapasitas-jartaplokps" role="tabpanel" aria-labelledby="kapasitas-jartaplokps-tab">
                    {
                        role.role_id !== 3 && <div className="toolbar" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                            <Button
                                id="basic-button"
                                aria-controls="basic-menu"
                                size="large"
                                onClick={setupModalAddjartaplokps}
                                style={{ backgroundColor: 'rgba(0, 50, 122, 1)', color: '#fff', marginRight: '10px' }}
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i> &nbsp; Tambah
                            </Button>
                        </div>
                    }
                    <div className="card-content">
                        <h4 className="card-title text-center">JARTAPLOK PS Pelanggan {nama}</h4>
                        <div className="material-datatables">
                            <DataTable
                                columns={role.role_id !== 3 ? columns : columnsGuest}
                                data={jartaplokPsData}
                                progressPending={loading}
                                customStyles={customStyles}
                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100} width={100} />}
                                responsive={true}
                                pagination />
                            {/* <Bar data={dataChartjartaplokps} options={options} /> */}
                        </div>
                    </div>
                    {
                        role.role_id !== 3 && <div className="toolbar" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                            <Button
                                id="basic-button"
                                aria-controls="basic-menu"
                                size="large"
                                onClick={setupModalAddjartaplokpsJaringan}
                                style={{ backgroundColor: 'rgba(0, 50, 122, 1)', color: '#fff', marginRight: '10px' }}
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i> &nbsp; Tambah
                            </Button>
                        </div>
                    }
                    <div className="card-content">
                        <h4 className="card-title text-center">JARTAPLOK PS Jaringan {nama}</h4>
                        <div className="material-datatables">
                            <DataTable
                                columns={role.role_id !== 3 ? columnsJaringan : columnsJaringanGuest}
                                data={jartaplokPsJaringanData}
                                progressPending={loading}
                                customStyles={customStyles}
                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100} width={100} />}
                                responsive={true}
                                pagination />
                            {/* <Bar data={dataChartjartaplokps} options={options} /> */}
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="existing-jartaplokps" role="tabpanel" aria-labelledby="existing-jartaplokps-tab">
                    <p>Existing jartaplokps</p>
                </div>
            </TabContent>
            {/* modal pelanggan */}
            <ReactModal
                ariaHideApp={false}
                isOpen={showModalAddJartaplokps}
                preventScroll={true}
                scrollable={true}
                style={customStylesModal}
            >
                <div style={{ float: 'right' }}>
                    <Button id="basic-button"
                        aria-controls="basic-menu"
                        color="error"
                        onClick={setupModalAddjartaplokps}
                        size="small">
                        <span className="material-icons">
                            close
                        </span>
                    </Button>
                </div>
                <Modal.Title style={{ fontWeight: 'bold' }}>Tambah Jartaplok PS Pelanggan Baru</Modal.Title>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitUploadJartaplokpsPelanggan)}
                >
                    <h5 className="text-center">Import Data JARTAPLOK PS Pelanggan {nama}</h5>
                    <input
                        placeholder="Nama Perusahaan"
                        type="file"
                        name="nama_file"
                        onChangeCapture={onUpload}
                    />
                    <Link to="/download/jartaplok ps pelanggan.xlsx" target="_blank" style={{ float: 'right' }}>Download Template Upload JARTAPLOK PS Pelanggan</Link>
                    <br />
                    {
                        jartaplokpsMessage && jartaplokpsMessage.error && <span className="text-danger">{jartaplokpsMessage.error.nama_file}</span>
                    }
                    <br />
                    <Button type="submit" variant="contained" color="primary">Upload</Button>
                    {
                        jartaplokpsMessage && jartaplokpsMessage.error && jartaplokpsMessage.error.message && jartaplokpsMessage.error.message.map((w, index) => (
                            <ol key={index}>
                                <span className="text-danger">{index + 1}.{w}</span>
                            </ol>
                        ))
                    }
                </form>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitJaartaplok)}
                >
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="teknologi"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={jartaplokpsSelect}
                                    placeholder="Teknologi.."
                                    onChange={(e) => setTeknologiValue(e.value)}
                                />
                            )}
                        />
                    </Styles.InputForm>
                    {jartaplokpsMessage && jartaplokpsMessage.error && <span className="text-danger">{jartaplokpsMessage.error.teknologi}</span>}
                    <Styles.InputForm>
                        <input
                            placeholder="Perorangan..."
                            type="text"
                            className="form-control"
                            {...register("perorangan")}
                        />
                        {jartaplokpsMessage && jartaplokpsMessage.error && <span className="text-danger">{jartaplokpsMessage.error.perorangan}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Korporasi..."
                            type="text"
                            className="form-control"
                            {...register("korporasi")}
                        />
                        {jartaplokpsMessage && jartaplokpsMessage.error && <span className="text-danger">{jartaplokpsMessage.error.korporasi}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="tahun"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={tahun}
                                    placeholder="Tahun.."
                                    onChange={(e) => setInputTahun(e.value)}
                                />
                            )}
                        />
                        {jartaplokpsMessage && jartaplokpsMessage.error && <span className="text-danger">{jartaplokpsMessage.error.tahun}</span>}
                    </Styles.InputForm>
                    <Button color="error" variant="contained" onClick={setupModalAddjartaplokps} style={{ marginRight: 5 }}>Close</Button>
                    <Button color="primary" variant="contained" type="submit">Save</Button>
                </form>
            </ReactModal>
            {/* modal Jaringan */}
            <ReactModal
                ariaHideApp={false}
                isOpen={showModalAddJartaplokpsJaringan}
                preventScroll={true}
                scrollable={true}
                style={customStylesModal}
            >
                <div style={{ float: 'right' }}>
                    <Button id="basic-button"
                        aria-controls="basic-menu"
                        color="error"
                        onClick={setupModalAddjartaplokpsJaringan}
                        size="small">
                        <span className="material-icons">
                            close
                        </span>
                    </Button>
                </div>
                <Modal.Title style={{ fontWeight: 'bold' }}>Tambah Jartaplok PS Jaringan Baru</Modal.Title>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitUploadJartaplokpsJaringan)}
                >
                    <h5 className="text-center">Import Data JARTAPLOK PS Jaringan {nama}</h5>
                    <input
                        placeholder="Nama Perusahaan"
                        type="file"
                        name="nama_file"
                        onChangeCapture={onUploadJartaplokJaringan}
                    />
                    <Link to="/download/jartaplok ps jaringan.xlsx" target="_blank" style={{ float: 'right' }}>Download Template Upload JARTAPLOK PS Jaringan</Link>
                    <br />
                    {
                        jartaplokpsMessage && jartaplokpsMessage.error && <span className="text-danger">{jartaplokpsMessage.error.nama_file}</span>
                    }
                    <br />
                    <Button type="submit" variant="contained" color="primary">Upload</Button>
                    {
                        jartaplokpsMessage && jartaplokpsMessage.error && jartaplokpsMessage.error.message && jartaplokpsMessage.error.message.map((w, index) => (
                            <ol key={index}>
                                <span className="text-danger">{index + 1}.{w}</span>
                            </ol>
                        ))
                    }
                </form>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitJartaplokpsJaringan)}
                >
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="nama_teknologi"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={jartaplokpsSelect}
                                    placeholder="Nama Teknologi.."
                                    onChange={(e) => {
                                        getTeknologiItemJartaplokps(e.value);
                                        setTeknologiJaringanValue(e.value)
                                    }}
                                />
                            )}
                        />
                        {jartaplokpsMessage && jartaplokpsMessage.error && <span className="text-danger">{jartaplokpsMessage.error.nama_teknologi}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="nama_item"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={teknologiItemState}
                                    placeholder="Nama Item Teknologi.."
                                    onChange={(e) => setNamaItemValue(e.value)}
                                />
                            )}
                        />
                        {jartaplokpsMessage && jartaplokpsMessage.error && <span className="text-danger">{jartaplokpsMessage.error.nama_item}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Jumlah"
                            type="text"
                            className="form-control"
                            {...register("jumlah")}
                        />
                        {jartaplokpsMessage && jartaplokpsMessage.error && <span className="text-danger">{jartaplokpsMessage.error.jumlah}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="tahun"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={tahun}
                                    placeholder="Tahun.."
                                    onChange={(e) => setInputTahun(e.value)}
                                />
                            )}
                        />
                        {jartaplokpsMessage && jartaplokpsMessage.error && <span className="text-danger">{jartaplokpsMessage.error.tahun}</span>}
                    </Styles.InputForm>
                    <Button color="error" variant="contained" onClick={setupModalAddjartaplokpsJaringan} style={{ marginRight: 5 }}>Close</Button>
                    <Button color="primary" variant="contained" type="submit">Save</Button>
                </form>
            </ReactModal>
        </>
    )
}

export default Jartaplokps

const Tahun = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= 1945; i--) {
        tahun.push(i);
    }
    return tahun;
}