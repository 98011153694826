import {
    SAHAM_REQ,
    SAHAM_SUCCESS,
    SAHAM_FAIL,
    SAHAM_DETAIL_REQ,
    SAHAM_DETAIL_SUCCESS,
    SAHAM_DETAIL_FAIL,
    SAHAM_MESSAGE_REQ,
    SAHAM_MESSAGE_SUCCESS,
    SAHAM_MESSAGE_FAIL,
} from './sahamTypes'

import api from '../services';
import { config, configUpload } from '../config';

export const sahamDetailReq = () => {
    return { type: SAHAM_DETAIL_REQ }
}

export const sahamDetailSuccess = (data) => {
    return { type: SAHAM_DETAIL_SUCCESS, payload: data }
}

export const sahamDetailFail = (err) => {
    return { type: SAHAM_DETAIL_FAIL, payload: err }
}

export const sahamMessageReq = () => {
    return { type: SAHAM_MESSAGE_REQ }
}

export const sahamMessageSuccess = (data) => {
    return { type: SAHAM_MESSAGE_SUCCESS, payload: data }
}

export const sahamMessageFail = (err) => {
    return { type: SAHAM_MESSAGE_FAIL, payload: err }
}

export const sahamReq = () => {
    return { type: SAHAM_REQ }
}

export const sahamSuccess = (data) => {
    return { type: SAHAM_SUCCESS, payload: data }
}

export const sahamFail = (err) => {
    return { type: SAHAM_FAIL, payload: err }
}

export const uploadSaham = (data) => {
    return async (dispatch) => {
        dispatch(sahamMessageReq);
        await api.post(`/data-saham-create-import`, data, configUpload)
            .then(res => {
                dispatch(sahamMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(sahamMessageFail(err.response.data));
            })
    }
}

export const updateDetailSaham = (id, data) => {
    return async (dispatch) => {
        dispatch(sahamMessageReq);
        await api.put(`/data-saham-update/${id}`, data, config)
            .then(res => {
                dispatch(sahamMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(sahamMessageFail(err.response.data));
            })
    }
}

export const getDetailSaham = (id) => {
    return async (dispatch) => {
        dispatch(sahamDetailReq);
        await api.get(`/data-saham/${id}`, config)
            .then(res => {
                dispatch(sahamDetailSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(sahamDetailFail(err.response.status));
            })
    }
}

export const addSaham = (data) => {
    return async (dispatch) => {
        dispatch(sahamMessageReq);
        await api.post(`/data-saham-create`, data, config)
            .then(res => {
                dispatch(sahamMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(sahamMessageFail(err.response.data));
            })
    }
}

export const getSaham = (id) => {
    return async (dispatch) => {
        dispatch(sahamReq);
        await api.get(`/data-saham-company/${id}`, config)
            .then(res => {
                dispatch(sahamSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(sahamFail(err.response.status));
            })
    }
}

export const deleteSaham = (id) => {
    return async (dispatch) => {
        dispatch(sahamMessageReq);
        await api.delete(`/data-saham-delete/${id}`, config)
            .then(res => {
                dispatch(sahamMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(sahamMessageFail(err.response.status));
            })
    }
}