import Keuangan from './Keuangan';
import { connect } from 'react-redux';
import {
    addkeuangan,
    getKeuanganCrud,
    getKeuanganPivot,
    getOptionKeuangan,
    deleletkeuangan,
    uploadKeuanganUmum
} from '../../redux';
// import getRasioKesehatanKeuanganPivot from '../../redux/rasioKesehatanKeuangan/rasioKesehatanKeuanganAction';

const mapStateToProps = state => {
    return {
        keuangan: state.keuangan,
        keuanganCrud: state.keuanganCrud,
        keuanganCrudMessage: state.keuanganCrudMessage,
        optionKeuangan: state.optionKeuangan,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        uploadKeuanganUmum: (data) => dispatch(uploadKeuanganUmum(data)),
        getKeuanganPivot: (id) => dispatch(getKeuanganPivot(id)),
        getKeuanganCrud: (id) => dispatch(getKeuanganCrud(id)),
        addkeuangan: (data) => dispatch(addkeuangan(data)),
        deleletkeuangan: (id) => dispatch(deleletkeuangan(id)),
        getOptionKeuangan: () => dispatch(getOptionKeuangan()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Keuangan);