import {
    CAPEXOPEX_REQ,
    CAPEXOPEX_SUCCESS,
    CAPEXOPEX_FAIL,
    CAPEXOPEX_DETAIL_REQ,
    CAPEXOPEX_DETAIL_SUCCESS,
    CAPEXOPEX_DETAIL_FAIL,
    CAPEXOPEX_MESSAGE_REQ,
    CAPEXOPEX_MESSAGE_SUCCESS,
    CAPEXOPEX_MESSAGE_FAIL,
} from './capexOpexTypes'

import api from '../services';
import { config } from '../config';

export const capexOpexDetailReq = () => {
    return { type: CAPEXOPEX_DETAIL_REQ }
}

export const capexOpexDetailSuccess = (data) => {
    return { type: CAPEXOPEX_DETAIL_SUCCESS, payload: data }
}

export const capexOpexDetailFail = (err) => {
    return { type: CAPEXOPEX_DETAIL_FAIL, payload: err }
}

export const capexOpexReq = () => {
    return { type: CAPEXOPEX_REQ }
}

export const capexOpexSuccess = (data) => {
    return { type: CAPEXOPEX_SUCCESS, payload: data }
}

export const capexOpexFail = (err) => {
    return { type: CAPEXOPEX_FAIL, payload: err }
}

export const capexOpexMessageReq = () => {
    return { type: CAPEXOPEX_MESSAGE_REQ }
}

export const capexOpexMessageSuccess = (data) => {
    return { type: CAPEXOPEX_MESSAGE_SUCCESS, payload: data }
}

export const capexOpexMessageFail = (err) => {
    return { type: CAPEXOPEX_MESSAGE_FAIL, payload: err }
}

export const uploadCapexOpex = (data) => {
    return async (dispatch) => {
        dispatch(capexOpexMessageReq);
        await api.post(`/data-capex-opex-create-import`, data, config)
            .then(res => {
                dispatch(capexOpexMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(capexOpexMessageFail(err.response.data))
            })
    }
}

export const getCapexOpexDetail = (id) => {
    return async (dispatch) => {
        dispatch(capexOpexDetailReq);
        await api.get(`/data-capex-opex/${id}`, config)
            .then(res => {
                dispatch(capexOpexDetailSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(capexOpexDetailFail(err.response.data));
            })
    }
}

export const getCapexOpex = (company_id) => {
    return async (dispatch) => {
        dispatch(capexOpexReq);
        await api.get(`/data-capex-opex-company/${company_id}`, config)
            .then(res => {
                dispatch(capexOpexSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(capexOpexFail(err.response.data));
            })
    }
}

export const updateCapexOpex = (id, data) => {
    return async (dispatch) => {
        dispatch(capexOpexMessageReq);
        await api.put(`/data-capex-opex-update/${id}`, data, config)
            .then(res => {
                dispatch(capexOpexMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(capexOpexMessageFail(err.response.data))
            })
    }
}

export const deleteCapexOpex = (id) => {
    return async (dispatch) => {
        dispatch(capexOpexMessageReq);
        await api.delete(`/data-capex-opex-delete/${id}`, config)
            .then(res => {
                dispatch(capexOpexMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(capexOpexMessageFail(err.response.data))
            })
    }
}

export const addCapexOpex = (data) => {
    return async (dispatch) => {
        dispatch(capexOpexMessageReq);
        await api.post('/data-capex-opex-create', data, config)
            .then(res => {
                dispatch(capexOpexMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(capexOpexMessageFail(err.response.data))
            })
    }
}