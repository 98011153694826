import React from 'react';
import {
    Container, Spinner,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Sidebar, Navbar, } from '../../components';
import { SectionProfil } from '../../parts';

const Profil = ({
    user,
    getUser
}) => {
    const history = useHistory()
    const [loading, setLoading] = React.useState(false);
    const [userData, setUserData] = React.useState(null);

    React.useEffect(() => {
        document.title = "Dashboard | PPI"
    }, []);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            user && user.user && user.user.map(u => setUserData(u));
            user && user.error === "Unauthenticated." && history.push('/');
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [history, user]);

    React.useEffect(() => {
        getUser();
    }, [getUser]);
    return loading ? <Spinner /> : (<>
        <Sidebar menu="PROFIL" />
        <div className="main-panel">
            <Navbar user={userData} />
            <div className="content">
                <Container fluid>
                    <SectionProfil />
                </Container>
            </div>
        </div>
    </>
    )
}

export default Profil
