export const KEUANGAN_REQ = 'KEUANGAN_REQ';
export const KEUANGAN_SUCCESS = 'KEUANGAN_SUCCESS';
export const KEUANGAN_FAIL = 'KEUANGAN_FAIL';

export const OPTION_KEUANGAN_REQ = 'OPTION_KEUANGAN_REQ';
export const OPTION_KEUANGAN_SUCCESS = 'OPTION_KEUANGAN_SUCCESS';
export const OPTION_KEUANGAN_FAIL = 'OPTION_KEUANGAN_FAIL';

export const KEUANGAN_CRUD_REQ = 'KEUANGAN_CRUD_REQ';
export const KEUANGAN_CRUD_SUCCESS = 'KEUANGAN_CRUD_SUCCESS';
export const KEUANGAN_CRUD_FAIL = 'KEUANGAN_CRUD_FAIL';

export const KEUANGAN_CRUD_MESSAGE_REQ = 'KEUANGAN_CRUD_MESSAGE_REQ';
export const KEUANGAN_CRUD_MESSAGE_SUCCESS = 'KEUANGAN_CRUD_MESSAGE_SUCCESS';
export const KEUANGAN_CRUD_MESSAGE_FAIL = 'KEUANGAN_CRUD_MESSAGE_FAIL';

export const RASIO_KEUANGAN_REQ = 'RASIO_KEUANGAN_REQ';
export const RASIO_KEUANGAN_SUCCESS = 'RASIO_KEUANGAN_SUCCESS';
export const RASIO_KEUANGAN_FAIL = 'RASIO_KEUANGAN_FAIL';

export const RASIO_KEUANGAN_CRUD_REQ = 'RASIO_KEUANGAN_CRUD_REQ';
export const RASIO_KEUANGAN_CRUD_SUCCESS = 'RASIO_KEUANGAN_CRUD_SUCCESS';
export const RASIO_KEUANGAN_CRUD_FAIL = 'RASIO_KEUANGAN_CRUD_FAIL';

export const RASIO_KEUANGAN_CRUD_MESSAGE_REQ = 'RASIO_KEUANGAN_CRUD_MESSAGE_REQ';
export const RASIO_KEUANGAN_CRUD_MESSAGE_SUCCESS = 'RASIO_KEUANGAN_CRUD_MESSAGE_SUCCESS';
export const RASIO_KEUANGAN_CRUD_MESSAGE_FAIL = 'RASIO_KEUANGAN_CRUD_MESSAGE_FAIL';

export const RASIO_REQ = 'RASIO_REQ';
export const RASIO_SUCCESS = 'RASIO_SUCCESS';
export const RASIO_FAIL = 'RASIO_FAIL';

export const KETERANGAN_RASIO_REQ = 'KETERANGAN_RASIO_REQ';
export const KETERANGAN_RASIO_SUCCESS = 'KETERANGAN_RASIO_SUCCESS';
export const KETERANGAN_RASIO_FAIL = 'KETERANGAN_RASIO_FAIL';

export const RUMUS_RASIO_REQ = 'RUMUS_RASIO_REQ';
export const RUMUS_RASIO_SUCCESS = 'RUMUS_RASIO_SUCCESS';
export const RUMUS_RASIO_FAIL = 'RUMUS_RASIO_FAIL';