import React from 'react';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { Sidebar, Navbar, Spinner } from '../../components';

const JartaplokSljj = ({
    jartapsljj,
    getAlljartapsljj,
    user,
    getUser
}) => {
    const history = useHistory();
    const [rows, setRows] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [userData, setUserData] = React.useState([])
    React.useEffect(() => {
        document.title = 'Jartaplok SLJJ | PPI'
    }, []);
    React.useEffect(() => {
        getUser();
        getAlljartapsljj();
    }, [getAlljartapsljj, getUser]);
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            user && user.user && user.user.map(u => setUserData(u));
            user && user.error === "Unauthenticated." && history.push('/');
            jartapsljj && jartapsljj.jartapsljj &&
                setRows(jartapsljj.jartapsljj.map((jbt) => ({
                    company_name: jbt.company_name,
                    keterangan: jbt.keterangan,
                    outgoing: jbt.outgoing,
                    incoming: jbt.incoming,
                    tahun: jbt.tahun
                })));
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [history, jartapsljj, user]);
    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { minimumFractionDigits: 0 }
        ).format(money);
    }
    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none'
            },
        },
    };
    const columns = [
        {
            name: 'Nama Perusahaan',
            selector: 'company_name'
        },
        {
            name: 'Keterangan',
            selector: r => r.keterangan
        },
        {
            name: 'Outgoing',
            selector: r => formatRupiah(r.outgoing),
        },
        {
            name: 'Incoming',
            selector: r => formatRupiah(r.incoming),
        },
        {
            name: 'Tahun',
            selector: r => r.tahun
        },
    ];
    return loading ? <Spinner /> : (
        <>
            <Sidebar menu="JARTAPLOK SLJJ" />
            <div className="main-panel">
                <Navbar user={userData} />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="toolbar" style={{ marginTop: 50, marginBottom: 100 }}>
                                        <div className="card-header card-header-icon" data-background-color="purple"
                                        //  onClick={() => exportCsv()}
                                        >
                                            <i className="fa fa-file-excel-o fa-2x" aria-hidden="true"></i>
                                        </div>
                                        <div className="card-header card-header-icon" data-background-color="blue">
                                            <i className="fa fa-print fa-2x" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div className="card-content">
                                        <div className="material-datatables">
                                            <DataTable
                                                columns={columns}
                                                data={rows}
                                                customStyles={customStyles}
                                                progressPending={loading}
                                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100}
                                                    width={100} />}
                                                responsive={true}
                                                pagination />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default JartaplokSljj
