export const NODEB3G_REQ = 'NODEB3G_REQ';
export const NODEB3G_SUCCESS = 'NODEB3G_SUCCESS';
export const NODEB3G_FAIL = 'NODEB3G_FAIL';
export const NODEB3G_CRUD_REQ = 'NODEB3G_CRUD_REQ';
export const NODEB3G_CRUD_SUCCESS = 'NODEB3G_CRUD_SUCCESS';
export const NODEB3G_CRUD_FAIL = 'NODEB3G_CRUD_FAIL';
export const NODEB3G_DETAIL_REQ = 'NODEB3G_DETAIL_REQ';
export const NODEB3G_DETAIL_SUCCESS = 'NODEB3G_DETAIL_SUCCESS';
export const NODEB3G_DETAIL_FAIL = 'NODEB3G_DETAIL_FAIL';
export const NODEB3G_MESSAGE_REQ = 'NODEB3G_MESSAGE_REQ';
export const NODEB3G_MESSAGE_SUCCESS = 'NODEB3G_MESSAGE_SUCCESS';
export const NODEB3G_MESSAGE_FAIL = 'NODEB3G_MESSAGE_FAIL';