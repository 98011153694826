import {
    JABERSEL_PENYELENGGARA_REQ,
    JABERSEL_PENYELENGGARA_SUCCESS,
    JABERSEL_PENYELENGGARA_FAIL,
    JABERSEL_PENYELENGGARA_MESSAGE_REQ,
    JABERSEL_PENYELENGGARA_MESSAGE_SUCCESS,
    JABERSEL_PENYELENGGARA_MESSAGE_FAIL,
    JABERSEL_PENYELENGGARA_DETAIL_REQ,
    JABERSEL_PENYELENGGARA_DETAIL_SUCCESS,
    JABERSEL_PENYELENGGARA_DETAIL_FAIL,
} from './jaberselPenyelenggaraTypes';

const jaberselPenyelenggaraDetailState = {
    loading: false,
    jaberselPenyelenggaraDetail: null,
    error: ''
}

const jaberselPenyelanggaraMessageState = {
    loading: false,
    jaberselPenyelanggaraMessage: '',
    error: ''
}

const jaberselPenyelenggaraState = {
    loading: false,
    jaberselPenyelanggara: [],
    error: ''
}

export const jaberselPenyelenggaraDetailReducer = (state = jaberselPenyelenggaraDetailState, action) => {
    switch (action.type) {
        case JABERSEL_PENYELENGGARA_DETAIL_REQ:
            return {
                ...state,
                loading: false
            }
        case JABERSEL_PENYELENGGARA_DETAIL_SUCCESS:
            return {
                loading: true,
                jaberselPenyelenggaraDetail: action.payload,
                error: ''
            }
        case JABERSEL_PENYELENGGARA_DETAIL_FAIL:
            return {
                loading: true,
                jaberselPenyelenggaraDetail: null,
                error: action.payload
            }
        default:
            return state
    }
}

export const jaberselPenyelenggaraMessageReducer = (state = jaberselPenyelanggaraMessageState, action) => {
    switch (action.type) {
        case JABERSEL_PENYELENGGARA_MESSAGE_REQ:
            return {
                ...state,
                loading: false,
            }
        case JABERSEL_PENYELENGGARA_MESSAGE_SUCCESS:
            return {
                loading: true,
                jaberselPenyelenggaraMessage: action.payload,
                error: ''
            }
        case JABERSEL_PENYELENGGARA_MESSAGE_FAIL:
            return {
                loading: true,
                jaberselPenyelenggaraMessage: "",
                error: action.payload
            }
        default:
            return state
    }
}

const jaberselPenyelenggaraReducer = (state = jaberselPenyelenggaraState, action) => {
    switch (action.type) {
        case JABERSEL_PENYELENGGARA_REQ:
            return {
                ...state,
                loading: false,
            }
        case JABERSEL_PENYELENGGARA_SUCCESS:
            return {
                loading: true,
                jaberselPenyelenggara: action.payload,
                error: ''
            }
        case JABERSEL_PENYELENGGARA_FAIL:
            return {
                loading: true,
                jaberselPenyelenggara: null,
                error: action.payload
            }
        default:
            return state
    }
}

export default jaberselPenyelenggaraReducer