import {
    TEKNOLOGI_REQ,
    TEKNOLOGI_SUCCESS,
    TEKNOLOGI_FAIL,
    TEKNOLOGI_MESSAGE_REQ,
    TEKNOLOGI_MESSAGE_SUCCESS,
    TEKNOLOGI_MESSAGE_FAIL,
    TEKNOLOGI_DETAIL_REQ,
    TEKNOLOGI_DETAIL_SUCCESS,
    TEKNOLOGI_DETAIL_FAIL,
    TEKNOLOGI_UPLOAD_REQ,
    TEKNOLOGI_UPLOAD_SUCCESS,
    TEKNOLOGI_UPLOAD_FAIL,

    TEKNOLOGI_CRUD_REQ,
    TEKNOLOGI_CRUD_SUCCESS,
    TEKNOLOGI_CRUD_FAIL,

    TEKNOLOGI_PENYELENGGARA_REQ,
    TEKNOLOGI_PENYELENGGARA_SUCCESS,
    TEKNOLOGI_PENYELENGGARA_FAIL,

    TEKNOLOGI_IZIN_REQ,
    TEKNOLOGI_IZIN_SUCCESS,
    TEKNOLOGI_IZIN_FAIL,

    TEKNOLOGI_IZIN_TANGGAL_REQ,
    TEKNOLOGI_IZIN_TANGGAL_SUCCESS,
    TEKNOLOGI_IZIN_TANGGAL_FAIL,

    NAMA_TEKNOLOGI_REQ,
    NAMA_TEKNOLOGI_SUCCESS,
    NAMA_TEKNOLOGI_FAIL,
} from './teknologiTypes';

const namaTeknologiState = {
    loading: false,
    namaTeknologi: [],
    error: ''
}

export const namaTeknologiReducer = (state = namaTeknologiState, action) => {
    switch (action.type) {
        case NAMA_TEKNOLOGI_REQ:
            return {
                ...state,
                loading: false,
            }
        case NAMA_TEKNOLOGI_SUCCESS:
            return {
                loading: true,
                namaTeknologi: action.payload,
                error: ''
            }
        case NAMA_TEKNOLOGI_FAIL:
            return {
                loading: false,
                namaTeknologi: [],
                error: action.payload
            }
        default:
            return state
    }
}

const teknologiUploadState = {
    loading: false,
    teknologiUpload: "",
    error: ""
}

const teknologiDetailState = {
    loading: false,
    teknologiDetail: [],
    error: ''
}

const teknologiState = {
    loading: false,
    teknologi: [],
    error: ''
}

const teknologiCrudState = {
    loading: false,
    teknologiCrud: [],
    error: ''
}

const teknologiMessageState = {
    loading: false,
    teknologiMessage: '',
    error: ''
}

const teknologiPenyelenggaraState = {
    loading: false,
    teknologiPenyelenggara: [],
    error: ''
}

const teknologiIzinState = {
    loading: false,
    teknologiIzin: [],
    error: ''
}

const teknologiIzinTanggalState = {
    loading: false,
    teknologiIzinTanggal: [],
    error: ''
}

export const teknologiIzinTanggalReducer = (state = teknologiIzinTanggalState, action) => {
    switch (action.type) {
        case TEKNOLOGI_IZIN_TANGGAL_REQ:
            return {
                ...state,
                loading: false,
            }
        case TEKNOLOGI_IZIN_TANGGAL_SUCCESS:
            return {
                loading: true,
                teknologiIzinTanggal: action.payload,
                error: ''
            }
        case TEKNOLOGI_IZIN_TANGGAL_FAIL:
            return {
                loading: false,
                teknologiIzinTanggal: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const teknologiIzinReducer = (state = teknologiIzinState, action) => {
    switch (action.type) {
        case TEKNOLOGI_IZIN_REQ:
            return {
                ...state,
                loading: false,
            }
        case TEKNOLOGI_IZIN_SUCCESS:
            return {
                loading: true,
                teknologiIzin: action.payload,
                error: ''
            }
        case TEKNOLOGI_IZIN_FAIL:
            return {
                loading: false,
                teknologiIzin: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const teknologiPenyelenggaraReducer = (state = teknologiPenyelenggaraState, action) => {
    switch (action.type) {
        case TEKNOLOGI_PENYELENGGARA_REQ:
            return {
                ...state,
                loading: false,
            }
        case TEKNOLOGI_PENYELENGGARA_SUCCESS:
            return {
                loading: true,
                teknologiPenyelenggara: action.payload,
                error: ''
            }
        case TEKNOLOGI_PENYELENGGARA_FAIL:
            return {
                loading: false,
                teknologiPenyelenggara: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const teknologiCrudReducer = (state = teknologiCrudState, action) => {
    switch (action.type) {
        case TEKNOLOGI_CRUD_REQ:
            return {
                ...state,
                loading: false,
            }
        case TEKNOLOGI_CRUD_SUCCESS:
            return {
                loading: true,
                teknologiCrud: action.payload,
                error: ''
            }
        case TEKNOLOGI_CRUD_FAIL:
            return {
                loading: false,
                teknologiCrud: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const teknologiUploadReducer = (state = teknologiUploadState, action) => {
    switch (action.type) {
        case TEKNOLOGI_UPLOAD_REQ:
            return {
                ...state,
                loading: false,
            }
        case TEKNOLOGI_UPLOAD_SUCCESS:
            return {
                loading: true,
                teknologiUpload: action.payload,
                error: ''
            }
        case TEKNOLOGI_UPLOAD_FAIL:
            return {
                loading: true,
                teknologiUpload: '',
                error: action.payload
            }
        default:
            return state
    }
}

export const teknologiDetailReducer = (state = teknologiDetailState, action) => {
    switch (action.type) {
        case TEKNOLOGI_DETAIL_REQ:
            return {
                ...state,
                loading: false,
            }
        case TEKNOLOGI_DETAIL_SUCCESS:
            return {
                loading: true,
                teknologiDetail: action.payload,
                error: ''
            }
        case TEKNOLOGI_DETAIL_FAIL:
            return {
                loading: true,
                teknologiDetail: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const teknologiMessageReducer = (state = teknologiMessageState, action) => {
    switch (action.type) {
        case TEKNOLOGI_MESSAGE_REQ:
            return {
                ...state,
                loading: false,
            }
        case TEKNOLOGI_MESSAGE_SUCCESS:
            return {
                loading: true,
                teknologiMessage: action.payload,
                error: ''
            }
        case TEKNOLOGI_MESSAGE_FAIL:
            return {
                loading: false,
                teknologiMessage: '',
                error: action.payload
            }
        default:
            return state
    }
}

const teknologiReducer = (state = teknologiState, action) => {
    switch (action.type) {
        case TEKNOLOGI_REQ:
            return {
                ...state,
                loading: false,
            }
        case TEKNOLOGI_SUCCESS:
            return {
                loading: true,
                teknologi: action.payload,
                error: ''
            }
        case TEKNOLOGI_FAIL:
            return {
                loading: false,
                teknologi: [],
                error: action.payload
            }
        default:
            return state
    }
}

export default teknologiReducer