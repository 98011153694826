import EditNodeb3g from './EditNodeb3g';
import { connect } from 'react-redux';
import {
    getnodeb3gDetailById,
    getProv,
    getUser,
    updatenodeb3g,
} from '../../redux';

const mapStateToProps = state => {
    return {
        nodeb3gDetail: state.nodeb3gDetail,
        user: state.user,
        nodeb3gMessage: state.nodeb3gMessage,
        provinsi: state.provinsi
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getnodeb3gDetailById: (id) => dispatch(getnodeb3gDetailById(id)),
        updatenodeb3g: (id, data) => dispatch(updatenodeb3g(id, data)),
        getUser: () => dispatch(getUser()),
        getProvinsi: () => dispatch(getProv()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditNodeb3g);
