import {
    KEUANGAN_REQ,
    KEUANGAN_SUCCESS,
    KEUANGAN_FAIL,
    KEUANGAN_CRUD_REQ,
    KEUANGAN_CRUD_SUCCESS,
    KEUANGAN_CRUD_FAIL,
    KEUANGAN_CRUD_MESSAGE_REQ,
    KEUANGAN_CRUD_MESSAGE_SUCCESS,
    KEUANGAN_CRUD_MESSAGE_FAIL,
    OPTION_KEUANGAN_REQ,
    OPTION_KEUANGAN_SUCCESS,
    OPTION_KEUANGAN_FAIL,

    RASIO_KEUANGAN_REQ,
    RASIO_KEUANGAN_SUCCESS,
    RASIO_KEUANGAN_FAIL,
    RASIO_KEUANGAN_CRUD_REQ,
    RASIO_KEUANGAN_CRUD_SUCCESS,
    RASIO_KEUANGAN_CRUD_FAIL,
    RASIO_KEUANGAN_CRUD_MESSAGE_REQ,
    RASIO_KEUANGAN_CRUD_MESSAGE_SUCCESS,
    RASIO_KEUANGAN_CRUD_MESSAGE_FAIL,
    RASIO_REQ,
    RASIO_SUCCESS,
    RASIO_FAIL,
    KETERANGAN_RASIO_REQ,
    KETERANGAN_RASIO_SUCCESS,
    KETERANGAN_RASIO_FAIL,
    RUMUS_RASIO_REQ,
    RUMUS_RASIO_SUCCESS,
    RUMUS_RASIO_FAIL,

} from './keuanganTypes'

import api from '../services';
import { config, configUpload } from '../config';

// rasio keuangan
export const keteranganRasioReq = () => {
    return { type: KETERANGAN_RASIO_REQ }
}

export const keteranganRasioSuccess = (data) => {
    return { type: KETERANGAN_RASIO_SUCCESS, payload: data }
}
export const keteranganRasioFail = (err) => {
    return { type: KETERANGAN_RASIO_FAIL, payload: err }
}

export const rumusRasioReq = () => {
    return { type: RUMUS_RASIO_REQ }
}

export const rumusRasioSuccess = (data) => {
    return { type: RUMUS_RASIO_SUCCESS, payload: data }
}
export const rumusRasioFail = (err) => {
    return { type: RUMUS_RASIO_FAIL, payload: err }
}
export const rasioReq = () => {
    return { type: RASIO_REQ }
}

export const rasioSuccess = (data) => {
    return { type: RASIO_SUCCESS, payload: data }
}
export const rasioFail = (err) => {
    return { type: RASIO_FAIL, payload: err }
}

export const rasioKeuanganCrudMessageReq = () => {
    return { type: RASIO_KEUANGAN_CRUD_MESSAGE_REQ }
}

export const rasioKeuanganCrudMessageSuccess = (data) => {
    return { type: RASIO_KEUANGAN_CRUD_MESSAGE_SUCCESS, payload: data }
}

export const rasioKeuanganCrudMessageFail = (err) => {
    return { type: RASIO_KEUANGAN_CRUD_MESSAGE_FAIL, payload: err }
}

export const rasioKeuanganCrudReq = () => {
    return { type: RASIO_KEUANGAN_CRUD_REQ }
}

export const rasioKeuanganCrudSuccess = (data) => {
    return { type: RASIO_KEUANGAN_CRUD_SUCCESS, payload: data }
}

export const rasioKeuanganCrudFail = (err) => {
    return { type: RASIO_KEUANGAN_CRUD_FAIL, payload: err }
}

export const rasioKeuanganReq = () => {
    return { type: RASIO_KEUANGAN_REQ }
}

export const rasioKeuanganSuccess = (data) => {
    return { type: RASIO_KEUANGAN_SUCCESS, payload: data }
}

export const rasioKeuanganFail = (err) => {
    return { type: RASIO_KEUANGAN_FAIL, payload: err }
}

export const uploadRasioKeuangan = (data) => {
    return async (dispatch) => {
        dispatch(rasioKeuanganCrudMessageReq);
        await api.post(`/rasio-keuangan-create-import`, data, configUpload)
            .then(res => {
                dispatch(rasioKeuanganCrudMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(rasioKeuanganCrudMessageFail(err.response.data));
            })
    }
}

export const updateRasioKeuangan = (id, data) => {
    return async (dispatch) => {
        dispatch(rasioKeuanganCrudMessageReq);
        await api.put(`/rasio-keuangan-update/${id}`, data, config)
            .then(res => dispatch(rasioKeuanganCrudMessageSuccess(res.data)))
            .catch(err => dispatch(rasioKeuanganCrudMessageFail(err.response.data)))
    }
}

export const getRasioKeuanganDetail = (id) => {
    return async (dispatch) => {
        dispatch(rasioKeuanganReq);
        await api.get(`/rasio-keuangan/${id}`, config)
            .then(res => {
                dispatch(rasioKeuanganSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(rasioKeuanganFail(err.response.data));
            })
    }
}

export const deleletRasioKeuangan = (id) => {
    return async (dispatch) => {
        dispatch(rasioKeuanganCrudMessageReq);
        await api.delete(`/rasio-keuangan-delete/${id}`, config)
            .then(res => {
                dispatch(rasioKeuanganCrudMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(rasioKeuanganCrudMessageReq(err.response.data));
            })
    }
}

export const getRumusRasio = (id) => {
    return async (dispatch) => {
        dispatch(rumusRasioReq);
        await api.get(`/nama-rumus-rasio/${id}`, config)
            .then(res => dispatch(rumusRasioSuccess(res.data.data)))
            .catch(err => dispatch(rumusRasioFail(err.response.data)))
    }
}

export const getNamaRasio = (id) => {
    return async (dispatch) => {
        dispatch(keteranganRasioReq);
        await api.get(`/nama-rasio/${id}`, config)
            .then(res => dispatch(keteranganRasioSuccess(res.data.data)))
            .catch(err => dispatch(keteranganRasioFail(err.response.data)))
    }
}

export const getRasio = () => {
    return async (dispatch) => {
        dispatch(rasioReq);
        await api.get('/nama-keterangan-rasio', config)
            .then(res => dispatch(rasioSuccess(res.data.data)))
            .catch(err => dispatch(rasioFail(err.response.data)))
    }
}

export const addRasioKeuangan = (data) => {
    return async (dispatch) => {
        dispatch(rasioKeuanganCrudMessageReq);
        await api.post('/rasio-keuangan-create', data, config)
            .then(res => {
                dispatch(rasioKeuanganCrudMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(rasioKeuanganCrudMessageFail(err.response.data));
            })
    }
}

export const getRasioKeuanganCrud = (company_id) => {
    return async (dispatch) => {
        dispatch(rasioKeuanganCrudReq);
        await api.get(`/rasio-keuangan-company/${company_id}`, config)
            .then(res => {
                dispatch(rasioKeuanganCrudSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(rasioKeuanganCrudFail(err.response.data));
            })
    }
}

export const getRasioKeuanganPivot = (company_id) => {
    return async (dispatch) => {
        dispatch(rasioKeuanganReq);
        await api.get(`/rasio-keuangan-pivot/${company_id}`, config)
            .then(res => {
                dispatch(rasioKeuanganSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(rasioKeuanganFail(err.response.data));
            })
    }
}

// keuangan
export const optionKeuanganReq = () => {
    return { type: OPTION_KEUANGAN_REQ }
}

export const optionKeuanganSuccess = (data) => {
    return { type: OPTION_KEUANGAN_SUCCESS, payload: data }
}

export const optionKeuanganFail = (err) => {
    return { type: OPTION_KEUANGAN_FAIL, payload: err }
}

export const keuanganCrudMessageReq = () => {
    return { type: KEUANGAN_CRUD_MESSAGE_REQ }
}

export const keuanganCrudMessageSuccess = (data) => {
    return { type: KEUANGAN_CRUD_MESSAGE_SUCCESS, payload: data }
}

export const keuanganCrudMessageFail = (err) => {
    return { type: KEUANGAN_CRUD_MESSAGE_FAIL, payload: err }
}

export const keuanganCrudReq = () => {
    return { type: KEUANGAN_CRUD_REQ }
}

export const keuanganCrudSuccess = (data) => {
    return { type: KEUANGAN_CRUD_SUCCESS, payload: data }
}

export const keuanganCrudFail = (err) => {
    return { type: KEUANGAN_CRUD_FAIL, payload: err }
}

export const keuanganReq = () => {
    return { type: KEUANGAN_REQ }
}

export const keuanganSuccess = (data) => {
    return { type: KEUANGAN_SUCCESS, payload: data }
}

export const keuanganFail = (err) => {
    return { type: KEUANGAN_FAIL, payload: err }
}

export const uploadKeuanganUmum = (data) => {
    return async (dispatch) => {
        dispatch(keuanganCrudMessageReq);
        await api.post(`/keuangan-umum-create-import`, data, configUpload)
            .then(res => {
                dispatch(keuanganCrudMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(keuanganCrudMessageFail(err.response.data));
            })
    }
}

export const updatekeuangan = (id, data) => {
    return async (dispatch) => {
        dispatch(keuanganCrudMessageReq);
        await api.put(`/keuangan-umum-update/${id}`, data, config)
            .then(res => dispatch(keuanganCrudMessageSuccess(res.data)))
            .catch(err => dispatch(keuanganCrudMessageFail(err.response.data)))
    }
}

export const getkeuanganDetail = (id) => {
    return async (dispatch) => {
        dispatch(keuanganReq);
        await api.get(`/keuangan-umum/${id}`, config)
            .then(res => {
                dispatch(keuanganSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(keuanganFail(err.response));
            })
    }
}

export const deleletkeuangan = (id) => {
    return async (dispatch) => {
        dispatch(keuanganCrudMessageReq);
        await api.delete(`/keuangan-umum-delete/${id}`, config)
            .then(res => {
                dispatch(keuanganCrudMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(keuanganCrudMessageFail(err.response.data));
            })
    }
}

export const getOptionKeuangan = () => {
    return async (dispatch) => {
        dispatch(optionKeuanganReq);
        await api.get('/keterangan-keuangan', config)
            .then(res => dispatch(optionKeuanganSuccess(res.data.data)))
            .catch(err => dispatch(optionKeuanganFail(err.response.data)))
    }
}

export const addkeuangan = (data) => {
    return async (dispatch) => {
        dispatch(keuanganCrudMessageReq);
        await api.post('/keuangan-umum-create', data, config)
            .then(res => {
                dispatch(keuanganCrudMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(keuanganCrudMessageFail(err.response.data));
            })
    }
}

export const getKeuanganCrud = (company_id) => {
    return async (dispatch) => {
        dispatch(keuanganCrudReq);
        await api.get(`keuangan-umum-company/${company_id}`, config)
            .then(res => {
                dispatch(keuanganCrudSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(keuanganCrudFail(err.response.data));
            })
    }
}

export const getKeuanganPivot = (company_id) => {
    return async (dispatch) => {
        dispatch(keuanganReq);
        await api.get(`keuangan-umum-pivot/${company_id}`, config)
            .then(res => {
                dispatch(keuanganSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(keuanganFail(err.response.data));
            })
    }
}