import React from 'react';
import DataTable from 'react-data-table-component';
import Select from "react-select";
import { Spinner, Modal } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import ReactModal from 'react-modal';
import { useForm, Controller } from 'react-hook-form';
import { useParams, useHistory, Link } from 'react-router-dom';
import swal from 'sweetalert';
import _ from 'lodash';
import { Bar } from 'react-chartjs-2';
import Button from '@mui/material/Button';

import { ActionButton, Styles, /*FilterComponent*/ } from '../../components';

const Saham = ({
    saham,
    getSaham,
    addSaham,
    sahamMessage,
    negara,
    getNegara,
    deleteSaham,
    uploadSaham,
    role,
}) => {
    const { id, nama } = useParams();
    const history = useHistory();
    const { register, handleSubmit, control } = useForm();
    const [rows, setRows] = React.useState([]);
    const [namaNegara, setNamaNegara] = React.useState([]);
    const [inputNegara, setInputNegara] = React.useState('');
    const [tahun, setTahun] = React.useState([]);
    const [inputTahun, setInputTahun] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [dataChartValue, setDataChartValue] = React.useState([]);
    const [fileUpload, setFileUpload] = React.useState(null);
    // const [filterText, setFilterText] = React.useState('');
    const [showModalAddSaham, setShowModalAddSaham] = React.useState(false);
    const setUpModalAddSaham = () => setShowModalAddSaham(!showModalAddSaham);

    React.useEffect(() => {
        setTahun(Tahun().map(t => ({ value: t, label: t })));
    }, []);

    React.useEffect(() => {
        getSaham(id);
        getNegara();
    }, [getNegara, getSaham, id]);

    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { minimumFractionDigits: 0 }
        ).format(money);
    }
    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none'
            },
        },
    };
    const columns = [
        {
            name: 'No.',
            selector: (_, index) => index + 1,
            minWidth: '10px'
        },
        {
            name: 'Nama',
            selector: row => row.nama,
            minWidth: '300px'
        },
        {
            name: 'Negara',
            selector: row => row.negara,
            minWidth: '150px'
        },
        {
            name: 'Tahun',
            selector: row => row.tahun,
        },
        {
            name: 'Nominal',
            selector: row => formatRupiah(row.nominal),
            minWidth: '150px'
        },
        {
            name: 'Persentase',
            selector: row => row.persentase,
        },
        {
            name: 'Action',
            selector: 'action'
        }
    ];
    const columnsGuest = [
        {
            name: 'Nama',
            selector: row => row.nama,
            minWidth: '250px'
        },
        {
            name: 'Negara',
            selector: row => row.negara,
            minWidth: '150px'
        },
        {
            name: 'Tahun',
            selector: row => row.tahun,
        },
        {
            name: 'Nominal',
            selector: row => formatRupiah(row.nominal),
            minWidth: '150px'
        },
        {
            name: 'Persentase',
            selector: row => row.persentase,
        }
    ];

    React.useEffect(() => {
        if (saham && saham.error && saham.error === 401) {
            window.location.href = "/";
        }
        const timeout = setTimeout(() => {
            function onlyUnique(value, index, self) {
                return self.indexOf(value) === index;
            }
            function makeid() {
                var letters = '0123456789ABCDEF';
                var color = '#';
                for (var i = 0; i < 6; i++) {
                    color += letters[Math.floor(Math.random() * 16)];
                }
                return color;
            }
            saham && saham.saham &&
                setRows(Object.values(saham.saham).map(s => (
                    {
                        nama: s.nama,
                        negara: s.negara,
                        tahun: s.tahun,
                        nominal: s.nominal,
                        persentase: s.persentase * 100 + (' %'),
                        action: <><ActionButton
                            title="fa fa-pencil"
                            css="btn-warning"
                            fun={() => history.push(`/penyelenggara-detail-edit/${id}/${nama}/edit/${s.id}/saham`)} />
                            &nbsp;
                            <ActionButton title="fa fa-trash" css="btn-danger" fun={() => swal(`Apakah Yakin Data saham ${s.company_name} - ${s.nama} akan dihapus?`, {
                                buttons: ["Batal", true],
                            }).then((value) => {
                                if (value) {
                                    deleteSaham(s.id);
                                }
                            })
                            } />
                        </>,
                    })));
            saham && saham.saham && setDataChartValue({
                labels: saham.saham.map(p => p.tahun).filter(onlyUnique),
                datasets: Object.values(_.groupBy(saham.saham, 'nama')).map(e => ({
                    label: e.map(p => p.nama).filter(onlyUnique),
                    data: e.map((f, index) => f.persentase * 100),
                    backgroundColor: makeid()
                }))
            });
            negara && negara.negara &&
                setNamaNegara(negara.negara.map(negara => ({ value: negara.nama, label: negara.nama })))
            setLoading(false);
        }, 3000);
        return () => clearTimeout(timeout);
    }, [deleteSaham, history, id, nama, negara, saham, sahamMessage.loading, sahamMessage.sahamMessage]);

    React.useEffect(() => {
        const notif = () => {
            sahamMessage.loading && sahamMessage.sahamMessage && swal(`${sahamMessage.sahamMessage.message}`, {
                buttons: true
            }).then((value) => {
                if (value) {
                    getSaham(id);
                }
            });
        }

        return notif();
    }, [getSaham, id, sahamMessage, showModalAddSaham]);


    React.useEffect(() => {
        sahamMessage.loading && sahamMessage.sahamMessage && swal(`${sahamMessage.sahamMessage.message}`, {
            buttons: ["cancel", true]
        }).then((value) => {
            if (value) {
                getSaham(id);
            }
        });
    }, [getSaham, id, sahamMessage]);
    const options = {
        scales: {
            y: {
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value, index, values) {
                        return value + ' %';
                    }
                }
            }
        }
    }

    const customStylesModal = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: '0',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '700px',
            height: '90%'
        },
    };

    const submitSaham = async (data) => {
        const input = {
            company_name: data.company_name,
            data_umum_id: data.data_umum_id,
            nama: data.nama,
            negara: inputNegara,
            tipe: data.tipe,
            tahun: `${inputTahun}`,
            nominal: data.nominal,
            persentase: data.persentase
        }
        // console.log(input);
        addSaham(input);
        await sahamMessage.loading && swal(`Data Saham Berhasil Ditambahkan!`, {
            buttons: ["Batal", true]
        }).then((value) => {
            if (value) {
                setUpModalAddSaham();
            }
        });
    };

    const onUpload = (e) => setFileUpload(e.target.files[0]);

    const submitUploadSaham = (data) => {
        const formData = new FormData();
        formData.append("nama_file", fileUpload);
        uploadSaham(formData)
    };

    return saham.loading ? <Spinner animation="border" variant="primary" /> : saham.error === "Unauthenticated." ? (
        window.location.href = "/") : (
        <div className="card">
            <h4 className="card-title text-bold" style={{ margin: 10, fontWeight: 'bold' }}>SAHAM {nama}</h4>
            {
                role && role.role_id !== 3 &&
                <div className="toolbar" style={{ display: 'flex', flexDirection: 'row-reverse', margin: '20px' }}>
                    <Button
                        id="basic-button"
                        aria-controls="basic-menu"
                        className="btn btn-sm"
                        size="large"
                        aria-haspopup="true" onClick={setUpModalAddSaham}
                        style={{ backgroundColor: 'rgba(0, 50, 122, 1)', color: '#fff', marginRight: '10px' }}
                    >
                        <i className="fa fa-plus" aria-hidden="true"></i> &nbsp; Tambah
                    </Button>
                </div>
            }
            <div className="card-content">
                <div className="material-datatables">
                    <DataTable
                        columns={role.role_id !== 3 ? columns : columnsGuest}
                        data={rows}
                        progressPending={loading}
                        progressComponent={<Loader type="Watch" color="#00BFFF" height={100} width={100} />}
                        responsive={true}
                        customStyles={customStyles}
                        pagination
                    // subHeaderComponent={subHeaderComponentMemo}
                    />
                    {/* <Bar data={dataChartValue} options={options} /> */}
                </div>
            </div>

            <ReactModal
                ariaHideApp={false}
                isOpen={showModalAddSaham}
                preventScroll={true}
                scrollable={true}
                style={customStylesModal}

            >
                <div style={{ float: 'right' }}>
                    <Button id="basic-button"
                        aria-controls="basic-menu"
                        color="error"
                        onClick={setUpModalAddSaham}
                        size="small">
                        <span className="material-icons">
                            close
                        </span>
                    </Button>
                </div>
                <Modal.Title style={{ fontWeight: 'bold' }}>Tambah Data Saham Baru</Modal.Title>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitUploadSaham)}
                >
                    <h5 className="text-center">Import Data Saham {nama}</h5>
                    <input
                        placeholder="Nama Perusahaan"
                        type="file"
                        onChangeCapture={onUpload}
                        name="nama_file"
                    />
                    {
                        sahamMessage && sahamMessage.error && <span className="text-danger">{sahamMessage.error.nama_file}</span>
                    }
                    {
                        sahamMessage && sahamMessage.error && <span className="text-danger">{sahamMessage.error.message}</span>
                    }
                    <Link to="/download/saham.xlsx" style={{ float: 'right' }} target="_blank">Download Template Upload Saham</Link><br />
                    <Button type="submit" variant="contained" color="primary">Upload</Button>
                </form><hr />
                <form className="form"
                    onSubmit={handleSubmit(submitSaham)}
                >
                    <input
                        placeholder="Nama Perusahaan"
                        type="hidden"
                        className="form-control"
                        value={id}
                        {...register("data_umum_id")}
                    />
                    <input
                        placeholder="Nama Perusahaan"
                        type="hidden"
                        className="form-control"
                        value={nama}
                        {...register("company_name")}
                    />

                    <Styles.InputForm>
                        <input
                            placeholder="Nama"
                            type="text"
                            className="form-control"
                            {...register("nama")}
                        />
                        {sahamMessage && sahamMessage.error && <span className="text-danger">{sahamMessage.error.nama}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="negara"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={namaNegara}
                                    placeholder="Pilih Negara.."
                                    onChange={(e) => setInputNegara(e.value)}
                                />
                            )}
                        />
                        {sahamMessage && sahamMessage.error && <span className="text-danger">{sahamMessage.error.negara}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <select className="form-control" {...register("tipe")} >
                            <option value="">-- Pilih Type --</option>
                            <option value="Publik">Publik</option>
                            <option value="Nasional">Nasional</option>
                            <option value="Asing">Asing</option>
                        </select>
                        {sahamMessage && sahamMessage.error && <span className="text-danger">{sahamMessage.error.tipe}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="Tahun"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={tahun}
                                    placeholder="Tahun.."
                                    onChange={(e) => setInputTahun(e.value)}
                                />
                            )}
                        />
                        {sahamMessage && sahamMessage.error && <span className="text-danger">{sahamMessage.error.tahun}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Nominal"
                            type="text"
                            className="form-control"
                            {...register("nominal")}
                        />
                        {sahamMessage && sahamMessage.error && <span className="text-danger">{sahamMessage.error.nominal}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Persentase"
                            type="text"
                            className="form-control"
                            {...register("persentase")}
                        />
                        {sahamMessage && sahamMessage.error && <span className="text-danger">{sahamMessage.error.persentase}</span>}
                    </Styles.InputForm>
                    <Button variant="contained" color="error" onClick={setUpModalAddSaham} style={{ marginRight: 5 }}>Close</Button>
                    <Button type="submit" variant="contained" color="primary">Save</Button>
                </form>
            </ReactModal>
        </div>
    )
}

export default Saham

const Tahun = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= 1945; i--) {
        tahun.push(i);
    }
    return tahun;
}