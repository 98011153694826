import React from 'react';
import { useParams } from 'react-router';
import DataTable from 'react-data-table-component';
import Loader from 'react-loader-spinner';
import ReactModal from 'react-modal';
import { ListGroup, Modal, TabContent } from 'react-bootstrap';
import Select from "react-select";
import swal from 'sweetalert';
import { useForm, Controller } from 'react-hook-form';
import { Bar } from 'react-chartjs-2';
import Button from '@mui/material/Button';

import { Styles, ActionButton } from '../../components';
import { Link } from 'react-router-dom';

const Jartaplok = ({
    jartaplok,
    getjartaplokByCompany,
    addjartaplok,
    jartaplokMessage,
    deletejartaplok,
    role,
    uploadJartaplok
}) => {

    const { id, nama } = useParams();
    const { register, handleSubmit, control } = useForm();
    const [loading, setLoading] = React.useState(true);
    const [jartaplokData, setJartaplokData] = React.useState([]);
    const [dataChartValue, setDataChartValue] = React.useState([]);
    const [tahun, setTahun] = React.useState([]);
    const [inputTahun, setInputTahun] = React.useState('');
    const [showModalAddjartaplok, setShowModalAddjartaplok] = React.useState(false);
    const [fileUpload, setFileUpload] = React.useState(null);

    const setupModalAddjartaplok = () => setShowModalAddjartaplok(!showModalAddjartaplok);
    React.useEffect(() => {
        setTahun(Tahun().map(t => ({ value: t, label: t })));
    }, []);
    React.useState(() => {
        getjartaplokByCompany(id);
    }, [getjartaplokByCompany]);
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            jartaplok.loading && jartaplok.jartaplok &&
                setJartaplokData(jartaplok.jartaplok.map((jrtpk) => ({
                    pelanggan: jrtpk.pelanggan,
                    kab_kota: jrtpk.kab_kota,
                    provinsi: jrtpk.provinsi,
                    kapasitas_sistem_terpasang: jrtpk.kapasitas_sistem_terpasang,
                    outgoing: jrtpk.outgoing,
                    incoming: jrtpk.incoming,
                    arpu: jrtpk.arpu,
                    tahun: jrtpk.tahun,
                    action: <>
                        <ActionButton
                            title="fa fa-pencil"
                            css="btn-warning"
                            fun={() => window.location.href = `/penyelenggara-detail-pelanggan-kapasitas-edit/${id}/${nama}/edit/${jrtpk.id}/jartaplok`}
                        />
                        &nbsp;
                        <ActionButton title="fa fa-trash" css="btn-danger"
                            fun={
                                () => swal(`Apakah Yakin Data jrtpk ${jrtpk.company_name} ini akan dihapus?`, {
                                    buttons: ["Batal", true],
                                }).then((value) => {
                                    if (value) {
                                        deletejartaplok(jrtpk.id);
                                    }
                                })
                            }
                        />
                    </>
                })));
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [deletejartaplok, id, jartaplok.jartaplok, jartaplok.loading, nama]);
    React.useEffect(() => {
        jartaplok && jartaplok.jartaplok && setDataChartValue({
            pelanggan: jartaplok.jartaplok.map(jp => jp.pelanggan),
            arpu: jartaplok.jartaplok.map(jp => jp.arpu),
            outgoing: jartaplok.jartaplok.map(jp => jp.outgoing),
            incoming: jartaplok.jartaplok.map(jp => jp.incoming),
            tahun: jartaplok.jartaplok.map((p) => p.tahun),
        });
    }, [jartaplok]);
    React.useEffect(() => {
        jartaplokMessage.loading && jartaplokMessage.jartaplokMessage && swal(`${jartaplokMessage.jartaplokMessage.message}`, {
            buttons: true
        }).then((value) => {
            if (value) {
                getjartaplokByCompany(id);
            }
        });
    }, [getjartaplokByCompany, id, jartaplokMessage]);
    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { minimumFractionDigits: 0 }
        ).format(money);
    }
    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none'
            },
        },
    };
    const columns = [
        {
            name: 'Pelanggan',
            selector: row => formatRupiah(row.pelanggan)
        },
        {
            name: 'Kabupaten / Kota',
            selector: row => formatRupiah(row.kab_kota),
        },
        {
            name: 'Provinsi',
            selector: row => formatRupiah(row.provinsi),
        },
        {
            name: 'Kapasitas Sistem Tepasang',
            selector: row => formatRupiah(row.kapasitas_sistem_terpasang),
        },
        {
            name: 'Outgoing',
            selector: row => formatRupiah(row.outgoing),
        },
        {
            name: 'Incoming',
            selector: row => formatRupiah(row.incoming),
        },
        {
            name: 'Arpu',
            selector: row => formatRupiah(row.arpu),
        },
        {
            name: 'Tahun',
            selector: 'tahun',
        },
        {
            name: 'Action',
            selector: 'action'
        }
    ];
    const columnsGuest = [
        {
            name: 'Pelanggan',
            selector: row => formatRupiah(row.pelanggan)
        },
        {
            name: 'Kabupaten / Kota',
            selector: row => formatRupiah(row.kab_kota),
        },
        {
            name: 'Provinsi',
            selector: row => formatRupiah(row.provinsi),
        },
        {
            name: 'Kapasitas Sistem Tepasang',
            selector: row => formatRupiah(row.kapasitas_sistem_terpasang),
        },
        {
            name: 'Outgoing',
            selector: row => formatRupiah(row.outgoing),
        },
        {
            name: 'Incoming',
            selector: row => formatRupiah(row.incoming),
        },
        {
            name: 'Arpu',
            selector: row => formatRupiah(row.arpu),
        },
        {
            name: 'Tahun',
            selector: 'tahun',
        }
    ];
    const dataChartjartaplok = {
        labels: dataChartValue.tahun,
        datasets: [
            {
                label: `pelanggan`,
                data: dataChartValue.pelanggan,
                backgroundColor: 'red',
            },
            {
                label: `arpu`,
                data: dataChartValue.arpu,
                backgroundColor: 'orange',
            },
            {
                label: `outgoing`,
                data: dataChartValue.outgoing,
                backgroundColor: 'yellow',
            },
            {
                label: `Incoming`,
                data: dataChartValue.incoming,
                backgroundColor: 'indigo',
            },
        ],
    };
    const submitjartaplok = (data) => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            pelanggan: data.pelanggan,
            kab_kota: data.kab_kota,
            provinsi: data.provinsi,
            kapasitas_sistem_terpasang: data.kapasitas_sistem_terpasang,
            arpu: data.arpu,
            outgoing: data.outgoing,
            incoming: data.incoming,
            tahun: inputTahun
        }
        addjartaplok(input);
    }
    const customStylesModal = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: '0',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '700px',
            height: '100%'
        },
    };

    const onUpload = (e) => setFileUpload(e.target.files[0]);

    const submitUploadJartapSljj = (data) => {
        const formData = new FormData();
        formData.append("nama_file", fileUpload);
        uploadJartaplok(formData)
    };

    return loading ? <Loader /> : jartaplok.error === "Unauthenticated." ? (
        window.location.href = "/") : (
        <>
            <ListGroup as="ul" className="nav nav-tabs" id="myTab" role="tablist">
                <ListGroup as="li" className="nav-item active" role="presentation">
                    <a className="nav-link active" id="kapasitas-jartaplok-tab" data-toggle="tab" href="#kapasitas-jartaplok" role="tab" aria-controls="kapasitas-jartaplok" aria-selected="true">Kapasitas Pelanggan</a>
                </ListGroup>
                {/* <ListGroup as="li" className="nav-item" role="presentation">
                    <a className="nav-link" id="existing-jartaplok-tab" data-toggle="tab" href="#existing-jartaplok" role="tab" aria-controls="existing-jartaplok" aria-selected="false">Existing</a>
                </ListGroup> */}
            </ListGroup>
            <TabContent>
                <div className="tab-pane active" id="kapasitas-jartaplok" role="tabpanel" aria-labelledby="kapasitas-jartaplok-tab">
                    {
                        role.role_id !== 3 &&
                        <div className="toolbar" style={{ display: 'flex', flexDirection: 'row-reverse', }}>
                            <Button
                                id="basic-button"
                                aria-controls="basic-menu"
                                className="btn btn-sm"
                                size="large"
                                aria-haspopup="true"
                                onClick={setupModalAddjartaplok}
                                style={{ backgroundColor: 'rgba(0, 50, 122, 1)', color: '#fff', marginRight: '10px' }}

                            >
                                <i className="fa fa-plus" aria-hidden="true"></i> &nbsp; Tambah
                            </Button>
                        </div>
                    }
                    <div className="card-content">
                        <div className="material-datatables">
                            <DataTable
                                columns={role.role_id !== 3 ? columns : columnsGuest}
                                data={jartaplokData}
                                progressPending={loading}
                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100} width={100} />}
                                customStyles={customStyles}
                                responsive={true}
                                pagination />
                            <Bar data={dataChartjartaplok} />
                        </div>
                    </div>
                </div>
                {/* <div className="tab-pane fade" id="existing-jartaplok" role="tabpanel" aria-labelledby="existing-jartaplok-tab">
                    <p>Existing Jartplok</p>
                </div> */}
            </TabContent>

            <ReactModal
                ariaHideApp={false}
                isOpen={showModalAddjartaplok}
                preventScroll={true}
                scrollable={true}
                style={customStylesModal}
            >
                <div style={{ float: 'right' }}>
                    <Button id="basic-button"
                        aria-controls="basic-menu"
                        color="error"
                        onClick={setupModalAddjartaplok}
                        size="small">
                        <span className="material-icons">
                            close
                        </span>
                    </Button>
                </div>
                <Modal.Title style={{ fontWeight: 'bold' }}>Tambah JARTAPLOK Baru</Modal.Title>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitUploadJartapSljj)}
                >
                    <h5 className="text-center">Import Data JARTAPLOK {nama}</h5>
                    <input
                        placeholder="Nama Perusahaan"
                        type="file"
                        name="nama_file"
                        onChangeCapture={onUpload}
                    />
                    <Link to="/download/jartaplok.xlsx" target="_blank" style={{ float: 'right' }}>Download Template Upload JARTAPLOK</Link>
                    <br />
                    {
                        jartaplokMessage && jartaplokMessage.error && <span className="text-danger">{jartaplokMessage.error.nama_file}</span>
                    }
                    <br />
                    <Button type="submit" variant="contained" color="primary">Upload</Button>
                    {
                        jartaplokMessage && jartaplokMessage.error && jartaplokMessage.error.message && jartaplokMessage.error.message.map((w, index) => (
                            <ol key={index}>
                                <span className="text-danger">{index + 1}.{w}</span>
                            </ol>
                        ))
                    }
                </form>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitjartaplok)}
                >
                    <Styles.InputForm>
                        <input
                            placeholder="Pelanggan..."
                            type="text"
                            className="form-control"
                            {...register("pelanggan")}
                        />
                        {jartaplokMessage && jartaplokMessage.error && <span className="text-danger">{jartaplokMessage.error.pelanggan}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Provinsi..."
                            type="text"
                            className="form-control"
                            {...register("provinsi")}
                        />
                        {jartaplokMessage && jartaplokMessage.error && <span className="text-danger">{jartaplokMessage.error.provinsi}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Kabupaten / Kota..."
                            type="text"
                            className="form-control"
                            {...register("kab_kota")}
                        />
                        {jartaplokMessage && jartaplokMessage.error && <span className="text-danger">{jartaplokMessage.error.kab_kota}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Kapasitas Sistem Terpasang..."
                            type="text"
                            className="form-control"
                            {...register("kapasitas_sistem_terpasang")}
                        />
                        {jartaplokMessage && jartaplokMessage.error && <span className="text-danger">{jartaplokMessage.error.kapasitas_sistem_terpasang}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Outgoing..."
                            type="text"
                            className="form-control"
                            {...register("outgoing")}
                        />
                        {jartaplokMessage && jartaplokMessage.error && <span className="text-danger">{jartaplokMessage.error.outgoing}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Incoming..."
                            type="text"
                            className="form-control"
                            {...register("incoming")}
                        />
                        {jartaplokMessage && jartaplokMessage.error && <span className="text-danger">{jartaplokMessage.error.incoming}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Arpu..."
                            type="text"
                            className="form-control"
                            {...register("arpu")}
                        />
                        {jartaplokMessage && jartaplokMessage.error && <span className="text-danger">{jartaplokMessage.error.arpu}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="tahun"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={tahun}
                                    placeholder="Tahun.."
                                    onChange={(e) => setInputTahun(e.value)}
                                />
                            )}
                        />
                        {jartaplokMessage && jartaplokMessage.error && <span className="text-danger">{jartaplokMessage.error.tahun}</span>}
                    </Styles.InputForm>
                    <Button color="error" variant="contained" onClick={setupModalAddjartaplok} style={{ marginRight: 5 }}>Close</Button>
                    <Button color="primary" variant="contained" type="submit">Save</Button>
                </form>
            </ReactModal>
        </>
    )
}

export default Jartaplok

const Tahun = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= 1945; i--) {
        tahun.push(i);
    }
    return tahun;
}