import JartaplokSljj from "./JartaplokSljj"
import { getAlljartapsljj, getUser } from "../../redux";
import { connect } from 'react-redux';

const mapStateToProps = state => {
    return {
        jartapsljj: state.jartapsljj,
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUser: () => dispatch(getUser()),
        getAlljartapsljj: () => dispatch(getAlljartapsljj())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JartaplokSljj);
