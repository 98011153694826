export const TEKNOLOGI_REQ = 'TEKNOLOGI_REQ';
export const TEKNOLOGI_SUCCESS = 'TEKNOLOGI_SUCCESS';
export const TEKNOLOGI_FAIL = 'TEKNOLOGI_FAIL';
export const TEKNOLOGI_MESSAGE_REQ = 'TEKNOLOGI_MESSAGE_REQ';
export const TEKNOLOGI_MESSAGE_SUCCESS = 'TEKNOLOGI_MESSAGE_SUCCESS';
export const TEKNOLOGI_MESSAGE_FAIL = 'TEKNOLOGI_MESSAGE_FAIL';
export const TEKNOLOGI_DETAIL_REQ = 'TEKNOLOGI_DETAIL_REQ';
export const TEKNOLOGI_DETAIL_SUCCESS = 'TEKNOLOGI_DETAIL_SUCCESS';
export const TEKNOLOGI_DETAIL_FAIL = 'TEKNOLOGI_DETAIL_FAIL';
export const TEKNOLOGI_UPLOAD_REQ = 'TEKNOLOGI_UPLOAD_REQ';
export const TEKNOLOGI_UPLOAD_SUCCESS = 'TEKNOLOGI_UPLOAD_SUCCESS';
export const TEKNOLOGI_UPLOAD_FAIL = 'TEKNOLOGI_UPLOAD_FAIL';

export const TEKNOLOGI_CRUD_REQ = 'TEKNOLOGI_CRUD_REQ';
export const TEKNOLOGI_CRUD_SUCCESS = 'TEKNOLOGI_CRUD_SUCCESS';
export const TEKNOLOGI_CRUD_FAIL = 'TEKNOLOGI_CRUD_FAIL';

export const TEKNOLOGI_PENYELENGGARA_REQ = 'TEKNOLOGI_PENYELENGGARA_REQ';
export const TEKNOLOGI_PENYELENGGARA_SUCCESS = 'TEKNOLOGI_PENYELENGGARA_SUCCESS';
export const TEKNOLOGI_PENYELENGGARA_FAIL = 'TEKNOLOGI_PENYELENGGARA_FAIL';

export const TEKNOLOGI_IZIN_REQ = 'TEKNOLOGI_IZIN_REQ';
export const TEKNOLOGI_IZIN_SUCCESS = 'TEKNOLOGI_IZIN_SUCCESS';
export const TEKNOLOGI_IZIN_FAIL = 'TEKNOLOGI_IZIN_FAIL';

export const TEKNOLOGI_IZIN_TANGGAL_REQ = 'TEKNOLOGI_IZIN_REQ';
export const TEKNOLOGI_IZIN_TANGGAL_SUCCESS = 'TEKNOLOGI_IZIN_TANGGAL_SUCCESS';
export const TEKNOLOGI_IZIN_TANGGAL_FAIL = 'TEKNOLOGI_IZIN_TANGGAL_FAIL';

export const NAMA_TEKNOLOGI_REQ = 'NAMA_TEKNOLOGI_REQ';
export const NAMA_TEKNOLOGI_SUCCESS = 'NAMA_TEKNOLOGI_SUCCESS';
export const NAMA_TEKNOLOGI_FAIL = 'NAMA_TEKNOLOGI_FAIL';