import {
    JARTAPLOK_REQ,
    JARTAPLOK_SUCCESS,
    JARTAPLOK_FAIL,
    JARTAPLOK_MESSAGE_REQ,
    JARTAPLOK_MESSAGE_SUCCESS,
    JARTAPLOK_MESSAGE_FAIL,
    JARTAPLOK_DETAIL_REQ,
    JARTAPLOK_DETAIL_SUCCESS,
    JARTAPLOK_DETAIL_FAIL,
} from './jartaplokTypes';

import api from '../services';
import { config } from '../config';

export const jartaplokDetailReq = () => {
    return { type: JARTAPLOK_DETAIL_REQ }
}

export const jartaplokDetailSuccess = (data) => {
    return { type: JARTAPLOK_DETAIL_SUCCESS, payload: data }
}

export const jartaplokDetailFail = (err) => {
    return { type: JARTAPLOK_DETAIL_FAIL, payload: err }
}

export const jartaplokReq = () => {
    return { type: JARTAPLOK_REQ }
}

export const jartaplokSuccess = (data) => {
    return { type: JARTAPLOK_SUCCESS, payload: data }
}

export const jartaplokFail = (err) => {
    return { type: JARTAPLOK_FAIL, payload: err }
}

export const jartaplokMessageReq = () => {
    return { type: JARTAPLOK_MESSAGE_REQ }
}

export const jartaplokMessageSuccess = (data) => {
    return { type: JARTAPLOK_MESSAGE_SUCCESS, payload: data }
}

export const jartaplokMessageFail = (err) => {
    return { type: JARTAPLOK_MESSAGE_FAIL, payload: err }
}

export const uploadJartaplok = (data) => {
    return async (dispatch) => {
        dispatch(jartaplokMessageReq);
        await api.post('/jartaplok-create-import', data, config)
            .then(res => {
                dispatch(jartaplokMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartaplokMessageFail(err.response.data));
            })
    }
}

export const getAlljartaplok = () => {
    return async (dispatch) => {
        dispatch(jartaplokReq);
        await api.get(`/jartaplok`, config)
            .then(res => {
                dispatch(jartaplokSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jartaplokFail(err.response.data));
            })
    }
}
export const getjartaplokByCompany = (id) => {
    return async (dispatch) => {
        dispatch(jartaplokReq);
        await api.get(`/jartaplok-company/${id}`, config)
            .then(res => {
                dispatch(jartaplokSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jartaplokFail(err.response.data));
            })
    }
}

export const deletejartaplok = (id) => {
    return async (dispatch) => {
        dispatch(jartaplokMessageReq);
        await api.delete(`/jartaplok-delete/${id}`, config)
            .then(res => {
                dispatch(jartaplokMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartaplokMessageFail(err.response.data));
            })
    }
}

export const addJartaplok = (data) => {
    return async (dispatch) => {
        dispatch(jartaplokMessageReq);
        await api.post('/jartaplok-create', data, config)
            .then(res => {
                dispatch(jartaplokMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartaplokMessageFail(err.response.data));
            })
    }
}

export const updatejartaplok = (id, data) => {
    return async (dispatch) => {
        dispatch(jartaplokMessageReq);
        await api.put(`/jartaplok-update/${id}`, data, config)
            .then(res => {
                dispatch(jartaplokMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartaplokMessageFail(err.response.data));
            })
    }
}

export const getjartaplokDetailById = (id) => {
    return async (dispatch) => {
        dispatch(jartaplokDetailReq);
        await api.get(`/jartaplok/${id}`, config)
            .then(res => {
                dispatch(jartaplokDetailSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jartaplokDetailSuccess(err.response));
            })
    }
}