import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { Images, Styles } from '../../components';
import { useParams } from 'react-router-dom';
import swal from 'sweetalert';

const Background = createGlobalStyle`
    body {
        background-image: url("${Images.bgLogin}");
        background-opacity:0.6;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
    }
`
const ResetPassword = ({ reset, resetPassword }) => {
    const history = useHistory();
    const { register, handleSubmit } = useForm();
    const { token } = useParams();
    const email = localStorage.getItem('email-reset-validation');
    React.useEffect(() => {
        document.title = "Reset Password | PPI"
    }, []);

    React.useEffect(() => {
        reset.loading && reset.resetPass !== null && swal(`${reset.resetPass.message}`, {
            buttons: true
        }).then((value) => {
            if (value) {
                history.push('/');
            }
        });
    }, [history, reset])

    const onSubmit = async (data) => {
        let input = {
            token: token,
            email: email,
            password: data.password,
            password_confirmation: data.password_confirmation
        }
        resetPassword(input);
    }
    return (
        <Styles.Section className="container" >
            <Background />
            <div className="card card-signup">
                <div className="card-header">
                    <Styles.Img src={Images.logoPutih} alt="logo putih" />
                    <p className="text-center" style={{ marginBottom: '40px' }}>Reset Password</p>
                </div>
                <div className="card-body">
                    <form className="form" onSubmit={handleSubmit(onSubmit)}>

                        <Styles.Input className="input-group">
                            <span className="input-group-addon">
                                <i className="material-icons">lock</i>
                            </span>
                            <input
                                type="password"
                                className="form-control"
                                placeholder="New Password"
                                {...register("password")} />
                            {reset.error && <span className="text-danger">{reset.error.password}</span>}
                        </Styles.Input>
                        <Styles.Input className="input-group">
                            <span className="input-group-addon">
                                <i className="material-icons">lock</i>
                            </span>
                            <input
                                type="password"
                                className="form-control"
                                placeholder="Confirm New Password"
                                {...register("password_confirmation")} />
                        </Styles.Input>
                        <div className="row">
                            <Styles.Button style={{ float: 'right', marginRight: 40 }}>
                                <button type="submit" className="btn btn-primary btn-round button btn-login" >Reset Password</button>
                            </Styles.Button>
                        </div>
                    </form>
                </div>
            </div>
        </Styles.Section>
    )
}

export default ResetPassword
