export const JARTAPTUP_PELANGGAN_REQ = 'JARTAPTUP_PELANGGAN_REQ';
export const JARTAPTUP_PELANGGAN_SUCCESS = 'JARTAPTUP_PELANGGAN_SUCCESS';
export const JARTAPTUP_PELANGGAN_FAIL = 'JARTAPTUP_PELANGGAN_FAIL';
export const JARTAPTUP_JARINGAN_REQ = 'JARTAPTUP_JARINGAN_REQ';
export const JARTAPTUP_JARINGAN_SUCCESS = 'JARTAPTUP_JARINGAN_SUCCESS';
export const JARTAPTUP_JARINGAN_FAIL = 'JARTAPTUP_JARINGAN_FAIL';
export const JARTAPTUP_DETAIL_REQ = 'JARTAPTUP_DETAIL_REQ';
export const JARTAPTUP_DETAIL_SUCCESS = 'JARTAPTUP_DETAIL_SUCCESS';
export const JARTAPTUP_DETAIL_FAIL = 'JARTAPTUP_DETAIL_FAIL';
export const JARTAPTUP_MESSAGE_REQ = 'JARTAPTUP_MESSAGE_REQ';
export const JARTAPTUP_MESSAGE_SUCCESS = 'JARTAPTUP_MESSAGE_SUCCESS';
export const JARTAPTUP_MESSAGE_FAIL = 'JARTAPTUP_MESSAGE_FAIL';

export const JARTAPTUP_TECHNAME_REQ = 'JARTAPTUP_TECHNAME_REQ';
export const JARTAPTUP_TECHNAME_SUCCESS = 'JARTAPTUP_TECHNAME_SUCCESS';
export const JARTAPTUP_TECHNAME_FAIL = 'JARTAPTUP_TECHNAME_FAIL';

export const JARTAPTUP_NETWORKNAME_REQ = 'JARTAPTUP_NETWORKNAME_REQ';
export const JARTAPTUP_NETWORKNAME_SUCCESS = 'JARTAPTUP_NETWORKNAME_SUCCESS';
export const JARTAPTUP_NETWORKNAME_FAIL = 'JARTAPTUP_NETWORKNAME_FAIL';

