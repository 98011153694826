import Report from './Report';
import { connect } from 'react-redux';
import { getIzinDetail, getProfilUmum, getUser, reportExport } from '../../redux';

const mapStateToProps = state => {
    return {
        user: state.user,
        report: state.report,
        profilUmum: state.profilUmum,
        izinDetail: state.izinDetail,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUser: () => dispatch(getUser()),
        reportExport: (data) => dispatch(reportExport(data)),
        getProfilUmum: () => dispatch(getProfilUmum()),
        getIzinDetail: (id) => dispatch(getIzinDetail(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Report)