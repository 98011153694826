import React from 'react';
import { Link } from 'react-router-dom';
import { Images } from '..';
// import { Images } from '..'

const Sidebar = ({ menu, user }) => {
    const menuAdmin = React.useMemo(() => {
        return <div className="collapse" id="componentsExamples">
            <ul className="nav" >
                <li className={menu === 'USERS' ? "active" : null} onClick={() => window.location.href = '/users'}>
                    <Link to="#">All Users</Link>
                </li>
                <li className={menu === 'ADD NEW' ? "active" : null} onClick={() => window.location.href = '/add-new'}>
                    <Link to="#">Add New User</Link>
                </li>
                <li className={menu === 'PROFIL' ? "active" : null} onClick={() => window.location.href = '/profil'}>
                    <Link to="#">User Profile</Link>
                </li>
            </ul>
        </div>
    }, [menu]);
    const menuNonAdmin = React.useMemo(() => {
        return <div className="collapse" id="componentsExamples">
            <ul className="nav" >
                <li className={menu === 'PROFIL' ? "active" : null} onClick={() => window.location.href = '/profil'}>
                    <Link to="#">Profil</Link>
                </li>
            </ul>
        </div>
    }, [menu]);


    return (
        <div className="sidebar" data-background-color="biru" style={{ position: 'fixed', zIndex: 0, color: '#fff !important' }}>
            <div className="logo">
                <Link to="#">
                    <span style={{ color: '#ffff', display: 'flex' }}>
                        <img src={Images.logoDashboard} alt="logo" width="50" height="50" style={{ background: '#fff', borderRadius: '30px' }} />
                        <p style={{ fontSize: '14px' }}>
                            Aplikasi Profiling Industri Telekomunikasi
                        </p>
                    </span>
                </Link>
            </div>
            <div className="logo logo-mini ml-2">
                <img src={Images.logoDashboard} alt="logo" width="50" height="50" style={{ background: '#fff', borderRadius: '30px', marginLeft: '15px' }} />
            </div>
            <div className="sidebar-wrapper" >
                <ul className="nav">
                    {/* <li className={menu === 'DASHBOARD' ? "active" : null} onClick={() => window.location.href = '/dashboard'}> */}
                    <li className={menu === 'DASHBOARD' ? "active open" : null} onClick={() => window.location.href = '/dashboard'}>
                        <Link to="#">
                            <i className="material-icons">dashboard</i>
                            <p>Dashboard</p>
                        </Link>
                    </li>
                    <li className={menu === 'USERS' ? "active open" : null} style={{ zIndex: 1 }}>
                        <a data-toggle="collapse" href="#componentsExamples">
                            <i className="material-icons">person</i>
                            <p>Users
                                <b className="caret"></b>
                            </p>
                        </a>
                        {
                            user.loading && user.user.length > 0 && (user.user[0].role.role_name === "Super Admin" || user.user[0].role.role_name === "Admin") ?
                                menuAdmin : menuNonAdmin
                        }
                    </li>
                    <li className={(menu === 'PENYELENGGARA' || menu === 'DATA PENYELENGGARAAN') ? "open active" : null} onClick={() => window.location.href = '/penyelenggara'}>
                        <Link to="#">
                            <i className="material-icons">widgets</i>
                            <p>Penyelenggara</p>
                        </Link>
                    </li>
                    <li className={menu === 'IZIN' ? "active" : null} >
                        <a data-toggle="collapse" href="#izin">
                            <i className="material-icons">send</i>
                            <p>Izin Penyelenggara
                                <b className="caret"></b>
                            </p>
                        </a>
                        <div className="collapse" id="izin">
                            <ul className="nav">
                                <li className={menu === 'IZIN' ? "active" : null} onClick={() => window.location.href = '/izin'}>
                                    <Link to="#">SEMUA IZIN</Link>
                                </li>
                                <li className={menu === 'JABERSEL' ? "active" : null} onClick={() => window.location.href = '/jabersel'}>
                                    <Link to="#">JABERSEL</Link>
                                </li>
                                <li className={menu === 'JABERTRUNK' ? "active" : null} onClick={() => window.location.href = '/jabertrunk'}>
                                    <Link to="#">JABERTRUNK</Link>
                                </li>
                                <li className={menu === 'JARTAPLOK' ? "active" : null} onClick={() => window.location.href = '/jartaplok'}>
                                    <Link to="#">JARTAPLOK</Link>
                                </li>
                                <li className={menu === 'JARTAPLOK PS' ? "active" : null} onClick={() => window.location.href = '/jartaplok-ps'}>
                                    <Link to="#">JARTAPLOK PS</Link>
                                </li>
                                <li className={menu === 'JARTAP SLI' ? "active" : null} onClick={() => window.location.href = '/jartap-sli'}>
                                    <Link to="#">JARTAP SLI</Link>
                                </li >
                                <li className={menu === 'JARTAP SLJJ' ? "active" : null} onClick={() => window.location.href = '/jartap-sljj'}>
                                    <Link to="#">JARTAP SLJJ</Link>
                                </li >
                                <li className={menu === 'JARTAPTUP' ? "active" : null} onClick={() => window.location.href = '/jartaptup'}>
                                    <Link to="#">JARTAPTUP</Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className={(menu === 'TEKNOLOGI' || menu === 'DATA TEKNOLOGI') ? "open active" : null} onClick={() => window.location.href = '/teknologi'}>
                        <Link to="/penyelenggara">
                            <i className="fas fa-broadcast-tower" aria-hidden="true"></i>
                            <p>Teknologi Jaringan</p>
                        </Link>
                    </li>
                    <li className={(menu === 'REPORT') ? "open active" : null} onClick={() => window.location.href = '/report'}>
                        <Link to="/penyelenggara">
                            <i className="material-icons">assessment</i>
                            <p>Report</p>
                        </Link>
                    </li>
                </ul>
            </div>
        </div >
    )
}

export default Sidebar
