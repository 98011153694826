import CapexOpex from './CapexOpex';
import { connect } from 'react-redux';
import { getCapexOpex, addCapexOpex, deleteCapexOpex, uploadCapexOpex } from '../../redux';

const mapStateToProps = state => {
    return {
        capexOpex: state.capexOpex,
        capexOpexMessage: state.capexOpexMessage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCapexOpex: (id) => dispatch(getCapexOpex(id)),
        deleteCapexOpex: (id) => dispatch(deleteCapexOpex(id)),
        addCapexOpex: (data) => dispatch(addCapexOpex(data)),
        uploadCapexOpex: (data) => dispatch(uploadCapexOpex(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CapexOpex);