import styled from 'styled-components';

const InputForm = styled.div`
  margin-top: 20px;
  margin-bottom: 20px; 
`

const Construct = styled.div`
.bgimg {
    height: 100%;
    background-position: center;
    background-size: cover;
    position: relative;
    color: white;
    font-family: "Courier New", Courier, monospace;
    font-size: 25px;
    font-weight: bold;
  }
    
  .middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: blue;
  }
`

const ButtonLogout = styled.button`
position: relative;
display: block;
padding: 10px 29px;
position: relative;
    display: block;
    border: none;
    background: none;
    white-space: nowrap;
`

const Section = styled.section`
        max-width: 370px;
        margin-top: 150px;
        padding-top: 10px;
        padding-bottom: 10px;

`

const Img = styled.img`
    background-color: #fff;
    padding: 30px;
    border-radius: 20px;
    max-width: 300px;
    max-height: 200px;
    margin: -100px auto 30px;
    display:flex;
    flex-direction: row;
    align-item: center;

`

const Input = styled.div`
    margin-left:10px;
    margin-right: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
    input[type="text"] {
    color: #fff;
    input[type="password"] {
    color: #fff;
    }
    
`
const Cari = styled.div`
    margin: 0;
    input[type="text"] {
    color: #000;
    }
    
`
const Button = styled.div`
display: flex;
margin-left: 40px;
button.btn-login {
  :hover: #18d1ff !important;
    background-color: #fff;
    color: #28166F;
    margin-left: 70px;
    margin-bottom: 40px;
}
`
export const ButtonModal = styled.button`
  background: none;
  border:none;
`

export const Styles = {
  Cari,
  InputForm,
  Construct,
  ButtonLogout,
  ButtonModal,
  Img,
  Input,
  Button,
  Section
}

