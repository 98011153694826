import {
    JARTAPSLJJ_REQ,
    JARTAPSLJJ_SUCCESS,
    JARTAPSLJJ_FAIL,
    JARTAPSLJJ_MESSAGE_REQ,
    JARTAPSLJJ_MESSAGE_SUCCESS,
    JARTAPSLJJ_MESSAGE_FAIL,
    JARTAPSLJJ_DETAIL_REQ,
    JARTAPSLJJ_DETAIL_SUCCESS,
    JARTAPSLJJ_DETAIL_FAIL,
} from './jartapsljjTypes';

const jartapsljjMessageState = {
    loading: false,
    jartapsljjMessage: '',
    error: ''
}

const jartapsljjState = {
    loading: false,
    jartapsljj: [],
    error: ''
}

const jartapsljjDetailState = {
    loading: false,
    jartapsljjDetail: null,
    error: ''
}

export const jartapsljjMessageReducer = (state = jartapsljjMessageState, action) => {
    switch (action.type) {
        case JARTAPSLJJ_MESSAGE_REQ:
            return {
                ...state,
                loading: false,
            }
        case JARTAPSLJJ_MESSAGE_SUCCESS:
            return {
                loading: true,
                jartapsljjMessage: action.payload,
                error: ''
            }
        case JARTAPSLJJ_MESSAGE_FAIL:
            return {
                loading: true,
                jartapsljjMessage: "",
                error: action.payload
            }
        default:
            return state
    }
}

const jartapsljjReducer = (state = jartapsljjState, action) => {
    switch (action.type) {
        case JARTAPSLJJ_REQ:
            return {
                ...state,
                loading: false,
            }
        case JARTAPSLJJ_SUCCESS:
            return {
                loading: true,
                jartapsljj: action.payload,
                error: ''
            }
        case JARTAPSLJJ_FAIL:
            return {
                loading: true,
                jartapsljj: null,
                error: action.payload
            }
        default:
            return state
    }
}

export const jartapsljjDetailReducer = (state = jartapsljjDetailState, action) => {
    switch (action.type) {
        case JARTAPSLJJ_DETAIL_REQ:
            return {
                ...state,
                loading: false
            }
        case JARTAPSLJJ_DETAIL_SUCCESS:
            return {
                loading: true,
                jartapsljjDetail: action.payload,
                error: ''
            }
        case JARTAPSLJJ_DETAIL_FAIL:
            return {
                loading: true,
                jartapsljjDetail: null,
                error: action.payload
            }
        default:
            return state
    }
}

export default jartapsljjReducer