import {
    RASIO_KONVERSI_KESEHATAN_KEUANGAN_REQ,
    RASIO_KONVERSI_KESEHATAN_KEUANGAN_SUCCESS,
    RASIO_KONVERSI_KESEHATAN_KEUANGAN_FAIL,
    RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_REQ,
    RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_SUCCESS,
    RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_FAIL,
    RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_MESSAGE_REQ,
    RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_MESSAGE_SUCCESS,
    RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_MESSAGE_FAIL,
} from './rasioKonversiKesehatanKeuanganTypes';

import api from '../services';
import { config } from '../config';

export const rasioKonversiKesehatanKeuanganCrudMessageReq = () => {
    return { type: RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_MESSAGE_REQ }
}
export const rasioKonversiKesehatanKeuanganCrudMessageSuccess = (data) => {
    return { type: RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_MESSAGE_SUCCESS, payload: data }
}
export const rasioKonversiKesehatanKeuanganCrudMessageFail = (err) => {
    return { type: RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_MESSAGE_FAIL, payload: err }
}

export const rasioKonversiKesehatanKeuanganCrudReq = () => {
    return { type: RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_REQ }
}

export const rasioKonversiKesehatanKeuanganCrudSuccess = (data) => {
    return { type: RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_SUCCESS, payload: data }
}

export const rasioKonversiKesehatanKeuanganCrudFail = (err) => {
    return { type: RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_FAIL, payload: err }
}

export const rasioKonversiKesehatanKeuanganReq = () => {
    return { type: RASIO_KONVERSI_KESEHATAN_KEUANGAN_REQ }
}

export const rasioKonversiKesehatanKeuanganSuccess = (data) => {
    return { type: RASIO_KONVERSI_KESEHATAN_KEUANGAN_SUCCESS, payload: data }
}

export const rasioKonversiKesehatanKeuanganFail = (err) => {
    return { type: RASIO_KONVERSI_KESEHATAN_KEUANGAN_FAIL, payload: err }
}

export const updateRasioKonversiKesehatanKeuangan = (id, data) => {
    return async (dispatch) => {
        dispatch(rasioKonversiKesehatanKeuanganCrudMessageReq);
        await api.put(`/nilai-konversi-rasio-kesehatan-update/${id}`, data, config)
            .then(res => dispatch(rasioKonversiKesehatanKeuanganCrudMessageSuccess(res.data)))
            .catch(err => dispatch(rasioKonversiKesehatanKeuanganCrudMessageFail(err.response.data)))
    }
}

export const getKonversiKesehatanKeuanganDetail = (id) => {
    return async (dispatch) => {
        dispatch(rasioKonversiKesehatanKeuanganCrudReq);
        await api.get(`/nilai-konversi-rasio-kesehatan/${id}`, config)
            .then(res => {
                dispatch(rasioKonversiKesehatanKeuanganCrudSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(rasioKonversiKesehatanKeuanganCrudFail(err.response.data));
            })
    }
}

export const deleteRasioKonversiKesehatanKeuangan = (id) => {
    return async (dispatch) => {
        dispatch(rasioKonversiKesehatanKeuanganCrudMessageReq);
        await api.delete(`/nilai-konversi-rasio-kesehatan-delete/${id}`, config)
            .then(res => dispatch(rasioKonversiKesehatanKeuanganCrudMessageSuccess(res.data)))
            .catch(err => dispatch(rasioKonversiKesehatanKeuanganCrudMessageFail(err.response.data)))
    }
}

export const addRasioKonversiKesehatanKeuangan = (data) => {
    return async (dispatch) => {
        dispatch(rasioKonversiKesehatanKeuanganCrudMessageReq);
        await api.post(`/nilai-konversi-rasio-kesehatan-create`, data, config)
            .then(res => dispatch(rasioKonversiKesehatanKeuanganCrudMessageSuccess(res.data)))
            .catch(err => dispatch(rasioKonversiKesehatanKeuanganCrudMessageFail(err.response.data)))
    }
}

export const uploadRasioKonversiKesehatanKeuangan = (data) => {
    return async (dispatch) => {
        dispatch(rasioKonversiKesehatanKeuanganCrudMessageReq);
        await api.post(`/nilai-konversi-rasio-kesehatan-create-import`, data, config)
            .then(res => dispatch(rasioKonversiKesehatanKeuanganCrudMessageSuccess(res.data)))
            .catch(err => dispatch(rasioKonversiKesehatanKeuanganCrudMessageFail(err.response.data)))
    }
}

export const getKonversiKesehatanKeuanganCrud = (id) => {
    return async (dispatch) => {
        dispatch(rasioKonversiKesehatanKeuanganCrudReq);
        await api.get(`/nilai-konversi-rasio-kesehatan-company/${id}`, config)
            .then(res => {
                dispatch(rasioKonversiKesehatanKeuanganCrudSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(rasioKonversiKesehatanKeuanganCrudFail(err.response.data));
            })
    }
}

const getKonversiKesehatanKeuanganPivot = (id) => {
    return async (dispatch) => {
        dispatch(rasioKonversiKesehatanKeuanganReq);
        await api.get(`/nilai-konversi-rasio-kesehatan-pivot/${id}`, config)
            .then(res => {
                dispatch(rasioKonversiKesehatanKeuanganSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(rasioKonversiKesehatanKeuanganFail(err.response.data));
            })
    }
}

export default getKonversiKesehatanKeuanganPivot;