import {
    KEUANGAN_REQ,
    KEUANGAN_SUCCESS,
    KEUANGAN_FAIL,
    KEUANGAN_CRUD_REQ,
    KEUANGAN_CRUD_SUCCESS,
    KEUANGAN_CRUD_FAIL,
    KEUANGAN_CRUD_MESSAGE_REQ,
    KEUANGAN_CRUD_MESSAGE_SUCCESS,
    KEUANGAN_CRUD_MESSAGE_FAIL,
    OPTION_KEUANGAN_REQ,
    OPTION_KEUANGAN_SUCCESS,
    OPTION_KEUANGAN_FAIL,

    RASIO_KEUANGAN_REQ,
    RASIO_KEUANGAN_SUCCESS,
    RASIO_KEUANGAN_FAIL,
    RASIO_KEUANGAN_CRUD_REQ,
    RASIO_KEUANGAN_CRUD_SUCCESS,
    RASIO_KEUANGAN_CRUD_FAIL,
    RASIO_KEUANGAN_CRUD_MESSAGE_REQ,
    RASIO_KEUANGAN_CRUD_MESSAGE_SUCCESS,
    RASIO_KEUANGAN_CRUD_MESSAGE_FAIL,
    RASIO_REQ,
    RASIO_SUCCESS,
    RASIO_FAIL,
    KETERANGAN_RASIO_REQ,
    KETERANGAN_RASIO_SUCCESS,
    KETERANGAN_RASIO_FAIL,
    RUMUS_RASIO_REQ,
    RUMUS_RASIO_SUCCESS,
    RUMUS_RASIO_FAIL,
} from './keuanganTypes';

// state rasio keuangan
const keteranganRasioState = {
    loading: false,
    keteranganRasio: [],
    error: ''
}

const rumusRasioState = {
    loading: false,
    rumusRasio: [],
    error: ''
}
const rasioState = {
    loading: false,
    rasio: [],
    error: ''
}

const rasioKeuanganCrudMessageState = {
    loading: false,
    rasioKeuanganCrudMessage: [],
    error: ''
}

const rasioKeuanganCrudState = {
    loading: false,
    rasioKeuanganCrud: [],
    error: ''
}


const rasioKeuanganState = {
    loading: false,
    rasioKeuangan: [],
    error: ''
}

// state Keuangan
const optionKeuanganState = {
    loading: false,
    optionKeuangan: [],
    error: ''
}

const keuanganCrudState = {
    loading: false,
    keuanganCrud: [],
    error: ''
}

const keuanganCrudMessageState = {
    loading: false,
    keuanganCrudMessage: '',
    error: ''
}

const keuanganState = {
    loading: false,
    keuangan: [],
    error: ''
}

// function ratio keuangan
export const rumusRasioReducer = (state = rumusRasioState, action) => {
    switch (action.type) {
        case RUMUS_RASIO_REQ:
            return {
                ...state,
                loading: false,
            }
        case RUMUS_RASIO_SUCCESS:
            return {
                loading: true,
                rumusRasio: action.payload,
                error: ''
            }
        case RUMUS_RASIO_FAIL:
            return {
                loading: false,
                rumusRasio: [],
                error: action.payload
            }
        default:
            return state
    }
}
export const keteranganRasioReducer = (state = keteranganRasioState, action) => {
    switch (action.type) {
        case KETERANGAN_RASIO_REQ:
            return {
                ...state,
                loading: false,
            }
        case KETERANGAN_RASIO_SUCCESS:
            return {
                loading: true,
                keteranganRasio: action.payload,
                error: ''
            }
        case KETERANGAN_RASIO_FAIL:
            return {
                loading: false,
                keteranganRasio: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const rasioReducer = (state = rasioState, action) => {
    switch (action.type) {
        case RASIO_REQ:
            return {
                ...state,
                loading: false,
            }
        case RASIO_SUCCESS:
            return {
                loading: true,
                rasio: action.payload,
                error: ''
            }
        case RASIO_FAIL:
            return {
                loading: false,
                rasio: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const rasioKeuanganCrudMessageReducer = (state = rasioKeuanganCrudMessageState, action) => {
    switch (action.type) {
        case RASIO_KEUANGAN_CRUD_MESSAGE_REQ:
            return {
                ...state,
                loading: false,
            }
        case RASIO_KEUANGAN_CRUD_MESSAGE_SUCCESS:
            return {
                loading: true,
                rasioKeuanganCrudMessage: action.payload,
                error: ''
            }
        case RASIO_KEUANGAN_CRUD_MESSAGE_FAIL:
            return {
                loading: true,
                rasioKeuanganCrudMessage: "",
                error: action.payload
            }
        default:
            return state
    }
}

export const rasioKeuanganCrudReducer = (state = rasioKeuanganCrudState, action) => {
    switch (action.type) {
        case RASIO_KEUANGAN_CRUD_REQ:
            return {
                ...state,
                loading: false,
            }
        case RASIO_KEUANGAN_CRUD_SUCCESS:
            return {
                loading: true,
                rasioKeuanganCrud: action.payload,
                error: ''
            }
        case RASIO_KEUANGAN_CRUD_FAIL:
            return {
                loading: true,
                rasioKeuanganCrud: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const rasioKeuanganReducer = (state = rasioKeuanganState, action) => {
    switch (action.type) {
        case RASIO_KEUANGAN_REQ:
            return {
                ...state,
                loading: false,
            }
        case RASIO_KEUANGAN_SUCCESS:
            return {
                loading: true,
                rasioKeuangan: action.payload,
                error: ''
            }
        case RASIO_KEUANGAN_FAIL:
            return {
                loading: false,
                rasioKeuangan: [],
                error: action.payload
            }
        default:
            return state
    }
}

// function keuangan
export const optionKeuanganReducer = (state = optionKeuanganState, action) => {
    switch (action.type) {
        case OPTION_KEUANGAN_REQ:
            return {
                ...state,
                loading: false,
            }
        case OPTION_KEUANGAN_SUCCESS:
            return {
                loading: true,
                optionKeuangan: action.payload,
                error: ''
            }
        case OPTION_KEUANGAN_FAIL:
            return {
                loading: true,
                optionKeuangan: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const keuanganCrudMessageReducer = (state = keuanganCrudMessageState, action) => {
    switch (action.type) {
        case KEUANGAN_CRUD_MESSAGE_REQ:
            return {
                ...state,
                loading: false,
            }
        case KEUANGAN_CRUD_MESSAGE_SUCCESS:
            return {
                loading: true,
                keuanganCrudMessage: action.payload,
                error: ''
            }
        case KEUANGAN_CRUD_MESSAGE_FAIL:
            return {
                loading: true,
                keuanganCrudMessage: "",
                error: action.payload
            }
        default:
            return state
    }
}

export const keuanganCrudReducer = (state = keuanganCrudState, action) => {
    switch (action.type) {
        case KEUANGAN_CRUD_REQ:
            return {
                ...state,
                loading: false,
            }
        case KEUANGAN_CRUD_SUCCESS:
            return {
                loading: true,
                keuanganCrud: action.payload,
                error: ''
            }
        case KEUANGAN_CRUD_FAIL:
            return {
                loading: true,
                keuanganCrud: [],
                error: action.payload
            }
        default:
            return state
    }
}
const keuanganReducer = (state = keuanganState, action) => {
    switch (action.type) {
        case KEUANGAN_REQ:
            return {
                ...state,
                loading: false,
            }
        case KEUANGAN_SUCCESS:
            return {
                loading: true,
                keuangan: action.payload,
                error: ''
            }
        case KEUANGAN_FAIL:
            return {
                loading: true,
                keuangan: [],
                error: action.payload
            }
        default:
            return state
    }
}

export default keuanganReducer