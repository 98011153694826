import {
    USER_REQ,
    USER_SUCCESS,
    USER_FAIL,
    USER_MESSAGE_REQ,
    USER_MESSAGE_SUCCESS,
    USER_MESSAGE_FAIL,
} from './userTypes';

const userState = {
    loading: false,
    user: [],
    error: ''
}

const userMessageState = {
    loading: false,
    userMessage: "",
    error: ""
}

export const userMessageReducer = (state = userMessageState, action) => {
    switch (action.type) {
        case USER_MESSAGE_REQ:
            return {
                ...state,
                loading: false,
            }
        case USER_MESSAGE_SUCCESS:
            return {
                loading: true,
                userMessage: action.payload,
                error: ''
            }
        case USER_MESSAGE_FAIL:
            return {
                loading: false,
                userMessage: "",
                error: action.payload
            }
        default:
            return state
    }
}

const loginReducer = (state = userState, action) => {
    switch (action.type) {
        case USER_REQ:
            return {
                ...state,
                loading: false,
            }
        case USER_SUCCESS:
            return {
                loading: true,
                user: action.payload,
                error: ''
            }
        case USER_FAIL:
            return {
                loading: false,
                user: [],
                error: action.payload
            }
        default:
            return state
    }
}

export default loginReducer
