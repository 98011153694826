import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { Images, Styles } from '../../components';

const Background = createGlobalStyle`
    body {
        background-image: url("${Images.bgLogin}");
        background-opacity:0.6;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
    }
`
const Login = ({
    loginData,
    login,
    forgotPass,
    forgotPassword,
    user,
    getUser
}) => {
    const history = useHistory();
    const { register, handleSubmit } = useForm();
    const [formForgotPassword, setFormForgotPassword] = React.useState(true);
    const [emailConfirm, setEmailConfirm] = React.useState('');
    const setupForm = () => setFormForgotPassword(!formForgotPassword);

    React.useEffect(() => {
        document.title = "Login | PPI"
    }, []);
    React.useEffect(() => {
        if (forgotPass.loading && forgotPass.forgotPass !== null) {
            localStorage.setItem('email-reset-validation', emailConfirm);
        }
    }, [emailConfirm, forgotPass.forgotPass, forgotPass.loading]);

    React.useEffect(() => {
        if (user.loading) {
            history.push("/dashboard");
        }
    }, [history, user]);

    React.useEffect(() => {
        getUser();
    }, [getUser]);

    const onSubmit = async (data) => {
        login(data)
    }
    const onForgotPassSubmit = async (data) => {
        forgotPassword(data);
        setEmailConfirm(data.email);
    }
    return (
        <Styles.Section className="container" >
            <Background />
            <div className="card card-signup" style={{ background: '#28166F' }}>
                <div className="card-header shadow">
                    <Styles.Img src={Images.logo} alt="logo putih" />
                </div>
                <div className="card-body pb-4">
                    {
                        formForgotPassword ?
                            (<form className="form" onSubmit={handleSubmit(onSubmit)}>
                                {loginData.error && <p className="text-center text-danger">{loginData.error.message}</p>}
                                <Styles.Input className="input-group">
                                    <span className="input-group-addon text-light">
                                        <i className="material-icons" style={{ color: '#fff' }}>person</i>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Username / email"
                                        autoComplete="off"
                                        {...register("username")} />
                                    {loginData.error && <span className="text-danger">{loginData.error.username}</span>}
                                </Styles.Input>
                                <Styles.Input className="input-group">
                                    <span className="input-group-addon">
                                        <i className="material-icons" style={{ color: '#fff' }}>lock</i>
                                    </span>
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Password"
                                        autoComplete="off"
                                        {...register("password")} />
                                    {loginData.error && <span className="text-danger">{loginData.error.password}</span>}
                                </Styles.Input>
                                <div className="row">
                                    <Styles.Button>
                                        <Link to="#" style={{ marginLeft: 5, paddingTop: 10, color: '#18d1ff' }} onClick={setupForm}>Forgot Password ?</Link>
                                        <button type="submit" className="btn btn-primary btn-round button btn-login" >Sign in</button>
                                    </Styles.Button>
                                </div>
                            </form>) : (<form className="form" onSubmit={handleSubmit(onForgotPassSubmit)}>
                                {forgotPass.loading && forgotPass.forgotPass !== null && <span className="text-success text-center" style={{ marginLeft: 30 }}>{forgotPass.forgotPass.message}</span>}
                                <Styles.Input className="input-group">
                                    <span className="input-group-addon">
                                        <i className="material-icons" style={{ color: '#fff' }}>email</i>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Email"
                                        autoComplete="off"
                                        {...register("email")} />
                                    {forgotPass.loading && forgotPass.forgotPass === null && <span className="text-danger">{forgotPass.error.email}</span>}
                                </Styles.Input>
                                <div className="row">
                                    <Styles.Button>
                                        <Link to="#" style={{ marginLeft: 0, paddingTop: 10, color: '#18d1ff' }} onClick={setupForm}>Back to Login ?</Link>
                                        <button type="submit" className="btn btn-primary btn-round button btn-login" >Send Email</button>
                                    </Styles.Button>
                                </div>
                            </form>)
                    }
                    <p className="text-center" style={{ fontSize: '10px', color: '#fff', textAlign: 'justify', marginLeft: 30, marginRight: 30, marginTop: 0, paddingBottom: 30 }}>Untuk penggunaan, gunakan email atau username dan password Anda. Untuk me-reset kata sandi, silahkan klik forgot password dan isikan email yang terdaftar pada aplikasi, atau hubungi admin</p>
                </div>
            </div>
        </Styles.Section>
    )
}

export default Login
