import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux';
import store from '../../redux/store';
import Routes from "../Routes";

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Routes />
      </Router>
    </Provider>
  )
}

export default App
