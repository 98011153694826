import React from 'react';
import { ListGroup, Modal, TabContent } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Loader from 'react-loader-spinner';
import ReactModal from 'react-modal';
import _ from 'lodash';
import { Bar } from 'react-chartjs-2';
import { useForm, Controller } from 'react-hook-form';
import Select from "react-select";
import swal from 'sweetalert';

import Button from '@mui/material/Button';

import { Styles, ActionButton, Spinner } from '../../components'
import RasioKeuangan from './RasioKeuangan';
import RasioKesehatanKeuangan from './RasioKesehatanKeuangan';
import RasioKonversiKesahatanKeuangan from './RasioKonversiKesahatanKeuangan';

const Keuangan = ({
    keuangan,
    keuanganCrud,
    getKeuanganPivot,
    getKeuanganCrud,
    keuanganCrudMessage,
    addkeuangan,
    optionKeuangan,
    getOptionKeuangan,
    deleletkeuangan,
    role,
    uploadKeuanganUmum
}) => {
    const { id, nama } = useParams();
    const { register, handleSubmit, control } = useForm();

    // keuangan umum state
    const [rows, setRows] = React.useState([]);
    const [rowCrud, setRowCrud] = React.useState([]);
    const [tahunValue, setTahunValue] = React.useState([]);
    const [dataChartValue, setDataChartValue] = React.useState([]);
    const [tahun, setTahun] = React.useState([]);
    const [crud, setCrud] = React.useState(true);
    const [optionKeuanganValue, setOptionKeuanganValue] = React.useState([]);
    const [optionKeuanganValueInput, setOptionKeuanganValueInput] = React.useState('');
    const [inputTahun, setInputTahun] = React.useState('');
    const [keterangan, setKeterangan] = React.useState('');
    const [fileUpload, setFileUpload] = React.useState(null);

    const [showModalAddKeuangan, setShowModalAddKeuangan] = React.useState(false);
    const setupShowModalKeuangan = () => setShowModalAddKeuangan(!showModalAddKeuangan);

    React.useEffect(() => {
        const notif = () => {
            keuanganCrudMessage.loading && keuanganCrudMessage.keuanganCrudMessage && swal(`${keuanganCrudMessage.keuanganCrudMessage.message}`, {
                buttons: true
            }).then((value) => {
                if (value) {
                    getKeuanganPivot(id);
                    getKeuanganCrud(id);
                }
            });
        };

        return notif();
    }, [getKeuanganCrud, getKeuanganPivot, id, keuanganCrudMessage]);

    const [loading, setLoading] = React.useState(true);
    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { minimumFractionDigits: 0 }
        ).format(money);
    }
    React.useEffect(() => {
        setTahun(Tahun().map(t => ({ value: t, label: t })));
    }, []);
    React.useEffect(() => {
        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }
        function makeid() {
            var letters = '0123456789ABCDEF';
            var color = '#';
            for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        }

        const timeout = setTimeout(() => {
            keuangan.loading && keuangan.keuangan && keuangan.keuangan.length > 0 && setTahunValue(Object.keys(keuangan.keuangan[0]));
            keuangan.loading && keuangan.keuangan && setRows(keuangan.keuangan);
            keuanganCrud.loading && keuanganCrud.keuanganCrud && setRowCrud(keuanganCrud.keuanganCrud.map((keu, index) => ({
                nomor: index + 1,
                keterangan: keu.keterangan,
                jumlah: keu.jumlah,
                tahun: keu.tahun,
                action: <>
                    <ActionButton
                        title="fa fa-pencil"
                        css="btn-warning"
                        fun={() => window.location.href = `/penyelenggara-detail-keuangan/${id}/${nama}/edit/${keu.id}/keuangan umum`}
                    />
                    &nbsp;
                    <ActionButton title="fa fa-trash" css="btn-danger"
                        fun={
                            () => swal(`Apakah Yakin Data Rasio Keuangan ${keu.nama_keterangan} ${keu.company_name} tahun ${keu.tahun} ini akan dihapus?`, {
                                buttons: ["Batal", true],
                            }).then((value) => {
                                if (value) {
                                    deleletkeuangan(keu.id);
                                }
                            })
                        }
                    />
                </>,
            })));
            keuanganCrud && keuanganCrud.keuanganCrud && setDataChartValue({
                labels: keuanganCrud.keuanganCrud.map(p => p.tahun).filter(onlyUnique),
                datasets: Object.values(_.groupBy(keuanganCrud.keuanganCrud, 'keterangan')).map(e => ({
                    label: e.map(p => p.keterangan).filter(onlyUnique),
                    data: e.map((f, index) => f.jumlah),
                    backgroundColor: makeid()
                }))
            });
            optionKeuangan && optionKeuangan.optionKeuangan && setOptionKeuanganValue(optionKeuangan.optionKeuangan.map(t => ({ value: t.id, label: t.keterangan })));
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [deleletkeuangan, id, keuangan, keuanganCrud, nama, optionKeuangan]);

    React.useEffect(() => {
        const notifikasi = () => {
            keuanganCrudMessage && keuanganCrudMessage.keuanganCrudMessage && swal(`${keuanganCrudMessage.keuanganCrudMessage.message}`, {
                buttons: ["cancel", true]
            }).then((value) => {
                if (value) {
                    getKeuanganPivot(id);
                    getKeuanganCrud(id);
                }
            })
        }
        return () => notifikasi();
    }, [getKeuanganCrud, getKeuanganPivot, id, keuanganCrudMessage]);

    React.useEffect(() => {
        getKeuanganPivot(id);
        getKeuanganCrud(id);
        getOptionKeuangan();
    }, [getKeuanganCrud, getKeuanganPivot, getOptionKeuangan, id]);

    const customStylesModal = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: '0',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '700px',
            height: '80%'
        },
    };

    // konfig table dan chart
    // rasio konversi kesehatan
    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none'
            },
        },
    };


    //keuangan
    const columns = [
        {
            name: 'No',
            selector: (row, index) => index + 1,
            maxWidth: '10px'
        },
        {
            name: `Keterangan`,
            selector: row => row.keterangan,
        },
        {
            name: `${tahunValue.length > 0 ? tahunValue[1].slice(6) : tahunValue}`,
            selector: tahunValue.length > 0 ? tahunValue[1] : tahunValue
        },
        {
            name: `${tahunValue.length > 0 ? tahunValue[2].slice(6) : tahunValue}`,
            selector: tahunValue.length > 0 ? tahunValue[2] : tahunValue
        },
        {
            name: `${tahunValue.length > 0 ? tahunValue[3].slice(6) : tahunValue}`,
            selector: `${tahunValue.length > 0 ? tahunValue[3] : tahunValue}`
        },
        {
            name: `${tahunValue.length > 0 ? tahunValue[4].slice(6) : tahunValue}`,
            selector: `${tahunValue.length > 0 ? tahunValue[4] : tahunValue}`
        },
        {
            name: `${tahunValue.length > 0 ? tahunValue[5].slice(6) : tahunValue}`,
            selector: `${tahunValue.length > 0 ? tahunValue[5] : tahunValue}`
        },

    ];

    const columnCrud = [
        {
            name: 'No',
            selector: row => row.nomor
        },
        {
            name: 'Keterangan',
            selector: row => row.keterangan
        },
        {
            name: 'jumlah',
            selector: row => formatRupiah(row.jumlah)
        },
        {
            name: 'tahun',
            selector: row => row.tahun
        },
        {
            name: 'action',
            selector: row => row.action
        }
    ];


    // functiom keuangan
    const toCrud = () => setCrud(!crud);
    const submitKeuangan = (data) => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            ket_keuangan_umum_id: optionKeuanganValueInput,
            keterangan: keterangan,
            jumlah: data.jumlah,
            tahun: inputTahun
        }
        addkeuangan(input);
    }

    const onUpload = (e) => setFileUpload(e.target.files[0]);


    const submikeuanganUmum = (data) => {
        const formData = new FormData();
        formData.append("nama_file", fileUpload);
        uploadKeuanganUmum(formData);
    };

    return loading ? <Spinner /> : keuangan.error === "Unauthenticated." ? (
        window.location.href = "/") : (
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header">
                        <h4 className="card-title text-bold" style={{ margin: 10, fontWeight: 'bold' }}>KEUANGAN {nama}</h4>
                        <ListGroup as="ul" className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <ListGroup as="li" className="nav-item active">
                                <a className="nav-link" id="pills-keuangan-umum-tab" data-toggle="pill" href="#pills-keuangan-umum" role="tab" aria-controls="pills-keuangan-umum" aria-selected="false">Keuangan Umum</a>
                            </ListGroup>
                            <ListGroup as="li" className="nav-item">
                                <a className="nav-link" id="pills-rasio-keuangan-tab" data-toggle="pill" href="#pills-rasio-keuangan" role="tab" aria-controls="pills-rasio-keuangan" aria-selected="true">Rasio Keuangan</a>
                            </ListGroup>
                            <ListGroup as="li" className="nav-item">
                                <a className="nav-link" id="pills-rasio-kesehatan-keuangan-tab" data-toggle="pill" href="#pills-rasio-kesehatan-keuangan" role="tab" aria-controls="pills-rasio-kesehatan-keuangan" aria-selected="false">Rasio Kesehatan Keuangan</a>
                            </ListGroup>
                            <ListGroup as="li" className="nav-item">
                                <a className="nav-link" id="pills-konversi-rasio-kesehatan-keuangan-tab" data-toggle="pill" href="#pills-konversi-rasio-kesehatan-keuangan" role="tab" aria-controls="pills-konversi-rasio-konversi-kesehatan-keuangan" aria-selected="false">Nilai Konversi Rasio Kesehatan Keuangan</a>
                            </ListGroup>
                        </ListGroup>
                    </div>
                    <TabContent>
                        {/* Keuangan Umum */}
                        <div className="tab-pane active" id="pills-keuangan-umum" role="tabpanel" aria-labelledby="pills-keuangan-umum-tab">
                            <div className="toolbar" style={{ float: 'right', display: 'flex', flexDirection: 'row' }}>
                                {
                                    role.role_id !== 3 &&
                                    <div className="card-header"><Button
                                        id="basic-button"
                                        aria-controls="basic-menu"
                                        className="btn btn"
                                        size="large"
                                        aria-haspopup="true"
                                        onClick={setupShowModalKeuangan}
                                        style={{ backgroundColor: 'rgba(0, 50, 122, 1)', color: '#fff', marginRight: '10px' }}
                                    >
                                        <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;Tambah
                                    </Button>
                                        <Button
                                            id="basic-button"
                                            aria-controls="basic-menu"
                                            size="large"
                                            className={crud ? "btn btn-success" : "btn btn-danger"}
                                            onClick={toCrud}
                                        >
                                            <i className={crud ? "fa fa-edit" : "fa fa-eye"} aria-hidden="true"></i>{crud ? " Edit" : " View"}
                                        </Button>
                                    </div>
                                }
                            </div>
                            <div className="card-content">
                                <div className="material-datatables">
                                    {
                                        crud ?
                                            <DataTable
                                                columns={columns}
                                                data={rows}
                                                progressPending={loading}
                                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100}
                                                    width={100} />}
                                                customStyles={customStyles}
                                                responsive={true}
                                                pagination
                                            // subHeaderComponent={subHeaderComponentMemo} 
                                            /> : <DataTable
                                                columns={columnCrud}
                                                data={rowCrud}
                                                progressPending={loading}
                                                customStyles={customStyles}
                                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100}
                                                    width={100} />}
                                                responsive={true}
                                                pagination
                                            />
                                    }
                                    {/* <Bar data={dataChartValue} /> */}
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane" id="pills-rasio-keuangan" role="tabpanel" aria-labelledby="pills-rasio-keuangan-tab">
                            <RasioKeuangan
                                nama={nama}
                                id={id}
                                role={role}
                            />
                        </div>
                        <div className="tab-pane" id="pills-rasio-kesehatan-keuangan" role="tabpanel" aria-labelledby="pills-rasio-kesehatan-keuangan-tab">
                            <RasioKesehatanKeuangan
                                nama={nama}
                                role={role}
                                id={id} />
                        </div>
                        <div className="tab-pane" id="pills-konversi-rasio-kesehatan-keuangan" role="tabpanel" aria-labelledby="pills-konversi-rasio-kesehatan-keuangan-tab">
                            <RasioKonversiKesahatanKeuangan
                                nama={nama}
                                id={id}
                                role={role}
                            />
                        </div>
                    </TabContent>
                </div>
            </div>

            {/* form keuangan */}
            <ReactModal
                ariaHideApp={false}
                isOpen={showModalAddKeuangan}
                preventScroll={true}
                scrollable={true}
                style={customStylesModal}
            >
                <div style={{ float: 'right' }}>
                    <Button id="basic-button"
                        aria-controls="basic-menu"
                        // variant="outlined"
                        // className="btn"
                        color="error"
                        onClick={setupShowModalKeuangan}
                        size="small">
                        <span className="material-icons">
                            close
                        </span>
                    </Button>
                </div>
                <Modal.Title style={{ fontWeight: 'bold' }}>Tambah Data Keuangan Baru</Modal.Title>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submikeuanganUmum)}
                >
                    <h5 className="text-center">Import Data Keuangan Umum {nama}</h5>
                    <input
                        type="file"
                        name="nama_file"
                        onChange={onUpload}
                    />
                    {
                        keuanganCrudMessage && keuanganCrudMessage.error && <span className="text-danger">{keuanganCrudMessage.error.nama_file}</span>
                    }
                    <Link to="/download/keuangan umum.xlsx" style={{ float: 'right' }} target="_blank">Download Template Upload Keuangan Umum</Link><br />
                    <Button variant="contained" color="primary" type="submit" >Upload</Button>
                    <br />
                    {
                        keuanganCrudMessage && keuanganCrudMessage.error && <span className="text-danger">{JSON.stringify(keuanganCrudMessage.error.message)}</span>
                    }
                </form>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitKeuangan)}
                >
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="tahun"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={optionKeuanganValue}
                                    placeholder="Keterangan.."
                                    onChange={(e) => {
                                        setOptionKeuanganValueInput(e.value);
                                        setKeterangan(e.label);
                                    }}
                                />
                            )}
                        />
                        {keuanganCrudMessage && keuanganCrudMessage.error && <span className="text-danger">{keuanganCrudMessage.error.keterangan}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="tahun"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={tahun}
                                    placeholder="Tahun.."
                                    onChange={(e) => setInputTahun(e.value)}
                                />
                            )}
                        />
                        {keuanganCrudMessage && keuanganCrudMessage.error && <span className="text-danger">{keuanganCrudMessage.error.tahun}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Jumlah..."
                            type="text"
                            className="form-control"
                            {...register("jumlah")}
                        />
                        {keuanganCrudMessage && keuanganCrudMessage.error && <span className="text-danger">{keuanganCrudMessage.error.jumlah}</span>}
                    </Styles.InputForm>
                    <Button variant="contained" color="error" onClick={setupShowModalKeuangan} style={{ marginRight: 5 }}>Close</Button>
                    <Button variant="contained" color="primary" type="submit">Save</Button>
                </form>
            </ReactModal>
        </div>
    )
}

export default Keuangan;

const Tahun = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= 1945; i--) {
        tahun.push(i);
    }
    return tahun;
}

/*
*
*/