import {
    SELLULER_REQ,
    SELLULER_SUCCESS,
    SELLULER_FAIL,
} from './selulerTypes';


const selulerState = {
    loading: false,
    data: [],
    err: ''
}

const selulerReducer = (state = selulerState, action) => {
    switch (action.type) {
        case SELLULER_REQ:
            return {
                ...state,
                loading: false,
            }
        case SELLULER_SUCCESS:
            return {
                loading: true,
                data: action.payload,
                error: ''
            }
        case SELLULER_FAIL:
            return {
                loading: false,
                data: [],
                error: action.payload
            }
        default:
            return state
    }
}

export default selulerReducer