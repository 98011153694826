import {
    USERS_REQ,
    USERS_SUCCESS,
    USERS_FAIL,
    USERS_MESSAGE_REQ,
    USERS_MESSAGE_SUCCESS,
    USERS_MESSAGE_FAIL,
} from './usersTypes';

import api from '../services';
import { config } from '../config';

export const usersMessageReq = () => {
    return { type: USERS_MESSAGE_REQ }
}

export const usersMessageSuccess = (data) => {
    return { type: USERS_MESSAGE_SUCCESS, payload: data }
}

export const usersMessageFail = (err) => {
    return { type: USERS_MESSAGE_FAIL, payload: err }
}

export const usersReq = () => {
    return { type: USERS_REQ }
}

export const usersSuccess = (data) => {
    return { type: USERS_SUCCESS, payload: data }
}

export const usersFail = (err) => {
    return { type: USERS_FAIL, payload: err }
}

export const deleteUser = (id) => {
    return async (dispatch) => {
        dispatch(usersMessageReq);
        await api.delete(`/user-delete/${id}`, config)
            .then(res => dispatch(usersMessageSuccess(res.data)))
            .catch(err => dispatch(usersMessageFail(err.response.data)));
    }
}

export const activateUser = (id) => {
    return async (dispatch) => {
        dispatch(usersMessageReq);
        await api.put(`/active/${id}`, null, config)
            .then(res => dispatch(usersMessageSuccess(res.data)))
            .catch(err => dispatch(usersMessageFail(err.response.data)))
    }
}

export const addUsers = (data) => {
    return async (dispatch) => {
        dispatch(usersMessageReq);
        await api.post('/register', data, config)
            .then(res => {
                dispatch(usersMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(usersMessageFail(err.response.data));
            })
    }
}

export const getUsers = () => {
    return async (dispatch) => {
        dispatch(usersReq);
        await api.get('/users', config)
            .then(res => {
                dispatch(usersSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(usersFail(err.response.data));
            })
    }
}