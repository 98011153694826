import {
    JARTAPLOKPS_REQ,
    JARTAPLOKPS_SUCCESS,
    JARTAPLOKPS_FAIL,
    JARTAPLOKPS_MESSAGE_REQ,
    JARTAPLOKPS_MESSAGE_SUCCESS,
    JARTAPLOKPS_MESSAGE_FAIL,
    JARTAPLOKPS_DETAIL_REQ,
    JARTAPLOKPS_DETAIL_SUCCESS,
    JARTAPLOKPS_DETAIL_FAIL,

    JARTAPLOKPS_TEKNOLOGI_REQ,
    JARTAPLOKPS_TEKNOLOGI_SUCCESS,
    JARTAPLOKPS_TEKNOLOGI_FAIL,

    JARTAPLOKPS_TEKNOLOGI_ITEM_REQ,
    JARTAPLOKPS_TEKNOLOGI_ITEM_SUCCESS,
    JARTAPLOKPS_TEKNOLOGI_ITEM_FAIL,

    JARTAPLOKPS_JARINGAN_REQ,
    JARTAPLOKPS_JARINGAN_SUCCESS,
    JARTAPLOKPS_JARINGAN_FAIL,
    JARTAPLOKPS_JARINGAN_DETAIL_REQ,
    JARTAPLOKPS_JARINGAN_DETAIL_SUCCESS,
    JARTAPLOKPS_JARINGAN_DETAIL_FAIL,
} from './jartaplokpsTypes';

import api from '../services';
import { config } from '../config';

export const jartaplokpsTeknologiItemReq = () => {
    return { type: JARTAPLOKPS_TEKNOLOGI_ITEM_REQ }
}
export const jartaplokpsTeknologiItemSuccess = (data) => {
    return { type: JARTAPLOKPS_TEKNOLOGI_ITEM_SUCCESS, payload: data }
}
export const jartaplokpsTeknologiItemFail = (err) => {
    return { type: JARTAPLOKPS_TEKNOLOGI_ITEM_FAIL, payload: err }
}

export const jartaplokpsTeknologiReq = () => {
    return { type: JARTAPLOKPS_TEKNOLOGI_REQ }
}
export const jartaplokpsTeknologiSuccess = (data) => {
    return { type: JARTAPLOKPS_TEKNOLOGI_SUCCESS, payload: data }
}
export const jartaplokpsTeknologiFail = (err) => {
    return { type: JARTAPLOKPS_TEKNOLOGI_FAIL, payload: err }
}

export const jartaplokpsJaringanReq = () => {
    return { type: JARTAPLOKPS_JARINGAN_REQ }
}

export const jartaplokpsJaringanSuccess = (data) => {
    return { type: JARTAPLOKPS_JARINGAN_SUCCESS, payload: data }
}

export const jartaplokpsJaringanFail = (err) => {
    return { type: JARTAPLOKPS_JARINGAN_FAIL, payload: err }
}

export const jartaplokpsJaringanDetailReq = () => {
    return { type: JARTAPLOKPS_JARINGAN_DETAIL_REQ }
}

export const jartaplokpsJaringanDetailSuccess = (data) => {
    return { type: JARTAPLOKPS_JARINGAN_DETAIL_SUCCESS, payload: data }
}

export const jartaplokpsJaringanDetailFail = (err) => {
    return { type: JARTAPLOKPS_JARINGAN_DETAIL_FAIL, payload: err }
}

export const jartaplokpsReq = () => {
    return { type: JARTAPLOKPS_REQ }
}

export const jartaplokpsSuccess = (data) => {
    return { type: JARTAPLOKPS_SUCCESS, payload: data }
}

export const jartaplokpsFail = (err) => {
    return { type: JARTAPLOKPS_FAIL, payload: err }
}

export const jartaplokpsDetailReq = () => {
    return { type: JARTAPLOKPS_DETAIL_REQ }
}

export const jartaplokpsDetailSuccess = (data) => {
    return { type: JARTAPLOKPS_DETAIL_SUCCESS, payload: data }
}

export const jartaplokpsDetailFail = (err) => {
    return { type: JARTAPLOKPS_DETAIL_FAIL, payload: err }
}

export const jartaplokpsMessageReq = () => {
    return { type: JARTAPLOKPS_MESSAGE_REQ }
}

export const jartaplokpsMessageSuccess = (data) => {
    return { type: JARTAPLOKPS_MESSAGE_SUCCESS, payload: data }
}

export const jartaplokpsMessageFail = (err) => {
    return { type: JARTAPLOKPS_MESSAGE_FAIL, payload: err }
}

export const getTeknologiItemJartaplokps = (id) => {
    return async (dispatch) => {
        dispatch(jartaplokpsTeknologiItemReq);
        await api.get(`nama-item-jartaplokps-jaringan/${id}`, config)
            .then(res => {
                dispatch(jartaplokpsTeknologiItemSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jartaplokpsTeknologiItemFail(err.response.data));
            })
    }
}

export const getTeknologiJartaplokps = () => {
    return async (dispatch) => {
        dispatch(jartaplokpsTeknologiReq);
        await api.get('nama-teknologi-jartaplokps-jaringan', config)
            .then(res => {
                dispatch(jartaplokpsTeknologiSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jartaplokpsTeknologiFail(err.response.data));
            })
    }
}

export const addjartaplokpsJaringan = (data) => {
    return async (dispatch) => {
        dispatch(jartaplokpsMessageReq);
        await api.post('/jartaplokps-jaringan-create', data, config)
            .then(res => {
                dispatch(jartaplokpsMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartaplokpsMessageFail(err.response.data));
            })
    }
}

export const deletejartaplokpsJaringan = (id) => {
    return async (dispatch) => {
        dispatch(jartaplokpsMessageReq);
        await api.delete(`/jartaplokps-jaringan-delete/${id}`, config)
            .then(res => {
                dispatch(jartaplokpsMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartaplokpsMessageFail(err.response.data));
            })
    }
}

export const uploadJartaplokpsJaringan = (data) => {
    return async (dispatch) => {
        dispatch(jartaplokpsMessageReq);
        await api.post('/jartaplokps-jaringan-create-import', data, config)
            .then(res => {
                dispatch(jartaplokpsMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartaplokpsMessageFail(err.response.data));
            })
    }
}

export const uploadJartaplokps = (data) => {
    return async (dispatch) => {
        dispatch(jartaplokpsMessageReq);
        await api.post('/jartaplokps-create-import', data, config)
            .then(res => {
                dispatch(jartaplokpsMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartaplokpsMessageFail(err.response.data));
            })
    }
}

export const getJartaplokpsJaringanByCompany = (id) => {
    return async (dispatch) => {
        dispatch(jartaplokpsJaringanReq);
        await api.get(`/jartaplokps-jaringan-company/${id}`, config)
            .then(res => {
                dispatch(jartaplokpsJaringanSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jartaplokpsJaringanReq(err.response.data));
            })
    }
}

export const getAllJartaplokps = () => {
    return async (dispatch) => {
        dispatch(jartaplokpsReq);
        await api.get(`/jartaplokps`, config)
            .then(res => {
                dispatch(jartaplokpsSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jartaplokpsFail(err.response.data));
            })
    }
}

export const getJartaplokpsByCompany = (id) => {
    return async (dispatch) => {
        dispatch(jartaplokpsReq);
        await api.get(`/jartaplokps-company/${id}`, config)
            .then(res => {
                dispatch(jartaplokpsSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jartaplokpsFail(err.response.data));
            })
    }
}

export const updatejartapokps = (id, data) => {
    return async (dispatch) => {
        dispatch(jartaplokpsMessageReq);
        await api.put(`/jartaplokps-update/${id}`, data, config)
            .then(res => {
                dispatch(jartaplokpsMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartaplokpsMessageFail(err.response.data));
            })
    }
}

export const getjartaplokpsDetailById = (id) => {
    return async (dispatch) => {
        dispatch(jartaplokpsDetailSuccess);
        await api.get(`/jartaplokps/${id}`, config)
            .then(res => {
                dispatch(jartaplokpsDetailSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jartaplokpsDetailSuccess(err.response));
            })
    }
}

export const deletejartaplokps = (id) => {
    return async (dispatch) => {
        dispatch(jartaplokpsMessageReq);
        await api.delete(`/jartaplokps-delete/${id}`, config)
            .then(res => {
                dispatch(jartaplokpsMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartaplokpsMessageFail(err.response.data));
            })
    }
}


export const addjartaplokps = (data) => {
    return async (dispatch) => {
        dispatch(jartaplokpsMessageReq);
        await api.post('/jartaplokps-create', data, config)
            .then(res => {
                dispatch(jartaplokpsMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartaplokpsMessageFail(err.response.data));
            })
    }
}