import {
    JARTAPSLI_REQ,
    JARTAPSLI_SUCCESS,
    JARTAPSLI_FAIL,
    JARTAPSLI_MESSAGE_REQ,
    JARTAPSLI_MESSAGE_SUCCESS,
    JARTAPSLI_MESSAGE_FAIL,
    JARTAPSLI_DETAIL_REQ,
    JARTAPSLI_DETAIL_SUCCESS,
    JARTAPSLI_DETAIL_FAIL,
} from './jartapsliTypes';

import api from '../services';
import { config } from '../config';

export const jartapsliDetailReq = () => {
    return { type: JARTAPSLI_DETAIL_REQ }
}

export const jartapsliDetailSuccess = (data) => {
    return { type: JARTAPSLI_DETAIL_SUCCESS, payload: data }
}

export const jartapsliDetailFail = (err) => {
    return { type: JARTAPSLI_DETAIL_FAIL, payload: err }
}

export const jartapsliMessageReq = () => {
    return { type: JARTAPSLI_MESSAGE_REQ }
}

export const jartapsliMessageSuccess = (data) => {
    return { type: JARTAPSLI_MESSAGE_SUCCESS, payload: data }
}

export const jartapsliMessageFail = (err) => {
    return { type: JARTAPSLI_MESSAGE_FAIL, payload: err }
}

export const jartapsliReq = () => {
    return { type: JARTAPSLI_REQ }
}

export const jartapsliSuccess = (data) => {
    return { type: JARTAPSLI_SUCCESS, payload: data }
}

export const jartapsliFail = (err) => {
    return { type: JARTAPSLI_FAIL, payload: err }
}

export const uploadJartapsli = (data) => {
    return async (dispatch) => {
        dispatch(jartapsliMessageReq);
        await api.post('/jartapsli-create-import', data, config)
            .then(res => {
                dispatch(jartapsliMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartapsliMessageFail(err.response.data));
            })
    }
}

export const getAlljartapsli = (id) => {
    return async (dispatch) => {
        dispatch(jartapsliReq);
        await api.get(`/jartapsli`, config)
            .then(res => {
                dispatch(jartapsliSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jartapsliFail(err.response.data));
            })
    }
}

export const updatejartapsli = (id, data) => {
    return async (dispatch) => {
        dispatch(jartapsliMessageReq);
        await api.put(`/jartapsli-update/${id}`, data, config)
            .then(res => {
                dispatch(jartapsliMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartapsliMessageFail(err.response.data));
            })
    }
}

export const getjartapsliDetailById = (id) => {
    return async (dispatch) => {
        dispatch(jartapsliDetailReq);
        await api.get(`/jartapsli/${id}`, config)
            .then(res => {
                dispatch(jartapsliDetailSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jartapsliDetailSuccess(err.response));
            })
    }
}

export const deletejartapsli = (id) => {
    return async (dispatch) => {
        dispatch(jartapsliMessageReq);
        await api.delete(`/jartapsli-delete/${id}`, config)
            .then(res => {
                dispatch(jartapsliMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartapsliMessageFail(err.response.data));
            })
    }
}

export const addJartapsli = (data) => {
    return async (dispatch) => {
        dispatch(jartapsliMessageReq);
        await api.post('/jartapsli-create', data, config)
            .then(res => {
                dispatch(jartapsliMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(jartapsliMessageFail(err.response.data));
            })
    }
}

export const getjartapsliByCompany = (id) => {
    return async (dispatch) => {
        dispatch(jartapsliReq);
        await api.get(`/jartapsli-company/${id}`, config)
            .then(res => {
                dispatch(jartapsliSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(jartapsliFail(err.response.data));
            })
    }
}