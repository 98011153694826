import React from 'react';
import {
    Container,
} from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import Select from "react-select";
import Button from '@mui/material/Button';
import { useForm, Controller } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import swal from 'sweetalert';

import { Sidebar, Navbar, Styles, Spinner } from '../../components';

const EditENodeb4g = ({
    user,
    getUser,
    provinsi,
    getProvinsi,
    enodeb4gDetail,
    enodeb4gMessage,
    getenodeb4gDetailById,
    updateenodeb4g
}) => {

    const { register, handleSubmit, control } = useForm();
    const history = useHistory();
    const { id, nama } = useParams();
    const [userData, setUserData] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const [tahun, setTahun] = React.useState([]);
    const [prov, setProv] = React.useState([]);
    const [valueEdit, setValueEdit] = React.useState([]);

    const [inputTahun, setInputTahun] = React.useState('');
    const [valueProvinsi, setValueProvinsi] = React.useState('');

    React.useEffect(() => {
        setTahun(Tahun().map(t => ({ value: t, label: t })));
    }, []);

    React.useEffect(() => {
        const notification = () => {
            enodeb4gMessage.loading && enodeb4gMessage.enodeb4gMessage && swal(`${enodeb4gMessage.enodeb4gMessage.message}`, {
                buttons: true
            }).then((value) => {
                if (value) {
                    history.goBack();
                }
            });
        }

        return notification();
    }, [enodeb4gMessage, history, id, nama]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            user && user.user && user.user.map(u => setUserData(u));
            user && user.error === "Unauthenticated." && history.push('/');
            provinsi && provinsi.provinsi && setProv(provinsi.provinsi.map(p => ({ value: p.id, label: p.nama_provinsi })));
            enodeb4gDetail && enodeb4gDetail.enodeb4gDetail && setValueEdit(enodeb4gDetail.enodeb4gDetail);
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [history, enodeb4gDetail, provinsi, user]);

    React.useEffect(() => {
        getUser();
        getProvinsi();
        getenodeb4gDetailById(id)
    }, [getProvinsi, getUser, getenodeb4gDetailById, id]);

    const updateSubmitNodeb3g = (data) => {
        const input = {
            data_umum_id: valueEdit.data_umum_id,
            company_name: nama,
            tahun: inputTahun === "" ? valueEdit.tahun : inputTahun,
            jumlah: data.jumlah,
            provinsi: valueProvinsi === "" ? valueEdit.provinsi_id : valueProvinsi
        }
        console.log(input);
        updateenodeb4g(id, input)
    }
    return loading ? <Spinner /> : (
        <>
            <Sidebar menu="DATA PENYELENGGARAAN" />
            <div className="main-panel">
                <Navbar user={userData} />
                <div className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header card-header-icon" data-background-color="blue">
                                        <Styles.ButtonModal
                                            type="button"
                                            onClick={() => history.goBack()}>
                                            <i className="fa fa-chevron-circle-left fa-2x" aria-hidden="true"></i>
                                        </Styles.ButtonModal>
                                    </div>
                                    <h4 className="card-title">Edit Data E Node B 3G {nama}</h4>
                                    <div className="card-content">
                                        {
                                            valueEdit.length = 0 ? <div className="d-flex align-content-center"><Loader type="Bars" color="#00BFFF" height={100} width={100} /></div> :
                                                <form className="form"
                                                    onSubmit={handleSubmit(updateSubmitNodeb3g)}
                                                >
                                                    <Styles.InputForm>
                                                        <Controller
                                                            control={control}
                                                            name="tahun"
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    options={tahun}
                                                                    placeholder="Tahun.."
                                                                    defaultValue={tahun.filter(t => t.value === valueEdit.tahun)}
                                                                    onChange={(e) => setInputTahun(e.value)}
                                                                />
                                                            )}
                                                        />
                                                        {enodeb4gMessage && enodeb4gMessage.error && <span className="text-danger">{enodeb4gMessage.error.tahun}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <Controller
                                                            control={control}
                                                            name="provinsi"
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    options={prov}
                                                                    placeholder="Provinsi.."
                                                                    defaultValue={prov.filter(t => t.value === valueEdit.provinsi_id)}
                                                                    onChange={(e) => setValueProvinsi(e.value)}
                                                                />
                                                            )}
                                                        />
                                                        {enodeb4gMessage && enodeb4gMessage.error && <span className="text-danger">{enodeb4gMessage.error.provinsi}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <input
                                                            placeholder="Jumlah..."
                                                            type="text"
                                                            className="form-control"
                                                            {...register("jumlah")}
                                                            defaultValue={valueEdit.jumlah}
                                                        />
                                                        {enodeb4gMessage && enodeb4gMessage.error && <span className="text-danger">{enodeb4gMessage.error.jumlah}</span>}
                                                    </Styles.InputForm>
                                                    <Button color="primary" variant="contained" type="submit">Save changes</Button>
                                                </form>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default EditENodeb4g

const Tahun = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= 1945; i--) {
        tahun.push(i);
    }
    return tahun;
}