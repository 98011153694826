import {
    PROFIL_UMUM_REQ,
    PROFIL_UMUM_SUCCESS,
    PROFIL_UMUM_FAIL,
    PROFIL_UMUM_MESSAGE_REQ,
    PROFIL_UMUM_MESSAGE_SUCCESS,
    PROFIL_UMUM_MESSAGE_FAIL,
    PROFIL_UMUM_DETAIL_REQ,
    PROFIL_UMUM_DETAIL_SUCCESS,
    PROFIL_UMUM_DETAIL_FAIL,
    PROFIL_UMUM_UPLOAD_REQ,
    PROFIL_UMUM_UPLOAD_SUCCESS,
    PROFIL_UMUM_UPLOAD_FAIL,
} from './profilUmumTypes';

const profilUmumUploadState = {
    loading: false,
    profilUmumUpload: "",
    error: ""
}

const profilUmumDetailState = {
    loading: false,
    profilUmumDetail: [],
    error: ''
}

const profilUmumState = {
    loading: false,
    profilUmum: [],
    error: ''
}

const profilUmumMessageState = {
    loading: false,
    profilUmumMessage: '',
    error: ''
}

export const profilUmumUploadReducer = (state = profilUmumUploadState, action) => {
    switch (action.type) {
        case PROFIL_UMUM_UPLOAD_REQ:
            return {
                ...state,
                loading: false,
            }
        case PROFIL_UMUM_UPLOAD_SUCCESS:
            return {
                loading: true,
                profilUmumUpload: action.payload,
                error: ''
            }
        case PROFIL_UMUM_UPLOAD_FAIL:
            return {
                loading: true,
                profilUmumUpload: '',
                error: action.payload
            }
        default:
            return state
    }
}

export const profilUmumDetailReducer = (state = profilUmumDetailState, action) => {
    switch (action.type) {
        case PROFIL_UMUM_DETAIL_REQ:
            return {
                ...state,
                loading: false,
            }
        case PROFIL_UMUM_DETAIL_SUCCESS:
            return {
                loading: true,
                profilUmumDetail: action.payload,
                error: ''
            }
        case PROFIL_UMUM_DETAIL_FAIL:
            return {
                loading: true,
                profilUmumDetail: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const profilUmumMessageReducer = (state = profilUmumMessageState, action) => {
    switch (action.type) {
        case PROFIL_UMUM_MESSAGE_REQ:
            return {
                ...state,
                loading: false,
            }
        case PROFIL_UMUM_MESSAGE_SUCCESS:
            return {
                loading: true,
                profilUmumMessage: action.payload,
                error: ''
            }
        case PROFIL_UMUM_MESSAGE_FAIL:
            return {
                loading: false,
                profilUmumMessage: '',
                error: action.payload
            }
        default:
            return state
    }
}

const profilUmumReducer = (state = profilUmumState, action) => {
    switch (action.type) {
        case PROFIL_UMUM_REQ:
            return {
                ...state,
                loading: false,
            }
        case PROFIL_UMUM_SUCCESS:
            return {
                loading: true,
                profilUmum: action.payload,
                error: ''
            }
        case PROFIL_UMUM_FAIL:
            return {
                loading: true,
                profilUmum: [],
                error: action.payload
            }
        default:
            return state
    }
}

export default profilUmumReducer