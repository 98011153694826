import React from 'react';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { Sidebar, Navbar, Spinner } from '../../components';

const Jartaplok = ({
    jartaplok,
    getAlljartaplok,
    user,
    getUser
}) => {
    const history = useHistory()
    const [loading, setLoading] = React.useState(true);
    const [userData, setUserData] = React.useState([])
    const [rows, setRows] = React.useState([]);
    React.useEffect(() => {
        document.title = 'Jartaplok | PPI'
    }, []);
    React.useEffect(() => {
        getUser()
        getAlljartaplok()
    }, [getAlljartaplok, getUser]);
    React.useEffect(() => {
        user && user.user && user.user.map(u => setUserData(u));
        user && user.error === "Unauthenticated." && history.push('/');
        const timeout = setTimeout(() => {
            jartaplok && jartaplok.jartaplok &&
                setRows(jartaplok.jartaplok.map((jbt) => ({
                    company_name: jbt.company_name,
                    pelanggan: jbt.pelanggan,
                    kab_kota: jbt.kab_kota,
                    provinsi: jbt.provinsi,
                    kapasitas_sistem_terpasang: jbt.kapasitas_sistem_terpasang,
                    outgoing: jbt.outgoing,
                    incoming: jbt.incoming,
                    arpu: jbt.arpu,
                    tahun: jbt.tahun
                })));
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [history, jartaplok, user]);
    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { minimumFractionDigits: 0 }
        ).format(money);
    }
    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none'
            },
        },
    };
    const columns = [
        {
            name: 'Nama Perusahaan',
            selector: 'company_name'
        },
        {
            name: 'pelanggan',
            selector: r => r.pelanggan
        },
        {
            name: 'Kabupaten/ Kota',
            selector: r => formatRupiah(r.kab_kota),
        },
        {
            name: 'Provinsi',
            selector: r => formatRupiah(r.provinsi),
        },
        {
            name: 'Kapasitas Sitem Terpasang',
            selector: r => formatRupiah(r.kapasitas_sistem_terpasang),
        },
        {
            name: 'Outgoing',
            selector: r => formatRupiah(r.outgoing),
        },
        {
            name: 'Incoming',
            selector: r => formatRupiah(r.incoming),
        },
        {
            name: 'arpu',
            selector: r => formatRupiah(r.arpu)
        },
        {
            name: 'Tahun',
            selector: r => r.tahun
        },
    ];
    return loading ? <Spinner /> : (
        <>
            <Sidebar menu="JARTAPLOK" />
            <div className="main-panel">
                <Navbar menu="JARTAPLOK" user={userData} />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="toolbar">
                                        <div className="card-header card-header-icon" data-background-color="blue">
                                            <i className="material-icons">send</i>
                                        </div>
                                    </div>
                                    <h3 className="title" style={{ fontWeight: 'bold' }}>Jartaplok</h3>
                                    <div className="card-content">
                                        <div className="material-datatables">
                                            <DataTable
                                                customStyles={customStyles}
                                                columns={columns}
                                                data={rows}
                                                progressPending={loading}
                                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100}
                                                    width={100} />}
                                                responsive={true}
                                                pagination />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Jartaplok
