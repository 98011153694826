import React from 'react';
import DataTable from 'react-data-table-component';
import { useParams } from 'react-router';
import Loader from 'react-loader-spinner';
import Select from "react-select";
import swal from 'sweetalert';
import ReactModal from 'react-modal';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

import { Styles, ActionButton, Spinner } from '../../components';

const JaberselPelanggan = ({
    jaberselPelanggan,
    getJaberselPelangganByCompany,
    jaberselPelangganMessage,
    addJaberselPel,
    deleteJaberselPel,
    role,
    uploadJaberselPelanggan
}) => {
    const { id, nama } = useParams();
    const { register, handleSubmit, control } = useForm();
    const [jaberselPelangganData, setJaberselPelangganData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [inputTahun, setInputTahun] = React.useState('');
    const [tahun, setTahun] = React.useState([]);
    const [dataChartValue, setDataChartValue] = React.useState([]);
    const [showModalAddJaberselPelanggan, setShowModalAddJaberselPelanggan] = React.useState(false);
    const [fileUpload, setFileUpload] = React.useState(null);


    const setupModalAddJaberselPelanggan = () => setShowModalAddJaberselPelanggan(!showModalAddJaberselPelanggan);

    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { minimumFractionDigits: 0 }
        ).format(money);
    }
    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none'
            },
        },
    };
    const columns = [
        {
            name: 'No',
            selector: row => row.number,
            maxWidth: '20px'
        },
        {
            name: 'Prabayar',
            selector: row => formatRupiah(row.prabayar),
            minWidth: '120px'
        },
        {
            name: 'Pasca Bayar',
            selector: row => formatRupiah(row.pascabayar)
        },
        {
            name: 'Total Pelanggan Jasa Teleponi',
            selector: row => formatRupiah(row.total_pelanggan_jasa_telepon),
            minWidth: '120px'
        },
        {
            name: 'Populasi',
            selector: row => formatRupiah(row.populasi),
            minWidth: '120px'
        },
        {
            name: 'Teledensitas',
            selector: row => row.teledensitas.toFixed(2) + ' %'
        },
        {
            name: 'Pelanggan Data',
            selector: row => formatRupiah(row.pelanggan_data),
            minWidth: '120px'
        },
        {
            name: 'Tahun',
            selector: 'tahun',
        },
        {
            name: 'Action',
            selector: 'action',
            minWidth: '120px'
        }
    ];
    const columnsGuest = [
        {
            name: 'No',
            selector: row => row.number,
            maxWidth: '20px'
        },
        {
            name: 'Prabayar',
            selector: row => formatRupiah(row.prabayar),
            minWidth: '120px'
        },
        {
            name: 'Pasca Bayar',
            selector: row => formatRupiah(row.pascabayar)
        },
        {
            name: 'Total Pelanggan Jasa Teleponi',
            selector: row => formatRupiah(row.total_pelanggan_jasa_telepon),
            minWidth: '120px'
        },
        {
            name: 'Populasi',
            selector: row => formatRupiah(row.populasi),
            minWidth: '120px'
        },
        {
            name: 'Teledensitas',
            selector: row => row.teledensitas.toFixed(2) + ' %'
        },
        {
            name: 'Pelanggan Data',
            selector: row => formatRupiah(row.pelanggan_data),
            minWidth: '120px'
        },
        {
            name: 'Tahun',
            selector: 'tahun',
        }
    ];
    React.useEffect(() => {
        setTahun(Tahun().map(t => ({ value: t, label: t })));
    }, []);
    React.useState(() => {
        getJaberselPelangganByCompany(id);
    }, [getJaberselPelangganByCompany]);
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            jaberselPelanggan && jaberselPelanggan.jaberselPelanggan && jaberselPelanggan.jaberselPelanggan.hasil &&
                setJaberselPelangganData(jaberselPelanggan.jaberselPelanggan.hasil.map((jaberselpel, index) => ({
                    number: index + 1,
                    prabayar: jaberselpel.prabayar,
                    pascabayar: jaberselpel.pascabayar,
                    total_pelanggan_jasa_telepon: jaberselpel.total_pelanggan_jasa_telepon,
                    populasi: jaberselpel.populasi,
                    teledensitas: jaberselpel.teledensitas * 100,
                    // market_share: jaberselpel.pelanggan_data / jaberselPelanggan.jaberselPelanggan.pembagi,
                    pelanggan_data: jaberselpel.pelanggan_data,
                    tahun: jaberselpel.tahun,
                    action: <>
                        <ActionButton
                            title="fa fa-pencil"
                            css="btn-warning"
                            fun={() => window.location.href = `/penyelenggara-detail-pelanggan-kapasitas-edit/${id}/${nama}/edit/${jaberselpel.id}/jabersel pelanggan`}
                        />
                        &nbsp;
                        <ActionButton title="fa fa-trash" css="btn-danger"
                            fun={
                                () => swal(`Apakah Yakin Data Jabersel ${jaberselpel.company_name} ini akan dihapus?`, {
                                    buttons: ["Batal", true],
                                }).then((value) => {
                                    if (value) {
                                        deleteJaberselPel(jaberselpel.id);
                                    }
                                })
                            }
                        />
                    </>
                })));
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [deleteJaberselPel, id, jaberselPelanggan, nama]);
    React.useEffect(() => {
        jaberselPelangganMessage.loading && jaberselPelangganMessage.jaberselPelangganMessage && swal(`${jaberselPelangganMessage.jaberselPelangganMessage.message}`, {
            buttons: ["cancel", true]
        }).then((value) => {
            if (value) {
                getJaberselPelangganByCompany(id);
            }
        });
    }, [getJaberselPelangganByCompany, id, jaberselPelangganMessage]);
    React.useEffect(() => {
        jaberselPelanggan && jaberselPelanggan.jaberselPelanggan && jaberselPelanggan.jaberselPelanggan.hasil && setDataChartValue({
            pascabayar: jaberselPelanggan.jaberselPelanggan.hasil.map(jp => jp.pascabayar),
            prabayar: jaberselPelanggan.jaberselPelanggan.hasil.map(jp => jp.prabayar),
            total_pelanggan_jasa_telepon: jaberselPelanggan.jaberselPelanggan.hasil.map(jp => jp.total_pelanggan_jasa_telepon),
            populasi: jaberselPelanggan.jaberselPelanggan.hasil.map(jp => jp.populasi),
            pelanggan_data: jaberselPelanggan.jaberselPelanggan.hasil.map(jp => jp.pelanggan_data),
            tahun: jaberselPelanggan.jaberselPelanggan.hasil.map((p) => p.tahun),
        });
    }, [jaberselPelanggan]);

    const dataChartJberselPlel = {
        labels: dataChartValue.tahun,
        datasets: [
            {
                label: `Pasca Bayar`,
                data: dataChartValue.pascabayar,
                backgroundColor: 'rgb(255, 99, 132)',
            },
            {
                label: `prabayar`,
                data: dataChartValue.prabayar,
                backgroundColor: 'rgb(54, 162, 235)',
            },
            {
                label: `Total Pelanggan Jasa Telepon`,
                data: dataChartValue.total_pelanggan_jasa_telepon,
                backgroundColor: 'green',
            },
            {
                label: `Populasi`,
                data: dataChartValue.populasi,
                backgroundColor: 'yellow',
            },
            {
                label: `Pelanggan Data`,
                data: dataChartValue.pelanggan_data,
                backgroundColor: 'purple',
            },
        ],
    }

    const customStylesModal = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: '0',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '700px',
            height: '90%'
        },
    };

    const submitJaberselPelNew = (data) => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            prabayar: data.prabayar,
            pascabayar: data.pascabayar,
            populasi: data.populasi,
            pelanggan_data: data.pelanggan_data,
            tahun: inputTahun,
        }
        addJaberselPel(input)
    }

    const onUpload = (e) => setFileUpload(e.target.files[0]);

    const submitUploadJaberselPelanggan = (data) => {
        const formData = new FormData();
        formData.append("nama_file", fileUpload);
        uploadJaberselPelanggan(formData)
    };

    return loading ? <Spinner /> : jaberselPelanggan.error === "Unauthenticated." ? (
        window.location.href = "/") : (
        <>
            {
                role.role_id !== 3 &&
                <div className="toolbar" style={{ display: 'flex', flexDirection: 'row-reverse', }}>
                    <Button
                        id="basic-button"
                        aria-controls="basic-menu"
                        className="btn btn-sm"
                        size="large"
                        aria-haspopup="true"
                        onClick={setupModalAddJaberselPelanggan}
                        style={{ backgroundColor: 'rgba(0, 50, 122, 1)', color: '#fff', marginRight: '10px' }}
                    >
                        <i className="fa fa-plus" aria-hidden="true"></i> &nbsp; Tambah
                    </Button>
                </div>
            }
            <div className="card-content">
                <div className="material-datatables">
                    <h4 className="card-title text-center">Jaringan Bergerak Seluler Pelanggan</h4>
                    <DataTable
                        columns={role.role_id !== 3 ? columns : columnsGuest}
                        data={jaberselPelangganData}
                        progressPending={loading}
                        progressComponent={<Loader type="Watch" color="#00BFFF" height={100} width={100} />}
                        responsive={true}
                        customStyles={customStyles}
                        pagination />
                    <Bar data={dataChartJberselPlel} />
                </div>
            </div>
            <ReactModal
                ariaHideApp={false}
                isOpen={showModalAddJaberselPelanggan}
                preventScroll={true}
                scrollable={true}
                style={customStylesModal}
            >
                <div style={{ float: 'right' }}>
                    <Button id="basic-button"
                        aria-controls="basic-menu"
                        color="error"
                        onClick={setupModalAddJaberselPelanggan}
                        size="small">
                        <span className="material-icons">
                            close
                        </span>
                    </Button>
                </div>
                <Modal.Title style={{ fontWeight: 'bold' }}>Tambah Jabersel Pelanggan Baru</Modal.Title>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitUploadJaberselPelanggan)}
                >
                    <h5 className="text-center">Import Data Jabersel Pelanggan {nama}</h5>
                    <input
                        placeholder="Nama Perusahaan"
                        type="file"
                        name="nama_file"
                        onChangeCapture={onUpload}
                    />
                    <Link to="/download/jabersel pelanggan.xlsx" target="_blank" style={{ float: 'right' }}>Download Template Upload Jabersel Pelanggan</Link>
                    <br />
                    {
                        jaberselPelangganMessage && jaberselPelangganMessage.error && <span className="text-danger">{jaberselPelangganMessage.error.nama_file}</span>
                    }
                    <br />
                    <Button type="submit" variant="contained" color="primary">Upload</Button>
                    {
                        jaberselPelangganMessage && jaberselPelangganMessage.error && jaberselPelangganMessage.error.message && jaberselPelangganMessage.error.message.map((w, index) => (
                            <ol key={index}>
                                <span className="text-danger">{index + 1}.{w}</span>
                            </ol>
                        ))
                    }
                </form>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitJaberselPelNew)}
                >
                    <Styles.InputForm>
                        <input
                            placeholder="Prabayar..."
                            type="text"
                            className="form-control"
                            {...register("prabayar")}
                        />
                        {jaberselPelangganMessage && jaberselPelangganMessage.error && <span className="text-danger">{jaberselPelangganMessage.error.prabayar}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Pasca Bayar..."
                            type="text"
                            className="form-control"
                            {...register("pascabayar")}
                        />
                        {jaberselPelangganMessage && jaberselPelangganMessage.error && <span className="text-danger">{jaberselPelangganMessage.error.pascabayar}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Populasi..."
                            type="text"
                            className="form-control"
                            {...register("populasi")}
                        />
                        {jaberselPelangganMessage && jaberselPelangganMessage.error && <span className="text-danger">{jaberselPelangganMessage.error.populasi}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Pelanggan Data..."
                            type="text"
                            className="form-control"
                            {...register("pelanggan_data")}
                        />
                        {jaberselPelangganMessage && jaberselPelangganMessage.error && <span className="text-danger">{jaberselPelangganMessage.error.pelanggan_data}</span>}
                    </Styles.InputForm>

                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="Tahun"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={tahun}
                                    placeholder="Tahun.."
                                    onChange={(e) => setInputTahun(e.value)}
                                />
                            )}
                        />
                        {jaberselPelangganMessage && jaberselPelangganMessage.error && <span className="text-danger">{jaberselPelangganMessage.error.tahun}</span>}
                    </Styles.InputForm>
                    <Button color="error" variant="contained" onClick={setupModalAddJaberselPelanggan} style={{ marginRight: 5 }}>Close</Button>
                    <Button color="primary" variant="contained" type="submit">Save</Button>
                </form>
            </ReactModal>
        </>
    )
}

export default JaberselPelanggan

const Tahun = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= 1945; i--) {
        tahun.push(i);
    }
    return tahun;
}