import React from 'react'
import { Modal } from 'react-bootstrap';
import Button from '@mui/material/Button';
import DataTable from 'react-data-table-component';
import { Controller, useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import ReactModal from 'react-modal';
import Select from 'react-select';
import { ActionButton, FilterComponentSelect, Spinner, Styles } from '../../components';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';

const TeknologiSection = ({
    getTeknologi,
    teknologi,
    getTeknologiCrud,
    teknologiCrud,
    teknologiMessage,
    addTeknologi,
    teknologiPenyelenggara,
    getTeknologiPenyelenggara,
    getTeknologiIzin,
    teknologiIzin,
    teknologiIzinTanggal,
    getTeknologiIzinTanggal,
    getNamaTeknologi,
    namaTeknologi,
    deleteTeknologi,
    uploadteknologi
}) => {
    const tahun = new Date().getFullYear();
    const [teknologiState, setTeknologiState] = React.useState([]);
    const { handleSubmit, control } = useForm();
    const [rowCrud, setRowCrud] = React.useState([]);
    const [tahunSelect, setTahunSelect] = React.useState([])
    const [tahunTeknologi, setTahunTeknologi] = React.useState(tahun);
    const [teknologiPenyelenggaraState, setTeknologiPenyelenggaraState] = React.useState([]);
    const [teknologiIzinState, setTeknologiIzinState] = React.useState([]);
    const [teknologiTanggalState, setTeknologiTanggalState] = React.useState([]);
    const [namaTeknologiState, setNamaTeknologiState] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [showModalAddTeknologi, setShowModalAddTeknologi] = React.useState(false);
    const [crud, setCrud] = React.useState(true);
    const [fileUpload, setFileUpload] = React.useState(null);


    const [valueNamaPerusaan, setValueNamaPerusaan] = React.useState('');
    const [teknologiIzinValue, setTeknologiIzinValue] = React.useState('');
    const [tanggalIzinValue, setTanggalIzinValue] = React.useState('');
    const [namaTeknologiValue, setNamaTeknologiValue] = React.useState('');
    const [tahunInput, setTahunInput] = React.useState('');

    React.useEffect(() => {
        setTahunSelect(Tahun().map(t => t));
    }, []);

    // API
    React.useEffect(() => {
        teknologiMessage && teknologiMessage.teknologiMessage && swal(`${teknologiMessage.teknologiMessage.message}`, {
            buttons: true
        }).then(value => {
            if (value) {
                getTeknologi(tahunTeknologi);
                getTeknologiCrud();
                getTeknologiPenyelenggara();
            }
        })
    }, [getTeknologi, getTeknologiCrud, getTeknologiPenyelenggara, tahunTeknologi, teknologiMessage]);
    React.useEffect(() => {
        namaTeknologi && namaTeknologi.namaTeknologi.length > 0 && setNamaTeknologiState(namaTeknologi.namaTeknologi.map(techName => ({ value: techName.id, label: techName.teknologi })))
    }, [namaTeknologi]);
    React.useEffect(() => {
        teknologiIzinTanggal && teknologiIzinTanggal.teknologiIzinTanggal && setTeknologiTanggalState(teknologiIzinTanggal.teknologiIzinTanggal.map(tglIzin => ({ value: tglIzin.permit_date, label: tglIzin.permit_date })))
    }, [teknologiIzinTanggal]);
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            teknologiIzin && teknologiIzin.teknologiIzin.length > 0 && setTeknologiIzinState(teknologiIzin.teknologiIzin.map(izin => ({ value: izin.permit_name, label: izin.permit_name })))
            teknologiPenyelenggara && teknologiPenyelenggara.teknologiPenyelenggara.length > 0 && setTeknologiPenyelenggaraState(teknologiPenyelenggara.teknologiPenyelenggara.map(t => ({ value: t.id, label: t.company_name })));
            teknologiCrud && teknologiCrud.teknologiCrud && setRowCrud(teknologiCrud.teknologiCrud.map((tech) => ({
                company_name: tech.company_name,
                tanggal_izin: tech.tanggal_izin,
                jenis_izin: tech.jenis_izin,
                teknologi: tech.teknologi,
                nilai: tech.nilai === 1 ? "Ada" : "Tidak Ada",
                tahun: tech.tahun,
                action: <ActionButton title="fa fa-trash" css="btn-danger"
                    fun={
                        () => swal(`Apakah Yakin Data Teknologi ${tech.company_name} tahun ${tech.tahun} ini akan dihapus?`, {
                            buttons: ["Batal", true],
                        }).then((value) => {
                            if (value) {
                                deleteTeknologi(tech.id);
                            }
                        })
                    }
                />,
            })));
            teknologi && teknologi.teknologi && setTeknologiState(teknologi.teknologi.map((tech) => ({
                company_name: tech.company_name,
                tanggal_izin: tech.tanggal_izin,
                jenis_izin: tech.jenis_izin,
                bwa: tech['BWA'] === 1 ? <i className="fas fa-check-circle"></i> : tech['BWA'],
                fiber_optik: tech['FIBER OPTIC'] === 1 ? <i className="fas fa-check-circle"></i> : tech['FIBER OPTIC'],
                microwave_link: tech['MICROWAVE LINK'] === 1 ? <i className="fas fa-check-circle"></i> : tech['MICROWAVE LINK'],
                satelit: tech['SATELIT'] === 1 ? <i className="fas fa-check-circle"></i> : tech['SATELIT'] === 1,
                seluler: tech.SELULAR === 1 ? <i className="fas fa-check-circle"></i> : tech.SELULAR,
                trunking: tech['TRUNKING'] === 1 ? <i className="fas fa-check-circle"></i> : tech['TRUNKING'],
                vsat: tech['VSAT'] === 1 ? <i className="fas fa-check-circle"></i> : tech['VSAT'],
            })));
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [deleteTeknologi, teknologi, teknologiCrud, teknologiIzin, teknologiPenyelenggara]);
    React.useEffect(() => {
        getTeknologi(tahunTeknologi);
        getTeknologiCrud();
        getTeknologiPenyelenggara();
    }, [getTeknologi, getTeknologiCrud, getTeknologiPenyelenggara, tahunTeknologi]);

    // Table
    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none',
            },
        },
    };
    const columnsCrud = [
        {
            name: 'No.',
            selector: (_, index) => index + 1,
            maxWidth: '10px'
        },
        {
            name: 'Nama Perusahaan',
            selector: row => row.company_name,
            minWidth: '220px'
        },
        {
            name: 'Tanggal Izin',
            selector: row => row.tanggal_izin,
            minWidth: '120px'
        },
        {
            name: 'Jenis Izin',
            selector: row => row.jenis_izin,
            width: '130px'
        },
        {
            name: 'Teknologi',
            selector: row => row.teknologi,
            minWidth: '150px'
        },
        {
            name: 'Nilai',
            selector: row => row.nilai,
        },
        {
            name: 'Tahun',
            selector: row => row.tahun,
        },
        {
            name: 'Action',
            selector: row => row.action
        },
    ];
    const columns = [
        {
            name: 'No.',
            selector: (_, index) => index + 1,
            minWidth: '10px'
        },
        {
            name: 'Nama Perusahaan',
            selector: row => row.company_name,
            minWidth: '220px'
        },
        {
            name: 'Tanggal Izin',
            selector: row => row.tanggal_izin
        },
        {
            name: 'Jenis Izin',
            selector: row => row.jenis_izin,
            minWidth: '130px'
        },
        {
            name: 'SATELIT',
            selector: row => row.satelit,
            style: {
                paddingLeft: '30px',
            },
        },
        {
            name: 'VSAT',
            selector: row => row.vsat,
            style: {
                paddingLeft: '30px',
            },
        },
        {
            name: 'FIBER OPTIK',
            selector: row => row.fiber_optik,
            minWidth: '120px',
            style: {
                paddingLeft: '30px',
            },
        },
        {
            name: 'MICROWAVE LINK',
            selector: row => row.microwave_link,
            minWidth: '160px'
        },
        {
            name: 'SELULAR',
            selector: row => row.seluler,
            style: {
                paddingLeft: '30px',
            },
        },
        {
            name: 'BWA',
            selector: row => row.bwa,
            minWidth: '10px'
        },
        {
            name: 'TRUNKING',
            selector: row => row.trunking
        },

    ];

    // function

    const addTeknologiFunc = (data) => {
        const input = {
            company_name: valueNamaPerusaan,
            jenis_izin: teknologiIzinValue,
            tanggal_izin: tanggalIzinValue,
            teknologi: namaTeknologiValue,
            nilai: 1,
            tahun: tahunInput
        }
        console.log(input);
        addTeknologi(input);
    }
    const customStylesModal = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '500px',
            height: 'auto'
        },
    };
    const toCrud = () => setCrud(!crud);
    const setUpModalAddTeknologi = () => setShowModalAddTeknologi(!showModalAddTeknologi);

    const subHeaderComponentMemo = React.useMemo(() => {
        return <div className="row">
            <div className="col-md-12">
                <select className="form-control"
                    onChange={(e) => setTahunTeknologi(e.target.value)}>
                    {
                        tahunSelect.map((t, index) => (
                            <option value={t} key={index}>{t}</option>
                        ))
                    }
                </select>
            </div>
        </div>
    }, [tahunSelect]);

    const onUpload = (e) => setFileUpload(e.target.files[0]);

    const submitUploadTeknologi = (data) => {
        const formData = new FormData();
        formData.append("nama_file", fileUpload);
        uploadteknologi(formData)
    };

    return loading ? <Spinner /> : teknologi.error === "Unauthenticated." ? (
        window.location.href = "/") : (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header card-header-icon" data-background-color="red">
                            <i className="fas fa-broadcast-tower" aria-hidden="true"></i>
                        </div>
                        <h3 className="title" style={{ fontWeight: 'bold' }}>Teknologi Jaringan</h3>
                        <div className="toolbar" style={{ display: 'flex', flexDirection: 'row-reverse', bottom: 0, marginRight: 20 }}>
                            <Button
                                id="basic-button"
                                aria-controls="basic-menu"
                                size="large"
                                className={crud ? "btn btn-success" : "btn btn-danger"}
                                onClick={toCrud}
                            >
                                <i className={crud ? "fa fa-edit" : "fa fa-undo-alt"} aria-hidden="true"> </i>&nbsp;{crud ? " Edit" : " Kembali"}
                            </Button>
                            <Button
                                id="basic-button"
                                aria-controls="basic-menu"
                                size="large"
                                className="btn btn-info"
                                onClick={setUpModalAddTeknologi}
                                style={{ marginRight: 10 }}
                            >
                                <i className="fa fa-plus-circle" aria-hidden="true"></i> &nbsp;Tambah
                            </Button>
                        </div>
                        <div className="card-content">
                            <div className="material-datatables">
                                {
                                    crud ? <DataTable
                                        columns={columns}
                                        data={teknologiState}
                                        progressPending={loading}
                                        progressComponent={<Loader type="Rings" color="#00BFFF" />}
                                        responsive={true}
                                        customStyles={customStyles}
                                        striped={true}
                                        highlightOnHover={true}
                                        subHeaderComponent={subHeaderComponentMemo}
                                        subHeader
                                        pagination /> :
                                        <DataTable
                                            columns={columnsCrud}
                                            data={rowCrud}
                                            progressPending={loading}
                                            progressComponent={<Loader type="Rings" color="#00BFFF" />}
                                            responsive={true}
                                            striped={true}
                                            highlightOnHover={true}
                                            customStyles={customStyles}
                                            pagination />
                                }

                                <ReactModal
                                    ariaHideApp={false}
                                    isOpen={showModalAddTeknologi}
                                    preventScroll={true}
                                    scrollable={true}
                                    onHide={setUpModalAddTeknologi}
                                    style={customStylesModal}
                                >
                                    <div style={{ float: 'right' }}>
                                        <Button id="basic-button"
                                            aria-controls="basic-menu"
                                            color="error"
                                            onClick={setUpModalAddTeknologi}
                                            size="small">
                                            <span className="material-icons">
                                                close
                                            </span>
                                        </Button>
                                    </div>
                                    <Modal.Title style={{ fontWeight: 'bold' }}>Tambah Teknologi Baru</Modal.Title>
                                    <hr />
                                    <form className="form"
                                        onSubmit={handleSubmit(submitUploadTeknologi)}
                                    >
                                        <input
                                            placeholder="Nama Perusahaan"
                                            type="file"
                                            onChangeCapture={onUpload}
                                            name="nama_file"
                                        />
                                        <Link to="/download/data teknologi.xlsx" target="_blank" style={{ float: 'right' }}>Download Template Upload Teknologi</Link>
                                        <br />
                                        {
                                            teknologiMessage && teknologiMessage.error && <span className="text-danger">{teknologiMessage.error.nama_file}</span>
                                        }
                                        <br />
                                        <Button type="submit" variant="contained" color="primary">Upload</Button>
                                        {
                                            teknologiMessage && teknologiMessage.error && teknologiMessage.error.message && teknologiMessage.error.message.map((w, index) => (
                                                <ol key={index}>
                                                    <span className="text-danger">{index + 1}.{w}</span>
                                                </ol>
                                            ))
                                        }
                                    </form>
                                    <hr />
                                    <form onSubmit={handleSubmit(addTeknologiFunc)}>
                                        <Styles.InputForm>
                                            <Controller
                                                control={control}
                                                name="company_name"
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        options={teknologiPenyelenggaraState}
                                                        placeholder="Pilih Perusahaan.."
                                                        onChange={(e) => {
                                                            getTeknologiIzin(e.value);
                                                            setValueNamaPerusaan(e.value);
                                                        }}
                                                    />
                                                )}
                                            />
                                            {teknologiMessage && teknologiMessage.error && <span className="text-danger">{teknologiMessage.error.company_name}</span>}
                                        </Styles.InputForm>
                                        <Styles.InputForm>
                                            <Controller
                                                control={control}
                                                name="jenis_izin"
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        options={teknologiIzinState}
                                                        placeholder="Pilih Jenis Izin.."
                                                        // onChange={e => console.log(e)}
                                                        onChange={e => {
                                                            getNamaTeknologi(e.value);
                                                            setTeknologiIzinValue(e.value);
                                                            getTeknologiIzinTanggal(e.value, valueNamaPerusaan);
                                                        }}
                                                    />
                                                )}
                                            />
                                            {teknologiMessage && teknologiMessage.error && <span className="text-danger">{teknologiMessage.error.jenis_izin}</span>}
                                        </Styles.InputForm>
                                        <Styles.InputForm><Controller
                                            control={control}
                                            name="teknologi"
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    options={namaTeknologiState}
                                                    placeholder="Pilih Nama Teknologi.."
                                                    onChange={(e) => setNamaTeknologiValue(e.value)}
                                                />
                                            )}
                                        />
                                            {teknologiMessage && teknologiMessage.error && <span className="text-danger">{teknologiMessage.error.teknologi}</span>}
                                        </Styles.InputForm>

                                        <Styles.InputForm><Controller
                                            control={control}
                                            name="Tanggal Izin"
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    options={teknologiTanggalState}
                                                    placeholder="Pilih Tanggal Izin.."
                                                    onChange={(e) => setTanggalIzinValue(e.value)}
                                                />
                                            )}
                                        />
                                            {teknologiMessage && teknologiMessage.error && <span className="text-danger">{teknologiMessage.error.tanggal_izin}</span>}
                                        </Styles.InputForm>
                                        <FilterComponentSelect yearValue={tahun} fun={e => setTahunInput(e)} />
                                        {teknologiMessage && teknologiMessage.error && <span className="text-danger">{teknologiMessage.error.tahun}</span>}
                                        <Button onClick={setUpModalAddTeknologi} variant="contained" color="error" style={{ marginRight: 5 }}>
                                            Close
                                        </Button>
                                        <Button type="submit" variant="contained" color="primary">Submit</Button>
                                    </form>
                                </ReactModal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TeknologiSection

const Tahun = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= year - 10; i--) {
        tahun.push(i);
    }
    return tahun;
}