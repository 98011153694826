import React from 'react';


const FilterComponent = ({ filterText, onFilter, placeholder }) => {
	return (
		<div className="input-group">
			<input
				id="search"
				type="text"
				className="form-control"
				placeholder={placeholder}
				value={filterText}
				onChange={onFilter} />
			<span className="input-group-addon">
				<i className="material-icons" >search</i>
			</span>
		</div>
	)
}

export default FilterComponent
