import Login from './Login';
import { connect } from 'react-redux';
import { login, getUser, forgotPassword } from '../../redux';

const mapStateToProps = state => {
    return {
        loginData: state.login,
        forgotPass: state.forgotPass,
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        login: (data) => dispatch(login(data)),
        forgotPassword: (email) => dispatch(forgotPassword(email)),
        getUser: () => dispatch(getUser()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)