import { getbtsByCompanyCrud, addbts, getProv, getbtsByCompanyPivot, uploadBts, deleteBts } from '../../redux';
import Bts from './Bts';
import { connect } from 'react-redux';

const mapStateToProps = state => {
    return {
        bts: state.bts,
        btsCrud: state.btsCrud,
        btsMessage: state.btsMessage,
        provinsi: state.provinsi
    }
}

const mapDispatchToProps = dispatch => {
    return {
        uploadBts: (data) => dispatch(uploadBts(data)),
        getbtsByCompanyPivot: (id, from, to) => dispatch(getbtsByCompanyPivot(id, from, to)),
        getbtsByCompanyCrud: (id) => dispatch(getbtsByCompanyCrud(id)),
        addbts: (data) => dispatch(addbts(data)),
        deleteBts: (id) => dispatch(deleteBts(id)),
        getProv: () => dispatch(getProv()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Bts)