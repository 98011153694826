import EditENodeb4g from './EditENodeb4g';
import { connect } from 'react-redux';
import {
    getenodeb4gDetailById,
    getProv,
    getUser,
    updateenodeb4g
} from '../../redux';

const mapStateToProps = state => {
    return {
        enodeb4gDetail: state.enodeb4gDetail,
        user: state.user,
        enodeb4gMessage: state.enodeb4gMessage,
        provinsi: state.provinsi
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getenodeb4gDetailById: (id) => dispatch(getenodeb4gDetailById(id)),
        updateenodeb4g: (id, data) => dispatch(updateenodeb4g(id, data)),
        getUser: () => dispatch(getUser()),
        getProvinsi: () => dispatch(getProv()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditENodeb4g);
