import React from 'react';
import DataTable from 'react-data-table-component';
import { useParams } from 'react-router';
import Loader from 'react-loader-spinner';
import ReactModal from 'react-modal';
import { ListGroup, Modal, TabContent } from 'react-bootstrap';
import Select from "react-select";
import swal from 'sweetalert';
import { useForm, Controller } from 'react-hook-form';
import Button from '@mui/material/Button';

import { Styles, ActionButton, Spinner } from '../../components';
import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';

const Jabertrunk = ({
    jabertrunk,
    getJabertrunkByCompany,
    jabertrunkMessage,
    addJabertrunk,
    deleteJabertrunk,
    role,
    uploladJabertrunk
}) => {
    const { id, nama } = useParams();
    const { register, handleSubmit, control } = useForm();
    const [loading, setLoading] = React.useState(true);
    const [jabertrunkData, setJabertrunkData] = React.useState([]);
    const [dataChartValue, setDataChartValue] = React.useState([]);
    const [tahun, setTahun] = React.useState([]);
    const [inputTahun, setInputTahun] = React.useState('');
    const [fileUpload, setFileUpload] = React.useState(null);


    const [showModalAddJabetrunk, setShowModalAddJabertrunk] = React.useState(false);
    const setupModalAddJabertrunk = () => setShowModalAddJabertrunk(!showModalAddJabetrunk);

    React.useEffect(() => {
        setTahun(Tahun().map(t => ({ value: t, label: t })));
    }, []);

    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none'
            },
        },
    };

    const customStylesModal = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: '0',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '700px',
            height: '90%'
        },
    };

    const columns = [
        {
            name: 'Frekuensi',
            selector: 'frekuensi'
        },
        {
            name: 'Pelanggan',
            selector: 'pelanggan',
        },
        {
            name: 'Switching',
            selector: 'switching',
        },
        {
            name: 'BTS',
            selector: 'bts',
        },
        {
            name: 'Kapasitas Pelanggan',
            selector: 'kapasitas_pelanggan',
        },
        {
            name: 'Tahun',
            selector: 'tahun',
        },
        {
            name: 'Action',
            selector: 'action'
        }
    ];
    const columnsGuest = [
        {
            name: 'Frekuensi',
            selector: 'frekuensi'
        },
        {
            name: 'Pelanggan',
            selector: 'pelanggan',
        },
        {
            name: 'Switching',
            selector: 'switching',
        },
        {
            name: 'BTS',
            selector: 'bts',
        },
        {
            name: 'Kapasitas Pelanggan',
            selector: 'kapasitas_pelanggan',
        },
        {
            name: 'Tahun',
            selector: 'tahun',
        }
    ];
    React.useState(() => {
        getJabertrunkByCompany(id);
    }, [getJabertrunkByCompany]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            jabertrunk.loading && jabertrunk.jabertrunk &&
                setJabertrunkData(jabertrunk.jabertrunk.map((trunk) => ({
                    frekuensi: trunk.frekuensi,
                    pelanggan: trunk.pelanggan,
                    switching: trunk.switching,
                    bts: trunk.bts,
                    kapasitas_pelanggan: trunk.kapasitas_pelanggan,
                    tahun: trunk.tahun,
                    action: <>
                        <ActionButton
                            title="fa fa-pencil"
                            css="btn-warning"
                            fun={() => window.location.href = `/penyelenggara-detail-pelanggan-kapasitas-edit/${id}/${nama}/edit/${trunk.id}/jabertrunk`}
                        />
                        &nbsp;
                        <ActionButton title="fa fa-trash" css="btn-danger"
                            fun={
                                () => swal(`Apakah Yakin Data trunk ${trunk.company_name} ini akan dihapus?`, {
                                    buttons: ["Batal", true],
                                }).then((value) => {
                                    if (value) {
                                        deleteJabertrunk(trunk.id);
                                    }
                                })
                            }
                        />
                    </>
                })));
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [deleteJabertrunk, id, jabertrunk.jabertrunk, jabertrunk.loading, nama]);
    React.useEffect(() => {
        jabertrunk && jabertrunk.jabertrunk && setDataChartValue({
            pelanggan: jabertrunk.jabertrunk.map(jp => jp.pelanggan),
            switching: jabertrunk.jabertrunk.map(jp => jp.switching),
            bts: jabertrunk.jabertrunk.map(jp => jp.bts),
            kapasitas_pelanggan: jabertrunk.jabertrunk.map(jp => jp.kapasitas_pelanggan),
            tahun: jabertrunk.jabertrunk.map((p) => p.tahun),
        });
    }, [jabertrunk]);
    React.useEffect(() => {
        jabertrunkMessage.loading && jabertrunkMessage.jabertrunkMessage && swal(`${jabertrunkMessage.jabertrunkMessage.message}`, {
            buttons: ["cancel", true]
        }).then((value) => {
            if (value) {
                getJabertrunkByCompany(id);
            }
        });
    }, [getJabertrunkByCompany, id, jabertrunkMessage]);

    const dataChartJabertrunk = {
        labels: dataChartValue.tahun,
        datasets: [
            {
                label: `Pelanggan`,
                data: dataChartValue.pelanggan,
                backgroundColor: 'yellow',
            },
            {
                label: `switching`,
                data: dataChartValue.switching,
                backgroundColor: 'green',
            },
            {
                label: `BTS`,
                data: dataChartValue.bts,
                backgroundColor: 'blue',
            },
            {
                label: `Kapasitas Pelanggan`,
                data: dataChartValue.kapasitas_pelanggan,
                backgroundColor: 'purple',
            },
        ],
    };

    const submitJabertrunk = (data) => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            frekuensi: data.frekuensi,
            pelanggan: data.pelanggan,
            switching: data.switching,
            bts: data.bts,
            kapasitas_pelanggan: data.kapasitas_pelanggan,
            tahun: inputTahun
        }
        addJabertrunk(input);
    }

    const onUpload = (e) => setFileUpload(e.target.files[0]);

    const submitUploadJabertrunk = (data) => {
        const formData = new FormData();
        formData.append("nama_file", fileUpload);
        uploladJabertrunk(formData)
    };

    return loading ? <Spinner /> : jabertrunk.error === "Unauthenticated." ? (
        window.location.href = "/") : (
        <>
            <ListGroup as="ul" className="nav nav-tabs" id="myTab" role="tablist">
                <ListGroup as="li" className="nav-item active" role="presentation">
                    <a className="nav-link active" id="kapasitas-tab" data-toggle="tab" href="#kapasitas" role="tab" aria-controls="kapasitas" aria-selected="true">Kapasitas Pelanggan</a>
                </ListGroup>
                {/* <ListGroup as="li" className="nav-item" role="presentation">
                    <a className="nav-link" id="existing-tab" data-toggle="tab" href="#existing" role="tab" aria-controls="existing" aria-selected="false">Existing</a>
                </ListGroup> */}
            </ListGroup>
            <TabContent>
                <div className="tab-pane active" id="kapasitas" role="tabpanel" aria-labelledby="kapasitas-tab">
                    {
                        role && role.role_id !== 3 &&
                        <div className="toolbar" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                            <Button
                                id="basic-button"
                                aria-controls="basic-menu"
                                size="large"
                                onClick={setupModalAddJabertrunk}
                                style={{ backgroundColor: 'rgba(0, 50, 122, 1)', color: '#fff', marginRight: '10px' }}
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i> &nbsp; Tambah
                            </Button>
                        </div>
                    }
                    <div className="card-content">
                        <div className="material-datatables">
                            <DataTable
                                columns={role.role_id !== 3 ? columns : columnsGuest}
                                data={jabertrunkData}
                                progressPending={loading}
                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100} width={100} />}
                                responsive={true}
                                customStyles={customStyles}
                                pagination />
                            <Bar data={dataChartJabertrunk} />
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="existing" role="tabpanel" aria-labelledby="existing-tab">
                    <p>Existing</p>
                </div>
                <ReactModal
                    ariaHideApp={false}
                    isOpen={showModalAddJabetrunk}
                    preventScroll={true}
                    scrollable={true}
                    style={customStylesModal}
                >
                    <div style={{ float: 'right' }}>
                        <Button id="basic-button"
                            aria-controls="basic-menu"
                            color="error"
                            onClick={setupModalAddJabertrunk}
                            size="small">
                            <span className="material-icons">
                                close
                            </span>
                        </Button>
                    </div>
                    <Modal.Title style={{ fontWeight: 'bold' }}>Tambah Jabertrunk Baru</Modal.Title>
                    <hr />

                    <form className="form"
                        onSubmit={handleSubmit(submitUploadJabertrunk)}
                    >
                        <input
                            placeholder="Nama Perusahaan"
                            type="file"
                            onChangeCapture={onUpload}
                            name="nama_file"
                        />
                        <Link to="/download/jabertrunk.xlsx" target="_blank" style={{ float: 'right' }}>Download Template Upload Jabertrunk</Link>
                        <br />
                        {
                            jabertrunkMessage && jabertrunkMessage.error && <span className="text-danger">{jabertrunkMessage.error.nama_file}</span>
                        }
                        <br />
                        <Button type="submit" variant="contained" color="primary">Upload</Button>
                        {
                            jabertrunkMessage && jabertrunkMessage.error && jabertrunkMessage.error.message && jabertrunkMessage.error.message.map((w, index) => (
                                <ol key={index}>
                                    <span className="text-danger">{index + 1}.{w}</span>
                                </ol>
                            ))
                        }
                    </form>
                    <hr />
                    <form className="form"
                        onSubmit={handleSubmit(submitJabertrunk)}
                    >
                        <Styles.InputForm>
                            <input
                                placeholder="Frekuensi..."
                                type="text"
                                className="form-control"
                                {...register("frekuensi")}
                            />
                            {jabertrunkMessage && jabertrunkMessage.error && <span className="text-danger">{jabertrunkMessage.error.frekuensi}</span>}
                        </Styles.InputForm>
                        <Styles.InputForm>
                            <input
                                placeholder="Pelanggan..."
                                type="text"
                                className="form-control"
                                {...register("pelanggan")}
                            />
                            {jabertrunkMessage && jabertrunkMessage.error && <span className="text-danger">{jabertrunkMessage.error.pelanggan}</span>}
                        </Styles.InputForm>
                        <Styles.InputForm>
                            <input
                                placeholder="Swiching..."
                                type="text"
                                className="form-control"
                                {...register("switching")}
                            />
                            {jabertrunkMessage && jabertrunkMessage.error && <span className="text-danger">{jabertrunkMessage.error.switching}</span>}
                        </Styles.InputForm>
                        <Styles.InputForm>
                            <input
                                placeholder="BTS..."
                                type="text"
                                className="form-control"
                                {...register("bts")}
                            />
                            {jabertrunkMessage && jabertrunkMessage.error && <span className="text-danger">{jabertrunkMessage.error.bts}</span>}
                        </Styles.InputForm>
                        <Styles.InputForm>
                            <input
                                placeholder="Kapasitas Pelanggan..."
                                type="text"
                                className="form-control"
                                {...register("kapasitas_pelanggan")}
                            />
                            {jabertrunkMessage && jabertrunkMessage.error && <span className="text-danger">{jabertrunkMessage.error.kapasitas_pelanggan}</span>}
                        </Styles.InputForm>
                        <Styles.InputForm>
                            <Controller
                                control={control}
                                name="tahun"
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={tahun}
                                        placeholder="Tahun.."
                                        onChange={(e) => setInputTahun(e.value)}
                                    />
                                )}
                            />
                            {jabertrunkMessage && jabertrunkMessage.error && <span className="text-danger">{jabertrunkMessage.error.tahun}</span>}
                        </Styles.InputForm>
                        <Button variant="contained" color="error" onClick={setupModalAddJabertrunk} style={{ marginRight: 5 }}>Close</Button>
                        <Button type="submit" variant="contained" color="primary">Save</Button>
                    </form>
                </ReactModal>
            </TabContent>
        </>
    )
}

export default Jabertrunk

const Tahun = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= 1945; i--) {
        tahun.push(i);
    }
    return tahun;
}