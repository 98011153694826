import {
    USER_REQ,
    USER_SUCCESS,
    USER_FAIL,
    USER_MESSAGE_REQ,
    USER_MESSAGE_SUCCESS,
    USER_MESSAGE_FAIL,
} from './userTypes';

import api from '../services';
import { config } from '../config';

export const userMessageReq = () => {
    return { type: USER_MESSAGE_REQ }
}

export const userMessageSuccess = (data) => {
    return { type: USER_MESSAGE_SUCCESS, payload: data }
}

export const userMessageFail = (err) => {
    return { type: USER_MESSAGE_FAIL, payload: err }
}

export const userReq = () => {
    return { type: USER_REQ }
}

export const userSuccess = (data) => {
    return { type: USER_SUCCESS, payload: data }
}

export const userFail = (err) => {
    return { type: USER_FAIL, payload: err }
}

export const updateUser = (data) => {
    return async (dispatch) => {
        dispatch(userMessageReq);
        await api.post('/user-update', data, config)
            .then(res => dispatch(userMessageSuccess(res.data)))
            .catch(err => dispatch(userMessageFail(err.response.data)))
    }
}

export const getUser = () => {
    return (dispatch) => {
        dispatch(userReq);
        api.get('/user', config)
            .then(res => {
                const data = res.data.data;
                dispatch(userSuccess(data));
            })
            .catch(err => {
                const errMsg = err.response.data.message;
                dispatch(userFail(errMsg));
                errMsg === 'Unauthenticated.' && localStorage.getItem('token') !== null && localStorage.removeItem('token')
            })
    }
}