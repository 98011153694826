import React from 'react';
import DataTable from 'react-data-table-component';
import Loader from 'react-loader-spinner';
import { /*useHistory,*/ useParams } from 'react-router';
import { Spinner } from '../../components';


const MarketShare = ({
    marketSharePendapatan,
    marketSharePelanggan,
    getMarketSharePendapatan,
    getMarketSharePelanggan
}) => {
    const { id } = useParams();
    const [tahun, setTahun] = React.useState([]);
    const [tahunPelanggan, setTahunPelanggan] = React.useState([]);
    const [marketSharePendapatanState, setMarketSharePendapatanState] = React.useState([]);
    const [marketSharePelangganState, setMarketSharePelangganState] = React.useState([]);
    const [loading, setLoading] = React.useState(true);


    React.useEffect(() => {
        const timeout = setTimeout(() => {
            marketSharePendapatan.loading && marketSharePendapatan.data && marketSharePendapatan.data.length > 0 && setTahun(Object.keys(marketSharePendapatan.data[0]));
            marketSharePendapatan.loading && marketSharePendapatan.data && setMarketSharePendapatanState(
                marketSharePendapatan.data);
            marketSharePelanggan.loading && marketSharePelanggan.data && marketSharePelanggan.data.length > 0 && setTahunPelanggan(Object.keys(marketSharePelanggan.data[0]));
            marketSharePelanggan.loading && marketSharePelanggan.data && setMarketSharePelangganState(marketSharePelanggan.data);
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [marketSharePelanggan, marketSharePendapatan]);

    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none'
            },
        },
    };

    const columnPelanggan = [
        {
            name: `Nama Penyelenggara`,
            selector: row => row.company_name
        },
        {
            name: `${tahunPelanggan.length > 0 ? tahunPelanggan[1].slice(6) : tahunPelanggan}`,
            selector: tahunPelanggan.length > 0 ? tahunPelanggan[1] : tahunPelanggan
        },
        {
            name: `${tahunPelanggan.length > 0 ? tahunPelanggan[2].slice(6) : tahunPelanggan}`,
            selector: tahunPelanggan.length > 0 ? tahunPelanggan[2] : tahunPelanggan
        },
        {
            name: `${tahunPelanggan.length > 0 ? tahunPelanggan[3].slice(6) : tahunPelanggan}`,
            selector: tahunPelanggan.length > 0 ? tahunPelanggan[3] : tahunPelanggan
        },
        {
            name: `${tahunPelanggan.length > 0 ? tahunPelanggan[4].slice(6) : tahunPelanggan}`,
            selector: tahunPelanggan.length > 0 ? tahunPelanggan[4] : tahunPelanggan
        },
        {
            name: `${tahunPelanggan.length > 0 ? tahunPelanggan[5].slice(6) : tahunPelanggan}`,
            selector: tahunPelanggan.length > 0 ? tahunPelanggan[5] : tahunPelanggan
        },
    ]

    const columnPendapatan = [
        {
            name: `Nama Penyelenggara`,
            selector: row => row.company_name
        },
        {
            name: `${tahun.length > 0 ? tahun[1].slice(6) : tahun}`,
            selector: tahun.length > 0 ? tahun[1] : tahun
        },
        {
            name: `${tahun.length > 0 ? tahun[2].slice(6) : tahun}`,
            selector: tahun.length > 0 ? tahun[2] : tahun
        },
        {
            name: `${tahun.length > 0 ? tahun[3].slice(6) : tahun}`,
            selector: tahun.length > 0 ? tahun[3] : tahun
        },
        {
            name: `${tahun.length > 0 ? tahun[4].slice(6) : tahun}`,
            selector: tahun.length > 0 ? tahun[4] : tahun
        },
        {
            name: `${tahun.length > 0 ? tahun[5].slice(6) : tahun}`,
            selector: tahun.length > 0 ? tahun[5] : tahun
        },
    ]

    React.useEffect(() => {
        getMarketSharePendapatan(id);
        getMarketSharePelanggan(id);
    }, [getMarketSharePelanggan, getMarketSharePendapatan, id]);

    return loading ? <Spinner /> : (
        <>
            <div className="card-content">
                <div className="material-datatables">
                    <DataTable
                        title='Market Share Pendapatan'
                        columns={columnPendapatan}
                        data={marketSharePendapatanState}
                        progressPending={loading}
                        customStyles={customStyles}
                        progressComponent={<Loader type="Watch" color="#00BFFF" height={300}
                            width={100} />}
                        responsive={true}
                    />
                    <DataTable
                        title='Market Share Pelanggan'
                        columns={columnPelanggan}
                        data={marketSharePelangganState}
                        progressPending={loading}
                        customStyles={customStyles}
                        progressComponent={<Loader type="Watch" color="#00BFFF" height={300}
                            width={100} />}
                        responsive={true}
                    />
                </div>
            </div>

        </>
    )
}

export default MarketShare