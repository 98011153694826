import {
    MARKETSHARE_PENDAPATAN_REQ,
    MARKETSHARE_PENDAPATAN_SUCCESS,
    MARKETSHARE_PENDAPATAN_FAIL,
    MARKETSHARE_PELANGGAN_REQ,
    MARKETSHARE_PELANGGAN_SUCCESS,
    MARKETSHARE_PELANGGAN_FAIL,
    PENILAIAN_KESEHATAN_REQ,
    PENILAIAN_KESEHATAN_SUCCESS,
    PENILAIAN_KESEHATAN_FAIL,
} from './marketshareTypes';

import api from '../services';
import { config } from '../config';

export const penilaianKehahatanReq = () => {
    return { type: PENILAIAN_KESEHATAN_REQ }
}
export const penilaianKehahatanSuccess = (data) => {
    return { type: PENILAIAN_KESEHATAN_SUCCESS, payload: data }
}
export const penilaianKehahatanFail = (err) => {
    return { type: PENILAIAN_KESEHATAN_FAIL, payload: err }
}

export const marketSharePendapatanChartReq = () => {
    return { type: MARKETSHARE_PENDAPATAN_REQ }
}

export const marketSharePendapatanChartSuccess = (data) => {
    return { type: MARKETSHARE_PENDAPATAN_SUCCESS, payload: data }
}

export const marketSharePendapatanChartFail = (err) => {
    return { type: MARKETSHARE_PENDAPATAN_FAIL, payload: err }
}

export const marketSharePelangganChartReq = () => {
    return { type: MARKETSHARE_PELANGGAN_REQ }
}

export const marketSharePelangganChartSuccess = (data) => {
    return { type: MARKETSHARE_PELANGGAN_SUCCESS, payload: data }
}

export const marketSharePelangganChartFail = (err) => {
    return { type: MARKETSHARE_PELANGGAN_FAIL, payload: err }
}

export const marketSharePendapatanReq = () => {
    return { type: MARKETSHARE_PENDAPATAN_REQ }
}

export const marketSharePendapataneSuccess = (data) => {
    return { type: MARKETSHARE_PENDAPATAN_SUCCESS, payload: data }
}

export const marketSharePendapatanFail = (err) => {
    return { type: MARKETSHARE_PENDAPATAN_FAIL, payload: err }
}

export const marketSharePelangganReq = () => {
    return { type: MARKETSHARE_PELANGGAN_REQ }
}

export const marketSharePelangganeSuccess = (data) => {
    return { type: MARKETSHARE_PELANGGAN_SUCCESS, payload: data }
}

export const marketSharePelangganFail = (err) => {
    return { type: MARKETSHARE_PELANGGAN_FAIL, payload: err }
}

export const getPenilaianKesehatan = () => {
    return async (dispatch) => {
        dispatch(penilaianKehahatanReq);
        await api.get(`/penilaian-kesehatan-all`, config)
            .then(res => {
                dispatch(penilaianKehahatanSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(penilaianKehahatanFail(err.response));
            })
    }
}

export const getMarketSharePelangganChart = () => {
    return async (dispatch) => {
        dispatch(marketSharePelangganChartReq);
        await api.get(`/market-share-pelanggan-all`, config)
            .then(res => {
                dispatch(marketSharePelangganChartSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(marketSharePelangganChartFail(err.response));
            })
    }
}

export const getMarketSharePendapatanChart = () => {
    return async (dispatch) => {
        dispatch(marketSharePendapatanChartReq);
        await api.get(`/market-share-pendapatan-all`, config)
            .then(res => {
                dispatch(marketSharePendapatanChartSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(marketSharePendapatanChartFail(err.response));
            })
    }
}


export const getMarketSharePendapatan = (company_id) => {
    return async (dispatch) => {
        dispatch(marketSharePendapatanReq);
        await api.get(`/market-share-pivot-pendapatan-company/${company_id}`, config)
            .then(res => {
                dispatch(marketSharePendapataneSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(marketSharePendapatanFail(err.response));
            })
    }
}

export const getMarketSharePelanggan = (company_id) => {
    return async (dispatch) => {
        dispatch(marketSharePelangganReq);
        await api.get(`/market-share-pivot-pelanggan-company/${company_id}`, config)
            .then(res => {
                dispatch(marketSharePelangganeSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(marketSharePelangganFail(err.response));
            })
    }
}