import {
    BTS_REQ,
    BTS_SUCCESS,
    BTS_FAIL,
    BTS_MESSAGE_REQ,
    BTS_MESSAGE_SUCCESS,
    BTS_MESSAGE_FAIL,
    BTS_DETAIL_REQ,
    BTS_DETAIL_SUCCESS,
    BTS_DETAIL_FAIL,
    BTS_CRUD_REQ,
    BTS_CRUD_SUCCESS,
    BTS_CRUD_FAIL,
} from './btsTypes';


const btsCrudlState = {
    loading: false,
    btsCrud: [],
    error: ''
}

const btsDetailState = {
    loading: false,
    btsDetail: [],
    error: ''
}

const btsState = {
    loading: false,
    bts: null,
    error: ''
}

const btsMessageState = {
    loading: false,
    btsMessage: '',
    error: ''
}

export const btsDetailReducer = (state = btsDetailState, action) => {
    switch (action.type) {
        case BTS_DETAIL_REQ:
            return {
                ...state,
                loading: false
            }
        case BTS_DETAIL_SUCCESS:
            return {
                loading: true,
                btsDetail: action.payload,
                error: ''
            }
        case BTS_DETAIL_FAIL:
            return {
                loading: true,
                btsDetail: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const btsMessageReducer = (state = btsMessageState, action) => {
    switch (action.type) {
        case BTS_MESSAGE_REQ:
            return {
                ...state,
                loading: false,
            }
        case BTS_MESSAGE_SUCCESS:
            return {
                loading: true,
                btsMessage: action.payload,
                error: ''
            }
        case BTS_MESSAGE_FAIL:
            return {
                loading: true,
                btsMessage: "",
                error: action.payload
            }
        default:
            return state
    }
}

export const btsCrudReducer = (state = btsCrudlState, action) => {
    switch (action.type) {
        case BTS_CRUD_REQ:
            return {
                ...state,
                loading: false,
            }
        case BTS_CRUD_SUCCESS:
            return {
                loading: true,
                btsCrud: action.payload,
                error: ''
            }
        case BTS_CRUD_FAIL:
            return {
                loading: false,
                btsCrud: [],
                error: action.payload
            }
        default:
            return state
    }
}

const btsReducer = (state = btsState, action) => {
    switch (action.type) {
        case BTS_REQ:
            return {
                ...state,
                loading: true,
                bts: null,
                error: ''
            }
        case BTS_SUCCESS:
            return {
                loading: false,
                bts: action.payload,
                error: ''
            }
        case BTS_FAIL:
            return {
                loading: false,
                bts: null,
                error: action.payload
            }
        default:
            return state
    }
}

export default btsReducer