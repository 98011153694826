import MarketShare from './MarketShare';
import { connect } from 'react-redux';
import { getMarketSharePelanggan, getMarketSharePendapatan } from '../../redux';

const mapStateToProps = state => {
    return {
        marketSharePendapatan: state.marketSharePendapatan,
        marketSharePelanggan: state.marketSharePelanggan
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getMarketSharePendapatan: (id) => dispatch(getMarketSharePendapatan(id)),
        getMarketSharePelanggan: (id) => dispatch(getMarketSharePelanggan(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketShare)