import EditTeknologi from "./EditTeknologi";
import { connect } from 'react-redux';
import {
    getTeknologiDetail
} from '../../redux';

const mapStateToProps = state => {
    return {
        teknologi: state.teknologi,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTeknologiDetail: (id) => dispatch(getTeknologiDetail(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTeknologi);
