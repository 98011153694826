import React from 'react';
import {
    Container,
    Button
} from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import Select from "react-select";
import Loader from 'react-loader-spinner';
import { useForm, Controller } from 'react-hook-form';

import { Sidebar, Navbar, Styles } from '../../components';
import swal from 'sweetalert';

const EditKeuangan = ({
    keuangan,
    getkeuanganDetail,
    keuanganCrudMessage,
    optionKeuangan,
    getOptionKeuangan,
    updatekeuangan,

    rasioKeuangan,
    rasio,
    getRasioKeuanganDetail,
    rasioKeuanganCrudMessage,
    getRasio,
    getNamaRasio,
    getRumusRasio,
    rumusRasio,
    keteranganRasio,
    updateRasioKeuangan,

    getRasioKesehatanKeuanganDetail,
    rasioKesehatanKeuangan,
    keteranganRasioKesehatan,
    rasioKesehatanKeuanganMessage,
    updateRasioKesehatanKeuangan,
    getKeteranganRasioKesehatan,

    rasioKonversiKesehatanKeuangan,
    rasioKonversiKesehatanKeuanganCrudMessage,
    getKonversiKesehatanKeuanganDetail,
    rasioKonversiKesehatanKeuanganCrud,
    updateRasioKonversiKesehatanKeuangan,

    getUser,
    user
}) => {
    const histrory = useHistory();
    const { register, handleSubmit, control } = useForm();
    const { id, nama, dataId, menu } = useParams();
    const [load, setLoad] = React.useState(true);
    const [inputTahun, setInputTahun] = React.useState('');
    const [tahun, setTahun] = React.useState([]);
    const [userData, setUserData] = React.useState(null);
    // state
    const [optionKeuanganValue, setOptionKeuanganValue] = React.useState([]);
    const [keteranganRasioMap, setKeteranganRasioMap] = React.useState([]);
    const [keuanganValue, setkeuanganValue] = React.useState(null);
    console.log(rasioKonversiKesehatanKeuanganCrudMessage);
    // value editable 
    const [namaRasioIdValue, setNamaRasioIdValue] = React.useState('');
    const [keteranganRasioIdValue, setketeranganRasioIdValue] = React.useState('');

    // select option
    const [keteranganValue, setKetanganValue] = React.useState('');
    const [keuanganId, setKeuanganId] = React.useState('');
    const [rasioInput, setRasioInput] = React.useState([]);
    const [rumusRasioMap, setRumusRasioMap] = React.useState([]);

    React.useEffect(() => {
        setTahun(Tahun().map(t => ({ value: t, label: t })));
    }, []);
    // notifikasi
    React.useEffect(() => {
        const notification = () => {
            if (menu === "keuangan umum") {
                keuanganCrudMessage.loading && keuanganCrudMessage.keuanganCrudMessage && swal(`${keuanganCrudMessage.keuanganCrudMessage.message}`, {
                    buttons: true,
                }).then((value) => {
                    if (value) {
                        window.location.href = `/penyelenggara-detail/${id}/${nama}`;
                    }
                });
            } else if (menu === "rasio keuangan") {
                rasioKeuanganCrudMessage.loading && rasioKeuanganCrudMessage.rasioKeuanganCrudMessage && swal(`${rasioKeuanganCrudMessage.rasioKeuanganCrudMessage.message}`, {
                    buttons: true
                }).then(value => {
                    if (value) {
                        window.location.href = `/penyelenggara-detail/${id}/${nama}`;
                    }
                })
            } else if (menu === "rasio kesehatan keuangan") {
                rasioKesehatanKeuanganMessage.loading && rasioKesehatanKeuanganMessage.rasioKesehatanKeuanganMessage && swal(`${rasioKesehatanKeuanganMessage.rasioKesehatanKeuanganMessage.message}`, {
                    buttons: true
                }).then(value => {
                    if (value) {
                        window.location.href = `/penyelenggara-detail/${id}/${nama}`;
                    }
                })
            } else if (menu === "rasio konversi kesehatan keuangan") {
                rasioKonversiKesehatanKeuanganCrudMessage.loading && rasioKonversiKesehatanKeuanganCrudMessage.data && swal(`${rasioKonversiKesehatanKeuanganCrudMessage.data.message}`, {
                    buttons: true
                }).then(value => {
                    if (value) {
                        window.location.href = `/penyelenggara-detail/${id}/${nama}`;
                    }
                })
            }
        }

        return notification();
    }, [id, keuanganCrudMessage, menu, nama, rasioKesehatanKeuanganMessage, rasioKeuanganCrudMessage, rasioKonversiKesehatanKeuanganCrudMessage])

    // kondisi menu
    React.useEffect(() => {
        getUser();
        if (menu === "keuangan umum") {
            getOptionKeuangan();
            getkeuanganDetail(dataId);
        } else if (menu === "rasio keuangan") {
            getRasioKeuanganDetail(dataId);
            getRasio();
        } else if (menu === "rasio kesehatan keuangan") {
            getRasioKesehatanKeuanganDetail(dataId);
            getKeteranganRasioKesehatan();
        } else if (menu === "rasio konversi kesehatan keuangan") {
            getKeteranganRasioKesehatan();
            getKonversiKesehatanKeuanganDetail(dataId);
        }
    }, [dataId, getKeteranganRasioKesehatan, getOptionKeuangan, getRasio, getRasioKesehatanKeuanganDetail, getRasioKeuanganDetail, getKonversiKesehatanKeuanganDetail, getUser, getkeuanganDetail, menu]);

    //  load State
    React.useEffect(() => {
        keteranganRasio.loading && keteranganRasio.keteranganRasio && setKeteranganRasioMap(keteranganRasio.keteranganRasio.map(e => ({ value: e.id, label: e.nama_rasio })));
        keteranganRasioKesehatan.loading && keteranganRasioKesehatan.keteranganRasioKesehatan && setKeteranganRasioMap(keteranganRasioKesehatan.keteranganRasioKesehatan.map(e => ({ value: e.id, label: e.nama_keterangan })));
        rumusRasio.loading && rumusRasio.rumusRasio && rumusRasio.rumusRasio.map(val => setRumusRasioMap({ id: val.id, value: val.rumus }));
    }, [keteranganRasio, keteranganRasioKesehatan, rumusRasio]);
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            user && user.user && user.user.map(u => setUserData(u));
            user && user.error === "Unauthenticated." && histrory.push('/');
            if (menu === "keuangan umum") {
                optionKeuangan && optionKeuangan.optionKeuangan && setOptionKeuanganValue(optionKeuangan.optionKeuangan.map(t => ({ value: t.id, label: t.keterangan })))
                keuangan.loading && setkeuanganValue(keuangan.keuangan);
                setLoad(false);
            } else if (menu === "rasio keuangan") {
                rasioKeuangan.loading && setkeuanganValue(rasioKeuangan.rasioKeuangan);
                rasioKeuangan.loading && getNamaRasio(rasioKeuangan.rasioKeuangan.nama_ket_rasio_id);
                rasioKeuangan.loading && getRumusRasio(rasioKeuangan.rasioKeuangan.nama_rasio_id);
                rasio && rasio.rasio && setRasioInput(rasio.rasio.map(r => ({ value: r.id, label: r.nama_keterangan })));
                setLoad(false);
            } else if (menu === "rasio kesehatan keuangan") {
                rasioKesehatanKeuangan.loading && setkeuanganValue(rasioKesehatanKeuangan.rasioKesehatanKeuangan)
                setLoad(false);
            } else if (menu === "rasio konversi kesehatan keuangan") {
                rasioKonversiKesehatanKeuanganCrud && rasioKonversiKesehatanKeuanganCrud.data && setkeuanganValue(rasioKonversiKesehatanKeuanganCrud.data)
                setLoad(false);
            }
        }, 3000);
        return () => clearTimeout(timeout);
    }, [getNamaRasio, getRumusRasio, histrory, keuangan, rasioKonversiKesehatanKeuanganCrud, menu, optionKeuangan, rasio, rasioKesehatanKeuangan, rasioKeuangan, rasioKonversiKesehatanKeuangan, user]);

    const submitEditRasioKeuangan = (data) => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            nama_rasio_id: namaRasioIdValue === "" ? keuanganValue.nama_ket_rasio_id : namaRasioIdValue,
            keterangan_id: keteranganRasioIdValue === "" ? keuanganValue.nama_rasio_id : keteranganRasioIdValue,
            total_rasio: data.total_rasio,
            rumus_id: rumusRasioMap.id,
            tahun: inputTahun === "" ? keuanganValue.tahun : inputTahun
        }
        updateRasioKeuangan(dataId, input)
    }

    const submitEditRasioKonversiKesehatanKeuangan = (data) => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            nama_ket_rasio_kesehatan_id: namaRasioIdValue === "" ? keuanganValue.nama_ket_rasio_kesehatan_id : namaRasioIdValue,
            nilai_konversi_rasio: data.nilai_konversi_rasio,
            tahun: inputTahun === "" ? keuanganValue.tahun : inputTahun
        }
        updateRasioKonversiKesehatanKeuangan(dataId, input)
    }

    const submitEditRasioKesehatanKeuangan = (data) => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            nama_ket_rasio_kesehatan_id: keteranganValue === "" ? keuanganValue.nama_ket_rasio_kesehatan_id : keteranganValue,
            nilai_rasio: data.nilai_rasio,
            tahun: inputTahun === "" ? keuanganValue.tahun : inputTahun
        };
        updateRasioKesehatanKeuangan(dataId, input);
    }

    const submitEditKeuangan = (data) => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            ket_keuangan_umum_id: keuanganId === "" ? keuanganValue.ket_keuangan_umum_id : keuanganId,
            keterangan: keteranganValue === "" ? keuanganValue.keterangan : keteranganValue,
            jumlah: data.jumlah,
            tahun: inputTahun === "" ? keuanganValue.tahun : inputTahun
        }
        updatekeuangan(dataId, input);
    }

    return (
        <>
            <Sidebar menu="DATA PENYELENGGARAAN" />
            <div className="main-panel">
                <Navbar user={userData} />
                <div className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header card-header-icon" data-background-color="blue">
                                        <Styles.ButtonModal
                                            type="button"
                                            onClick={() => window.history.back()}>
                                            <i className="fa fa-chevron-circle-left fa-2x" aria-hidden="true"></i>
                                        </Styles.ButtonModal>
                                    </div>
                                    <h4 className="card-title">Edit {menu}</h4>
                                    <div className="card-content">
                                        {
                                            load ? <div className="d-flex align-content-center"><Loader type="Bars" color="#00BFFF" height={100} width={100} /></div> :
                                                menu === "rasio konversi kesehatan keuangan" ? <form className="form"
                                                    onSubmit={handleSubmit(submitEditRasioKonversiKesehatanKeuangan)}
                                                >
                                                    <Styles.InputForm>
                                                        <Controller
                                                            control={control}
                                                            name="nama_rasio_id"
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    options={keteranganRasioMap}
                                                                    placeholder="Nama Keterangan Kesehatan Rasio.."
                                                                    defaultValue={
                                                                        keteranganRasioMap.filter(option => option.value === keuanganValue.nama_ket_rasio_kesehatan_id)
                                                                    }
                                                                    onChange={(e) => setKetanganValue(e.value)}
                                                                />
                                                            )}
                                                        />
                                                        {rasioKonversiKesehatanKeuanganCrudMessage && rasioKonversiKesehatanKeuanganCrudMessage.error && <span className="text-danger">{rasioKonversiKesehatanKeuanganCrudMessage.error.nama_ket_rasio_kesehatan_id}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <input
                                                            placeholder="Nilai Konversi Rasio..."
                                                            type="text"
                                                            className="form-control"
                                                            defaultValue={keuanganValue.nilai_konversi_rasio}
                                                            {...register("nilai_konversi_rasio")}
                                                        />
                                                        {rasioKonversiKesehatanKeuanganCrudMessage && rasioKonversiKesehatanKeuanganCrudMessage.error && <span className="text-danger">{rasioKonversiKesehatanKeuanganCrudMessage.error.nilai_konversi_rasio}</span>}
                                                    </Styles.InputForm>
                                                    <Styles.InputForm>
                                                        <Controller
                                                            control={control}
                                                            name="tahun"
                                                            render={({ field }) => (
                                                                <Select
                                                                    {...field}
                                                                    options={tahun}
                                                                    defaultValue={
                                                                        tahun.filter(t => t.value === keuanganValue.tahun)
                                                                    }
                                                                    placeholder="Tahun.."
                                                                    onChange={(e) => setInputTahun(e.value)}
                                                                />
                                                            )}
                                                        />
                                                        {rasioKonversiKesehatanKeuanganCrudMessage && rasioKonversiKesehatanKeuanganCrudMessage.error && <span className="text-danger">{rasioKonversiKesehatanKeuanganCrudMessage.error.tahun}</span>}
                                                    </Styles.InputForm>
                                                    <Button variant="primary" type="submit">Save changes</Button>
                                                </form> :
                                                    menu === "rasio kesehatan keuangan" ? <form className="form"
                                                        onSubmit={handleSubmit(submitEditRasioKesehatanKeuangan)}
                                                    >
                                                        <Styles.InputForm>
                                                            <Controller
                                                                control={control}
                                                                name="nama_rasio_id"
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        options={keteranganRasioMap}
                                                                        placeholder="Nama Keterangan Kesehatan Rasio.."
                                                                        defaultValue={
                                                                            keteranganRasioMap.filter(option => option.value === keuanganValue.nama_ket_rasio_kesehatan_id)
                                                                        }
                                                                        onChange={(e) => setKetanganValue(e.value)}
                                                                    />
                                                                )}
                                                            />
                                                            {rasioKesehatanKeuanganMessage && rasioKesehatanKeuanganMessage.error && <span className="text-danger">{rasioKesehatanKeuanganMessage.error.nama_ket_rasio_kesehatan_id}</span>}
                                                        </Styles.InputForm>
                                                        <Styles.InputForm>
                                                            <input
                                                                placeholder="Nilai Rasio..."
                                                                type="text"
                                                                className="form-control"
                                                                defaultValue={keuanganValue.nilai_rasio}
                                                                {...register("nilai_rasio")}
                                                            />
                                                            {rasioKesehatanKeuanganMessage && rasioKesehatanKeuanganMessage.error && <span className="text-danger">{rasioKesehatanKeuanganMessage.error.nilai_rasio}</span>}
                                                        </Styles.InputForm>
                                                        <Styles.InputForm>
                                                            <Controller
                                                                control={control}
                                                                name="tahun"
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        options={tahun}
                                                                        placeholder="Tahun.."
                                                                        defaultValue={
                                                                            tahun.filter(t => t.value === keuanganValue.tahun)
                                                                        }
                                                                        onChange={(e) => setInputTahun(e.value)}
                                                                    />
                                                                )}
                                                            />
                                                            {rasioKesehatanKeuanganMessage && rasioKesehatanKeuanganMessage.error && <span className="text-danger">{rasioKesehatanKeuanganMessage.error.tahun}</span>}
                                                        </Styles.InputForm>
                                                        <Button variant="primary" type="submit">Save changes</Button>
                                                    </form> :
                                                        menu === "rasio keuangan" ? <form className="form"
                                                            onSubmit={handleSubmit(submitEditRasioKeuangan)}
                                                        >
                                                            <Styles.InputForm>
                                                                <Controller
                                                                    control={control}
                                                                    name="nama_rasio_id"
                                                                    render={({ field }) => (
                                                                        <Select
                                                                            {...field}
                                                                            options={rasioInput}
                                                                            placeholder="Nama Rasio.."
                                                                            defaultValue={
                                                                                rasioInput.filter(option => option.value === keuanganValue.nama_ket_rasio_id)
                                                                            }
                                                                            onChange={(e) => {
                                                                                setNamaRasioIdValue(e.value);
                                                                                getNamaRasio(e.value);
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                                {rasioKeuanganCrudMessage && rasioKeuanganCrudMessage.error && <span className="text-danger">{rasioKeuanganCrudMessage.error.nama_rasio_id}</span>}
                                                            </Styles.InputForm>
                                                            {
                                                                keteranganRasioMap.length > 0 &&
                                                                <>
                                                                    <Styles.InputForm>
                                                                        <Controller
                                                                            control={control}
                                                                            name="keterangan_id"
                                                                            render={({ field }) => (
                                                                                <Select
                                                                                    {...field}
                                                                                    options={keteranganRasioMap}
                                                                                    placeholder="Keterangan Rasio.."
                                                                                    defaultValue={
                                                                                        keteranganRasioMap.filter(option => option.value === keuanganValue.nama_ket_rasio_id)
                                                                                    }
                                                                                    onChange={(e) => {
                                                                                        setketeranganRasioIdValue(e.value);
                                                                                        getRumusRasio(e.value);
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                                        {rasioKeuanganCrudMessage && rasioKeuanganCrudMessage.error && <span className="text-danger">{rasioKeuanganCrudMessage.error.keterangan_id}</span>}
                                                                    </Styles.InputForm>
                                                                    <Styles.InputForm>
                                                                        <textarea
                                                                            placeholder={`${rumusRasioMap.label}`}
                                                                            value={rumusRasioMap.value}
                                                                            className="form-control" disabled />
                                                                    </Styles.InputForm>
                                                                </>
                                                            }
                                                            <Styles.InputForm>
                                                                <input
                                                                    placeholder="Total Rasio..."
                                                                    type="text"
                                                                    className="form-control"
                                                                    defaultValue={keuanganValue.total_rasio}
                                                                    {...register("total_rasio")}
                                                                />
                                                                {rasioKeuanganCrudMessage && rasioKeuanganCrudMessage.error && <span className="text-danger">{rasioKeuanganCrudMessage.error.total_rasio}</span>}
                                                            </Styles.InputForm>
                                                            <Styles.InputForm>
                                                                <Controller
                                                                    control={control}
                                                                    name="tahun"
                                                                    render={({ field }) => (
                                                                        <Select
                                                                            {...field}
                                                                            options={tahun}
                                                                            defaultValue={
                                                                                tahun.filter(option => option.value === keuanganValue.tahun)
                                                                            }
                                                                            onChange={(e) => setInputTahun(e.value)}
                                                                        />
                                                                    )}
                                                                />
                                                                {rasioKeuanganCrudMessage && rasioKeuanganCrudMessage.error && <span className="text-danger">{rasioKeuanganCrudMessage.error.tahun}</span>}
                                                            </Styles.InputForm>
                                                            <Button variant="primary" type="submit">Save changes</Button>
                                                        </form> :
                                                            menu === 'keuangan umum' ? (<form className="form"
                                                                onSubmit={handleSubmit(submitEditKeuangan)}
                                                            >
                                                                <Styles.InputForm>
                                                                    <Controller
                                                                        control={control}
                                                                        name="tahun"
                                                                        render={({ field }) => (
                                                                            <Select
                                                                                {...field}
                                                                                options={optionKeuanganValue}
                                                                                placeholder="Keterangan.."
                                                                                defaultValue={optionKeuanganValue.filter(opt => opt.value === keuanganValue.ket_keuangan_umum_id)}
                                                                                onChange={(e) => {
                                                                                    setKeuanganId(e.value);
                                                                                    setKetanganValue(e.label);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                    {keuanganCrudMessage && keuanganCrudMessage.error && <span className="text-danger">{keuanganCrudMessage.error.keterangan}</span>}
                                                                </Styles.InputForm>
                                                                <Styles.InputForm>
                                                                    <Controller
                                                                        control={control}
                                                                        name="tahun"
                                                                        render={({ field }) => (
                                                                            <Select
                                                                                {...field}
                                                                                options={tahun}
                                                                                defaultValue={
                                                                                    tahun.filter(option => option.value === keuanganValue.tahun)
                                                                                }
                                                                                onChange={(e) => setInputTahun(e.value)}
                                                                            />
                                                                        )}
                                                                    />
                                                                    {keuanganCrudMessage && keuanganCrudMessage.error && <span className="text-danger">{keuanganCrudMessage.error.tahun}</span>}
                                                                </Styles.InputForm>
                                                                <Styles.InputForm>
                                                                    <input
                                                                        placeholder="Jumlah..."
                                                                        type="text"
                                                                        className="form-control"
                                                                        defaultValue={`${keuanganValue.jumlah}`}
                                                                        {...register("jumlah")}
                                                                    />
                                                                    {keuanganCrudMessage && keuanganCrudMessage.error && <span className="text-danger">{keuanganCrudMessage.error.jumlah}</span>}
                                                                </Styles.InputForm>
                                                                <Button variant="primary" type="submit">Save changes</Button>
                                                            </form>) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default EditKeuangan

const Tahun = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= 1945; i--) {
        tahun.push(i);
    }
    return tahun;
}