import {
    IZIN_REQ,
    IZIN_SUCCESS,
    IZIN_FAIL,
    IZIN_MESSAGE_REQ,
    IZIN_MESSAGE_SUCCESS,
    IZIN_MESSAGE_FAIL,
    IZIN_DETAIL_REQ,
    IZIN_DETAIL_SUCCESS,
    IZIN_DETAIL_FAIL,
} from './izinTypes';

const izinDetailState = {
    loading: false,
    izinDetail: [],
    error: ''
}

const izinState = {
    loading: false,
    izin: [],
    error: ''
}

const izinMessageState = {
    loading: false,
    izinMessage: '',
    error: ''
}

export const izinDetailReducer = (state = izinDetailState, action) => {
    switch (action.type) {
        case IZIN_DETAIL_REQ:
            return {
                ...state,
                loading: false
            }
        case IZIN_DETAIL_SUCCESS:
            return {
                loading: true,
                izinDetail: action.payload,
                error: ''
            }
        case IZIN_DETAIL_FAIL:
            return {
                loading: true,
                izinDetail: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const izinMessageReducer = (state = izinMessageState, action) => {
    switch (action.type) {
        case IZIN_MESSAGE_REQ:
            return {
                ...state,
                loading: false,
            }
        case IZIN_MESSAGE_SUCCESS:
            return {
                loading: true,
                izinMessage: action.payload,
                error: ''
            }
        case IZIN_MESSAGE_FAIL:
            return {
                loading: true,
                izinMessage: "",
                error: action.payload
            }
        default:
            return state
    }
}

const izinReducer = (state = izinState, action) => {
    switch (action.type) {
        case IZIN_REQ:
            return {
                ...state,
                loading: false,
                izin: [],
                error: ''
            }
        case IZIN_SUCCESS:
            return {
                loading: true,
                izin: action.payload,
                error: ''
            }
        case IZIN_FAIL:
            return {
                loading: false,
                izin: [],
                error: action.payload
            }
        default:
            return state
    }
}

export default izinReducer