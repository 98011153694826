import {
    JABERSEL_PELANGGAN_REQ,
    JABERSEL_PELANGGAN_SUCCESS,
    JABERSEL_PELANGGAN_FAIL,
    JABERSEL_PELANGGAN_MESSAGE_REQ,
    JABERSEL_PELANGGAN_MESSAGE_SUCCESS,
    JABERSEL_PELANGGAN_MESSAGE_FAIL,
    JABERSEL_PELANGGAN_DETAIL_REQ,
    JABERSEL_PELANGGAN_DETAIL_SUCCESS,
    JABERSEL_PELANGGAN_DETAIL_FAIL,
} from './jaberselPelangganTypes'

const jaberselPelangganDetailState = {
    loading: false,
    jaberselPelangganDetail: null,
    error: ''
}

const jaberselPelangganMessageState = {
    loading: false,
    jaberselPelangganMessage: '',
    error: ''
}

const jaberselPelangganState = {
    loading: false,
    jaberselPelanggan: [],
    error: ''
}

export const jaberselPelangganDetailReducer = (state = jaberselPelangganDetailState, action) => {
    switch (action.type) {
        case JABERSEL_PELANGGAN_DETAIL_REQ:
            return {
                ...state,
                loading: false
            }
        case JABERSEL_PELANGGAN_DETAIL_SUCCESS:
            return {
                loading: true,
                jaberselPelangganDetail: action.payload,
                error: ''
            }
        case JABERSEL_PELANGGAN_DETAIL_FAIL:
            return {
                loading: true,
                jaberselPelangganDetail: null,
                error: action.payload
            }
        default:
            return state
    }
}

export const jaberselPelangganMessageReducer = (state = jaberselPelangganMessageState, action) => {
    switch (action.type) {
        case JABERSEL_PELANGGAN_MESSAGE_REQ:
            return {
                ...state,
                loading: false,
            }
        case JABERSEL_PELANGGAN_MESSAGE_SUCCESS:
            return {
                loading: true,
                jaberselPelangganMessage: action.payload,
                error: ''
            }
        case JABERSEL_PELANGGAN_MESSAGE_FAIL:
            return {
                loading: true,
                jaberselPelangganMessage: "",
                error: action.payload
            }
        default:
            return state
    }
}

const jaberselPelangganReducer = (state = jaberselPelangganState, action) => {
    switch (action.type) {
        case JABERSEL_PELANGGAN_REQ:
            return {
                ...state,
                loading: true,
            }
        case JABERSEL_PELANGGAN_SUCCESS:
            return {
                loading: false,
                jaberselPelanggan: action.payload,
                error: ''
            }
        case JABERSEL_PELANGGAN_FAIL:
            return {
                loading: false,
                jaberselPelanggan: null,
                error: action.payload
            }
        default:
            return state
    }
}

export default jaberselPelangganReducer