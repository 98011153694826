import EditKeuangan from "./EditKeuangan";
import { connect } from 'react-redux';
import {
    getUser,
    getkeuanganDetail,
    getOptionKeuangan,
    updatekeuangan,

    getRasioKeuanganDetail,
    getRasio,
    getNamaRasio,
    getRumusRasio,
    updateRasioKeuangan,

    getRasioKesehatanKeuanganDetail,
    getKeteranganRasioKesehatan,
    updateRasioKesehatanKeuangan,
    getKonversiKesehatanKeuanganDetail,
    updateRasioKonversiKesehatanKeuangan
} from '../../redux';

const mapStateToProps = state => {
    return {
        user: state.user,

        keuangan: state.keuangan,
        rasioKeuangan: state.rasioKeuangan,
        rasio: state.rasio,
        keuanganCrudMessage: state.keuanganCrudMessage,

        optionKeuangan: state.optionKeuangan,
        rasioKeuanganCrudMessage: state.rasioKeuanganCrudMessage,
        keteranganRasio: state.keteranganRasio,
        rumusRasio: state.rumusRasio,

        rasioKesehatanKeuangan: state.rasioKesehatanKeuangan,
        keteranganRasioKesehatan: state.keteranganRasioKesehatan,
        rasioKesehatanKeuanganMessage: state.rasioKesehatanKeuanganMessage,

        rasioKonversiKesehatanKeuanganCrud: state.rasioKonversiKesehatanKeuanganCrud,
        rasioKonversiKesehatanKeuanganCrudMessage: state.rasioKonversiKesehatanKeuanganCrudMessage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUser: () => dispatch(getUser()),
        getkeuanganDetail: (id) => dispatch(getkeuanganDetail(id)),
        updatekeuangan: (id, data) => dispatch(updatekeuangan(id, data)),
        getOptionKeuangan: () => dispatch(getOptionKeuangan()),

        getRasioKeuanganDetail: (id) => dispatch(getRasioKeuanganDetail(id)),
        getRasio: () => dispatch(getRasio()),
        getRumusRasio: (id) => dispatch(getRumusRasio(id)),
        getNamaRasio: (id) => dispatch(getNamaRasio(id)),
        updateRasioKeuangan: (id, data) => dispatch(updateRasioKeuangan(id, data)),

        getRasioKesehatanKeuanganDetail: (id) => dispatch(getRasioKesehatanKeuanganDetail(id)),
        updateRasioKesehatanKeuangan: (id, data) => dispatch(updateRasioKesehatanKeuangan(id, data)),
        getKeteranganRasioKesehatan: () => dispatch(getKeteranganRasioKesehatan()),

        getKonversiKesehatanKeuanganDetail: (id) => dispatch(getKonversiKesehatanKeuanganDetail(id)),
        updateRasioKonversiKesehatanKeuangan: (id, data) => dispatch(updateRasioKonversiKesehatanKeuangan(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditKeuangan);
