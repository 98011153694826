import React from 'react';
import DataTable from 'react-data-table-component';
import Loader from 'react-loader-spinner';
import { Modal } from 'react-bootstrap';
import Button from '@mui/material/Button';

import ReactModal from 'react-modal';
import { useForm, Controller } from 'react-hook-form';
import Select from "react-select";
import swal from '@sweetalert/with-react';
import { useParams } from 'react-router';

import { Spinner, Styles, ActionButton } from '../../components';
import { Link } from 'react-router-dom';

const EnodeB4G = ({
    enodeb4g,
    getenodeb4gByCompany,
    enodeb4gMessage,
    addenodeb4g,
    enodeb4gCrud,
    provinsi,
    getenodeb4gPivot,
    getProv,
    role,
    uploadENodeb4g,
    deleteENodeb4g
}) => {

    const { id, nama } = useParams();
    const { register, handleSubmit, control } = useForm();
    const [rows, setRows] = React.useState([]);
    const [pivot, setPivot] = React.useState([]);
    const [tahunValue, setTahunValue] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [tahun, setTahun] = React.useState([]);
    const [inputTahun, setInputTahun] = React.useState('');
    const [prov, setProv] = React.useState([]);
    const [valueProvinsi, setValueProvinsi] = React.useState('');
    const [showModalAddNodeb4g, setShowModalAddNodeb4g] = React.useState(false);
    const [toCrudNodeb4g, setToCrudNodeb4g] = React.useState(true);
    const [fileUpload, setFileUpload] = React.useState(null);
    const [tahunFrom, setTahunFrom] = React.useState([]);
    const [tahunTo, setTahunTo] = React.useState([]);

    const toCrud = () => setToCrudNodeb4g(!toCrudNodeb4g);
    const setUpModalAddNodeb4g = () => setShowModalAddNodeb4g(!showModalAddNodeb4g);

    const subHeaderComponentMemo = React.useMemo(() => {
        const onfilter = (data) => {
            console.log(data);
            setLoading(true);
            getenodeb4gPivot(id, data.from, data.to)
        }
        return (<form onSubmit={handleSubmit(onfilter)} className="form-inline">
            <div className="input-group">
                <span className="input-group-addon text-light">
                    <label>Filter dari tahun:</label>
                </span>
                <select
                    className="form-control"
                    {...register('from')}
                >
                    {
                        tahunFrom.map((t, index) => (
                            <option value={t} key={index}>{t}</option>
                        ))
                    }
                </select>
            </div>
            <div className="input-group">
                <span className="input-group-addon text-light">
                    <label>sampai tahun:</label>
                </span>
                <select
                    className="form-control"
                    {...register('to')}
                >
                    {
                        tahunTo.map((t, index) => (
                            <option value={t} key={index}>{t}</option>
                        ))
                    }
                </select>
            </div>
            <button type="submit" className="btn btn-sm btn-primary" style={{ marginLeft: 15 }}>Submit</button>
        </form>)
    }, [getenodeb4gPivot, handleSubmit, id, register, tahunFrom, tahunTo]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            provinsi && provinsi.provinsi && setProv(provinsi.provinsi.map(p => ({ value: p.id, label: p.nama_provinsi })));

            enodeb4gCrud && enodeb4gCrud.enodeb4gCrud && setRows(enodeb4gCrud.enodeb4gCrud);
            enodeb4g.loading && enodeb4g.enodeb4g && enodeb4g.enodeb4g.length > 0 && setTahunValue(Object.keys(enodeb4g.enodeb4g[0]));
            enodeb4g.loading && enodeb4g.enodeb4g && setPivot(enodeb4g.enodeb4g);
            setLoading(false);
        }, 4000);
        return () => clearTimeout(timeout);
    }, [enodeb4g, enodeb4gCrud, provinsi]);
    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1)  solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none'
            },
        },
    };
    const columns = [
        {
            name: `provinsi`,
            selector: row => row.provinsi,
        },
        {
            name: `${tahunValue.length > 0 ? tahunValue[1].slice(6) : tahunValue}`,
            selector: `${tahunValue.length > 0 ? tahunValue[1] : tahunValue}`
        },
        {
            name: `${tahunValue.length > 0 ? tahunValue[2].slice(6) : tahunValue}`,
            selector: `${tahunValue.length > 0 ? tahunValue[2] : tahunValue}`
        },
        {
            name: `${tahunValue.length > 0 ? tahunValue[3].slice(6) : tahunValue}`,
            selector: `${tahunValue.length > 0 ? tahunValue[3] : tahunValue}`
        },
        {
            name: `${tahunValue.length > 0 ? tahunValue[4].slice(6) : tahunValue}`,
            selector: `${tahunValue.length > 0 ? tahunValue[4] : tahunValue}`
        },
        {
            name: `${tahunValue.length > 0 ? tahunValue[5].slice(6) : tahunValue}`,
            selector: `${tahunValue.length > 0 ? tahunValue[5] : tahunValue}`
        },
    ];
    const columnsCrud = [
        {
            name: `No`,
            selector: (_, index) => index + 1,
        },
        {
            name: `Provinsi`,
            selector: row => row.provinsi,
        },
        {
            name: `Tahun`,
            selector: row => row.tahun
        },
        {
            name: `Jumlah`,
            selector: row => row.jumlah
        },
        {
            name: 'Action',
            selector: row => <>
                <ActionButton
                    title="fa fa-pencil"
                    css="btn-warning"
                    fun={() => window.location.href = `/edit-enode-b-4-g/${row.id}/${row.company_name}`} />
                &nbsp;
                <ActionButton title="fa fa-trash" css="btn-danger" fun={() => swal(`Apakah Yakin Data enodeb4g ${row.provinsi} ini akan dihapus?`, {
                    buttons: ["Batal", true],
                })
                    .then((value) => {
                        if (value) {
                            deleteENodeb4g(row.id);
                        }
                    })
                } />
            </>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    React.useEffect(() => {
        setTahun(Tahun().map(t => ({ value: t, label: t })));
        setTahunTo(TahunTo().map(t => t));
        setTahunFrom(TahunFrom().map(t => t));
    }, []);
    React.useEffect(() => {
        getenodeb4gByCompany(id);
        getenodeb4gPivot(id);
        getProv();
    }, [getProv, getenodeb4gByCompany, getenodeb4gPivot, id]);
    React.useEffect(() => {
        enodeb4gMessage.loading && enodeb4gMessage.enodeb4gMessage && swal(`${enodeb4gMessage.enodeb4gMessage.message}`, {
            buttons: ["cancel", true]
        }).then((value) => {
            if (value) {
                getenodeb4gByCompany(id);
                getenodeb4gPivot(id);
            }
        });
    }, [getenodeb4gByCompany, id, enodeb4gMessage, getenodeb4gPivot]);


    const tambaheNodeb4g = async (data) => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            tahun: inputTahun,
            provinsi: valueProvinsi,
            jumlah: data.jumlah
        }
        addenodeb4g(input);
    }

    const onUpload = (e) => setFileUpload(e.target.files[0]);

    const submitUploadENodeB4g = (data) => {
        const formData = new FormData();
        formData.append("nama_file", fileUpload);
        uploadENodeb4g(formData)
    };

    const customStylesModal = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: '0',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '700px',
            height: '80%'
        },
    };

    return loading ? <Spinner /> : enodeb4g.error === "Unauthenticated." ? (
        window.location.href = "/") : (
        <>
            {
                role.role_id !== 3 &&
                <div className="toolbar" style={{ display: 'flex', flexDirection: 'row-reverse', }}>
                    <Button
                        id="basic-button"
                        aria-controls="basic-menu"
                        size="large"
                        aria-haspopup="true"
                        className={toCrudNodeb4g ? "btn btn-success" : "btn btn-danger"}
                        onClick={toCrud}
                    >
                        <i className={toCrudNodeb4g ? "fa fa-edit" : "fa fa-eye"} aria-hidden="true"></i> {toCrudNodeb4g ? "Edit" : "View"}
                    </Button>
                    <Button
                        id="basic-button"
                        aria-controls="basic-menu"
                        className="btn btn-sm"
                        size="large"
                        aria-haspopup="true"
                        onClick={setUpModalAddNodeb4g}
                        style={{ backgroundColor: 'rgba(0, 50, 122, 1)', color: '#fff', marginRight: '10px' }}
                    >
                        <i className="fa fa-plus" aria-hidden="true"></i> &nbsp; Tambah
                    </Button>
                </div>
            }
            <div className="card-content">
                <div className="material-datatables">
                    {
                        toCrudNodeb4g ?
                            <DataTable
                                columns={columns}
                                data={pivot}
                                progressPending={loading}
                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100}
                                    width={100} />}
                                responsive={true}
                                customStyles={customStyles}
                                pagination
                                subHeaderComponent={subHeaderComponentMemo}
                                subHeader
                            /> :
                            <DataTable
                                columns={columnsCrud}
                                data={rows}
                                progressPending={loading}
                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100}
                                    width={100} />}
                                responsive={true}
                                customStyles={customStyles}
                                pagination
                            // subHeaderComponent={subHeaderComponentMemo} 
                            />
                    }
                </div>
            </div>
            <ReactModal
                ariaHideApp={false}
                isOpen={showModalAddNodeb4g}
                preventScroll={true}
                scrollable={true}
                style={customStylesModal}
            >
                <div style={{ float: 'right' }}>
                    <Button id="basic-button"
                        aria-controls="basic-menu"
                        color="error"
                        onClick={setUpModalAddNodeb4g}
                        size="small">
                        <span className="material-icons">
                            close
                        </span>
                    </Button>
                </div>
                <Modal.Title style={{ fontWeight: 'bold' }}>Tambah Data E Node B 4G Baru</Modal.Title>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitUploadENodeB4g)}
                >
                    <h5 className="text-center">Import Data E Node B 4G {nama}</h5>
                    <input
                        placeholder="Nama Perusahaan"
                        type="file"
                        name="nama_file"
                        onChangeCapture={onUpload}
                    />
                    <Link to="/download/enode b 4g.xlsx" target="_blank" style={{ float: 'right' }}>Download Template Upload E Node B 4G</Link>
                    <br />
                    {
                        enodeb4gMessage && enodeb4gMessage.error && <span className="text-danger">{enodeb4gMessage.error.nama_file}</span>
                    }
                    <br />
                    <Button type="submit" variant="contained" color="primary">Upload</Button>
                    {
                        enodeb4gMessage && enodeb4gMessage.error && enodeb4gMessage.error.message && enodeb4gMessage.error.message.map((w, index) => (
                            <ol key={index}>
                                <span className="text-danger">{index + 1}.{w}</span>
                            </ol>
                        ))
                    }
                </form>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(tambaheNodeb4g)}
                >
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="tahun"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={tahun}
                                    placeholder="Tahun.."
                                    onChange={(e) => setInputTahun(e.value)}
                                />
                            )}
                        />
                        {enodeb4gMessage && enodeb4gMessage.error && <span className="text-danger">{enodeb4gMessage.error.tahun}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="provinsi"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={prov}
                                    placeholder="Provinsi.."
                                    onChange={(e) => setValueProvinsi(e.value)}
                                />
                            )}
                        />
                        {enodeb4gMessage && enodeb4gMessage.error && <span className="text-danger">{enodeb4gMessage.error.provinsi}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Jumlah..."
                            type="text"
                            className="form-control"
                            {...register("jumlah")}
                        />
                        {enodeb4gMessage && enodeb4gMessage.error && <span className="text-danger">{enodeb4gMessage.error.jumlah}</span>}
                    </Styles.InputForm>
                    <Button color="error" variant="contained" onClick={setUpModalAddNodeb4g} style={{ marginRight: 5 }}>Close</Button>
                    <Button color="primary" variant="contained" type="submit">Save</Button>
                </form>
            </ReactModal>
        </>
    )
}

export default EnodeB4G

const Tahun = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= 1945; i--) {
        tahun.push(i);
    }
    return tahun;
}

const TahunFrom = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= (year - 10); i--) {
        tahun.push(i);
    }
    return tahun;
}

const TahunTo = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= (year - 10); i--) {
        tahun.push(i);
    }
    return tahun;
}