import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import Select from "react-select";
import { Styles } from '..';

const Tahun = () => {
	const date = new Date();
	const year = date.getFullYear();
	const tahun = [];
	for (let i = year; i >= 2010; i--) {
		tahun.push(i);
	}
	return tahun;
}


const FilterComponentSelect = ({ yearValue, fun }) => {
	const { control } = useForm();
	const [tahun, setTahun] = React.useState([]);


	const customStyles = {
		option: (provided, state) => ({
			...provided,
			borderBottom: '1px dotted pink',
			color: state.isSelected ? 'red' : 'blue',
			padding: 20,
			width: '500px !mportant',
		}),
		control: () => ({
			// none of react-select's styles are passed to <Control />
			width: '500px',
		}),
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = 'opacity 300ms';

			return { ...provided, opacity, transition };
		}
	}

	React.useEffect(() => {
		setTahun(Tahun().map(t => ({ value: t, label: t })));
	}, []);
	return (
		<Styles.InputForm>
			<Controller
				control={control}
				name="tahun"
				render={({ field }) => (
					<Select
						{...field}
						options={tahun}
						customStyles={customStyles}
						defaultValue={yearValue}
						placeholder="Tahun"
						onChange={(e) => fun(e.value)}
					/>
				)}
			/>
		</Styles.InputForm>

	)
}

export default FilterComponentSelect;
