import React from 'react';
import DataTable from 'react-data-table-component';
import Loader from 'react-loader-spinner';

import { Spinner, FilterComponent } from '../../components';


const ListIzin = ({
    getIzin,
    izin
}) => {
    const [rows, setRows] = React.useState([]);
    const [filterText, setFilterText] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    // const permitName = ;

    const filteredItems = rows.filter(
        item => (
            (item.company_name && item.company_name.toLowerCase().match(filterText.toLowerCase())) ||
            (item.permit && item.permit.toLowerCase().match(filterText.toLowerCase())) ||
            (item.permit_name && item.permit_name.toLowerCase().match(filterText.toLowerCase())) ||
            (item.permit_date && item.permit_date.toLowerCase().match(filterText.toLowerCase())) ||
            (item.signer && item.signer.toLowerCase().match(filterText.toLowerCase()))
        )
    );

    const subHeaderComponentMemo = React.useMemo(() => {
        return <div className="row">
            <div className="col-md-6">
                <select className="form-control" onChange={(e) => {
                    setLoading(false);
                    getIzin(e.target.value);
                }}>
                    <option value=''>Semua Izin</option>
                    <option value='JABERSEL'>JABERSEL</option>
                    <option value='JABERTRUNK'>JABERTRUNK</option>
                    <option value='JARTAPLOK'>JARTAPLOK</option>
                    <option value='JARTAPLOK PS'>JARTAPLOK PS</option>
                    <option value='JARTAPLOK SLI'>JARTAPLOK SLI</option>
                    <option value='JARTAPLOK SLJJ'>JARTAPLOK SLJJ</option>
                    <option value='JARTAPTUP'>JARTAPTUP</option>
                </select>
            </div>
            <div className="col-md-6">
                <FilterComponent onFilter={e => setFilterText(e.target.value)} filterText={filterText} placeholder="Cari.." />
            </div>
        </div>
    }, [filterText, getIzin]);
    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none'
            },
        },
    };
    const columns = [
        {
            name: 'Nama Perusahaan',
            selector: 'company_name',
            minWidth: '300px',
        },
        {
            name: 'Izin',
            selector: 'permit',
            minWidth: '200px',
        },
        {
            name: 'Nama Izin',
            selector: 'permit_name',
            minWidth: '150px',
        },
        {
            name: 'Nomor',
            selector: 'permit_number',
            minWidth: '350px',
        },
        {
            name: 'Tanggal',
            selector: 'permit_date',
            minWidth: '10px',
        },
        {
            name: 'Penandatangan',
            selector: 'signer',
            minWidth: '260px',
        }
    ];

    React.useEffect(() => {
        getIzin();
    }, [getIzin]);
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            izin && izin.izin &&
                setRows(izin.izin.map((izin) => ({
                    company_name: izin.company_name,
                    permit: izin.permit,
                    permit_name: izin.permit_name,
                    permit_number: izin.permit_number,
                    permit_date: izin.permit_date,
                    signer: izin.signer
                })));
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [izin]);

    return loading ? <Spinner /> : izin.error.message === "Unauthenticated." ? (
        window.location.href = "/" && localStorage.removeItem('token')) : (
        <div className="row">
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header card-header-icon" data-background-color="blue">
                        <i className="material-icons">send</i>
                    </div>
                    <h3 className="title" style={{ fontWeight: 'bold', top: 0 }}>Izin Penyelenggara</h3>
                    <div className="card-content">
                        <div className="material-datatables">
                            <DataTable
                                columns={columns}
                                data={filteredItems}
                                // paginationTotalRows={filteredItems.length}
                                progressPending={loading}
                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100}
                                    width={100} />}
                                responsive={true}
                                pagination
                                subHeader
                                customStyles={customStyles}
                                subHeaderComponent={subHeaderComponentMemo} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListIzin
