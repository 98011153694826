import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import Select from 'react-select';
import swal from 'sweetalert';
import { Sidebar, Navbar, Styles, Spinner } from '../../components';

const AddNew = ({
    usersMessage,
    addUsers,
    user,
    getUser
}) => {
    const history = useHistory();
    const { register, handleSubmit, control } = useForm();
    const [inputRole, setIputRole] = React.useState('');
    const [userData, setUserData] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    // console.log(userData.role.id)

    const role = [
        {
            value: 1,
            label: 'Super Admin'
        },
        {
            value: 2,
            label: 'Admin'
        },
        {
            value: 3,
            label: 'Guest'
        },
    ];

    const addRoleByAdmin = [
        {
            value: 2,
            label: 'Admin'
        },
        {
            value: 3,
            label: 'Guest'
        },
    ];

    React.useEffect(() => {
        document.title = "Tambah User | PPI"
    }, []);

    React.useEffect(() => {
        getUser();
    }, [getUser]);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            user && user.error === "Unauthenticated." && history.push('/');
            user && user.user && user.user.map(u => setUserData(u));
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [history, user]);

    React.useEffect(() => {
        const notifikasi = () => {
            usersMessage.loading && usersMessage.usersMessage && swal(`${usersMessage.usersMessage.message}`, {
                buttons: true
            }).then((value) => {
                if (value) {
                    history.push('/users');
                }
            })
        }
        return notifikasi()
    }, [history, usersMessage.loading, usersMessage.usersMessage]);

    const onSubmit = (data) => {
        let reg = {
            username: data.username,
            email: data.email,
            role_id: inputRole,
            password: data.password,
            password_confirmation: data.password_confirmation
        }
        addUsers(reg);
    }
    return loading ? <Spinner /> : userData !== null && userData.role.role_name !== 'Super Admin' && userData.role.role_name !== 'Admin' ? history.push('/dashboard') : (
        <>
            <Sidebar menu="ADD NEW" />
            <div className="main-panel">
                <Navbar menu="ADD NEW" user={userData} />
                <div className="content">
                    <Container fluid>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header card-header-icon" data-background-color="blue">
                                        <Styles.ButtonModal
                                            type="button"
                                            onClick={() => history.goBack()}>
                                            <i className="fa fa-chevron-circle-left fa-2x" aria-hidden="true"></i>
                                        </Styles.ButtonModal>
                                    </div>
                                    <h3 className="title" style={{ fontWeight: 'bold', marginTop: '0px' }}>Add New User</h3>
                                    <div className="card-content">
                                        <form className="form"
                                            onSubmit={handleSubmit(onSubmit)}
                                        >
                                            <Styles.InputForm>
                                                <input
                                                    placeholder="Username"
                                                    type="text"
                                                    className="form-control"
                                                    {...register("username")}
                                                />
                                                {usersMessage && usersMessage.error && <span className="text-danger">{usersMessage.error.username}</span>}
                                            </Styles.InputForm>
                                            <Styles.InputForm>
                                                <input
                                                    placeholder="Alamat Email"
                                                    type="text"
                                                    className="form-control"
                                                    {...register("email")}
                                                />
                                                {usersMessage && usersMessage.error && <span className="text-danger">{usersMessage.error.email}</span>}
                                            </Styles.InputForm>
                                            <Styles.InputForm>
                                                <Controller
                                                    control={control}
                                                    name="role_id"
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            options={userData !== null && userData.role.id === 1 ? role : addRoleByAdmin}
                                                            placeholder="Pilih Role User"
                                                            onChange={(e) => setIputRole(e.value)}
                                                        />
                                                    )}
                                                />
                                                {usersMessage && usersMessage.error && <span className="text-danger">{usersMessage.error.role_id}</span>}
                                            </Styles.InputForm>
                                            <Styles.InputForm>
                                                <input
                                                    placeholder="Password"
                                                    type="password"
                                                    className="form-control"
                                                    {...register("password")}
                                                />
                                                {usersMessage && usersMessage.error && <span className="text-danger">{usersMessage.error.password}</span>}
                                            </Styles.InputForm>
                                            <Styles.InputForm>
                                                <input
                                                    placeholder="Konfirmasi Password"
                                                    type="password"
                                                    className="form-control"
                                                    {...register("password_confirmation")}
                                                />
                                                {usersMessage && usersMessage.error && <span className="text-danger">{usersMessage.error.password_confimation}</span>}
                                            </Styles.InputForm>
                                            <Button variant="primary" type="submit">Save</Button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default AddNew
