import EditDataPenyelenggaraan from "./EditDetailPenyelenggara";
import { connect } from 'react-redux';
import {
    getNegara,
    getDetailSaham,
    getPegawaiDetail,
    updateDetailSaham,
    updatePegawai,
    getCapexOpexDetail,
    updateCapexOpex,
    getIzinDetailById,
    updateIzin,
    getUser
} from '../../redux';

const mapStateToProps = state => {
    return {
        user: state.user,
        sahamDetail: state.sahamDetail,
        sahamMessage: state.sahamMessage,
        negara: state.negara,
        pegawaiDetail: state.pegawaiDetail,
        pegawaiMessage: state.pegawaiMessage,
        capexOpexDetail: state.capexOpexDetail,
        capexOpexMessage: state.capexOpexMessage,
        izinDetail: state.izinDetail,
        izinMessage: state.izinMessage,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUser: () => dispatch(getUser()),
        getDetailSaham: (id) => dispatch(getDetailSaham(id)),
        getPegawaiDetail: (id) => dispatch(getPegawaiDetail(id)),
        getCapexOpexDetail: (id) => dispatch(getCapexOpexDetail(id)),
        updateDetailSaham: (id, data) => dispatch(updateDetailSaham(id, data)),
        updatePegawai: (id, data) => dispatch(updatePegawai(id, data)),
        updateCapexOpex: (id, data) => dispatch(updateCapexOpex(id, data)),
        getNegara: () => dispatch(getNegara()),
        getIzinDetailById: (id) => dispatch(getIzinDetailById(id)),
        updateIzin: (id, data) => dispatch(updateIzin(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDataPenyelenggaraan);
