import {
    JARTAPTUP_PELANGGAN_REQ,
    JARTAPTUP_PELANGGAN_SUCCESS,
    JARTAPTUP_PELANGGAN_FAIL,
    JARTAPTUP_JARINGAN_REQ,
    JARTAPTUP_JARINGAN_SUCCESS,
    JARTAPTUP_JARINGAN_FAIL,
    JARTAPTUP_MESSAGE_REQ,
    JARTAPTUP_MESSAGE_SUCCESS,
    JARTAPTUP_MESSAGE_FAIL,
    JARTAPTUP_DETAIL_REQ,
    JARTAPTUP_DETAIL_SUCCESS,
    JARTAPTUP_DETAIL_FAIL,
    JARTAPTUP_TECHNAME_REQ,
    JARTAPTUP_TECHNAME_SUCCESS,
    JARTAPTUP_TECHNAME_FAIL,
    JARTAPTUP_NETWORKNAME_REQ,
    JARTAPTUP_NETWORKNAME_SUCCESS,
    JARTAPTUP_NETWORKNAME_FAIL,
} from './jartaptupTypes';

const jartaptupPelangganState = {
    loading: false,
    jartaptupPelanggan: [],
    error: ''
}
const jartaptupJaringanState = {
    loading: false,
    jartaptupJaringan: [],
    error: ''
}

const jartaptupTechState = {
    loading: false,
    jartaptupTech: [],
    error: ''
}

const jartaptupNetworkState = {
    loading: false,
    jartaptupNetwork: [],
    error: ''
}

const jartaptupMessageState = {
    loading: false,
    jartaptupMessage: '',
    error: ''
}

const jartaptupDetailState = {
    loading: false,
    jartaptupDetail: null,
    error: ''
}

export const jartaptupDetailReducer = (state = jartaptupDetailState, action) => {
    switch (action.type) {
        case JARTAPTUP_DETAIL_REQ:
            return {
                ...state,
                loading: false
            }
        case JARTAPTUP_DETAIL_SUCCESS:
            return {
                loading: true,
                jartaptupDetail: action.payload,
                error: ''
            }
        case JARTAPTUP_DETAIL_FAIL:
            return {
                loading: true,
                jartaptupDetail: null,
                error: action.payload
            }
        default:
            return state
    }
}

const jartaptupPelangganReducer = (state = jartaptupPelangganState, action) => {
    switch (action.type) {
        case JARTAPTUP_PELANGGAN_REQ:
            return {
                ...state,
                loading: false,
            }
        case JARTAPTUP_PELANGGAN_SUCCESS:
            return {
                loading: true,
                jartaptupPelanggan: action.payload,
                error: ''
            }
        case JARTAPTUP_PELANGGAN_FAIL:
            return {
                loading: true,
                jartaptupPelanggan: [],
                error: action.payload
            }
        default:
            return state
    }
}
export const jartaptupJaringanReducer = (state = jartaptupJaringanState, action) => {
    switch (action.type) {
        case JARTAPTUP_JARINGAN_REQ:
            return {
                ...state,
                loading: false,
            }
        case JARTAPTUP_JARINGAN_SUCCESS:
            return {
                loading: true,
                jartaptupJaringan: action.payload,
                error: ''
            }
        case JARTAPTUP_JARINGAN_FAIL:
            return {
                loading: true,
                jartaptupJaringan: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const jartaptupMessageReducer = (state = jartaptupMessageState, action) => {
    switch (action.type) {
        case JARTAPTUP_MESSAGE_REQ:
            return {
                ...state,
                loading: false,
            }
        case JARTAPTUP_MESSAGE_SUCCESS:
            return {
                loading: true,
                jartaptupMessage: action.payload,
                error: ''
            }
        case JARTAPTUP_MESSAGE_FAIL:
            return {
                loading: true,
                jartaptupMessage: "",
                error: action.payload
            }
        default:
            return state
    }
}

export const jartaptupNetworkReducer = (state = jartaptupNetworkState, action) => {
    switch (action.type) {
        case JARTAPTUP_NETWORKNAME_REQ:
            return {
                ...state,
                loading: false,
            }
        case JARTAPTUP_NETWORKNAME_SUCCESS:
            return {
                loading: true,
                jartaptupNetwork: action.payload,
                error: ''
            }
        case JARTAPTUP_NETWORKNAME_FAIL:
            return {
                loading: true,
                jartaptupNetwork: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const jartaptupTechReducer = (state = jartaptupTechState, action) => {
    switch (action.type) {
        case JARTAPTUP_TECHNAME_REQ:
            return {
                ...state,
                loading: false,
            }
        case JARTAPTUP_TECHNAME_SUCCESS:
            return {
                loading: true,
                jartaptupTech: action.payload,
                error: ''
            }
        case JARTAPTUP_TECHNAME_FAIL:
            return {
                loading: true,
                jartaptupTech: [],
                error: action.payload
            }
        default:
            return state
    }
}

export default jartaptupPelangganReducer