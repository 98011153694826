import EnodeB4G from './EnodeB4G';
import { connect } from 'react-redux';
import { addenodeb4g, getenodeb4gByCompany, getProv, getenodeb4gPivot, uploadENodeb4g, deleteENodeb4g } from '../../redux';

const mapStateToProps = state => {
    return {
        enodeb4g: state.enodeb4g,
        enodeb4gCrud: state.enodeb4gCrud,
        enodeb4gMessage: state.enodeb4gMessage,
        provinsi: state.provinsi
    }
}

const mapDispatchToProps = dispatch => {
    return {
        deleteENodeb4g: (id) => dispatch(deleteENodeb4g(id)),
        uploadENodeb4g: (data) => dispatch(uploadENodeb4g(data)),
        getenodeb4gByCompany: (id) => dispatch(getenodeb4gByCompany(id)),
        getenodeb4gPivot: (id, from, to) => dispatch(getenodeb4gPivot(id, from, to)),
        addenodeb4g: (data) => dispatch(addenodeb4g(data)),
        getProv: () => dispatch(getProv()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnodeB4G)