import React from 'react'
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import swal from 'sweetalert';


const Navbar = ({ user, toLogout }) => {
    const [sidebarView, setSidebarView] = React.useState(false);
    const toggle = () => {
        swal("Apakah Anda Yakin ingin keluar?", {
            buttons: ["Batal", true],
        })
            .then((value) => {
                if (value) {
                    toLogout();
                }
            });
    }

    const sidebarControl = () => {
        setSidebarView(!sidebarView);
        sidebarView ? document.body.classList.remove('sidebar-mini') :
            document.body.classList.add("sidebar-mini")
    }
    return (
        <nav className="navbar navbar-transparent navbar-absolute" style={{ background: '#6e6e6e' }}>
            <div className="container-fluid">
                <div className="navbar-minimize">
                    <button className="btn btn-round btn-white btn-fill btn-just-icon" onClick={sidebarControl}>
                        <i className="material-icons visible-on-sidebar-regular">view_list</i>
                        <i className="material-icons visible-on-sidebar-mini">view_list</i>
                    </button>
                </div>
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse">
                        <i className="material-icons visible-on-sidebar-regular">more_vert</i>
                    </button>
                    <Link className="navbar-brand" to="#">
                        <Moment format="DD MMM YYYY h:mm:ss" withTitle locale="in" interval={1000} />
                    </Link>
                </div>
                <div className="collapse navbar-collapse">
                    <ul className="nav navbar-nav navbar-right">
                        <li>
                            <Link to="#" className="dropdown-toggle" data-toggle="dropdown">
                                {user !== null ? user.details.fname : 'loading... '} <i className="material-icons">account_circle</i>
                            </Link>
                            <ul className="dropdown-menu">
                                <li>
                                    <Link to="/profil">Profil</Link>
                                </li>
                                <li >
                                    <Link to="#" onClick={() => toggle()}>
                                        Logout
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="separator hidden-lg hidden-md"></li>
                    </ul>
                </div>
            </div >
        </nav >
    )
}

export default Navbar;
