import {
    NODEB3G_REQ,
    NODEB3G_SUCCESS,
    NODEB3G_FAIL,
    NODEB3G_MESSAGE_REQ,
    NODEB3G_MESSAGE_SUCCESS,
    NODEB3G_MESSAGE_FAIL,
    NODEB3G_DETAIL_REQ,
    NODEB3G_DETAIL_SUCCESS,
    NODEB3G_DETAIL_FAIL,
    NODEB3G_CRUD_REQ,
    NODEB3G_CRUD_SUCCESS,
    NODEB3G_CRUD_FAIL,
} from './nodeb3gTypes';

const nodeb3gDetailState = {
    loading: false,
    nodeb3gDetail: [],
    error: ''
}

const nodeb3gCrudState = {
    loading: false,
    nodeb3gCrud: [],
    error: ''
}

const nodeb3gState = {
    loading: false,
    nodeb3g: [],
    error: ''
}

const nodeb3gMessageState = {
    loading: false,
    nodeb3gMessage: '',
    error: ''
}

export const nodeb3gDetailReducer = (state = nodeb3gDetailState, action) => {
    switch (action.type) {
        case NODEB3G_DETAIL_REQ:
            return {
                ...state,
                loading: false
            }
        case NODEB3G_DETAIL_SUCCESS:
            return {
                loading: true,
                nodeb3gDetail: action.payload,
                error: ''
            }
        case NODEB3G_DETAIL_FAIL:
            return {
                loading: false,
                nodeb3gDetail: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const nodeb3gMessageReducer = (state = nodeb3gMessageState, action) => {
    switch (action.type) {
        case NODEB3G_MESSAGE_REQ:
            return {
                ...state,
                loading: false,
            }
        case NODEB3G_MESSAGE_SUCCESS:
            return {
                loading: true,
                nodeb3gMessage: action.payload,
                error: ''
            }
        case NODEB3G_MESSAGE_FAIL:
            return {
                loading: true,
                nodeb3gMessage: "",
                error: action.payload
            }
        default:
            return state
    }
}

export const nodeb3gCrudReducer = (state = nodeb3gCrudState, action) => {
    switch (action.type) {
        case NODEB3G_CRUD_REQ:
            return {
                ...state,
                loading: false,
            }
        case NODEB3G_CRUD_SUCCESS:
            return {
                loading: true,
                nodeb3gCrud: action.payload,
                error: ''
            }
        case NODEB3G_CRUD_FAIL:
            return {
                loading: false,
                nodeb3gCrud: [],
                error: action.payload
            }
        default:
            return state
    }
}

const nodeb3gReducer = (state = nodeb3gState, action) => {
    switch (action.type) {
        case NODEB3G_REQ:
            return {
                ...state,
                loading: false,
            }
        case NODEB3G_SUCCESS:
            return {
                loading: true,
                nodeb3g: action.payload,
                error: ''
            }
        case NODEB3G_FAIL:
            return {
                loading: false,
                nodeb3g: [],
                error: action.payload
            }
        default:
            return state
    }
}

export default nodeb3gReducer