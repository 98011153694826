import {
    TEKNOLOGI_REQ,
    TEKNOLOGI_SUCCESS,
    TEKNOLOGI_FAIL,
    TEKNOLOGI_MESSAGE_REQ,
    TEKNOLOGI_MESSAGE_SUCCESS,
    TEKNOLOGI_MESSAGE_FAIL,
    TEKNOLOGI_DETAIL_REQ,
    TEKNOLOGI_DETAIL_SUCCESS,
    TEKNOLOGI_DETAIL_FAIL,
    TEKNOLOGI_UPLOAD_REQ,
    TEKNOLOGI_UPLOAD_SUCCESS,
    TEKNOLOGI_UPLOAD_FAIL,

    TEKNOLOGI_CRUD_REQ,
    TEKNOLOGI_CRUD_SUCCESS,
    TEKNOLOGI_CRUD_FAIL,

    TEKNOLOGI_PENYELENGGARA_REQ,
    TEKNOLOGI_PENYELENGGARA_SUCCESS,
    TEKNOLOGI_PENYELENGGARA_FAIL,

    TEKNOLOGI_IZIN_REQ,
    TEKNOLOGI_IZIN_SUCCESS,
    TEKNOLOGI_IZIN_FAIL,

    TEKNOLOGI_IZIN_TANGGAL_REQ,
    TEKNOLOGI_IZIN_TANGGAL_SUCCESS,
    TEKNOLOGI_IZIN_TANGGAL_FAIL,

    NAMA_TEKNOLOGI_REQ,
    NAMA_TEKNOLOGI_SUCCESS,
    NAMA_TEKNOLOGI_FAIL,
} from './teknologiTypes'

import api from '../services';
import { config, configUpload } from '../config';

// DELETE TEKNOLOGI
export const deleteTeknologi = (id) => {
    return async (dispatch) => {
        dispatch(teknologiMessageReq);
        await api.delete(`/data-teknologi-delete/${id}`, config)
            .then(res => {
                dispatch(teknologiMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(teknologiMessageFail(err.response.data));
            })
    }
}

// FORM TEKNOLOGI DETAIL
export const getTeknologiDetail = (id) => {
    return async (dispatch) => {
        dispatch(teknologiReq);
        await api.get(`/data-teknologi/${id}`, config)
            .then(res => {
                dispatch(teknologiSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(teknologiFail(err.response.data));
            })
    }
}

// NAMA TEKNOLOGI
export const namaTeknologiReq = () => {
    return { type: NAMA_TEKNOLOGI_REQ }
}

export const namaTeknologiSuccess = (data) => {
    return { type: NAMA_TEKNOLOGI_SUCCESS, payload: data }
}

export const namaTeknologiFail = (err) => {
    return { type: NAMA_TEKNOLOGI_FAIL, payload: err }
}

export const getNamaTeknologi = (namaizin) => {
    return async (dispatch) => {
        dispatch(namaTeknologiReq);
        await api.get(`/nama-data-teknologi/${namaizin}`, config)
            .then(res => {
                dispatch(namaTeknologiSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(namaTeknologiFail(err.response.data));
            })
    }
}

// TEKNOLOGI IZIN TANGGAL
export const teknologiIzinTanggalReq = () => {
    return { type: TEKNOLOGI_IZIN_TANGGAL_REQ }
}

export const teknologiIzinTanggalSuccess = (data) => {
    return { type: TEKNOLOGI_IZIN_TANGGAL_SUCCESS, payload: data }
}

export const teknologiIzinTanggalFail = (err) => {
    return { type: TEKNOLOGI_IZIN_TANGGAL_FAIL, payload: err }
}

export const getTeknologiIzinTanggal = (namaizin, company) => {
    return async (dispatch) => {
        dispatch(teknologiIzinTanggalReq);
        await api.get(`/data-teknologi-izin-tanggal/${namaizin}/${company}`, config)
            .then(res => {
                dispatch(teknologiIzinTanggalSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(teknologiIzinTanggalFail(err.response.data));
            })
    }
}

// TEKNOLOGI IZIN
export const teknologiIzinReq = () => {
    return { type: TEKNOLOGI_IZIN_REQ }
}

export const teknologiIzinSuccess = (data) => {
    return { type: TEKNOLOGI_IZIN_SUCCESS, payload: data }
}

export const teknologiIzinFail = (err) => {
    return { type: TEKNOLOGI_IZIN_FAIL, payload: err }
}

export const getTeknologiIzin = (id) => {
    return async (dispatch) => {
        dispatch(teknologiIzinReq);
        await api.get(`/data-teknologi-izin/${id}`, config)
            .then(res => {
                dispatch(teknologiIzinSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(teknologiIzinFail(err.response.data));
            })
    }
}

// TEKNOLOGI PENYELENGGARA
export const teknologiPenyelenggaraReq = () => {
    return { type: TEKNOLOGI_PENYELENGGARA_REQ }
}

export const teknologiPenyelenggaraSuccess = (data) => {
    return { type: TEKNOLOGI_PENYELENGGARA_SUCCESS, payload: data }
}

export const teknologiPenyelenggaraFail = (err) => {
    return { type: TEKNOLOGI_PENYELENGGARA_FAIL, payload: err }
}

export const getTeknologiPenyelenggara = () => {
    return async (dispatch) => {
        dispatch(teknologiPenyelenggaraReq);
        await api.get('/data-teknologi-penyelenggara', config)
            .then(res => {
                dispatch(teknologiPenyelenggaraSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(teknologiPenyelenggaraFail(err.response.data));
            })
    }
}

// 
export const teknologiMessageReq = () => {
    return { type: TEKNOLOGI_MESSAGE_REQ }
}

export const teknologiMessageSuccess = (data) => {
    return { type: TEKNOLOGI_MESSAGE_SUCCESS, payload: data }
}

export const teknologiMessageFail = (err) => {
    return { type: TEKNOLOGI_MESSAGE_FAIL, payload: err }
}

// Teknologi Create function
export const addTeknologi = (data) => {
    return async (dispatch) => {
        dispatch(teknologiMessageReq);
        await api.post('/data-teknologi-create', data, config)
            .then(res => {
                dispatch(teknologiMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(teknologiMessageFail(err.response.data));
            })
    }
}

// Teknologi get Data CRUD
export const teknologiCrudReq = () => {
    return { type: TEKNOLOGI_CRUD_REQ }
}

export const teknologiCrudSuccess = (data) => {
    return { type: TEKNOLOGI_CRUD_SUCCESS, payload: data }
}

export const teknologiCrudFail = (err) => {
    return { type: TEKNOLOGI_CRUD_FAIL, payload: err }
}

export const getTeknologiCrud = () => {
    return async (dispatch) => {
        dispatch(teknologiCrudReq);
        await api.get(`/data-teknologi`, config)
            .then(res => {
                dispatch(teknologiCrudSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(teknologiCrudFail(err.response.data));
            })
    }
}



// Teknologi Pivot

export const teknologiUploadReq = () => {
    return { type: TEKNOLOGI_UPLOAD_REQ }
}
export const teknologiUploadSuccess = (data) => {
    return { type: TEKNOLOGI_UPLOAD_SUCCESS, payload: data }
}
export const teknologiUploadFail = (err) => {
    return { type: TEKNOLOGI_UPLOAD_FAIL, payload: err }
}

export const teknologiDetailReq = () => {
    return { type: TEKNOLOGI_DETAIL_REQ }
}

export const teknologiDetailSuccess = (data) => {
    return { type: TEKNOLOGI_DETAIL_SUCCESS, payload: data }
}

export const teknologiDetailFail = (err) => {
    return { type: TEKNOLOGI_DETAIL_FAIL, payload: err }
}

export const teknologiReq = () => {
    return { type: TEKNOLOGI_REQ }
}

export const teknologiSuccess = (data) => {
    return { type: TEKNOLOGI_SUCCESS, payload: data }
}

export const teknologiFail = (err) => {
    return { type: TEKNOLOGI_FAIL, payload: err }
}

export const uploadteknologi = (data) => {
    return async (dispatch) => {
        dispatch(teknologiMessageReq);
        await api.post(`/data-teknologi-create-import`, data, configUpload)
            .then(res => dispatch(teknologiMessageSuccess(res.data)))
            .catch(err => dispatch(teknologiMessageFail(err.response.data)))
    }
}

export const getteknologiDetail = (id) => {
    return async (dispatch) => {
        dispatch(teknologiDetailReq);
        await api.get(`/data-umum/${id}`, config)
            .then(res => {
                dispatch(teknologiDetailSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(teknologiDetailFail(err.response.data));
            })
    }
}

export const getTeknologi = (data) => {
    return async (dispatch) => {
        dispatch(teknologiReq);
        await api.get(`/data-teknologi-pivot/${data}`, config)
            .then(res => {
                dispatch(teknologiSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(teknologiFail(err.response.data));
            })
    }
}
