import {
    REPORT_REQ,
    REPORT_SUCCESS,
    REPORT_FAIL,
} from './reportTypes';

const reportState = {
    load: false,
    data: [],
    false: ''
}

const reportReducer = (state = reportState, action) => {
    switch (action.type) {
        case REPORT_REQ:
            return {
                ...state,
                loading: true,
            }
        case REPORT_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                error: ''
            }
        case REPORT_FAIL:
            return {
                loading: false,
                data: [],
                error: action.payload
            }
        default:
            return state
    }
}

export default reportReducer