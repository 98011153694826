import Jabertrunk from "./Jebertrunk";
import { connect } from 'react-redux';
import { getAllJabertrunk, getUser } from "../../redux";

const mapStateToProps = state => {
    return {
        user: state.user,
        jabertrunk: state.jabertrunk
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUser: () => dispatch(getUser()),
        getAllJabertrunk: (data) => dispatch(getAllJabertrunk(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Jabertrunk);
