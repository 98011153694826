import JartaplokSli from "./JartaplokSli";
import { connect } from 'react-redux';
import { getAlljartapsli, getUser } from "../../redux";


const mapStateToProps = state => {
    return {
        jartapsli: state.jartapsli,
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUser: () => dispatch(getUser()),
        getAlljartapsli: (data) => dispatch(getAlljartapsli(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JartaplokSli);
