import Jartaplokps from './Jartaplokps';
import { connect } from 'react-redux';
import {
    addjartaplokps,
    deletejartaplokps,
    getJartaplokpsByCompany,
    getJartaplokpsJaringanByCompany,
    uploadJartaplokps,
    uploadJartaplokpsJaringan,
    deletejartaplokpsJaringan,
    addjartaplokpsJaringan,
    getTeknologiJartaplokps,
    getTeknologiItemJartaplokps
} from '../../redux';

const mapStateToProps = state => {
    return {
        jartaplokps: state.jartaplokps,
        jartaplokpsMessage: state.jartaplokpsMessage,
        jartaplokpsJaringan: state.jartaplokpsJaringan,
        jartaplokpsTeknologi: state.jartaplokpsTeknologi,
        teknologiItemJartaplokps: state.teknologiItemJartaplokps
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTeknologiItemJartaplokps: (id) => dispatch(getTeknologiItemJartaplokps(id)),
        getTeknologiJartaplokps: () => dispatch(getTeknologiJartaplokps()),
        addjartaplokpsJaringan: (data) => dispatch(addjartaplokpsJaringan(data)),
        uploadJartaplokps: (data) => dispatch(uploadJartaplokps(data)),
        uploadJartaplokpsJaringan: (data) => dispatch(uploadJartaplokpsJaringan(data)),
        getJartaplokpsJaringanByCompany: (id) => dispatch(getJartaplokpsJaringanByCompany(id)),
        getJartaplokpsByCompany: (id) => dispatch(getJartaplokpsByCompany(id)),
        addjartaplokps: (data) => dispatch(addjartaplokps(data)),
        deletejartaplokps: (data) => dispatch(deletejartaplokps(data)),
        deletejartaplokpsJaringan: (data) => dispatch(deletejartaplokpsJaringan(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Jartaplokps);