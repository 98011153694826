import React from 'react';
import {
    Container
} from 'react-bootstrap';
import {
    Sidebar, Navbar, Spinner
} from '../../components';
import {
    ProfilUmum
} from '../../parts'

const DataUmum = ({ getUser, user }) => {
    const [loading, setLoading] = React.useState(true);
    const [userData, setUserData] = React.useState(null);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            user && user.user && user.user.map(u => setUserData(u));
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [user]);

    React.useEffect(() => {
        document.title = "Penyelenggara | PPI"
    }, []);
    React.useEffect(() => {
        getUser();
    }, [getUser]);
    return loading ? <Spinner /> : (
        <>
            <Sidebar menu="PENYELENGGARA" />
            <div className="main-panel">
                <Navbar menu="DATA UMUM" user={userData} />
                <div className="content">
                    <Container fluid>
                        <ProfilUmum role={userData} />
                    </Container>
                </div>
            </div>
        </>
    )
}

export default DataUmum
