import ResetPassword from './ResetPassword';
import { connect } from 'react-redux';
import { resetPassword } from '../../redux';

const mapStateToProps = state => {
    return {
        reset: state.resetPass,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetPassword: (data) => dispatch(resetPassword(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)