import {
    RASIO_KESEHATAN_KEUANGAN_REQ,
    RASIO_KESEHATAN_KEUANGAN_SUCCESS,
    RASIO_KESEHATAN_KEUANGAN_FAIL,
    RASIO_KESEHATAN_KEUANGAN_CRUD_REQ,
    RASIO_KESEHATAN_KEUANGAN_CRUD_SUCCESS,
    RASIO_KESEHATAN_KEUANGAN_CRUD_FAIL,
    RASIO_KESEHATAN_KEUANGAN_CRUD_MESSAGE_REQ,
    RASIO_KESEHATAN_KEUANGAN_CRUD_MESSAGE_SUCCESS,
    RASIO_KESEHATAN_KEUANGAN_CRUD_MESSAGE_FAIL,
    KETERANGAN_RASIO_KESEHATAN_REQ,
    KETERANGAN_RASIO_KESEHATAN_SUCCESS,
    KETERANGAN_RASIO_KESEHATAN_FAIL,
} from './rasioKesehatanKeuanganTypes';

import api from '../services';
import { config } from '../config';

// rasio kesehatan keuangan
export const keteranganRasioKesehatanReq = () => {
    return { type: KETERANGAN_RASIO_KESEHATAN_REQ }
}

export const keteranganRasioKesehatanSuccess = (data) => {
    return { type: KETERANGAN_RASIO_KESEHATAN_SUCCESS, payload: data }
}

export const keteranganRasioKesehatanFail = (err) => {
    return { type: KETERANGAN_RASIO_KESEHATAN_FAIL, payload: err }
}

export const rasioKesehatanKeuanganCrudMessageReq = () => {
    return { type: RASIO_KESEHATAN_KEUANGAN_CRUD_MESSAGE_REQ }
}

export const rasioKesehatanKeuanganCrudMessageSuccess = (data) => {
    return { type: RASIO_KESEHATAN_KEUANGAN_CRUD_MESSAGE_SUCCESS, payload: data }
}

export const rasioKesehatanKeuanganCrudMessageFail = (err) => {
    return { type: RASIO_KESEHATAN_KEUANGAN_CRUD_MESSAGE_FAIL, payload: err }
}

export const rasioKesehatanKeuanganCrudReq = () => {
    return { type: RASIO_KESEHATAN_KEUANGAN_CRUD_REQ }
}

export const rasioKesehatanKeuanganCrudSuccess = (data) => {
    return { type: RASIO_KESEHATAN_KEUANGAN_CRUD_SUCCESS, payload: data }
}

export const rasioKesehatanKeuanganCrudFail = (err) => {
    return { type: RASIO_KESEHATAN_KEUANGAN_CRUD_FAIL, payload: err }
}

export const rasioKesehatanKeuanganReq = () => {
    return { type: RASIO_KESEHATAN_KEUANGAN_REQ }
}

export const rasioKesehatanKeuanganSuccess = (data) => {
    return { type: RASIO_KESEHATAN_KEUANGAN_SUCCESS, payload: data }
}

export const rasioKesehatanKeuanganFail = (err) => {
    return { type: RASIO_KESEHATAN_KEUANGAN_FAIL, payload: err }
}

export const uploadRasioKesehatanKeuangan = (data) => {
    return async (dispatch) => {
        dispatch(rasioKesehatanKeuanganCrudMessageReq);
        await api.post(`/rasio-kesehatan-create-import`, data, config)
            .then(res => dispatch(rasioKesehatanKeuanganCrudMessageSuccess(res.data)))
            .catch(err => dispatch(rasioKesehatanKeuanganCrudMessageFail(err.response.data)))
    }
}

export const updateRasioKesehatanKeuangan = (id, data) => {
    return async (dispatch) => {
        dispatch(rasioKesehatanKeuanganCrudMessageReq);
        await api.put(`/rasio-kesehatan-update/${id}`, data, config)
            .then(res => dispatch(rasioKesehatanKeuanganCrudMessageSuccess(res.data)))
            .catch(err => dispatch(rasioKesehatanKeuanganCrudMessageFail(err.response.data)))
    }
}

export const getRasioKesehatanKeuanganDetail = (id) => {
    return async (dispatch) => {
        dispatch(rasioKesehatanKeuanganReq);
        await api.get(`/rasio-kesehatan/${id}`, config)
            .then(res => {
                dispatch(rasioKesehatanKeuanganSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(rasioKesehatanKeuanganFail(err.response.data));
            })
    }
}

export const getKeteranganRasioKesehatan = () => {
    return async (dispatch) => {
        dispatch(keteranganRasioKesehatanReq);
        await api.get(`/nama-keterangan-rasio-kesehatan`, config)
            .then(res => {
                dispatch(keteranganRasioKesehatanSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(keteranganRasioKesehatanFail(err.response.data));
            })
    }
}

export const deleteRasioKesehatanKeuangan = (id) => {
    return async (dispatch) => {
        dispatch(rasioKesehatanKeuanganCrudMessageReq);
        await api.delete(`/rasio-kesehatan-delete/${id}`, config)
            .then(res => {
                dispatch(rasioKesehatanKeuanganCrudMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(rasioKesehatanKeuanganCrudMessageFail(err.response.data));
            })
    }
}

export const addRasioKesehatanKeuangan = (data) => {
    return async (dispatch) => {
        dispatch(rasioKesehatanKeuanganCrudMessageReq);
        await api.post(`/rasio-kesehatan-create`, data, config)
            .then(res => {
                dispatch(rasioKesehatanKeuanganCrudMessageSuccess(res.data));
            })
            .catch(err => {
                dispatch(rasioKesehatanKeuanganCrudMessageFail(err.response.data));
            })
    }
}

export const getRasioKesehatanKeuanganCrud = (company_id) => {
    return async (dispatch) => {
        dispatch(rasioKesehatanKeuanganCrudReq);
        await api.get(`/rasio-kesehatan-company/${company_id}`, config)
            .then(res => {
                dispatch(rasioKesehatanKeuanganCrudSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(rasioKesehatanKeuanganCrudFail(err.response.data));
            })
    }
}

const getRasioKesehatanKeuanganPivot = (company_id) => {
    return async (dispatch) => {
        dispatch(rasioKesehatanKeuanganReq);
        await api.get(`/rasio-kesehatan-pivot/${company_id}`, config)
            .then(res => {
                dispatch(rasioKesehatanKeuanganSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(rasioKesehatanKeuanganFail(err.response.data));
            })
    }
}

export default getRasioKesehatanKeuanganPivot