import React from 'react';
import {
    Container
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {
    Sidebar, Navbar, Spinner
} from '../../components';
import {
    TeknologiSection
} from '../../parts'

const Teknologi = ({
    user,
    getUser
}) => {

    const history = useHistory();
    const [loading, setLoading] = React.useState(true);
    const [userData, setUserData] = React.useState(null);

    React.useEffect(() => {
        document.title = "Dashboard | PPI"
    }, []);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            user && user.user && user.user.map(u => setUserData(u));
            user && user.error === "Unauthenticated." && history.push('/');
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [history, user]);

    React.useEffect(() => {
        getUser();
    }, [getUser]);

    React.useEffect(() => {
        document.title = "Teknologi | PPI"
    }, []);

    return loading ? <Spinner /> : (
        <>
            <Sidebar menu="TEKNOLOGI" />
            <div className="main-panel">
                <Navbar menu="TEKNOLOGI" user={userData} />
                <div className="content">
                    <Container fluid>
                        <TeknologiSection />
                    </Container>
                </div>
            </div>
        </>
    )
}

export default Teknologi
