import Saham from './Saham';
import { connect } from 'react-redux';
import { getSaham, addSaham, getNegara, deleteSaham, uploadSaham } from '../../redux';

const mapStateToProps = state => {
    return {
        saham: state.saham,
        sahamMessage: state.sahamMessage,
        negara: state.negara
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSaham: (id) => dispatch(getSaham(id)),
        deleteSaham: (id) => dispatch(deleteSaham(id)),
        addSaham: (data) => dispatch(addSaham(data)),
        getNegara: () => dispatch(getNegara()),
        uploadSaham: (data) => dispatch(uploadSaham(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Saham)