import React from 'react';
import Loader from 'react-loader-spinner';
import DataTable from 'react-data-table-component';
import { Modal } from 'react-bootstrap';
import ReactModal from 'react-modal';
import Select from "react-select";
import { useForm, Controller } from 'react-hook-form';
import { useParams, useHistory, Link } from 'react-router-dom';
import swal from '@sweetalert/with-react';
import Button from '@mui/material/Button';

import { Styles, ActionButton, Spinner } from '../../components';
import { Bar } from 'react-chartjs-2';

const CapexOpex = ({
    capexOpex,
    getCapexOpex,
    addCapexOpex,
    capexOpexMessage,
    deleteCapexOpex,
    uploadCapexOpex,
    role
}) => {

    const { id, nama } = useParams();
    const history = useHistory();
    const { register, handleSubmit, control } = useForm();
    const [row, setRow] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [showModalAdd, setShowModalAdd] = React.useState(false);
    const [inputTahun, setInputTahun] = React.useState('');
    const [tahun, setTahun] = React.useState([]);
    const [dataChartValue, setDataChartValue] = React.useState([]);
    const [fileUpload, setFileUpload] = React.useState(null);


    const setUpModalAdd = () => setShowModalAdd(!showModalAdd);

    React.useEffect(() => {
        setTahun(Tahun().map(t => ({ value: t, label: t })));
    }, []);

    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { minimumFractionDigits: 0 }
        ).format(money);
    }

    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none'
            },
        },
    };

    const columns = [
        {
            name: 'No',
            selector: 'company_name',
        },
        {
            name: 'Capex',
            selector: row => formatRupiah(row.capex)
        },
        {
            name: 'Opex',
            selector: row => formatRupiah(row.opex),
        },
        {
            name: 'Tahun',
            selector: 'tahun',
        },
        {
            name: 'Action',
            selector: 'action'
        }
    ];
    const columnsGuest = [
        {
            name: 'No',
            selector: 'company_name',
        },
        {
            name: 'Capex',
            selector: row => formatRupiah(row.capex)
        },
        {
            name: 'Opex',
            selector: row => formatRupiah(row.opex),
        },
        {
            name: 'Tahun',
            selector: 'tahun',
        }
    ];
    React.useEffect(() => {
        capexOpexMessage.loading && capexOpexMessage.capexOpexMessage && swal(`${capexOpexMessage.capexOpexMessage.message}`, {
            buttons: ["cancel", true]
        }).then((value) => {
            if (value) {
                getCapexOpex(id);
            }
        });
    }, [getCapexOpex, id, capexOpexMessage]);
    React.useEffect(() => {
        getCapexOpex(id);
    }, [getCapexOpex, id]);

    React.useEffect(() => {
        const timeOut = setTimeout(() => {
            capexOpex && capexOpex.capexOpex &&
                setRow(
                    capexOpex.capexOpex.map((cp, index) => ({
                        company_name: index + 1,
                        capex: cp.capex,
                        opex: cp.opex,
                        tahun: cp.tahun,
                        action: <>
                            <ActionButton
                                title="fa fa-pencil"
                                css="btn-warning" fun={() => history.push(`/penyelenggara-detail-edit/${id}/${nama}/edit/${cp.id}/capex opex`)} />
                            &nbsp;
                            <ActionButton title="fa fa-trash" css="btn-danger" fun={() => swal(`Apakah Yakin Data Capex Opex ${cp.company_name} ini akan dihapus?`, {
                                buttons: ["Batal", true],
                            }).then((value) => {
                                if (value) {
                                    deleteCapexOpex(cp.id);
                                }
                            })
                            } />
                        </>
                    }))
                );
            setLoading(false);
        }, 3000);
        return () => clearTimeout(timeOut);
    }, [capexOpex, capexOpexMessage, control, deleteCapexOpex, history, id, nama]);
    React.useEffect(() => {
        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }
        capexOpex && capexOpex.capexOpex && setDataChartValue({
            capex: capexOpex.capexOpex.map(p => p.capex),
            opex: capexOpex.capexOpex.map(p => p.opex),
            tahun: capexOpex.capexOpex.map((p, index, values) => p.tahun).filter(onlyUnique),
            // background: capexOpex.capexOpex.map(p => makeid(6)).filter(onlyUnique)
        });
    }, [capexOpex]);

    const onSubmit = async (data) => {
        const inputCapexOpex = {
            dataumum_id: data.dataumum_id,
            company_name: data.company_name,
            capex: data.capex,
            opex: data.opex,
            tahun: inputTahun,
        }
        addCapexOpex(inputCapexOpex);
    }

    const onUpload = (e) => setFileUpload(e.target.files[0]);

    const submitUploadCapexOpex = (data) => {
        const formData = new FormData();
        formData.append("nama_file", fileUpload);
        uploadCapexOpex(formData)
    };

    const dataChartCapexOpex = {
        labels: dataChartValue.tahun,
        datasets: [
            {
                label: `Capex`,
                data: dataChartValue.capex,
                backgroundColor: 'rgb(255, 99, 132)',
            },
            {
                label: `Opex`,
                data: dataChartValue.opex,
                backgroundColor: 'rgb(54, 162, 235)',
            },
        ],
    };

    const customStylesModal = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: '0',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '700px',
            height: '75%'
        },
    };

    return capexOpex.loading ? <Spinner /> : capexOpex.error === "Unauthenticated." ? (
        window.location.href = "/") : (
        <div className="card">
            <h4 className="card-title text-bold" style={{ margin: 10, fontWeight: 'bold' }}>CAPEX OPEX {nama}</h4>
            {
                role && role.role_id !== 3 &&
                <div className="toolbar" style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', alignContent: 'flex-end', justifyContent: 'flex-end' }}>
                    <Button
                        id="basic-button"
                        aria-controls="basic-menu"
                        className="btn btn-sm"
                        size="large"
                        aria-haspopup="true"
                        style={{ backgroundColor: 'rgba(0, 50, 122, 1)', color: '#fff', marginRight: '10px' }}
                        onClick={setUpModalAdd}>
                        <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;Tambah
                    </Button>
                </div>
            }
            <div className="card-content">
                <div className="material-datatables">
                    <DataTable
                        columns={role.role_id !== 3 ? columns : columnsGuest}
                        data={row}
                        progressPending={loading}
                        progressComponent={<Loader type="Watch" color="#00BFFF" height={100} width={100} />}
                        responsive={true}
                        customStyles={customStyles}
                        pagination />
                    <Bar data={dataChartCapexOpex} />
                </div>
            </div>
            <ReactModal
                ariaHideApp={false}
                isOpen={showModalAdd}
                style={customStylesModal}
            >
                <div style={{ float: 'right' }}>
                    <Button id="basic-button"
                        aria-controls="basic-menu"
                        color="error"
                        onClick={setUpModalAdd}
                        size="small">
                        <span className="material-icons">
                            close
                        </span>
                    </Button>
                </div>
                <Modal.Title>Tambah Data Capex Opex Baru</Modal.Title>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitUploadCapexOpex)}
                >
                    <h5 className="text-center">Import Data Capex Opex {nama}</h5>
                    <input
                        placeholder="Nama Perusahaan"
                        type="file"
                        onChangeCapture={onUpload}
                        name="nama_file"
                    />
                    <Link to="/download/capexopex.xlsx" target="_blank" style={{ float: 'right' }}>Download Template Upload Capex Opex</Link><br />
                    {
                        capexOpexMessage && capexOpexMessage.error && <span className="text-danger">{capexOpexMessage.error.nama_file}</span>
                    }
                    <Button type="submit" variant="contained" color="primary">Upload</Button>
                    <br />
                    {
                        capexOpexMessage && capexOpexMessage.error && <span className="text-danger">{JSON.stringify(capexOpexMessage.error.message)}</span>
                    }
                </form><hr />
                <form className="form" onSubmit={handleSubmit(onSubmit)} >
                    <input
                        type="hidden"
                        defaultValue={id}
                        {...register("dataumum_id")} />
                    <input
                        type="hidden"
                        defaultValue={`${nama}`}
                        {...register("company_name")} />
                    {capexOpexMessage && capexOpexMessage.error && <span className="text-danger">{capexOpexMessage.error.company_name}</span>}
                    <input
                        placeholder="Capex"
                        type="text"
                        className="form-control mb-2"
                        {...register("capex")} />
                    {capexOpexMessage && capexOpexMessage.error && <span className="text-danger">{capexOpexMessage.error.capex}</span>}
                    <input
                        placeholder="Opex"
                        type="text"
                        className="form-control mb-2"
                        {...register("opex")} />
                    {capexOpexMessage && capexOpexMessage.error && <span className="text-danger">{capexOpexMessage.error.opex}</span>}
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="Tahun"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={tahun}
                                    placeholder="Tahun.."
                                    onChange={(e) => setInputTahun(e.value)}
                                />
                            )}
                        />
                        {capexOpexMessage && capexOpexMessage.error && <span className="text-danger">{capexOpexMessage.error.tahun}</span>}
                    </Styles.InputForm>
                    <Button variant="contained" color="error" onClick={setUpModalAdd} style={{ marginRight: 5 }}>Close</Button>
                    <Button type="submit" variant="contained" color="primary">Save</Button>
                </form>
            </ReactModal>
        </div>
    )
}

export default CapexOpex

const Tahun = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= 1945; i--) {
        tahun.push(i);
    }
    return tahun;
}