import React from 'react';
import DataTable from 'react-data-table-component';
import Loader from 'react-loader-spinner';
import { Modal } from 'react-bootstrap';
import ReactModal from 'react-modal';
import { useForm, Controller } from 'react-hook-form';
import Select from "react-select";
import swal from '@sweetalert/with-react';
import Button from '@mui/material/Button';

import { Spinner, Styles, ActionButton } from '../../components';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

const NodeB3G = ({
    nodeb3g,
    nodeb3gCrud,
    getnodeb3gPivot,
    nodeb3gMessage,
    addnodeb3g,
    getnodeb3gByCompany,
    provinsi,
    getProv,
    role,
    uploadNodeb3g,
    deleteNodeb3g
}) => {

    const { id, nama } = useParams();
    // const history = useHistory();
    const { register, handleSubmit, control } = useForm();
    const [rows, setRows] = React.useState([]);
    const [nodeb3gState, setNodeb3gState] = React.useState([]);
    const [tahunValue, setTahunValue] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [tahun, setTahun] = React.useState([]);
    const [inputTahun, setInputTahun] = React.useState('');
    const [prov, setProv] = React.useState([]);
    const [valueProvinsi, setValueProvinsi] = React.useState('');
    const [showModalAddNodeb3g, setShowModalAddNodeb3g] = React.useState(false);
    const [toCrudNodeb3g, setToCrudNodeb3g] = React.useState(true);
    const [tahunFrom, setTahunFrom] = React.useState([]);
    const [tahunTo, setTahunTo] = React.useState([]);

    const setUpModalAddNodeb3g = () => setShowModalAddNodeb3g(!showModalAddNodeb3g);
    const toCrud = () => setToCrudNodeb3g(!toCrudNodeb3g);
    const [fileUpload, setFileUpload] = React.useState(null);

    const subHeaderComponentMemo = React.useMemo(() => {
        const onfilter = (data) => {
            console.log(data);
            setLoading(true);
            getnodeb3gPivot(id, data.from, data.to)
        }
        return (<form onSubmit={handleSubmit(onfilter)} className="form-inline">
            <div className="input-group">
                <span className="input-group-addon text-light">
                    <label>Filter dari tahun:</label>
                </span>
                <select
                    className="form-control"
                    {...register('from')}
                >
                    {
                        tahunFrom.map((t, index) => (
                            <option value={t} key={index}>{t}</option>
                        ))
                    }
                </select>
            </div>
            <div className="input-group">
                <span className="input-group-addon text-light">
                    <label>sampai tahun:</label>
                </span>
                <select
                    className="form-control"
                    {...register('to')}
                >
                    {
                        tahunTo.map((t, index) => (
                            <option value={t} key={index}>{t}</option>
                        ))
                    }
                </select>
            </div>
            {/* <Button
                id="basic-button"
                aria-controls="basic-menu"
                className="btn btn-sm"
                size="large"
                style={{ backgroundColor: 'rgba(0, 50, 122, 1)', color: '#fff', marginRight: '10px' }}
            >
                <i className="fa fa-plus" aria-hidden="true"></i> &nbsp; Tambah
            </Button> */}
            <button type="submit" className="btn btn-sm btn-primary" style={{ marginLeft: 15 }}>Submit</button>
        </form>)
    }, [getnodeb3gPivot, handleSubmit, id, register, tahunFrom, tahunTo]);

    // const [valuePermitType, setValuePermiType] = React.useState('')
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            provinsi && provinsi.provinsi && setProv(provinsi.provinsi.map(p => ({ value: p.id, label: p.nama_provinsi })));
            nodeb3gCrud && nodeb3gCrud.nodeb3gCrud && setNodeb3gState(nodeb3gCrud.nodeb3gCrud)
            nodeb3g.loading && nodeb3g.nodeb3g && setRows(nodeb3g.nodeb3g);
            nodeb3g.loading && nodeb3g.nodeb3g && nodeb3g.nodeb3g.length > 0 && setTahunValue(Object.keys(nodeb3g.nodeb3g[0]));
            setLoading(false);
        }, 4000);
        return () => clearTimeout(timeout);
    }, [nodeb3g, nodeb3gCrud, provinsi]);

    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none'
            },
        },
    };
    const columns = [
        {
            name: `Provinsi`,
            selector: row => row.provinsi,
        },
        {
            name: `${tahunValue.length > 0 ? tahunValue[1].slice(6) : tahunValue}`,
            selector: `${tahunValue.length > 0 ? tahunValue[1] : tahunValue}`
        },
        {
            name: `${tahunValue.length > 0 ? tahunValue[2].slice(6) : tahunValue}`,
            selector: `${tahunValue.length > 0 ? tahunValue[2] : tahunValue}`
        },
        {
            name: `${tahunValue.length > 0 ? tahunValue[3].slice(6) : tahunValue}`,
            selector: `${tahunValue.length > 0 ? tahunValue[3] : tahunValue}`
        },
        {
            name: `${tahunValue.length > 0 ? tahunValue[4].slice(6) : tahunValue}`,
            selector: `${tahunValue.length > 0 ? tahunValue[4] : tahunValue}`
        },
        {
            name: `${tahunValue.length > 0 ? tahunValue[5].slice(6) : tahunValue}`,
            selector: `${tahunValue.length > 0 ? tahunValue[5] : tahunValue}`
        },
    ];

    const columnsCrud = [
        {
            name: `Provinsi`,
            selector: row => row.provinsi,
        },
        {
            name: `Jumlah`,
            selector: row => row.jumlah,
        },
        {
            name: `Tahun`,
            selector: row => row.tahun,
        },
        {
            name: 'Action',
            selector: row => <>
                <ActionButton
                    title="fa fa-pencil"
                    css=" btn-warning"
                    fun={() => window.location.href = `/edit-node-b-3-g/${row.id}/${row.company_name}`}
                />
                &nbsp;
                <ActionButton title="fa fa-trash" css=" btn-danger" fun={() => swal(`Apakah Yakin Data nodeb3g ${row.provinsi} ini akan dihapus?`, {
                    buttons: ["Batal", true],
                })
                    .then((value) => {
                        if (value) {
                            deleteNodeb3g(row.id);
                        }
                    })
                } />
            </>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    React.useEffect(() => {
        setTahun(Tahun().map(t => ({ value: t, label: t })));
        setTahunTo(TahunTo().map(t => t));
        setTahunFrom(TahunFrom().map(t => t));
    }, []);
    React.useEffect(() => {
        getnodeb3gPivot(id);
        getnodeb3gByCompany(id)
        getProv()
    }, [getProv, getnodeb3gByCompany, getnodeb3gPivot, id]);
    React.useEffect(() => {
        nodeb3gMessage.loading && nodeb3gMessage.nodeb3gMessage && swal(`${nodeb3gMessage.nodeb3gMessage.message}`, {
            buttons: ["cancel", true]
        }).then((value) => {
            if (value) {
                getnodeb3gByCompany(id);
                getnodeb3gPivot(id);
            }
        });
    }, [getnodeb3gByCompany, getnodeb3gPivot, id, nodeb3gMessage]);

    const customStylesModal = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: '0',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '700px',
            height: '80%'
        },
    };

    const tambahNodeb3g = async (data) => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            tahun: inputTahun,
            provinsi: valueProvinsi,
            jumlah: data.jumlah
        }
        addnodeb3g(input);
    }

    const onUpload = (e) => setFileUpload(e.target.files[0]);

    const submitUploadNodeB3g = (data) => {
        const formData = new FormData();
        formData.append("nama_file", fileUpload);
        uploadNodeb3g(formData)
    };


    return loading ? <Spinner /> : nodeb3g.error === "Unauthenticated." ? (
        window.location.href = "/") : (
        <>
            {
                role.role_id !== 3 &&
                <div className="toolbar" style={{ display: 'flex', flexDirection: 'row-reverse', }}>
                    <Button
                        id="basic-button"
                        aria-controls="basic-menu"
                        size="large"
                        aria-haspopup="true"
                        className={toCrudNodeb3g ? "btn btn-success" : "btn btn-danger"}
                        onClick={toCrud}
                    >
                        <i className={toCrudNodeb3g ? "fa fa-edit" : "fa fa-eye"} aria-hidden="true"></i> {toCrudNodeb3g ? "Edit" : "View"}
                    </Button>
                    <Button
                        id="basic-button"
                        aria-controls="basic-menu"
                        className="btn btn-sm"
                        size="large"
                        aria-haspopup="true"
                        onClick={setUpModalAddNodeb3g}
                        style={{ backgroundColor: 'rgba(0, 50, 122, 1)', color: '#fff', marginRight: '10px' }}

                    >
                        <i className="fa fa-plus" aria-hidden="true"></i> &nbsp; Tambah
                    </Button>
                </div>
            }
            <div className="card-content">
                <div className="material-datatables">
                    {
                        toCrudNodeb3g ?
                            <DataTable
                                columns={columns}
                                data={rows}
                                progressPending={loading}
                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100}
                                    width={100} />}
                                responsive={true}
                                customStyles={customStyles}
                                pagination
                                subHeaderComponent={subHeaderComponentMemo}
                                subHeader

                            /> :
                            <DataTable
                                columns={columnsCrud}
                                data={nodeb3gState}
                                progressPending={loading}
                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100}
                                    width={100} />}
                                responsive={true}
                                customStyles={customStyles}
                                pagination
                            // subHeaderComponent={subHeaderComponentMemo} 
                            />
                    }
                </div>
            </div>
            <ReactModal
                ariaHideApp={false}
                isOpen={showModalAddNodeb3g}
                preventScroll={true}
                scrollable={true}
                style={customStylesModal}
            >
                <div style={{ float: 'right' }}>
                    <Button id="basic-button"
                        aria-controls="basic-menu"
                        color="error"
                        onClick={setUpModalAddNodeb3g}
                        size="small">
                        <span className="material-icons">
                            close
                        </span>
                    </Button>
                </div>
                <Modal.Title style={{ fontWeight: 'bold' }}>Tambah Data E Node B 3G Baru</Modal.Title>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitUploadNodeB3g)}
                >
                    <h5 className="text-center">Import Data E Node B 3G {nama}</h5>
                    <input
                        placeholder="Nama Perusahaan"
                        type="file"
                        name="nama_file"
                        onChangeCapture={onUpload}
                    />
                    <Link to="/download/node b 3g.xlsx" target="_blank" style={{ float: 'right' }}>Download Template Upload E Node B 3G</Link>
                    <br />
                    {
                        nodeb3gMessage && nodeb3gMessage.error && <span className="text-danger">{nodeb3gMessage.error.nama_file}</span>
                    }
                    <br />
                    <Button type="submit" variant="contained" color="primary">Upload</Button>
                    {
                        nodeb3gMessage && nodeb3gMessage.error && nodeb3gMessage.error.message && nodeb3gMessage.error.message.map((w, index) => (
                            <ol key={index}>
                                <span className="text-danger">{index + 1}.{w}</span>
                            </ol>
                        ))
                    }
                </form>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(tambahNodeb3g)}
                >
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="tahun"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={tahun}
                                    placeholder="Tahun.."
                                    onChange={(e) => setInputTahun(e.value)}
                                />
                            )}
                        />
                        {nodeb3gMessage && nodeb3gMessage.error && <span className="text-danger">{nodeb3gMessage.error.tahun}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="provinsi"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={prov}
                                    placeholder="Provinsi.."
                                    onChange={(e) => setValueProvinsi(e.value)}
                                />
                            )}
                        />
                        {nodeb3gMessage && nodeb3gMessage.error && <span className="text-danger">{nodeb3gMessage.error.provinsi}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="Jumlah..."
                            type="text"
                            className="form-control"
                            {...register("jumlah")}
                        />
                        {nodeb3gMessage && nodeb3gMessage.error && <span className="text-danger">{nodeb3gMessage.error.jumlah}</span>}
                    </Styles.InputForm>
                    <Button color="error" variant="contained" onClick={setUpModalAddNodeb3g} style={{ marginRight: 5 }}>Close</Button>
                    <Button color="primary" variant="contained" type="submit">Save changes</Button>
                </form>
            </ReactModal>
        </>
    )
}

export default NodeB3G

const Tahun = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= 1945; i--) {
        tahun.push(i);
    }
    return tahun;
}

const TahunFrom = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= (year - 10); i--) {
        tahun.push(i);
    }
    return tahun;
}
const TahunTo = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= (year - 10); i--) {
        tahun.push(i);
    }
    return tahun;
}