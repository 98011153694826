import React from 'react'

const ActionButton = ({ css, title, fun, id, target }) => {
    return (
        <button
            className={css}
            onClick={fun}
            id={id}
            type="button"
            rel="tooltip"
            data-toggle="modal"
            data-target={target}>
            <i className={title} ></i>
        </button>
    )
}

export default ActionButton
