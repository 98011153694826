import {
    PROFIL_UMUM_REQ,
    PROFIL_UMUM_SUCCESS,
    PROFIL_UMUM_FAIL,
    PROFIL_UMUM_MESSAGE_REQ,
    PROFIL_UMUM_MESSAGE_SUCCESS,
    PROFIL_UMUM_MESSAGE_FAIL,
    PROFIL_UMUM_DETAIL_REQ,
    PROFIL_UMUM_DETAIL_SUCCESS,
    PROFIL_UMUM_DETAIL_FAIL,
    PROFIL_UMUM_UPLOAD_REQ,
    PROFIL_UMUM_UPLOAD_SUCCESS,
    PROFIL_UMUM_UPLOAD_FAIL,
} from './profilUmumTypes'

import api from '../services';
import { config, configUpload } from '../config';

export const profilUmumUploadReq = () => {
    return { type: PROFIL_UMUM_UPLOAD_REQ }
}
export const profilUmumUploadSuccess = (data) => {
    return { type: PROFIL_UMUM_UPLOAD_SUCCESS, payload: data }
}
export const profilUmumUploadFail = (err) => {
    return { type: PROFIL_UMUM_UPLOAD_FAIL, payload: err }
}

export const profilUmumDetailReq = () => {
    return { type: PROFIL_UMUM_DETAIL_REQ }
}

export const profilUmumDetailSuccess = (data) => {
    return { type: PROFIL_UMUM_DETAIL_SUCCESS, payload: data }
}

export const profilUmumDetailFail = (err) => {
    return { type: PROFIL_UMUM_DETAIL_FAIL, payload: err }
}

export const profilUmumReq = () => {
    return { type: PROFIL_UMUM_REQ }
}

export const profilUmumSuccess = (data) => {
    return { type: PROFIL_UMUM_SUCCESS, payload: data }
}

export const profilUmumFail = (err) => {
    return { type: PROFIL_UMUM_FAIL, payload: err }
}

export const profilUmumMessageReq = () => {
    return { type: PROFIL_UMUM_MESSAGE_REQ }
}

export const profilUmumMessageSuccess = (data) => {
    return { type: PROFIL_UMUM_MESSAGE_SUCCESS, payload: data }
}

export const profilUmumMessageFail = (err) => {
    return { type: PROFIL_UMUM_MESSAGE_FAIL, payload: err }
}

export const uploadProfilUmum = (data) => {
    return async (dispatch) => {
        dispatch(profilUmumMessageReq);
        await api.post(`/data-umum-create-import`, data, configUpload)
            .then(res => dispatch(profilUmumMessageSuccess(res.data)))
            .catch(err => {
                dispatch(profilUmumMessageFail(err.response.data));
            });
    }
}

export const profilUmumUpdate = (id, data) => {
    return async (dispatch) => {
        dispatch(profilUmumMessageReq);
        await api.put(`/data-umum-update/${id}`, data, config)
            .then(res => {
                dispatch(profilUmumMessageSuccess(res.data));
                getProfilUmum();
            })
            .catch(err => {
                dispatch(profilUmumMessageFail(err.response.data));
            })
    }
}

export const getProfilUmumDetail = (id) => {
    return async (dispatch) => {
        dispatch(profilUmumDetailReq);
        await api.get(`/data-umum/${id}`, config)
            .then(res => {
                dispatch(profilUmumDetailSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(profilUmumDetailFail(err.response.data));
            })
    }
}

export const getProfilUmum = () => {
    return async (dispatch) => {
        dispatch(profilUmumReq);
        await api.get('/data-umum', config)
            .then(res => {
                dispatch(profilUmumSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(profilUmumFail(err.response.data));
            })
    }
}

export const addProfilUmum = (data) => {
    return async (dispatch) => {
        dispatch(profilUmumMessageReq);
        await api.post('/data-umum-create', data, config)
            .then(res => {
                dispatch(profilUmumMessageSuccess(res.data));
                getProfilUmum();
            })
            .catch(err => {
                dispatch(profilUmumMessageFail(err.response.data));
            })
    }
}

export const deleteProfilUmum = (data) => {
    return async (dispatch) => {
        dispatch(profilUmumMessageReq);
        await api.delete(`/data-umum-delete/${data}`, config)
            .then(res => {
                dispatch(profilUmumMessageSuccess(res.data));
                dispatch(getProfilUmum());
            })
            .catch(err => {
                dispatch(profilUmumMessageFail(err.response.data));
            })
    }
}