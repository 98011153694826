import React from 'react';
import { Spinner, Styles } from '../../components';
import {
    Button
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import swal from 'sweetalert';

const SectionProfil = ({
    user,
    getUser,
    updateUser,
    userMessage,
}) => {
    const [loading, setLoading] = React.useState(true);
    const [dataUser, setDataUser] = React.useState({});
    const [disabledField, setDisabledField] = React.useState(false);
    const setupDisabledField = () => setDisabledField(!disabledField);
    const { register, handleSubmit } = useForm();
    React.useEffect(() => {
        userMessage.loading && userMessage.userMessage && swal(`${userMessage.userMessage.message}`, {
            buttons: true
        }).then(value => {
            if (value) {
                getUser();
            }
        })
    }, [getUser, userMessage])
    React.useEffect(() => {
        if (user.loading) {
            user.user.map(u => setDataUser(u));
            setLoading(false);
        }
    }, [user]);
    React.useEffect(() => {
        document.title = 'Profil | PPI'
    }, []);
    React.useEffect(() => {
        getUser()
    }, [getUser]);

    const submitUserEdit = (data) => {
        updateUser(data);
    }

    return loading ? <Spinner /> : (
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header card-header-icon" data-background-color="blue">
                        <Styles.ButtonModal
                            type="button"
                            onClick={() => window.history.back()}>
                            <i className="fa fa-chevron-circle-left fa-2x" aria-hidden="true"></i>
                        </Styles.ButtonModal>
                    </div>
                    <div className="card-content">
                        <h3 className="title" style={{ fontWeight: 'bold', marginTop: '-5px' }}>User Profile</h3>
                        <div className="row">
                            <div className="col-md-12">
                                <Button variant={disabledField ? "info" : "primary"} onClick={setupDisabledField}>{disabledField ? "Back to Profile" : "Profile Update"}</Button>
                                {
                                    disabledField ? <form className="form"
                                        onSubmit={handleSubmit(submitUserEdit)}
                                    >
                                        <Styles.InputForm>
                                            <input
                                                placeholder="Nama Depan..."
                                                type="text"
                                                className="form-control"
                                                {...register("fname")}
                                            />
                                            {userMessage && userMessage.error && <span className="text-danger">{userMessage.error.fname}</span>}
                                        </Styles.InputForm>
                                        <Styles.InputForm>
                                            <input
                                                placeholder="Nama Belakang..."
                                                type="text"
                                                className="form-control"
                                                {...register("lname")}
                                            />
                                            {userMessage && userMessage.error && <span className="text-danger">{userMessage.error.lname}</span>}
                                        </Styles.InputForm>
                                        <Styles.InputForm>
                                            <input
                                                placeholder="Jabatan..."
                                                type="text"
                                                className="form-control"
                                                {...register("jabatan")}
                                            />
                                            {userMessage && userMessage.error && <span className="text-danger">{userMessage.error.jabatan}</span>}
                                        </Styles.InputForm>
                                        <Styles.InputForm>
                                            <input
                                                placeholder="No Telepon..."
                                                type="text"
                                                className="form-control"
                                                {...register("phone")}
                                            />
                                            {userMessage && userMessage.error && <span className="text-danger">{userMessage.error.phone}</span>}
                                        </Styles.InputForm>
                                        <Styles.InputForm>
                                            <textarea
                                                placeholder="Alamat..."
                                                type="text"
                                                className="form-control"
                                                {...register("address")}
                                            />
                                        </Styles.InputForm>
                                        <Button variant="primary" type="submit">Save changes</Button>
                                    </form> : <table className="table">
                                        <thead>
                                            <tr>
                                                <td>Username</td>
                                                <td>: {dataUser.username}</td>
                                            </tr>
                                            <tr>
                                                <td>Email</td>
                                                <td>: {dataUser.email}</td>
                                            </tr>
                                            <tr>
                                                <td>Nama Depan</td>
                                                <td>: {dataUser.details.fname}</td>
                                            </tr>
                                            <tr>
                                                <td>Nama Belakang</td>
                                                <td>: {dataUser.details.lname}</td>
                                            </tr>
                                            <tr>
                                                <td>Jabatan</td>
                                                <td>: {dataUser.details.jabatan}</td>
                                            </tr>
                                            <tr>
                                                <td>Alamat</td>
                                                <td>: {dataUser.details.address}</td>
                                            </tr>
                                            <tr>
                                                <td>No Telepon</td>
                                                <td>: {dataUser.details.phone}</td>
                                            </tr>
                                            <tr>
                                                <td>Role User</td>
                                                <td>: {dataUser.role.role_name}</td>
                                            </tr>
                                        </thead>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionProfil
