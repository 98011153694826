import {
    LOGIN_REQ,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
} from './loginTypes';

const loginState = {
    loading: false,
    login: null,
    error: ''
}

export const loginReducer = (state = loginState, action) => {
    switch (action.type) {
        case LOGIN_REQ:
            return {
                ...state,
                loading: false,
            }
        case LOGIN_SUCCESS:
            return {
                loading: true,
                login: action.payload,
                error: ''
            }
        case LOGIN_FAIL:
            return {
                loading: false,
                login: null,
                error: action.payload
            }
        default:
            return state
    }
}

export default loginReducer