import {
    PEGAWAI_REQ,
    PEGAWAI_SUCCESS,
    PEGAWAI_FAIL,
    PEGAWAI_MESSAGE_REQ,
    PEGAWAI_MESSAGE_SUCCESS,
    PEGAWAI_MESSAGE_FAIL,
    PEGAWAI_DETAIL_REQ,
    PEGAWAI_DETAIL_SUCCESS,
    PEGAWAI_DETAIL_FAIL,
} from './pegawaiTypes';

const pegawaiMessageState = {
    loading: false,
    pegawaiMessage: '',
    error: ''
}

const pegawaiDetailState = {
    loading: false,
    pegawaiDetail: [],
    error: ''
}

const pegawaiState = {
    loading: false,
    pegawai: '',
    error: ''
}

export const pegawaiDetailReducer = (state = pegawaiDetailState, action) => {
    switch (action.type) {
        case PEGAWAI_DETAIL_REQ:
            return {
                ...state,
                loading: false,
            }
        case PEGAWAI_DETAIL_SUCCESS:
            return {
                loading: true,
                pegawaiDetail: action.payload,
                error: ''
            }
        case PEGAWAI_DETAIL_FAIL:
            return {
                loading: true,
                pegawaiDetail: [],
                error: action.payload
            }
        default:
            return state
    }
}

export const pegawaiMessageReducer = (state = pegawaiMessageState, action) => {
    switch (action.type) {
        case PEGAWAI_MESSAGE_REQ:
            return {
                ...state,
                loading: true,
            }
        case PEGAWAI_MESSAGE_SUCCESS:
            return {
                loading: true,
                pegawaiMessage: action.payload,
                error: ''
            }
        case PEGAWAI_MESSAGE_FAIL:
            return {
                loading: false,
                pegawaiMessage: '',
                error: action.payload
            }
        default:
            return state
    }
}

const pegawaiReducer = (state = pegawaiState, action) => {
    switch (action.type) {
        case PEGAWAI_REQ:
            return {
                ...state,
                loading: true,
            }
        case PEGAWAI_SUCCESS:
            return {
                loading: false,
                pegawai: action.payload,
                error: ''
            }
        case PEGAWAI_FAIL:
            return {
                loading: false,
                pegawai: [],
                error: action.payload
            }
        default:
            return state
    }
}

export default pegawaiReducer