// 
export const RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_MESSAGE_REQ = 'RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_MESSAGE_REQ';
export const RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_MESSAGE_SUCCESS = 'RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_MESSAGE_SUCCESS';
export const RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_MESSAGE_FAIL = 'RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_MESSAGE_FAIL';

export const RASIO_KONVERSI_KESEHATAN_KEUANGAN_REQ = 'RASIO_KONVERSI_KESEHATAN_KEUANGAN_REQ';
export const RASIO_KONVERSI_KESEHATAN_KEUANGAN_SUCCESS = 'RASIO_KONVERSI_KESEHATAN_KEUANGAN_SUCCESS';
export const RASIO_KONVERSI_KESEHATAN_KEUANGAN_FAIL = 'RASIO_KONVERSI_KESEHATAN_KEUANGAN_FAIL';

export const RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_REQ = 'RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_REQ';
export const RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_SUCCESS = 'RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_SUCCESS';
export const RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_FAIL = 'RASIO_KONVERSI_KESEHATAN_KEUANGAN_CRUD_FAIL';