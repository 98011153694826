import {
    LOGIN_REQ,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
} from './loginTypes'

import api from '../services';

export const loginReq = () => {
    return { type: LOGIN_REQ }
}

export const loginSuccess = (data) => {
    return { type: LOGIN_SUCCESS, payload: data }
}

export const loginFail = (err) => {
    return { type: LOGIN_FAIL, payload: err }
}

export const login = (data) => {
    const config = {
        header: {
            Accept: 'application/json',
        }
    }
    return async (dispatch) => {
        dispatch(loginReq);
        await api.post('/login', data, config)
            .then(res => {
                dispatch(loginSuccess(res.data));
                localStorage.setItem('token', res.data.token);
                window.location.href = "/dashboard";
            })
            .catch(err => {
                dispatch(loginFail(err.response.data));
            })
    }
}