import {
    DASHBOARD_IZIN_REQ,
    DASHBOARD_IZIN_SUCCESS,
    DASHBOARD_IZIN_FAIL,
    DASHBOARD_TEKNOLOGI_REQ,
    DASHBOARD_TEKNOLOGI_SUCCESS,
    DASHBOARD_TEKNOLOGI_FAIL,
} from './dashboardType';

import api from '../services';
import { config } from '../config';

export const dashboarTeknologiReq = () => {
    return { type: DASHBOARD_TEKNOLOGI_REQ }
}

export const dashboarTeknologiSuccess = (data) => {
    return { type: DASHBOARD_TEKNOLOGI_SUCCESS, payload: data }
}

export const dashboarTeknologinFail = (err) => {
    return { type: DASHBOARD_TEKNOLOGI_FAIL, payload: err }
}

export const dashboarIzinReq = () => {
    return { type: DASHBOARD_IZIN_REQ }
}

export const dashboarIzinSuccess = (data) => {
    return { type: DASHBOARD_IZIN_SUCCESS, payload: data }
}

export const dashboardIzinFail = (err) => {
    return { type: DASHBOARD_IZIN_FAIL, payload: err }
}

export const getDashboardTeknologi = () => {
    return async (dispatch) => {
        dispatch(dashboarTeknologiReq);
        await api.get(`/data-teknologi-summary`, config)
            .then(res => {
                dispatch(dashboarTeknologiSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(dashboarTeknologinFail(err.response.data));
            })
    }
}

export const getDashboardIzin = () => {
    return async (dispatch) => {
        dispatch(dashboarIzinReq);
        await api.get(`/data-izin-summary`, config)
            .then(res => {
                dispatch(dashboarIzinSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(dashboardIzinFail(err.response.data));
            })
    }
}