import React from 'react';
import DataTable from 'react-data-table-component';
import { /*useHistory,*/ useParams } from 'react-router';
import Loader from 'react-loader-spinner';
import ReactModal from 'react-modal';
import { ListGroup, Modal, TabContent } from 'react-bootstrap';
import Select from "react-select";
import swal from 'sweetalert';
import { useForm, Controller } from 'react-hook-form';
import {
    Bts,
    NodeB3G,
    JaberselPelanggan,
    EnodeB4G,
    SellularAllTech,
    MarketShare
} from '..';
import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

import { Styles, ActionButton, Spinner } from '../../components';

const JaberselPenyelengara = ({
    jaberselPenyelenggara,
    getJaberselPenyelenggaraByCompany,
    jaberselPenyelenggaraMessage,
    addJaberselPenyelenggara,
    deleteJaberselPenyelenggara,
    role,
    uploadJaberselPenyelenggara
}) => {
    const { id, nama } = useParams();
    const { register, handleSubmit, control } = useForm();
    // const history = useHistory();
    const [jaberselPenyelenggaraData, setJaberselPenyelenggaraData] = React.useState([]);
    const [tahun, setTahun] = React.useState([]);
    const [inputTahun, setInputTahun] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [dataChartValue, setDataChartValue] = React.useState([]);
    const [fileUpload, setFileUpload] = React.useState(null);

    const [showModalAddJaberselPenyelenggara, setShowModalAddJaberselPenyelenggara] = React.useState(false);
    const setupModalAddJaberselPenyelenggara = () => setShowModalAddJaberselPenyelenggara(!showModalAddJaberselPenyelenggara);

    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { minimumFractionDigits: 0 }
        ).format(money);
    }
    const customStyles = {
        headCells: {
            style: {
                border: '3px rgba(0, 50, 122, 1) solid',
                textAlign: 'center',
                borderRightStyle: 'none',
                borderLeftStyle: 'none'
            },
        },
    };
    const columns = [
        {
            name: 'No',
            selector: row => row.number,
            minWidth: '10px'
        },
        {
            name: 'Kapasitas Terpasang',
            selector: row => formatRupiah(row.kapasitas_terpasang),
            minWidth: '150px'
        },
        {
            name: 'Voice Outgoing',
            selector: row => formatRupiah(row.vtv_out),
            minWidth: '130px'
        },
        {
            name: 'Voice Incoming',
            selector: row => formatRupiah(row.vtv_in),
            minWidth: '130px'
        },
        {
            name: 'Sms Outgoing',
            selector: row => formatRupiah(row.vtv_out),
            minWidth: '130px'
        },
        {
            name: 'Sms Incoming',
            selector: row => formatRupiah(row.vts_in),
            minWidth: '130px'
        },
        {
            name: 'Traffic Data',
            selector: row => formatRupiah(row.vtd),
        },
        {
            name: 'ARPU PRABAYAR',
            selector: row => formatRupiah(row.arpu_prabayar)
        },
        {
            name: 'ARPU PASCABAYAR',
            selector: row => formatRupiah(row.arpu_pascabayar)
        },
        {
            name: 'ARPU BLENDED',
            selector: row => formatRupiah(row.arpu_blended)
        },
        {
            name: 'Action',
            selector: 'action'
        }
    ];
    const columnsGuest = [
        {
            name: 'No',
            selector: row => row.number,
            minWidth: '10px'
        },
        {
            name: 'Kapasitas Terpasang',
            selector: row => formatRupiah(row.kapasitas_terpasang),
            minWidth: '150px'
        },
        {
            name: 'Voice Outgoing',
            selector: row => formatRupiah(row.vtv_out),
            minWidth: '130px'
        },
        {
            name: 'Voice Incoming',
            selector: row => formatRupiah(row.vtv_in),
            minWidth: '130px'
        },
        {
            name: 'Sms Outgoing',
            selector: row => formatRupiah(row.vtv_out),
            minWidth: '130px'
        },
        {
            name: 'Sms Incoming',
            selector: row => formatRupiah(row.vts_in),
            minWidth: '130px'
        },
        {
            name: 'Traffic Data',
            selector: row => formatRupiah(row.vtd),
        },
        {
            name: 'ARPU PRABAYAR',
            selector: row => formatRupiah(row.arpu_prabayar)
        },
        {
            name: 'ARPU PASCABAYAR',
            selector: row => formatRupiah(row.arpu_pascabayar)
        },
        {
            name: 'ARPU BLENDED',
            selector: row => formatRupiah(row.arpu_blended)
        },
    ];
    React.useEffect(() => {
        setTahun(Tahun().map(t => ({ value: t, label: t })));
    }, []);
    React.useState(() => {
        getJaberselPenyelenggaraByCompany(id);
    }, [getJaberselPenyelenggaraByCompany]);
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            jaberselPenyelenggara.loading && jaberselPenyelenggara.jaberselPenyelenggara &&
                setJaberselPenyelenggaraData(jaberselPenyelenggara.jaberselPenyelenggara.map((jabersel, index) => ({
                    number: index + 1,
                    kapasitas_terpasang: jabersel.kapasitas_terpasang,
                    vtv_out: jabersel.vtv_out,
                    vtv_in: jabersel.vtv_in,
                    vts_in: jabersel.vts_in,
                    vts_out: jabersel.vts_out,
                    vtd: jabersel.vtd,
                    arpu_prabayar: jabersel.arpu_prabayar,
                    arpu_pascabayar: jabersel.arpu_pascabayar,
                    arpu_blended: jabersel.arpu_blended,
                    action: <>
                        <ActionButton
                            title="fa fa-pencil"
                            css="btn-warning"
                            fun={() => window.location.href = `/penyelenggara-detail-pelanggan-kapasitas-edit/${id}/${nama}/edit/${jabersel.id}/jabersel penyelenggara`}
                        />
                        &nbsp;
                        <ActionButton title="fa fa-trash" css="btn-danger"
                            fun={
                                () => swal(`Apakah Yakin Data Jabersel ${jabersel.company_name} ini akan dihapus?`, {
                                    buttons: ["Batal", true],
                                }).then((value) => {
                                    if (value) {
                                        deleteJaberselPenyelenggara(jabersel.id);
                                    }
                                })
                            }
                        />
                    </>
                })));
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, [deleteJaberselPenyelenggara, getJaberselPenyelenggaraByCompany, id, jaberselPenyelenggara.jaberselPenyelenggara, jaberselPenyelenggara.loading, nama]);
    React.useEffect(() => {
        jaberselPenyelenggaraMessage.loading && jaberselPenyelenggaraMessage.jaberselPenyelenggaraMessage && swal(`${jaberselPenyelenggaraMessage.jaberselPenyelenggaraMessage.message}`, {
            buttons: ["cancel", true]
        }).then((value) => {
            if (value) {
                getJaberselPenyelenggaraByCompany(id);
            }
        });
    }, [getJaberselPenyelenggaraByCompany, id, jaberselPenyelenggaraMessage]);
    React.useEffect(() => {
        jaberselPenyelenggara && jaberselPenyelenggara.jaberselPenyelenggara && setDataChartValue({
            kapasitas_terpasang: jaberselPenyelenggara.jaberselPenyelenggara.map(jp => jp.kapasitas_terpasang),
            vtv_in: jaberselPenyelenggara.jaberselPenyelenggara.map(jp => jp.vtv_in),
            vtv_out: jaberselPenyelenggara.jaberselPenyelenggara.map(jp => jp.vtv_out),
            vts_in: jaberselPenyelenggara.jaberselPenyelenggara.map(jp => jp.vts_in),
            vts_out: jaberselPenyelenggara.jaberselPenyelenggara.map(jp => jp.vts_out),
            vtd: jaberselPenyelenggara.jaberselPenyelenggara.map(jp => jp.vtd),
            arpu_prabayar: jaberselPenyelenggara.jaberselPenyelenggara.map(jp => jp.arpu_prabayar),
            arpu_pascabayar: jaberselPenyelenggara.jaberselPenyelenggara.map(jp => jp.arpu_pascabayar),
            arpu_blended: jaberselPenyelenggara.jaberselPenyelenggara.map(jp => jp.arpu_blended),
            tahun: jaberselPenyelenggara.jaberselPenyelenggara.map((p) => p.tahun),
        });
    }, [jaberselPenyelenggara]);

    const dataChartJaberselPenyelenggara = {
        labels: dataChartValue.tahun,
        datasets: [
            {
                label: `Kapasitas Terpasang`,
                data: dataChartValue.kapasitas_terpasang,
                backgroundColor: 'red',
            },
            {
                label: `VOICE INCOMING`,
                data: dataChartValue.vtv_in,
                backgroundColor: 'yellow',
            },
            {
                label: `VOICE OUTGOING`,
                data: dataChartValue.vtv_out,
                backgroundColor: 'green',
            },
            {
                label: `SMS INCOMING`,
                data: dataChartValue.vts_in,
                backgroundColor: 'blue',
            },
            {
                label: `SMS OUTGOING`,
                data: dataChartValue.vts_out,
                backgroundColor: 'purple',
            },
            {
                label: `TRAFFIC DATA`,
                data: dataChartValue.vtd,
                backgroundColor: 'brown',
            },
            {
                label: `ARPU PRABAYAR`,
                data: dataChartValue.arpu_prabayar,
                backgroundColor: 'grey',
            },
            {
                label: `ARPU PASCA BAYAR`,
                data: dataChartValue.arpu_pascabayar,
                backgroundColor: 'rgb(255, 99, 132)',
            },
            {
                label: `Pelanggan Data`,
                data: dataChartValue.arpu_blended,
                backgroundColor: 'rgb(54, 162, 235)',
            },
        ],
        borderWidth: 1,
    };

    const submitJaberselPenyelenggara = (data) => {
        const input = {
            data_umum_id: id,
            company_name: nama,
            kapasitas_terpasang: data.kapasitas_terpasang,
            vtv_out: data.vtv_out,
            vtv_in: data.vtv_in,
            vts_in: data.vts_in,
            vts_out: data.vts_out,
            vtd: data.vtd,
            arpu_prabayar: data.arpu_prabayar,
            arpu_pascabayar: data.arpu_pascabayar,
            arpu_blended: data.arpu_blended,
            tahun: inputTahun
        }
        addJaberselPenyelenggara(input)
    }

    const customStylesModal = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: '0',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '700px',
            height: '90%'
        },
    };

    const onUpload = (e) => setFileUpload(e.target.files[0]);

    const submitUploadJaberselPenyelanggara = (data) => {
        const formData = new FormData();
        formData.append("nama_file", fileUpload);
        uploadJaberselPenyelenggara(formData)
    };

    return loading ? <Spinner /> : jaberselPenyelenggara.error === "Unauthenticated." ? (
        window.location.href = "/") : (
        <>
            <ListGroup as="ul" className="nav nav-tabs" id="myTab" role="tablist">
                <ListGroup as="li" className="nav-item active" role="presentation">
                    <a className="nav-link active" id="kapasitas-tab" data-toggle="tab" href="#kapasitas" role="tab" aria-controls="kapasitas" aria-selected="true">Kapasitas Pelanggan</a>
                </ListGroup>
                <ListGroup as="li" className="nav-item" role="market">
                    <a className="nav-link" id="market-tab" data-toggle="tab" href="#market" role="tab" aria-controls="kapasitas" aria-selected="true">Market Share</a>
                </ListGroup>
                <ListGroup as="li" className="nav-item" role="presentation">
                    <a className="nav-link" id="bts-tab" data-toggle="tab" href="#bts" role="tab" aria-controls="bts" aria-selected="false">BTS (2G)</a>
                </ListGroup>
                <ListGroup as="li" className="nav-item" role="presentation">
                    <a className="nav-link" id="nodeb-tab" data-toggle="tab" href="#nodeb" role="tab" aria-controls="nodeb" aria-selected="false">NODE B (3G)</a>
                </ListGroup>
                <ListGroup as="li" className="nav-item" role="presentation">
                    <a className="nav-link" id="nodebfourg-tab" data-toggle="tab" href="#nodebfourg" role="tab" aria-controls="bts" aria-selected="false">E NODE B (4G)</a>
                </ListGroup>
                <ListGroup as="li" className="nav-item" role="presentation">
                    <a className="nav-link" id="selular-tab" data-toggle="tab" href="#selular" role="tab" aria-controls="bts" aria-selected="false">Selular All Tech</a>
                </ListGroup>
            </ListGroup>
            <TabContent>
                <div className="tab-pane active" id="kapasitas" role="tabpanel" aria-labelledby="kapasitas-tab">
                    {
                        role.role_id !== 3 &&
                        <div className="toolbar" style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', alignContent: 'flex-end', justifyContent: 'flex-end' }}>
                            <Button
                                id="basic-button"
                                aria-controls="basic-menu"
                                className="btn btn-sm"
                                size="large"
                                aria-haspopup="true"
                                style={{ backgroundColor: 'rgba(0, 50, 122, 1)', color: '#fff', marginRight: '10px' }}
                                onClick={setupModalAddJaberselPenyelenggara}
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i> &nbsp; Tambah
                            </Button>
                        </div>
                    }
                    <div className="card-content" style={{ marginTop: 50 }}>
                        <h4 className="card-title text-center">Jaringan Bergerak Seluler Penyelenggara</h4>
                        <div className="material-datatables">
                            <DataTable
                                columns={role.role_id !== 3 ? columns : columnsGuest}
                                data={jaberselPenyelenggaraData}
                                progressPending={loading}
                                progressComponent={<Loader type="Watch" color="#00BFFF" height={100} width={100} />}
                                responsive={true}
                                customStyles={customStyles}
                                pagination />
                            <Bar data={dataChartJaberselPenyelenggara} />
                        </div>
                    </div>
                    <JaberselPelanggan role={role} />
                </div>
                <div className="tab-pane fade" id="market" role="tabpanel" aria-labelledby="market-tab">
                    <MarketShare />
                </div>
                <div className="tab-pane fade" id="bts" role="tabpanel" aria-labelledby="bts-tab">
                    <Bts role={role} />
                </div>
                <div className="tab-pane fade" id="nodeb" role="tabpanel" aria-labelledby="nodeb-tab">
                    <NodeB3G role={role} />
                </div>
                <div className="tab-pane fade" id="nodebfourg" role="tabpanel" aria-labelledby="nodebfourg-tab">
                    <EnodeB4G role={role} />
                </div>
                <div className="tab-pane fade" id="selular" role="tabpanel" aria-labelledby="selular-tab">
                    <SellularAllTech />
                </div>
            </TabContent>
            <ReactModal
                ariaHideApp={false}
                isOpen={showModalAddJaberselPenyelenggara}
                preventScroll={true}
                scrollable={true}
                onHide={setupModalAddJaberselPenyelenggara}
                style={customStylesModal}
            >
                <div style={{ float: 'right' }}>
                    <Button id="basic-button"
                        aria-controls="basic-menu"
                        color="error"
                        onClick={setupModalAddJaberselPenyelenggara}
                        size="small">
                        <span className="material-icons">
                            close
                        </span>
                    </Button>
                </div>
                <Modal.Title style={{ fontWeight: 'bold' }}>Tambah Jabersel Penyelenggara Baru</Modal.Title>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitUploadJaberselPenyelanggara)}
                >
                    <h5 className="text-center">Import Data Jabersel Penyelenggara {nama}</h5>
                    <input
                        placeholder="Nama Perusahaan"
                        type="file"
                        onChangeCapture={onUpload}
                        name="nama_file"
                    />
                    <Link to="/download/jabersel penyelenggara.xlsx" style={{ float: 'right' }} target="_blank">Download Template Upload Jabersel Penyelenggara</Link><br />
                    {
                        jaberselPenyelenggaraMessage && jaberselPenyelenggaraMessage.error && <span className="text-danger">{jaberselPenyelenggaraMessage.error.nama_file}</span>
                    }<br />
                    <Button type="submit" variant="contained" color="primary">Upload</Button>
                    {
                        jaberselPenyelenggaraMessage && jaberselPenyelenggaraMessage.error && jaberselPenyelenggaraMessage.error.message && jaberselPenyelenggaraMessage.error.message.map((w, index) => (
                            <ol key={index}>
                                <span className="text-danger">{index + 1}.{w}</span>
                            </ol>
                        ))
                    }
                </form>
                <hr />
                <form className="form"
                    onSubmit={handleSubmit(submitJaberselPenyelenggara)}
                >
                    <Styles.InputForm>
                        <input
                            placeholder="Kapasitas Terpasang..."
                            type="text"
                            className="form-control"
                            {...register("kapasitas_terpasang")}
                        />
                        {jaberselPenyelenggaraMessage && jaberselPenyelenggaraMessage.error && <span className="text-danger">{jaberselPenyelenggaraMessage.error.kapasitas_terpasang}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="VTV OUT..."
                            type="text"
                            className="form-control"
                            {...register("vtv_out")}
                        />
                        {jaberselPenyelenggaraMessage && jaberselPenyelenggaraMessage.error && <span className="text-danger">{jaberselPenyelenggaraMessage.error.vtv_out}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="VTS IN..."
                            type="text"
                            className="form-control"
                            {...register("vts_in")}
                        />
                        {jaberselPenyelenggaraMessage && jaberselPenyelenggaraMessage.error && <span className="text-danger">{jaberselPenyelenggaraMessage.error.vts_in}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="VTS OUT..."
                            type="text"
                            className="form-control"
                            {...register("vts_out")}
                        />
                        {jaberselPenyelenggaraMessage && jaberselPenyelenggaraMessage.error && <span className="text-danger">{jaberselPenyelenggaraMessage.error.vts_out}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="VTV IN..."
                            type="text"
                            className="form-control"
                            {...register("vtv_in")}
                        />
                        {jaberselPenyelenggaraMessage && jaberselPenyelenggaraMessage.error && <span className="text-danger">{jaberselPenyelenggaraMessage.error.vtv_in}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="VTD..."
                            type="text"
                            className="form-control"
                            {...register("vtd")}
                        />
                        {jaberselPenyelenggaraMessage && jaberselPenyelenggaraMessage.error && <span className="text-danger">{jaberselPenyelenggaraMessage.error.vtd}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="ARPU PRABAYAR..."
                            type="text"
                            className="form-control"
                            {...register("arpu_prabayar")}
                        />
                        {jaberselPenyelenggaraMessage && jaberselPenyelenggaraMessage.error && <span className="text-danger">{jaberselPenyelenggaraMessage.error.arpu_prabayar}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="ARPU PASCA BAYAR..."
                            type="text"
                            className="form-control"
                            {...register("arpu_pascabayar")}
                        />
                        {jaberselPenyelenggaraMessage && jaberselPenyelenggaraMessage.error && <span className="text-danger">{jaberselPenyelenggaraMessage.error.arpu_pascabayar}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <input
                            placeholder="ARPU Blended..."
                            type="text"
                            className="form-control"
                            {...register("arpu_blended")}
                        />
                        {jaberselPenyelenggaraMessage && jaberselPenyelenggaraMessage.error && <span className="text-danger">{jaberselPenyelenggaraMessage.error.arpu_prabayar}</span>}
                    </Styles.InputForm>
                    <Styles.InputForm>
                        <Controller
                            control={control}
                            name="Tahun"
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={tahun}
                                    placeholder="Tahun.."
                                    onChange={(e) => setInputTahun(e.value)}
                                />
                            )}
                        />
                        {jaberselPenyelenggaraMessage && jaberselPenyelenggaraMessage.error && <span className="text-danger">{jaberselPenyelenggaraMessage.error.tahun}</span>}
                    </Styles.InputForm>
                    <Button color="error" variant="contained" onClick={setupModalAddJaberselPenyelenggara} style={{ marginRight: 5 }}>Close</Button>
                    <Button color="primary" variant="contained" type="submit">Save</Button>
                </form>
            </ReactModal>
        </>
    )
}

export default JaberselPenyelengara

const Tahun = () => {
    const date = new Date();
    const year = date.getFullYear();
    const tahun = [];
    for (let i = year; i >= 1945; i--) {
        tahun.push(i);
    }
    return tahun;
}